import React from "react";
import { ValidationData } from "./ValidationArguments";
import { unpack } from "../../utils/unpack";
import { StringTypeEnum } from "../../utils/commonValidations";

type StringValidationProps = {
  data: ValidationData;
  handleChange: (key: string) => (value: string) => void;
  isSubmitted: boolean;
  inputErr: { [key: string]: string };
  handleOnClick: () => void;
  isOn: boolean;
  isOn2: boolean;
  handleToggle: () => void;
  setIsOn2: (value: boolean) => void;
};

const StringValidation: React.FC<StringValidationProps> = ({
  handleChange,
  data,
  isSubmitted,
  inputErr,
  handleOnClick,
  isOn,
  isOn2,
  handleToggle,
  setIsOn2,
}) => {
  return (
    <div className='flex flex-col gap-8'>
      <div>
        <h1 className='p-4 text-2xl font-bold'>String Validation</h1>
        <p className='px-4'>To add String Validation please pick one of the following options.</p>
      </div>
      <div className='flex flex-col items-center gap-8'>
        <div className='flex flex-col items-center gap-2'>
          <div className='flex items-center gap-4'>
            <label>Validation:</label>
            <select
              onChange={unpack(handleChange("selectType"))}
              value={data.params.selectType}
              className='w-full border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
            >
              <option value='' disabled selected>
                Select an option
              </option>
              {Object.values(StringTypeEnum).map(item => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          {isSubmitted && inputErr && <p className='text-red-600'>{inputErr.selectType}</p>}
        </div>
        {data.params.selectType === "Exact Length" && (
          <div className='flex flex-col items-center gap-8'>
            <div className='flex gap-4'>
              <div>
                <label>
                  Length:{" "}
                  <input
                    onChange={unpack(handleChange("length"))}
                    value={data.params.length || ""}
                    type='text'
                    className='w-full border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
                  />
                </label>
                {inputErr.length && <p className='text-red-600'>{inputErr.length}</p>}
              </div>
            </div>
          </div>
        )}
        {data.params.selectType === "Min Max" && (
          <div className='flex flex-col items-center gap-8'>
            <div className='flex items-center space-x-4'>
              <p className='text-gray-700'>Min Max</p>
              <div
                className={`w-14 h-8 flex items-center bg-sky-500 rounded-full p-1 cursor-pointer transition-all duration-300 ease-in-out`}
                onClick={handleToggle}
              >
                <div
                  className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
                    isOn ? "translate-x-6" : ""
                  }`}
                ></div>
              </div>
              <p className='text-gray-700'>Min | Max</p>
            </div>
            {!isOn ? (
              <div className='flex gap-4'>
                <div>
                  <label>
                    Min:{" "}
                    <input
                      onChange={unpack(handleChange("min"))}
                      value={data.params.min || ""}
                      type='text'
                      className='w-full border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
                    />
                  </label>
                  {inputErr.min && <p className='text-red-600'>{inputErr.min}</p>}
                </div>
                <div>
                  <label>
                    Max:{" "}
                    <input
                      onChange={unpack(handleChange("max"))}
                      value={data.params.max || ""}
                      type='text'
                      className='w-full border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
                    />
                  </label>
                  {inputErr.max && <p className='text-red-600'>{inputErr.max}</p>}
                </div>
              </div>
            ) : (
              <>
                <div className='flex items-center space-x-4'>
                  <p className='text-gray-700'>Min</p>
                  <div
                    className={`w-14 h-8 flex items-center bg-sky-500 rounded-full p-1 cursor-pointer transition-all duration-300 ease-in-out 
                  
                `}
                    onClick={() => setIsOn2(!isOn2)}
                  >
                    <div
                      className={`bg-white w-6 h-6 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
                        isOn2 ? "translate-x-6" : ""
                      }`}
                    ></div>
                  </div>
                  <p className='text-gray-700'>Max</p>
                </div>
                {
                  <div>
                    <label>
                      {!isOn2 ? "Min:" : "Max:"}
                      <input
                        onChange={unpack(handleChange(`${!isOn2 ? "min" : "max"}`))}
                        value={!isOn2 ? data.params.min || "" : data.params.max || ""}
                        type='text'
                        className='w-full border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
                      />
                    </label>
                    {!isOn2
                      ? inputErr.min && <p className='text-red-600'>{inputErr.min}</p>
                      : inputErr.max && <p className='text-red-600'>{inputErr.max}</p>}
                  </div>
                }
              </>
            )}
          </div>
        )}
        <button
          onClick={handleOnClick}
          className='w-1/2 border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500 hover:bg-sky-500 hover:text-white transition-colors duration-300'
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default StringValidation;
