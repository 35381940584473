import React, { useCallback, useEffect, useReducer } from "react";
import { Credentials, login } from "../../http/auth";
import { unpack } from "../../utils/unpack";
import classNames from "classnames";
import useAsyncFn from "../../utils/useAsyncFn";
import { useNavigate } from "react-router-dom";

export const HomePage = () => {
  const { exec: doLogin, data, loading } = useAsyncFn(login);

  const navigate = useNavigate();

  const [errors, setErrors] = useReducer(
    (p: Record<keyof Credentials, string | null>, c: Partial<Record<keyof Credentials, string | null>>) => ({
      ...p,
      ...c,
    }),
    { password: null, username: null },
  );

  const [creds, setCreds] = useReducer(
    (p: Credentials, c: Partial<Credentials>) => {
      if ("password" in c) {
        setErrors({
          password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?-])[A-Za-z\d@$!%*?-]{8,}$/.test(c.password || "")
            ? null
            : "Password must be a strong password",
        });
      } else {
        setErrors({ username: /^\S+@\S+\.\S+$/.test(c.username || "") ? null : "Username must be an email" });
      }
      return { ...p, ...c };
    },
    {
      password: "",
      username: "",
    },
  );

  const change = useCallback((k: keyof Credentials) => (v: string) => setCreds({ [k]: v }), []);

  const submit = useCallback(() => {
    if (!Object.entries(creds).some(([k, v]) => !v.trim() || errors[k as keyof Credentials])) {
      doLogin(creds);
    }
  }, [creds, doLogin, errors]);

  useEffect(() => {
    if (data?.ok) {
      localStorage.setItem("__token", data.data.token);
      navigate("/questionnaire");
    }
  }, [data, navigate]);

  if (loading) {
    return (
      <div className={"h-screen w-screen flex justify-center items-center"}>
        <div className={"max-w-[720px] m-4 rounded border bg-white p-6 flex flex-col gap-10 items-center"}>
          Authorizing...
        </div>
      </div>
    );
  }
  return (
    <div className={"h-screen w-screen flex justify-center items-center"}>
      <div className={"max-w-[720px] m-4 rounded border bg-white p-6 flex flex-col gap-10 items-center w-full"}>
        <h1 className='text-center text-sky-600 text-3xl'>Questionnaire Management</h1>
        <div className={"flex flex-col gap-3 w-full max-w-[520px]"}>
          <div>
            <label className='block text-gray-700 font-medium'>Username:</label>
            <input
              onChange={unpack(change("username"))}
              value={creds.username}
              className={classNames("w-full border rounded-md px-3 py-2 focus:outline-none", {
                "focus:border-sky-500 border-gray-300 ": !errors.username,
                "focus:border-red-500 border-red-500 ": errors.username,
              })}
            />
            {errors.username && <span className={"text-red-500"}>{errors.username}</span>}
          </div>
          <div>
            <label className='block text-gray-700 font-medium'>Password:</label>
            <input
              type={"password"}
              onChange={unpack(change("password"))}
              value={creds.password}
              className={classNames("w-full border rounded-md px-3 py-2 focus:outline-none focus:border-sky-500", {
                "focus:border-sky-500 border-gray-300 ": !errors.password,
                "focus:border-red-500 border-red-500 ": errors.password,
              })}
            />
            {errors.password && <span className={"text-red-500"}>{errors.password}</span>}
          </div>
        </div>
        <div className={"flex flex-col gap-3 w-full max-w-[520px] items-center"}>
          {data?.ok === false && <span className={"text-red-500 bg-red-100 p-2 rounded"}>{data.error}</span>}

          <button className={"w-32 py-1 bg-sky-600 text-white rounded-xl shadow"} onClick={submit}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
};
