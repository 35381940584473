export const chubbClassCodes = {
  "4": [
    "Certified accountants' (CPAs) offices",
    "Certified public accountants' (CPAs) offices",
    "Certified public accountants' (CPAs) private practices",
  ],
  "6": [
    "Advertising agencies",
    "Advertising agency consulting services",
    "Digital advertising agencies",
    "Internet advertising agencies",
    "Online advertising agencies",
  ],
  "9": [
    "Air-conditioning compressors wholesalers",
    "Air-conditioning condensing units wholesalers",
    "Air-conditioning equipment wholesalers",
    "Automotive air-conditioners wholesalers",
    "Cooling equipment and supplies wholesalers",
  ],
  "10": ["Animal hospitals"],
  "11": [
    "Emergency telephone dispatch services",
    "Local call centers",
    "Telephone answering services",
    "Telephone call forwarding services",
    "Telephone message answering services",
    "Voice mailbox services",
    "Wakeup call services",
  ],
  "12": ["Antique shops"],
  "16": [
    "Audio equipment wholesalers",
    "Automotive radios wholesalers",
    "Clothes dryers wholesalers",
    "Coffee makers wholesalers",
    "Compact disc players wholesalers",
    "Consumer electronics wholesalers",
    "Curling irons wholesalers",
    "Digital video disc (DVD) players wholesalers",
    "Dishwashers wholesalers",
    "Electric blankets wholesalers",
    "Electric insect control devices wholesalers",
    "Electric razors wholesalers",
    "Electric toasters wholesalers",
    "Electric toothbrushes wholesalers",
    "Fans wholesalers",
    "Freezers wholesalers",
    "Garbage disposal units wholesalers",
    "Hair dryers wholesalers",
    "Home movie apparatus wholesalers",
    "Household appliances wholesalers",
    "Household cooking equipment wholesalers",
    "Household laundry equipment wholesalers",
    "Household-type appliances wholesalers",
    "Irons wholesalers",
    "Kitchen appliances wholesalers",
    "Laundry machinery and equipment wholesalers",
    "Microwave ovens wholesalers",
    "Outdoor grills wholesalers",
    "Ovens wholesalers",
    "Personal care appliances wholesalers",
    "Personal hair dryers wholesalers",
    "Portable electric heaters wholesalers",
    "Portable humidifiers and dehumidifiers wholesalers",
    "Radios wholesalers",
    "Ranges wholesalers",
    "Recorders wholesalers",
    "Refrigerators wholesalers",
    "Room air-conditioners wholesalers",
    "Sewing machines wholesalers",
    "Smoke detectors wholesalers",
    "Sound equipment wholesalers",
    "Speaker systems wholesalers",
    "Stereo equipment wholesalers",
    "Stoves wholesalers",
    "Tape players and recorders wholesalers",
    "Telephone answering machines wholesalers",
    "Television sets wholesalers",
    "Trail video cameras wholesalers",
    "Trash compactors wholesalers",
    "Vacuum cleaners wholesalers",
    "Washing machines wholesalers",
  ],
  "17": ["Household appliance stores"],
  "18": [
    "Photography supply stores",
    "Radio and television stores",
    "Television and radio stores",
    "TV (television) stores",
    "Video game software stores",
    "Video tape stores",
  ],
  "20": ["Real estate appraisal services", "Real estate appraisers' offices"],
  "24": [
    "Arboreta",
    "Botanical conservatories",
    "Botanical gardens",
    "Zoological gardens",
    "Zoological or botanical gardens",
  ],
  "25": [
    "Architects' equipment and supplies wholesalers",
    "Drafting instruments wholesalers",
    "Drafting tables wholesalers",
    "Engineers' equipment and supplies wholesalers",
  ],
  "26": [
    "Architects' offices",
    "Architects' private practices",
    "Architectural construction specifications consulting services",
    "Architectural consultants' offices",
    "Architectural design services",
    "Architectural services",
    "Building architectural design services",
  ],
  "30": ["Art auctions", "Art dealers", "Art galleries retailing art"],
  "32": ["Commercial art studios"],
  "33": ["Retail art galleries"],
  "34": ["Alumni associations", "Alumni clubs"],
  "35": [
    "Agricultural organizations",
    "Animal breeders' associations",
    "Bankers' associations",
    "Better business bureaus",
    "Boards of trade",
    "Business associations",
    "Chambers of commerce",
    "Construction associations",
    "Contractors' associations",
    "Distributors' associations",
    "Farmers' associations",
    "Farmers' unions",
    "Growers' associations",
    "Hospital associations",
    "Industrial associations",
    "Insurers' associations",
    "Junior chambers of commerce",
    "Manufacturers' associations",
    "Merchants' associations",
    "Mining associations",
    "Producers' associations",
    "Public utility associations",
    "Real estate boards",
    "Restaurant associations",
    "Retailers' associations",
    "Service industries associations",
    "Shipping companies' associations",
    "Warehousing associations",
    "Wholesalers' associations",
  ],
  "36": [
    "Civic and fraternal social organizations",
    "Civic associations",
    "Civic or social membership associations",
    "Ethnic associations",
    "Girl guiding organizations",
    "Parent-teachers' associations",
    "Scouting organizations",
    "Social or civic fraternal associations or lodges",
    "Social retirement associations",
    "Social senior citizens' associations",
    "Students' associations",
    "Students' unions",
    "Veterans'  membership organizations",
    "Youth farming organizations",
    "Youth scouting organizations",
  ],
  "37": [
    "Accountants' associations",
    "Architects' associations",
    "Bar associations",
    "Consultants' associations",
    "Dentists' associations",
    "Dietitians' associations",
    "Educators' associations",
    "Engineers' associations",
    "Health professionals' associations",
    "Hospital administrators' associations",
    "Learned societies",
    "Medical associations",
    "Nurses' associations",
    "Occupational therapists' associations",
    "Optometrists' associations",
    "Peer review boards",
    "Personnel management associations",
    "Pharmacists' associations",
    "Professional associations",
    "Professional membership associations",
    "Professional standards review boards",
    "Professional standards review committees",
    "Psychologists' associations",
    "Scientific associations",
    "Social workers' associations",
  ],
  "38": ["Trade associations"],
  "41": ["Auditing services accounts", "Auditors' accounts offices", "Auditors' private practices"],
  "42": [
    "Auto body shop supplies wholesalers",
    "Automobile accessories wholesalers",
    "Automobile glass wholesalers",
    "Automobile service station equipment wholesalers",
    "Automotive aftermarket parts wholesalers",
    "Automotive batteries wholesalers",
    "Automotive brakes wholesalers",
    "Automotive electrical testing and measuring equipment wholesalers",
    "Automotive glass wholesalers",
    "Automotive hoists wholesalers",
    "Automotive mirrors wholesalers",
    "Automotive oil filters wholesalers",
    "Automotive pistons and valves wholesalers",
    "Automotive pumps wholesalers",
    "Automotive seat covers wholesalers",
    "Automotive stampings wholesalers",
    "Electric motor vehicle instruments wholesalers",
    "Exhaust mufflers wholesalers",
    "Gasoline marketing equipment wholesalers",
    "Gasoline measuring and dispensing pumps wholesalers",
    "Gasoline service station equipment wholesalers",
    "Mechanic's tools wholesalers",
    "Motor vehicle balancing equipment wholesalers",
    "Motor vehicle engine testing equipment wholesalers",
    "Motor vehicle hardware wholesalers",
    "Motor vehicle mechanics' hand tools wholesalers",
    "Motor vehicle radiators wholesalers",
    "Motor vehicle seat belts wholesalers",
    "Motor vehicle tools and equipment wholesalers",
    "Motor vehicle voltage regulators wholesalers",
    "New automotive engines and parts wholesalers",
    "New automotive parts wholesalers",
    "New motor vehicle generators wholesalers",
    "New motor vehicle parts and accessories wholesalers",
    "New motor vehicle parts wholesalers",
    "New motor vehicle wheels wholesalers",
    "New motorcycle parts wholesalers",
    "New trailer parts wholesalers",
    "New truck parts wholesalers",
    "Service station shop equipment wholesalers",
  ],
  "43": [
    "Automotive audio equipment stores",
    "Automotive battery dealers",
    "Automotive parts and accessory dealers",
    "Automotive parts and accessory stores",
    "Automotive stereo stores",
    "Truck cap stores",
  ],
  "44": ["Background check services"],
  "46": ["Bakeries with baking on the premises"],
  "47": ["Balloon shops", "Party supply stores"],
  "48": [
    "Barber shop equipment and supplies wholesalers",
    "Beauty and barber shop hydraulic chairs wholesalers",
    "Beauty parlor equipment and supplies wholesalers",
    "Manicurists supplies wholesalers",
    "Massage equipment wholesalers",
    "Professional hair preparations wholesalers",
  ],
  "49": ["Barber shops"],
  "50": ["Beauty supply stores", "Cosmetics stores", "Perfume stores"],
  "51": [
    "Beautician services",
    "Beauty and barber shops, combined",
    "Beauty parlors",
    "Beauty salons",
    "Beauty shops",
    "Hair stylist salons or shops ",
    "Hair stylist services ",
    "Hair stylist shops",
    "Hairdresser services",
    "Hairdressing salons or shops ",
  ],
  "52": ["Bath shops"],
  "53": ["Bottled soft drink stores", "Bottled water providers (direct sales)", "Bottled water stores"],
  "54": ["Duty free liquor shops", "Packaged beer stores", "Packaged liquor stores", "Packaged wine shops"],
  "55": ["Beverage bases wholesalers", "Carbonated beverages wholesalers", "Soft drinks wholesalers"],
  "56": ["Non-motorized bicycle shops"],
  "57": ["Medical coding services combined with accounting services"],
  "58": ["Billing services"],
  "59": [
    "Almanac binding without printing",
    "Bookbinding",
    "Bookbinding shops",
    "Dictionary binding without printing",
    "Fiction bookbinding",
    "Pamphlet binding without printing",
    "Professional bookbinding",
    "Religious bookbinding",
    "School textbooks binding without printing",
    "Trade binding services",
  ],
  "60": ["Bookkeepers' offices", "Bookkeepers' private practices", "Bookkeeping services"],
  "62": [
    "Book stores",
    "Comic book stores",
    "Home delivery newspaper routes (direct sales)",
    "Magazine stands (permanent structure)",
    "Mail-order new book clubs (non publisher)",
    "News dealers",
    "Religious book stores",
  ],
  "63": [
    "Antique book wholesalers",
    "Atlas wholesalers",
    "Book wholesalers",
    "Brochure wholesalers",
    "Magazine wholesalers",
    "Map (except globe, school, wall) wholesalers",
    "Newspaper agency wholesalers",
    "Newspaper wholesalers",
    "Pamphlet wholesalers",
    "Periodical wholesalers",
    "Textbook wholesalers",
  ],
  "65": ["Copper and copper alloy primary forms wholesalers"],
  "66": ["Curio shops"],
  "67": ["Non-custom bridal gown shops"],
  "68": [
    "Burial caskets wholesalers",
    "Coffins wholesalers",
    "Funeral home supplies wholesalers",
    "Undertakers' equipment and supplies wholesalers",
  ],
  "70": [
    "Baked ham stores",
    "Butcher shops",
    "Delicatessens (except grocery stores, restaurants)",
    "Direct selling locker meat provisioners",
    "Frozen meat stores",
    "Meat markets",
    "Poultry dealers",
  ],
  "72": ["Cake decorating supply stores"],
  "73": [
    "Camcorders wholesalers",
    "Camera film wholesalers",
    "Charged coupled devices (CCD) wholesalers",
    "Film developing equipment wholesalers",
    "Film finishing equipment wholesalers",
    "Identity recorders wholesalers",
    "Motion picture cameras, equipment, and supplies wholesalers",
    "Photofinishing equipment wholesalers",
    "Photographic camera equipment and supplies wholesalers",
    "Photographic developing apparatus wholesalers",
    "Photographic equipment and supplies wholesalers",
    "Photographic film and plates wholesalers",
    "Photographic film wholesalers",
    "Photographic printmaking apparatus wholesalers",
    "Photographic processing and finishing equipment wholesalers",
    "Photographic projection equipment (e.g., motion picture, slide) wholesalers",
    "Television cameras wholesalers",
    "Theater equipment wholesalers",
    "Video cameras (except household-type) wholesalers",
    "Video cameras wholesalers",
    "Wearable video cameras wholesalers",
  ],
  "74": ["Candle shops"],
  "75": ["Candle wholesalers"],
  "76": ["Packaged candy retail stores", "Packaged confectionery retail stores"],
  "77": [
    "Baking chocolate made from purchased chocolate",
    "Chocolate candy stores",
    "Chocolate (coating, instant, liquor, syrups) made from purchased chocolate",
    "Chocolate coatings and syrups made from purchased chocolate",
    "Chocolate covered candy bars made from purchased chocolate",
    "Chocolate covered granola bars made from purchased chocolate",
    "Chocolate covered nuts made from purchased chocolate",
    "Chocolate fudge made from purchased chocolate",
    "Chocolate granola bars and clusters made from purchased chocolate",
    "Chocolate liquor made from purchased chocolate",
    "Chocolate syrup made from purchased chocolate",
    "Cocoa powdered mixes made from purchased chocolate",
    "Drik cocoa powdered mixes made from purchased chocolate",
  ],
  "84": [
    "Carpet and rug cleaning plants",
    "Carpet and rug cleaning services",
    "Carpet cleaning plants",
    "Carpet cleaning services",
    "Cleaning carpets",
    "Cleaning rugs",
    "Furniture cleaning services",
    "Rug cleaning plants",
    "Rug cleaning services",
    "Upholstery cleaning services",
  ],
  "90": ["Mobile phone accessories stores"],
  "91": ["Cemeteries", "Cemetery associations", "Cemetery management services", "Cemetery subdividers"],
  "92": ["Columbariums"],
  "93": ["Community foundations", "Grantmaking foundations"],
  "97": ["Chinaware stores", "Glassware stores"],
  "98": [
    "Chinaware wholesalers",
    "Dishes wholesalers",
    "Glassware wholesalers",
    "Hollowware wholesalers",
    "Tableware wholesalers",
  ],
  "99": ["Christmas stores", "Seasonal and holiday decoration stores"],
  "101": [
    "Church supplies wholesalers",
    "Religious statuary wholesalers",
    "Religious supplies wholesalers",
    "Religious vestments wholesalers",
  ],
  "103": ["Clock shops"],
  "104": ["Women's and girls' clothing and apparel stores", "Women's and girls' furnishings stores"],
  "106": [
    "Clothing stores",
    "Coat stores",
    "Family clothing stores",
    "Fur apparel stores",
    "Furriers",
    "Leather coat stores",
    "Sock shops",
    "Western wear stores",
  ],
  "107": ["Hat and cap stores"],
  "108": ["Non-uniform sports apparel stores", "Swimwear stores"],
  "109": ["Men's and boys' clothing and apparel stores", "Men's and boys' furnishings stores"],
  "110": ["Apparel accessory stores", "Clothing accessories stores", "Costume jewelry stores", "Handbag stores"],
  "111": ["Costume stores"],
  "115": ["Baby clothing shops", "Children and infant clothing and apparel stores"],
  "116": ["Non-athletic uniform stores", "School uniform stores"],
  "118": ["Hosiery stores", "Lingerie stores"],
  "119": ["Maternity shops"],
  "120": [
    "Bridal wear rental",
    "Clothing rental",
    "Costume rental",
    "Dress suit rental",
    "Formal wear rental",
    "Fur rental",
    "Gown rental",
    "Graduation cap and gown rental",
    "Motion picture wardrobe and costume rental",
    "Suit rental",
    "Theatrical wardrobe and costume rental",
    "Tuxedo rental",
    "Wardrobe rental",
  ],
  "122": ["T-shirt shops"],
  "123": ["Neckwear stores", "Tie shops"],
  "124": [
    "Automobile clubs",
    "Book discussion clubs",
    "Booster clubs",
    "Classic car clubs",
    "Computer enthusiasts clubs",
    "Fan clubs",
    "Farm granges",
    "Garden clubs",
    "Golden age clubs",
    "Historical clubs",
    "Poetry clubs",
    "Public speaking improvement clubs",
    "Singing societies",
    "Social clubs",
    "Speakers' clubs",
    "Writing clubs",
  ],
  "125": [
    "Boy guiding organizations",
    "Fraternal lodges",
    "Fraternal organizations",
    "Fraternities",
    "Granges",
    "Sororities",
    "Student clubs",
    "University clubs",
    "Women's auxiliaries",
    "Women's clubs",
    "Youth civic clubs",
    "Youth clubs",
    "Youth social clubs",
  ],
  "128": ["Packaged coffee and tea stores"],
  "129": ["Coffee wholesalers", "Tea wholesalers"],
  "131": ["Collectible gift shops", "Novelty shops"],
  "133": [
    "Commercial condominium managers' offices",
    "Commercial property managing",
    "Commercial real estate managers' offices",
    "Commercial real estate property managers' offices",
    "Commercial real estate property managing",
    "Managing commercial condominiums",
    "Managing commercial real estate",
    "Nonresidential property managing",
    "Nonresidential real estate managers' offices",
    "Nonresidential real estate property managers' offices",
    "Nonresidential real estate property managing",
  ],
  "134": [
    "Brokerages, commodity contracts",
    "Commodity contract pool operators",
    "Commodity contract trading companies",
    "Commodity contracts brokerages",
    "Commodity contracts brokers' offices",
    "Commodity contracts dealing ",
    "Commodity contracts floor brokers",
    "Commodity contracts floor traders ",
    "Commodity contracts floor trading ",
    "Commodity contracts options brokerages",
    "Commodity contracts options dealing ",
    "Commodity contracts traders ",
    "Commodity futures brokerages",
    "Financial futures brokerages",
    "Foreign currency exchange brokering services",
    "Foreign currency exchange dealing ",
    "Foreign currency exchange services",
    "Futures commodity contracts brokerages",
    "Futures commodity contracts brokers' offices",
    "Futures commodity contracts dealing ",
    "Trading companies, commodity contracts",
    "Virtual currency (cryptocurrency) brokering",
    "Virtual currency (cryptocurrency) dealing",
    "Virtual currency exchange services",
  ],
  "137": [
    "Antennas wholesalers",
    "Audio amplifiers wholesalers",
    "Audio and video blank tapes wholesalers",
    "Audio and video teleconferencing equipment wholesalers",
    "Automatic call distributors wholesalers",
    "Bipolar transistors wholesalers",
    "Blank audiotapes wholesalers",
    "Blank CDs and DVDs wholesalers",
    "Blank diskettes wholesalers",
    "Blank magnetic tapes, cassettes, and disks wholesalers",
    "Blank video tapes wholesalers",
    "Broadcasting equipment wholesalers",
    "Cellular telephones wholesalers",
    "Circuit boards wholesalers",
    "Citizens' band (CB) radios wholesalers",
    "Communications equipment wholesalers",
    "Computer chips wholesalers",
    "Diodes wholesalers",
    "Electronic aircraft instruments wholesalers",
    "Electronic capacitors wholesalers",
    "Electronic carbon monoxide detectors wholesalers",
    "Electronic coils wholesalers",
    "Electronic communications equipment wholesalers",
    "Electronic condensers wholesalers",
    "Electronic connectors wholesalers",
    "Electronic natural gas detectors wholesalers",
    "Electronic navigational instruments wholesalers",
    "Electronic parts wholesalers",
    "Electronic rectifiers wholesalers",
    "Electronic resistors wholesalers",
    "Electronic sound equipment wholesalers",
    "Electronic switches wholesalers",
    "Electronic transformers wholesalers",
    "Electronic tubes wholesalers",
    "Facsimile machines wholesalers",
    "Integrated circuits wholesalers",
    "Klystron tubes wholesalers",
    "Liquid crystal displays wholesalers",
    "Magnetic bubble memories wholesalers",
    "Modems wholesalers",
    "Printed circuit boards wholesalers",
    "Public address systems and equipment wholesalers",
    "Radar equipment wholesalers",
    "Radio frequency identification (RFID) equipment wholesalers",
    "Radio parts and accessories wholesalers",
    "Random access memory (RAM) chips wholesalers",
    "Routers wholesalers",
    "Semiconductor devices wholesalers",
    "Smartphones wholesalers",
    "Smartwatches wholesalers",
    "Solar cells wholesalers",
    "Sonar equipment wholesalers",
    "Telecommunications equipment wholesalers",
    "Telegraph equipment wholesalers",
    "Telephone equipment wholesalers",
    "Telephones wholesalers",
    "Touch-screen displays wholesalers",
    "Transistors wholesalers",
    "Transmitters wholesalers",
    "Unloaded computer boards wholesalers",
  ],
  "140": ["Insurance actuarial services"],
  "142": ["Farm management services"],
  "144": [
    "Lobbying services",
    "Lobbyists' offices",
    "Political consulting services",
    "Public relations agencies",
    "Public relations consulting services",
    "Public relations services",
  ],
  "146": [
    "Bible schools",
    "Bridge and other card game instruction",
    "Charm schools",
    "Chess instruction",
    "Diction schools",
  ],
  "147": ["Wedding planning services"],
  "150": [
    "Aerial forest firefighting",
    "Aerial forest mulching or seeding",
    "Aerial wildland firefighting",
    "Cruising timber",
    "Estimating timber",
    "Forest fire prevention",
    "Forest firefighting",
    "Forest management plans preparation",
    "Forest thinning",
    "Forestry pest control services",
    "Forestry services",
    "Reforestation",
    "Timber valuation",
  ],
  "151": [
    "Commercial art services",
    "Commercial illustration services",
    "Corporate identification design services",
    "Graphic art and related design services",
    "Graphic art services",
    "Graphic design services",
    "Independent commercial artists",
    "Independent commercial illustrators",
    "Independent graphic artists",
    "Independent medical artists",
    "Medical art services",
    "Medical illustration services",
    "Silk screen design services",
    "Visual communication design services",
  ],
  "154": [
    "Decoration services for special events",
    "Interior decorating consulting services",
    "Interior design consulting services",
  ],
  "157": ["Site selection consulting services"],
  "162": [
    "Firearms training",
    "Personal development schools",
    "Pilates instruction, camps or schools",
    "Public speaking training",
    "Self defense instruction",
    "Speed reading instruction",
    "Survival training instruction",
    "Yoga instruction, camps or schools",
  ],
  "163": [
    "CPR (cardiopulmonary resuscitation) training and certification",
    "First-aid instruction",
    "Life guard training",
  ],
  "166": [
    "Administrative management consulting services",
    "Business management consulting services",
    "Business start-up consulting services",
    "General management consulting services",
    "Medical office management consulting services or consultants",
    "Records management consulting services",
    "Reorganizational consulting services",
    "Strategic planning consulting services",
  ],
  "175": [
    "Copy centers",
    "Copy shops",
    "Document copying services",
    "Document duplicating services",
    "Photocopying services",
    "Reprographic services",
  ],
  "178": ["Crematories", "Memorial gardens"],
  "180": ["Packaged curtain and drapery stores", "Window treatment stores"],
  "181": [
    "Curtains wholesalers",
    "Draperies wholesalers",
    "Window blinds and shades wholesalers",
    "Window shades and blinds wholesalers",
  ],
  "182": ["Blades wholesalers", "Cutlery wholesalers", "Knives wholesalers"],
  "183": ["Dairy product stores"],
  "185": [
    "Bridges custom made in dental laboratories",
    "Dental laboratories",
    "Dentures custom made in dental laboratories",
    "Mouth guards and protectors custom made in dental laboratories",
    "Orthodontic appliance custom made in dental laboratories",
    "Teeth custom made in dental laboratories",
  ],
  "186": [
    "Dental surgeons' offices",
    "Dentists' offices",
    "Doctors of dental medicine offices",
    "Doctors of dental surgery offices",
    "Endodontists' offices",
    "Family dentists' offices",
    "Oral and maxillofacial surgeons' offices",
    "Oral pathologists' offices",
    "Orthodontists' offices",
    "Pathologists', oral, offices",
    "Periodontists' offices",
    "Prosthodontists' offices",
  ],
  "189": [
    "Apron supply services",
    "Bed linen supply services",
    "Coat supply services",
    "Cooperative hospital laundries",
    "Diaper supply services",
    "Gown supply services",
    "Linen and uniform supply laundries",
    "Linen supply laundry services",
    "Linen supply services",
    "Table linen supply services",
    "Towel supply services",
    "Uniform supply services",
  ],
  "190": [
    "Advertising material preparation services for mailing or other direct distribution",
    "Direct mail advertising services",
    "Direct mail or other direct distribution advertising campaign services",
  ],
  "192": [
    "Desktop publishing services",
    "Dictation services",
    "Document preparation services",
    "Document transcription services",
    "Editing services",
    "Letter writing services",
    "Proofreading services",
    "Radio transcription services",
    "Resume writing services",
    "Secretarial services",
    "Stenographic services",
    "Technical editing services",
    "Transcription services",
    "Typing services",
    "Word processing services",
  ],
  "195": [
    "skyprint drafting services",
    "Computer-aided design drafting (CADD) services",
    "Drafting services",
    "Draftsmen's offices",
  ],
  "198": [
    "Apothecaries",
    "Drug stores",
    "Off-site mail-order institutional pharmacies",
    "Online institutional pharmacies",
    "On-site institutional pharmacies",
    "Pharmacies",
  ],
  "201": [
    "Apparel pressing services",
    "Cleaners, drycleaning and laundry service",
    "Cleaning and dyeing plants",
    "Curtain cleaning services",
    "Drapery cleaning services",
    "Drop-off and pick-up sites for laundries and drycleaners",
    "Drycleaner drop-off and pick-up sites",
    "Drycleaners",
    "Drycleaning services",
    "Family power laundries",
    "Fur garment cleaning services",
    "Garment cleaning services",
    "Hand laundries",
    "Hat cleaning services",
    "Laundries",
    "Laundry and drycleaning agents",
    "Laundry and drycleaning collecting and distributing agents",
    "Laundry drop-off and pick-up sites",
    "Laundry services",
    "Leather garment cleaning services",
    "Pick-up and drop-off sites for drycleaners and laundries",
    "Pillow cleaning services",
    "Suede garment cleaning services",
  ],
  "202": ["Drycleaning plants"],
  "203": [
    "Commercial laundry machinery, equipment, and supplies wholesalers",
    "Drycleaning equipment and supplies wholesalers",
  ],
  "211": ["School supply stores"],
  "213": [
    "Batteries wholesalers",
    "Bus bars and trolley ducts wholesalers",
    "Capacitors wholesalers",
    "Circuit breakers wholesalers",
    "Coaxial cable wholesalers",
    "Current-carrying wiring devices wholesalers",
    "Electric alarm apparatus wholesalers",
    "Electric fuses wholesalers",
    "Electric measuring instruments wholesalers",
    "Electric motor controls wholesalers",
    "Electric motors wholesalers",
    "Electric power distribution panel boards wholesalers",
    "Electric prime movers wholesalers",
    "Electric wire and cable conduit wholesalers",
    "Electrical apparatus wholesalers",
    "Electrical boxes and fittings wholesalers",
    "Electrical conduits and raceways wholesalers",
    "Electrical connectors wholesalers",
    "Electrical construction materials wholesalers",
    "Electrical distribution equipment wholesalers",
    "Electrical distribution switchboards wholesalers",
    "Electrical fastening devices wholesalers",
    "Electrical fittings wholesalers",
    "Electrical generators wholesalers",
    "Electrical hanging devices wholesalers",
    "Electrical industrial controls wholesalers",
    "Electrical insulators wholesalers",
    "Electrical lugs and connectors wholesalers",
    "Electrical meters wholesalers",
    "Electrical power transmission equipment wholesalers",
    "Electrical receptacles wholesalers",
    "Electrical switches wholesalers",
    "Electrical transmission equipment wholesalers",
    "Electrical unit substations wholesalers",
    "Insulated wire or cable wholesalers",
    "Insulated wire wholesalers",
    "Pole line hardware wholesalers",
    "Relays wholesalers",
    "Security systems wholesalers",
    "Signal systems and devices wholesalers",
    "Storage batteries wholesalers",
    "Transformers wholesalers",
    "Transmission pole and line hardware wholesalers",
    "Voltage regulators wholesalers",
    "Wiring supplies wholesalers",
  ],
  "214": ["Electric lamp shops"],
  "217": ["Consumer electronic stores (e.g., televisions, computers, cameras)", "Electronic part and component stores"],
  "218": [
    "Art needlework contractors on apparel",
    "Art needlework on clothing for the trade",
    "Decorative and novelty stitching on textile articles and apparel",
    "Embroidering contractors on apparel",
    "Embroidering on textile products or apparel for the trade",
    "Needlework art contractors on apparel",
    "Novelty stitching contractors on apparel",
  ],
  "219": [
    "Babysitting bureaus",
    "Casting agencies",
    "Casting bureaus",
    "Chauffeur registries",
    "Employee recruitment services",
    "Employment referral agencies or services",
    "Employment agencies",
    "Employment placement agencies or services",
    "Employment registries",
    "Internet job listing services",
    "Internet resume listing services",
    "Maid registries",
    "Model registries",
    "Motion picture or video casting agencies",
    "Motion picture or video casting bureaus",
    "Motion picture or video employment agencies",
    "Nurse registries",
    "Online employment registries or listing services",
    "Radio or television employment agencies",
    "Ship crew employment agencies",
    "Ship crew registries",
    "State operated employment job services offices",
    "Teacher registries",
    "Television employment agencies",
    "Theatrical casting agencies",
    "Theatrical casting bureaus",
    "Theatrical employment agencies",
  ],
  "221": [
    "Acoustical engineering consulting services",
    "Acoustical system engineering design services",
    "Audio visual system engineering design services",
    "Boat engineering design services",
    "Chemical engineering services",
    "Civil engineering services",
    "Combustion engineering consulting services",
    "Construction engineering services",
    "Consulting engineers' offices",
    "Consulting engineers' private practices",
    "Electrical engineering services",
    "Engineering consulting services",
    "Engineering design services",
    "Engineering services",
    "Engineers' offices",
    "Engineers' private practices",
    "Environmental engineering services",
    "Erosion control engineering services",
    "Geological engineering services",
    "Geophysical engineering services",
    "Heating engineering consulting services",
    "Industrial engineering services",
    "Logging engineering services",
    "Marine engineering services",
    "Maritime technology engineering services",
    "Mechanical engineering services",
    "Mining engineering services",
    "Petroleum engineering services",
    "Robotics automation engineering services",
    "Robotics engineering design and integration services",
    "Traffic engineering consulting services",
  ],
  "223": ["Engraving metals and metal products for the trade"],
  "224": [
    "Accounting machines wholesalers",
    "Addressing machines wholesalers",
    "Automated teller machines (ATM) wholesalers",
    "Bank equipment wholesalers",
    "skyprinting equipment wholesalers",
    "Bookkeeping machines wholesalers",
    "Business machines and equipment wholesalers",
    "Calculators and calculating machines wholesalers",
    "Cash registers wholesalers",
    "Check handling machines wholesalers",
    "Coin sorting machines wholesalers",
    "Copying machines wholesalers",
    "Currency handling machines wholesalers",
    "Dictating machines wholesalers",
    "Forms handling machines wholesalers",
    "Labeling machines wholesalers",
    "Letter and envelope handling machines wholesalers",
    "Mailing machines wholesalers",
    "Microfilm equipment and supplies wholesalers",
    "Office equipment wholesalers",
    "Office machines wholesalers",
    "Paper shredders wholesalers",
    "Perforating machines wholesalers",
    "Point of sale terminals wholesalers",
    "Postage meters wholesalers",
    "Security safes wholesalers",
    "Stapling machines wholesalers",
    "Tape dispensing machines wholesalers",
    "Time recording machines wholesalers",
    "Typewriters wholesalers",
  ],
  "225": [
    "Balances and scales wholesalers",
    "Bar equipment wholesalers",
    "Coin- or card-operated merchandising machine wholesalers",
    "Coin- or card-operated merchandising machines wholesalers",
    "Commercial chinaware wholesalers",
    "Commercial coffee makers wholesalers",
    "Commercial cooking equipment wholesalers",
    "Commercial cups wholesalers",
    "Commercial dishes wholesalers",
    "Commercial dishwashing equipment wholesalers",
    "Commercial food service equipment wholesalers",
    "Commercial garbage disposal units wholesalers",
    "Commercial kitchen appliances wholesalers",
    "Commercial kitchen utensils wholesalers",
    "Commercial microwave ovens wholesalers",
    "Commercial ovens wholesalers",
    "Commercial pans wholesalers",
    "Commercial shelving wholesalers",
    "Commercial woodenware wholesalers",
    "Display cases wholesalers",
    "Electrical signs wholesalers",
    "Hotel equipment and supplies wholesalers",
    "Lockers wholesalers",
    "Mannequins wholesalers",
    "Partitions wholesalers",
    "Restaurant equipment wholesalers",
    "Showcases wholesalers",
    "Soda fountain fixtures wholesalers",
    "Store equipment wholesalers",
    "Store fixtures wholesalers",
    "Vending machines wholesalers",
    "Window display equipment wholesalers",
  ],
  "227": ["Fabric shops"],
  "229": ["Fasteners wholesalers"],
  "230": ["Fencing dealers"],
  "233": ["Wood fencing wholesalers"],
  "234": ["Film developing and printing"],
  "235": [
    "Agencies of foreign banks",
    "Agreement corporations",
    "Agricultural credit institutions making loans or extending credit",
    "Agricultural lending",
    "Car title lending",
    "Commodity Credit Corporation",
    "Edge Act corporations",
    "Export trading companies",
    "Export-Import banks",
    "Factoring accounts receivable",
    "Federal Agricultural Mortgage Corporation",
    "Federal Home Loan Banks (FHLB)",
    "Federal Home Loan Mortgage Corporation (FHLMC)",
    "Federal Intermediate Credit Bank",
    "Federal National Mortgage Association (FNMA)",
    "FHLMC (Federal Home Loan Mortgage Corporation)",
    "FNMA (Federal National Mortgage Association)",
    "GNMA (Government National Mortgage Association)",
    "Government National Mortgage Association (GNMA)",
    "Government-sponsored enterprises providing secondary market financing",
    "International trade financing",
    "National Credit Union Administration (NCUA)",
    "Nondepository industrial banks",
    "Nondepository industrial loan companies",
    "Nondepository Morris Plans",
    "Pawnshops",
    "Private collateralized mortgage obligation (CMO) issuing",
    "Private real estate mortgage investment conduits (REMICs) issuing",
    "Private REMICs (real estate mortgage investment conduits) issuing",
    "Purchasing of accounts receivable",
    "Repackaging loans for sale to others (i.e., private conduits)",
    "Secondary market financing",
    "Short-term inventory credit lending",
    "SLMA (Student Loan Marketing Association)",
    "Student Loan Marketing Association (SLMA)",
    "Trade banks",
  ],
  "238": ["Carpet stores", "Rug stores"],
  "239": ["Carpet wholesalers", "Ceramic wall and floor tile wholesalers", "Floor coverings wholesalers"],
  "240": ["Florists", "Fresh flower shops"],
  "241": ["Florist supplies wholesalers", "Flower wholesalers", "Ornamental plants and flowers wholesalers"],
  "243": ["Artificial and dried flower shops"],
  "244": ["Artificial flowers and plants wholesalers"],
  "247": ["Bakery products wholesalers", "Bread wholesalers"],
  "249": [
    "Frozen bakery products wholesalers",
    "Frozen dinners wholesalers",
    "Frozen doughs wholesalers",
    "Frozen fruits wholesalers",
    "Frozen juices wholesalers",
    "Frozen meat product substitutes wholesalers",
    "Frozen meat substitutes wholesalers",
    "Frozen pizzas wholesalers",
    "Frozen prepared foods wholesalers",
    "Frozen pretzels wholesalers",
    "Frozen soups wholesalers",
    "Frozen vegetable patties wholesalers",
    "Frozen vegetables wholesalers",
    "Packaged frozen foods wholesalers",
    "Packaged frozen meats wholesalers",
    "Packaged frozen poultry wholesalers",
    "Packaged frozen prepared foods wholesalers",
    "Packaged frozen seafoods wholesalers",
  ],
  "251": [
    "Cured or smoked meats wholesalers",
    "Cutting of purchased carcasses wholesalers",
    "Fresh meats wholesalers",
    "Frozen meats wholesalers",
    "Lard wholesalers",
    "Meats and meat products wholesalers",
    "Processed meats wholesalers",
  ],
  "255": ["Custom picture frame shops"],
  "257": [
    "Fruit and produce markets",
    "Fruit and produce stands (permanent structure)",
    "Fruit and produce stands (temporary)",
  ],
  "258": [
    "Embalming services",
    "Funeral director services",
    "Funeral homes",
    "Funeral homes combined with crematories",
    "Funeral parlors",
    "Human remains transportation services",
    "Mortician services",
    "Mortuaries",
    "Undertaker services",
  ],
  "263": [
    "Bed retail stores",
    "Furniture and appliance retail stores",
    "Furniture stores",
    "Mattress stores (including waterbeds)",
  ],
  "264": ["Office furniture stores"],
  "266": [
    "Antique furniture wholesalers",
    "Baby furniture wholesalers",
    "Beds wholesalers",
    "Box springs wholesalers",
    "Freestanding kitchen cabinets wholesalers",
    "Furniture parts wholesalers",
    "Furniture wholesalers",
    "Hotel furniture wholesalers",
    "Household-type furniture wholesalers",
    "Mattresses wholesalers",
    "Outdoor furniture wholesalers",
    "Public building furniture wholesalers",
    "Religious furniture wholesalers",
    "Restaurant furniture wholesalers",
    "School furniture wholesalers",
    "Store furniture wholesalers",
    "Theater seats wholesalers",
  ],
  "267": ["Garden centers", "Nursery and garden centers (no tree production)"],
  "268": [
    "Ammonia and fertilizer material wholesalers",
    "Fertilizer and fertilizer materials wholesalers",
    "Flower bulbs wholesalers",
    "Garden supply wholesalers",
    "Herbicide wholesalers",
    "Lawn care supply wholesalers",
    "Mulch wholesalers",
    "Plant bulb wholesalers",
    "Straw wholesalers",
    "Vegetable dust and spray wholesalers",
    "Weed killer wholesalers",
  ],
  "269": ["Genealogical investigation services"],
  "271": [
    "Craft stores (non craft supply)",
    "Fruit basket or fruit bouquet stores",
    "Gift shops",
    "Gift stands (permanent structure)",
    "Souvenir shops",
  ],
  "274": ["Glass plate wholesalers", "Plate glass wholesalers"],
  "275": ["Golf pro shops"],
  "277": ["Greeting card shops"],
  "278": [
    "Commissaries (primarily groceries)",
    "Delicatessens (primarily groceries)",
    "Grocery stores",
    "Supermarkets",
  ],
  "280": ["Handwriting analysis services", "Handwriting expert services"],
  "282": ["Hardware stores", "Power and hand tool stores"],
  "283": [
    "Appliance hardware wholesalers",
    "Bolts, nuts, rivets, screws, and other fasteners wholesalers",
    "Brads wholesalers",
    "Cabinet hardware and fittings wholesalers",
    "Carpenters' tools wholesalers",
    "Drapery hardware wholesalers",
    "Hand tools wholesalers",
    "Hardware wholesalers",
    "Hinges wholesalers",
    "Keys and locks wholesalers",
    "Plumbers' tools and equipment wholesalers",
    "Power hand tools wholesalers",
    "Security locks wholesalers",
    "Specialty-line hardware wholesalers",
    "Staples wholesalers",
    "Tacks wholesalers",
  ],
  "286": [
    "Direct dental insurance carriers",
    "Direct health and medical insurance underwriting",
    "Direct health insurance carriers",
    "Direct hospital and medical service plans without providing health care services",
    "Direct hospitalization insurance carriers without providing health care services",
    "Direct medical insurance carriers",
    "Group hospitalization plans without providing health care services",
    "Medical service plans without providing health care services",
  ],
  "287": ["Hearing aid stores"],
  "288": ["Hearing aids wholesalers"],
  "290": [
    "Ductless heating and air-conditioning system equipment wholesalers",
    "Dust collection equipment wholesalers",
    "Heat pumps wholesalers",
    "HVAC equipment wholesalers",
    "Nonportable electric baseboard heaters wholesalers",
    "Sheet metal duct work wholesalers",
    "Warm air central heating equipment wholesalers",
    "Warm air furnaces wholesalers",
    "Warm air heating equipment wholesalers",
  ],
  "292": ["Craft supply stores (non needlecraft)", "Hobby shops", "Magic supply stores", "Unfinished pottery stores"],
  "293": [
    "Board games wholesalers",
    "Card games wholesalers",
    "Children's vehicles wholesalers",
    "Coloring books wholesalers",
    "Craft kits wholesalers",
    "Dolls wholesalers",
    "Electronic games wholesalers",
    "Fireworks wholesalers",
    "Games wholesalers",
    "Gaming consoles wholesalers",
    "Hobby craft kits wholesalers",
    "Hobbyists' supplies wholesalers",
    "Model kits wholesalers",
    "Philatelist stamps wholesalers",
    "Playing cards wholesalers",
    "Puzzles wholesalers",
    "Science kits and sets wholesalers",
    "Toy furniture wholesalers",
    "Toys wholesalers",
    "Trading cards wholesalers",
    "Video games wholesalers",
  ],
  "294": [
    "Agreement corporation",
    "Financial holding companies",
    "Holding companies",
    "Insurance holding companies",
    "Nonbank holding companies",
    "Personal holding companies",
    "Public utility holding companies",
    "Railroad holding companies",
    "Savings and loan holding companies",
    "Thrift holding companies",
  ],
  "295": ["Home furnishing stores", "Housewares stores", "Wood-burning stove stores"],
  "296": [
    "Antique home furnishings wholesalers",
    "Furniture coverings and protectors wholesalers",
    "Home furnishings wholesalers",
    "Lamps wholesalers",
    "Mirrors wholesalers",
    "Prefabricated fireplaces wholesalers",
    "Rugs wholesalers",
  ],
  "298": [
    "Condominium homeowners' associations",
    "Condominium owners' associations",
    "Cooperative owners' associations",
    "Homeowners' associations",
    "Property owners' associations",
  ],
  "304": [
    "Air pollution control equipment and supplies wholesalers",
    "Air pollution control equipment wholesalers",
    "Humidifiers and dehumidifiers wholesalers",
    "Ventilating equipment and supplies wholesalers",
  ],
  "305": ["Packaged ice cream stores"],
  "306": ["Block ice manufacturing", "Ice manufacturing"],
  "309": ["Industrial launderers", "Industrial laundry services", "Industrial uniform supply services"],
  "313": ["Insurance agencies", "Insurance brokerages", "Insurance brokers' offices", "Life insurance agencies"],
  "317": [
    "Open-ended investment funds",
    "Open-ended management investment offices",
    "Open-ended money market mutual funds",
    "Open-ended mutual funds",
  ],
  "320": [
    "Brooms wholesalers",
    "Carpet cleaning equipment and supplies wholesalers",
    "Commercial vacuum cleaning systems wholesalers",
    "Floor maintenance equipment wholesalers",
    "Industrial mops wholesalers",
    "Janitorial equipment and supplies wholesalers",
    "Pressure washers wholesalers",
  ],
  "321": [
    "Antique jewelry wholesalers",
    "Costume jewelry wholesalers",
    "Diamonds wholesalers",
    "Gem stones wholesalers",
    "Jewelers' findings wholesalers",
    "Jewelry wholesalers",
    "Medallions wholesalers",
    "Pearls wholesalers",
    "Plated metal cutlery or flatware wholesalers",
    "Precious and plated flatware wholesalers",
    "Precious and plated silverware wholesalers",
    "Precious and plated tableware wholesalers",
    "Precious and semiprecious stones wholesalers",
    "Precious ingots wholesalers",
    "Precious metal hollowware wholesalers",
    "Precious metals wholesalers",
  ],
  "322": ["Jewelry box wholesalers"],
  "323": ["Precious and semiprecious gem stone shops", "Precious jewelry stores"],
  "326": ["Kennels, pet boarding"],
  "328": ["Kitchenware stores"],
  "329": [
    "Antique houseware wholesalers",
    "Brooms and brushes wholesalers",
    "Closet accessories wholesalers",
    "Cooking utensils wholesalers",
    "Flatware wholesalers",
    "Household containers wholesalers",
    "Housewares wholesalers",
    "Ironing boards wholesalers",
    "Kitchen utensils wholesalers",
    "Mops wholesalers",
    "Napkins wholesalers",
    "Plastic cups, plastics wholesalers",
  ],
  "332": [
    "Electric light fixtures wholesalers",
    "Electric lighting fixtures wholesalers",
    "Electric motors, wiring supplies, and lighting fixtures wholesalers",
    "Flashlights wholesalers",
    "Light bulbs wholesalers",
    "Lightning arrestors wholesalers",
  ],
  "333": [
    "City planning services",
    "Garden planning services",
    "Golf course design services",
    "Industrial land use planning services",
    "Land use design services",
    "Land use planning services",
    "Landscape architects' offices",
    "Landscape architects' private practices",
    "Landscape architectural services",
    "Landscape consulting services",
    "Landscape design services",
    "Landscape planning services",
    "Ski area design services",
    "Ski area planning services",
    "Town planners' offices",
    "Town planning services",
    "Urban planners' offices",
    "Urban planning services",
  ],
  "336": [
    "Coin-operated and laundries",
    "Coin-operated drycleaners and laundries",
    "Coin-operated laundries",
    "Launderettes",
    "Laundromats",
    "Self-service drycleaners and laundries",
  ],
  "338": [
    "Attorneys' offices",
    "Attorneys' private practices",
    "Barristers' offices",
    "Barristers' private practices",
    "Corporate law offices",
    "Counselors' at law offices",
    "Counselors' at law private practices",
    "Criminal law offices",
    "Estate law offices",
    "Family law offices",
    "Law firms",
    "Law offices",
    "Law practices",
    "Lawyers' offices",
    "Lawyers' private practices",
    "Legal aid services",
    "Patent attorneys' offices",
    "Patent attorneys' private practices",
    "Private solicitors' offices",
    "Real estate law offices",
    "Solicitors' private practices",
    "Tax law attorneys' offices",
    "Tax law attorneys' private practices",
  ],
  "340": ["Briefcases wholesalers", "Luggage wholesalers", "Suitcases wholesalers"],
  "341": ["Leather goods stores"],
  "342": [
    "Amusement facility rental or leasing",
    "Arena rental or leasing",
    "Auditorium rental or leasing",
    "Bank building rental or leasing",
    "Banquet hall rental or leasing",
    "Commercial building rental or leasing",
    "Concert hall rental or leasing",
    "Conference center rental or leasing",
    "Convention center rental or leasing",
    "Coworking space rental or leasing",
    "Dance hall rental or leasing",
    "Dock and associated building rental or leasing",
    "Executive suites",
    "Exhibition hall rental or leasing",
    "Full service office space provision",
    "Hotel building rental or leasing",
    "Industrial building rental or leasing",
    "Insurance building rental or leasing",
    "Lessors of nonresidential buildings",
    "Mall property operation rental or leasing",
    "Manufacturing building rental or leasing",
    "Medical building rental or leasing",
    "Meeting hall and room rental or leasing",
    "Motel building rental or leasing",
    "Nonresidential building rental or leasing",
    "Nonresidential hall and banquet room rental or leasing",
    "Nonresidential hall rental or leasing",
    "Office building rental or leasing",
    "On-site mobile office building rental or leasing",
    "Piers and associated building rental or leasing",
    "Professional office building rental or leasing",
    "Real estate rental or leasing of nonresidential building",
    "Reception hall rental or leasing",
    "Shared workspace provision",
    "Shopping center rental or leasing",
    "Stadium rental or leasing",
    "Theater property rental or leasing",
    "Under roof flea market space rental or leasing",
  ],
  "344": [
    "Archives",
    "Bookmobiles",
    "Centers for documentation",
    "Circulating libraries",
    "Film archives",
    "Lending libraries",
    "Libraries",
    "Motion picture film library archives",
    "Music archives",
    "Reference libraries",
  ],
  "345": ["Linen stores"],
  "346": [
    "Bathroom accessories wholesalers",
    "Blankets wholesalers",
    "Linens wholesalers",
    "Slipcovers wholesalers",
    "Towels and washcloths wholesalers",
  ],
  "347": ["Key duplicating shops"],
  "348": ["Luggage stores"],
  "349": [
    "Apparel folding and packaging services",
    "Blister packaging services",
    "Cosmetic kit assembling and packaging services",
    "Folding and packaging services, textile and apparel",
    "Gift wrapping services",
    "Kit assembling and packaging services",
    "Labeling services",
    "Mounting merchandise on cards",
    "Packaging services",
    "Parcel packing services",
    "Product sterilization and packaging services",
    "Shrink wrapping services",
    "Skin blister packaging services",
    "Textile folding and packaging services",
  ],
  "358": [
    "Address list publishers",
    "Address list publishers and printing combined",
    "Business directory publishers",
    "Business directory publishers and printing combined",
    "Business directory publishers exclusively on Internet",
    "Catalog of collections publishers",
    "Catalog of collections publishers and printing combined",
    "Catalog of collections publishers exclusively on Internet",
    "Directory and mailing list publishers",
    "Directory and mailing list publishers and printing combined",
    "Directory publishers",
    "Directory publishers and printing combined",
    "Directory publishers exclusively on Internet",
    "Electronic directory publishers",
    "Electronic directory publishers exclusively on Internet",
    "Mailing list publishers",
    "Subscription directory publishers",
    "Telephone directory publishers",
    "Telephone directory publishers and printing combined",
    "Telephone directory publishers exclusively on Internet",
  ],
  "360": [
    "Broadcast media rating services",
    "Internet media audience research services",
    "Internet traffic monitoring research services",
    "Marketing analysis services",
    "Marketing research services",
    "Opinion research services",
    "Political opinion polling services",
    "Public opinion polling services",
    "Public opinion research services",
    "Statistical sampling services",
  ],
  "363": ["Mausoleums"],
  "366": ["Acupuncturists' offices"],
  "367": ["Allergists' offices"],
  "369": ["Cardiologists' offices"],
  "370": ["Chiropractors' offices"],
  "371": ["Dermatologists' offices"],
  "374": ["Dietitians' offices"],
  "375": ["Otolaryngologists' offices"],
  "377": ["Gastroenterologists' offices"],
  "378": [
    "Family physicians' offices",
    "Health screening services in physicians' offices",
    "Medical doctors' offices",
    "Physicians' offices",
    "Walk-in physicians' offices",
  ],
  "380": ["Gynecologists' offices"],
  "382": ["Internists' offices"],
  "384": ["Neurologists' offices"],
  "385": ["Obstetricians' offices"],
  "387": ["Oncologists' offices"],
  "388": ["Ophthalmologists' offices"],
  "389": ["Optometrists' offices"],
  "390": ["Orthopedic physicians' offices", "Orthopedic surgeons' offices"],
  "392": ["Pediatricians' offices"],
  "393": ["Podiatrists' offices"],
  "396": ["Pulmonary specialists' offices"],
  "400": ["Urologists' offices"],
  "402": [
    "Aluminum and aluminum alloy primary forms wholesalers",
    "Aluminum foil, plate, sheet, coil, and roll products wholesalers",
    "Black plate wholesalers",
    "Carbon and alloy steel primary forms wholesalers",
    "Cast iron pipe wholesalers",
    "Concrete reinforcing bars wholesalers",
    "Ferroalloys wholesalers",
    "Ferrous and nonferrous metals wholesalers",
    "Ferrous metals wholesalers",
    "Foundry products wholesalers",
    "Galvanized iron and steel products wholesalers",
    "Grinding balls wholesalers",
    "Ingots wholesalers",
    "Lead primary forms wholesalers",
    "Metal bars wholesalers",
    "Metal bearing piles wholesalers",
    "Metal billets wholesalers",
    "Metal blooms wholesalers",
    "Metal castings wholesalers",
    "Metal forgings wholesalers",
    "Metal pilings wholesalers",
    "Metal pipe wholesalers",
    "Metal poles wholesalers",
    "Metal posts wholesalers",
    "Metal products wholesalers",
    "Metal rails and accessories wholesalers",
    "Metal rods wholesalers",
    "Metal sewer pipe wholesalers",
    "Metal spikes wholesalers",
    "Metal storage tanks wholesalers",
    "Metal strips wholesalers",
    "Metal structural assemblies wholesalers",
    "Metal tubing wholesalers",
    "Metals sales offices",
    "Metals service centers",
    "Nails wholesalers",
    "Nickel and nickel alloy primary forms wholesalers",
    "Nonferrous metals wholesalers",
    "Noninsulated wire wholesalers",
    "Pig iron wholesalers",
    "Reinforcement mesh and wire wholesalers",
    "Semi-finished metal products wholesalers",
    "Stainless steel wholesalers",
    "Steel springs wholesalers",
    "Steel wholesalers",
    "Steel wire cloth wholesalers",
    "Steel wool wholesalers",
    "Storage metal tanks wholesalers",
    "Terneplate wholesalers",
    "Tin and tin alloy primary forms wholesalers",
    "Tin plate wholesalers",
    "Wire and cable wholesalers",
    "Wire cable wholesalers",
    "Wire rope wholesalers",
    "Wire ropes wholesalers",
    "Wire screening wholesalers",
    "Wire wholesalers",
    "Zinc and zinc alloy primary forms wholesalers",
  ],
  "405": ["Monuments and grave markers wholesalers", "Tombstones wholesalers"],
  "406": ["Mortgage banking", "Mortgage companies", "Reverse mortgage lending"],
  "408": [
    "Art galleries",
    "Art museums",
    "Community museums",
    "Contemporary art museums",
    "Decorative art museums",
    "Fine arts museums",
    "Halls of fame",
    "Herbariums",
    "Historical museums",
    "Human history museums",
    "Interactive museums",
    "Marine museums",
    "Military museums",
    "Mobile museums",
    "Multidisciplinary museums",
    "Museums",
    "Natural history museums",
    "Natural science museums",
    "Observatories",
    "Planetariums",
    "Science and technology museums",
    "Sports halls of fame",
    "Traveling museum exhibits",
    "War museums",
    "Wax museums",
  ],
  "409": ["Musical recordings wholesalers"],
  "411": ["Musical instrument stores", "Sheet music stores"],
  "412": ["Musical instrument accessories and supplies wholesalers", "Musical instruments wholesalers"],
  "413": ["Musical instrument repair shops", "Tuning and repair of musical instruments"],
  "415": ["Newsstands (permanent structure)"],
  "416": ["Notary public services", "Notary publics' private practices"],
  "417": ["Packaged nut stores"],
  "419": [
    "Administrative management services",
    "Business management services",
    "Executive management services",
    "Hotel management services",
    "Industrial management services",
    "Management services",
    "Managing offices of dentists",
    "Managing offices of physicians and surgeons",
    "Managing offices of professionals",
    "Medical office management services",
    "Motel management services",
    "Office administration services",
    "Office management services",
  ],
  "421": ["General rental centers", "Home and garden equipment rental centers", "Rent-all centers"],
  "422": ["Office furniture wholesalers"],
  "423": [
    "Facsimile machine repair and maintenance services",
    "Fax machine repair and maintenance services",
    "Office machine repair and maintenance services",
  ],
  "426": ["Office supply stores", "Stationery stores"],
  "427": ["Lens grinding, ophthalmic, in retail stores", "Optical goods stores "],
  "428": [
    "Binoculars wholesalers",
    "Contact lenses wholesalers",
    "Eyeglasses wholesalers",
    "Magnifying glasses wholesalers",
    "Ophthalmic frames wholesalers",
    "Ophthalmic goods wholesalers",
    "Ophthalmic lens blanks wholesalers",
    "Optical glasses wholesalers",
    "Optical goods wholesalers",
    "Optical lenses wholesalers",
    "Optical professional equipment and supplies wholesalers",
    "Optometric equipment and supplies wholesalers",
  ],
  "429": ["Paint stores", "Wallpaper and wall covering stores"],
  "430": [
    "Automotive paint wholesalers",
    "Calcimine wholesalers",
    "Enamel wholesalers",
    "Lacquer wholesalers",
    "Non-artist paint wholesalers",
    "Non-industrial spray painting equipment wholesalers",
    "Paint pigment wholesalers",
    "Paint remover wholesalers",
    "Paint roller wholesalers",
    "Paint thinner wholesalers",
    "Paintbrush wholesalers",
    "Painter supplies wholesalers",
    "Shellac wholesalers",
    "Stain wholesalers",
    "Varnish wholesalers",
    "Wall covering wholesalers",
    "Wallpaper wholesalers",
    "Wood paste wholesalers",
  ],
  "435": ["Art supply stores"],
  "438": ["Patent agent services"],
  "439": ["Motion picture production payroll services", "Payroll processing services", "Talent payment services"],
  "440": ["Personal organizer services", "Personal shopping services"],
  "442": ["Pet feed stores", "Pet supply stores"],
  "443": ["Animal grooming services", "Pet grooming services"],
  "444": ["Pet boarding services"],
  "445": ["Pet sitting services", "Pet walking services"],
  "446": ["Pet shops"],
  "447": ["Pet food wholesalers"],
  "448": ["Aquarium fish and supply wholesalers", "Dog wholesalers", "Pet supplies wholesalers", "Pet wholesalers"],
  "449": [
    "Guard dog training services",
    "Guide dog training services",
    "Pet obedience training services",
    "Pet training services",
    "Working, sporting, and service dog training services",
  ],
  "450": ["Photo finishing labs", "Photo finishing services"],
  "452": ["Engraving printing plate, for the printing trade", "Photoengraving plate preparation services"],
  "453": [
    "Passport photography services",
    "Portrait photography services",
    "Portrait photography studios",
    "Portrait video photography services",
    "School photography services",
    "Special events videotaping services",
    "Wedding photography services",
  ],
  "455": [
    "Analytical instruments wholesalers",
    "Barometers wholesalers",
    "Professional and scientific instruments wholesalers",
    "Professional equipment and supplies wholesalers",
    "Surveying equipment and supplies wholesalers",
  ],
  "456": ["Piano stores"],
  "458": ["Frames and pictures wholesalers"],
  "460": [
    "Needlecraft sewing supply stores",
    "Piece goods stores",
    "Quilting supply stores",
    "Sewing supply stores",
    "Upholstery materials stores",
  ],
  "461": [
    "Direct selling cut Christmas trees",
    "Farm supply stores",
    "Feed stores",
    "Lawn supply stores",
    "Nurseries (stock primarily grown off premises)",
  ],
  "462": [
    "Interior landscaping services",
    "Maintenance of plants and shrubs in buildings",
    "Plant and shrub maintenance in buildings",
  ],
  "463": [
    "Cellulose film wholesalers",
    "Foam, plastics, resins and shapes wholesalers",
    "Nylon resins wholesalers",
    "Plastic stabilizers wholesalers",
    "Plasticizers wholesalers",
    "Plastics basic shapes wholesalers",
    "Plastics foam wholesalers",
    "Plastics materials wholesalers",
    "Plastics resins wholesalers",
    "Polyester resins wholesalers",
  ],
  "470": ["Lie detection services", "Polygraph services"],
  "474": [
    "Commercial flexographic printing",
    "Flexographic job printing",
    "Flexographic print shops",
    "Flexographic printing",
  ],
  "477": [
    "Address lists commercial printing",
    "Advertising materials commercial printing ",
    "Agricultural magazines and periodicals commercial printing",
    "Albums and refills manufacturing",
    "Appointment books and refills manufacturing",
    "Art prints commercial printing",
    "Atlases commercial printing ",
    "Blank books and refills manufacturing",
    "Business cards printing",
    "Business directories commercial printing",
    "Business forms commercial printing",
    "Calendars commercial printing ",
    "Catalogs commercial printing ",
    "Catalogs of collections commercial printing",
    "Checkbooks and refills printing",
    "Comic books commercial printing",
    "Commercial digital printing ",
    "Commercial engraving printing",
    "Commercial gravure printing ",
    "Commercial letterpress printing ",
    "Commercial lithographic printing",
    "Commercial printing",
    "Computer forms printing",
    "Credit and identification card imprinting, embossing and encoding",
    "Credit card slips printing",
    "Databases commercial printing",
    "Diaries manufacturing",
    "Digital print shops",
    "Digital printing",
    "Directories commercial printing",
    "Discount coupon books commercial printing without publishing",
    "Engraving print shops",
    "Financial magazines and periodicals commercial printing",
    "Globe covers and maps commercial printing without publishing",
    "Gravure job printing",
    "Gravure print shops",
    "Gravure printing",
    "Greeting cards commercial printing",
    "Guides, street map commercial printing",
    "Heat press printing apparel and textile products",
    "Hydrographic printing",
    "Instant printing",
    "Intaglio printing",
    "Job printing",
    "Job printing and engraving",
    "Juvenile magazines and periodicals commercial printing",
    "Labels commercial printing",
    "Letterpress job printing",
    "Letterpress print shops",
    "Letterpress printing",
    "Lithographic job printing",
    "Lithographic print shops",
    "Lithographic printing",
    "Looseleaf binders and devices manufacturing",
    "Magazines and periodicals commercial printing",
    "Manifold business forms printing",
    "Manifold sales books printing",
    "Maps commercial printing",
    "Newsletters commercial printing ",
    "Newspapers commercial printing",
    "Offset job printing",
    "Offset printing",
    "Patterns and plans commercial printing ",
    "Periodicals commercial printing ",
    "Photo albums and refills manufacturing",
    "Photo-offset printing",
    "Postcards commercial printing",
    "Posters commercial printing ",
    "Printing apparel",
    "Printing manifold business forms",
    "Printing, engraving on paper products",
    "Printing, gravure",
    "Professional magazines and periodicals commercial printing ",
    "Racetrack programs commercial printing ",
    "Racing forms commercial printing",
    "Radio guides commercial printing",
    "Radio schedules commercial printing",
    "Religious magazines and periodicals commercial printing",
    "Rotogravure printing",
    "Scholarly journals commercial printing ",
    "Scholastic magazines and periodicals commercial printing",
    "Scrapbooks and refills manufacturing",
    "Sheet music commercial printing",
    "Shipping registers commercial printing",
    "Stationery commercial printing",
    "Technical magazines and periodicals commercial printing ",
    "Telephone directories commercial printing ",
    "Television guides commercial printing",
    "Time planners/organizers and refills manufacturing",
    "Trade journals commercial printing",
    "Trade magazines and periodicals commercial printing",
    "Yearbooks commercial printing",
  ],
  "478": [
    "Color separation services for the printing trade",
    "Electronic prepress services for the printing trade",
    "Electrotype plate preparation services",
    "Embossing plate preparation services",
    "Encyclopedia binding without printing",
    "Flexographic plate preparation services",
    "Gold stamping books for the trade",
    "Gravure plate and cylinder preparation services",
    "Image setting services, prepress",
    "Letterpress plate preparation services",
    "Lithographic plate preparation services",
    "Nonfiction bookbinding",
    "Offset plate preparation services",
    "Photocomposition services for the printing trade",
    "Phototypesetting services",
    "Postpress services on printed materials",
    "Prepress printing services ",
    "Printing plate preparation services",
    "Printing postpress services",
    "Printing prepress services ",
    "Rebinding books, magazines, or pamphlets",
    "Repairing books",
    "Rotogravure printing plates and cylinders preparation services",
    "Samples mounting",
    "Screen for printing, preparation services",
    "Swatches and samples, mounting for the trade",
    "Technical manual paper (books) binding without printing",
    "Typesetting ",
  ],
  "479": ["Commercial quick printing", "Quick print shops", "Quick printing"],
  "484": [
    "Almanac publishers",
    "Almanac publishers exclusively on Internet",
    "Atlas publishers",
    "Atlas publishers exclusively on Internet",
    "Book publishers",
    "Book publishers exclusively on Internet",
    "Dictionary publishers",
    "Dictionary publishers exclusively on Internet",
    "Encyclopedia publishers",
    "Encyclopedia publishers exclusively on Internet",
    "Fiction book publishers",
    "Fiction book publishers exclusively on Internet",
    "Globe cover publishers",
    "Graphic novel publishers",
    "Internet book publishers",
    "Internet map publishers",
    "Map publishers",
    "Map publishers exclusively on Internet",
    "Nonfiction book publishers",
    "Nonfiction book publishers exclusively on Internet",
    "Pamphlet publishers",
    "Pamphlet publishers exclusively on Internet",
    "Professional book publishers",
    "Professional book publishers exclusively on Internet",
    "Religious book publishers",
    "Religious book publishers exclusively on Internet",
    "School book publishers",
    "School book publishers exclusively on Internet",
    "School textbook publishers",
    "School textbook publishers exclusively on Internet",
    "Street map guide publishers",
    "Street map guide publishers exclusively on Internet",
    "Technical manual and paperback book publishers",
    "Technical manual publishers",
    "Technical manual publishers exclusively on Internet",
    "Travel guide book publishers",
    "Travel guide book publishers exclusively on Internet",
    "University press book publishers",
    "University press publishers",
    "University press publishers exclusively on Internet",
  ],
  "485": ["Greeting card publishers", "Greeting card publishers exclusively on Internet"],
  "486": [
    "Advertising periodical publishers",
    "Advertising periodical publishers exclusively on Internet",
    "Agricultural magazine and periodical publishers",
    "Agricultural magazine and periodical publishers exclusively on Internet",
    "Comic book publishers",
    "Comic book publishers exclusively on Internet",
    "Financial magazine and periodical publishers",
    "Financial magazine and periodical publishers exclusively on Internet",
    "Internet comic book publishing",
    "Internet magazine publishing",
    "Internet newsletter publishing",
    "Internet periodical publishers",
    "Juvenile magazine and periodical publishers",
    "Juvenile magazine and periodical publishers exclusively on Internet",
    "Magazine publishers",
    "Magazine publishers exclusively on Internet",
    "Medical journal and periodical publishers",
    "Medical journal and periodical publishers exclusively on Internet ",
    "Newsletter publishers",
    "Newsletter publishers exclusively on Internet",
    "Periodical publishers",
    "Periodical publishers combined with printing",
    "Periodical publishers exclusively on Internet",
    "Professional magazine and periodical publishers",
    "Professional magazine and periodical publishers exclusively on Internet",
    "Radio guide publishers",
    "Radio guide publishers exclusively on Internet",
    "Radio schedule publishers",
    "Radio schedule publishers exclusively on Internet",
    "Religious magazine and periodical publishers",
    "Religious magazine and periodical publishers exclusively on Internet",
    "Scholarly journal publishers",
    "Scholarly journal publishers exclusively on Internet",
    "Scholastic magazine and periodical publishers",
    "Scholastic magazine and periodical publishers exclusively on Internet",
    "Scientific journal and periodical publishers",
    "Scientific journal and periodical publishers exclusively on Internet",
    "Technical magazine and periodical publishers",
    "Technical magazine and periodical publishers exclusively on Internet",
    "Television guide publishers",
    "Television guide publishers exclusively on Internet",
    "Trade journal publishers",
    "Trade journal publishers exclusively on Internet",
    "Trade magazine and periodical publishers",
    "Trade magazine and periodical publishers exclusively on Internet",
  ],
  "487": [
    "Internet newspaper publishing",
    "Newspaper branch offices",
    "Newspaper publishers",
    "Newspaper publishing exclusively on Internet",
  ],
  "488": [
    "Almanac publishers and printing combined",
    "Atlas publishers and printing combined",
    "Book publishers and printing combined",
    "Dictionary publishers and printing combined",
    "Encyclopedia publishers and printing combined",
    "Fiction book publishers and printing combined",
    "Globe cover publishers and printing combined",
    "Guide, street map, publishers and printing combined",
    "Map publishers and printing combined",
    "Nonfiction book publishers and printing combined",
    "Pamphlet publishers and printing combined",
    "Professional book publishers and printing combined",
    "Religious book publishers and printing combined",
    "School book publishers and printing combined",
    "School textbook publishers and printing combined",
    "Technical manual and paperback book publishers and printing combined",
    "Travel guide book publishers and printing combined",
  ],
  "489": [
    "Coin- or card-operated phonographs and vending machines wholesalers",
    "Coin- or card-operated phonographs wholesalers",
  ],
  "490": [
    "Agencies, real estate",
    "Agents, real estate",
    "Auctioning real estate for others",
    "Buying real estate for others",
    "Commercial real estate agencies",
    "Commercial real estate agents' offices",
    "Exclusive buyers' agencies",
    "Offices of exclusive buyers' agents",
    "Real estate agencies",
    "Real estate agents' offices",
    "Real estate brokerages",
    "Real estate brokers' offices",
    "Real estate buyers' agents offices",
    "Real estate buying agencies",
    "Renting real estate for others",
    "Residential real estate agencies",
    "Residential real estate agents' offices",
    "Residential real estate brokerages",
    "Residential real estate brokers' offices",
    "Selling real estate for others",
    "Selling time-share condominiums for others",
  ],
  "491": ["Audio recording postproduction services", "Audio recording restoration services", "Sound recording studios"],
  "493": [
    "Cold storage machinery wholesalers",
    "Commercial freezers wholesalers",
    "Commercial refrigeration equipment and supplies wholesalers",
    "Commercial refrigerators wholesalers",
    "Ice making machines wholesalers",
    "Mechanical beverage coolers wholesalers",
    "Mechanical water coolers wholesalers",
    "Motor vehicle refrigeration units wholesalers",
    "Refrigerated display cases wholesalers",
    "Refrigerated drinking fountains  wholesalers",
    "Refrigerated fixtures wholesalers",
    "Refrigerated lockers wholesalers",
    "Refrigerated showcases wholesalers",
    "Refrigerated soda fountain fixtures wholesalers",
    "Refrigeration compressors wholesalers",
    "Refrigeration condensing units wholesalers",
  ],
  "494": ["Non-book religious goods stores"],
  "500": ["Beer making supply stores", "Wine making supply stores"],
  "507": ["Household sewing machine stores"],
  "511": ["Boot repair shops", "Shoe repair shops"],
  "512": [
    "Boot and shoe cut stock and findings wholesalers",
    "Shoe patterns wholesalers",
    "Shoe repair materials wholesalers",
  ],
  "514": [
    "Athletic shoe stores (except bowling, golf, cleated)",
    "Children's shoe stores",
    "Orthopedic shoe stores",
    "Shoe stores (except bowling, golf, cleated)",
  ],
  "522": ["Flag and banner shops"],
  "523": ["Billboards wholesalers", "Signs wholesalers"],
  "524": [
    "Boat repair and maintenance services",
    "Motorboat repair and maintenance services",
    "Motorcycle repair shops",
    "Outboard motor repair shops",
    "Pleasure boat maintenance services",
  ],
  "529": [
    "Fix-and-freeze meal stores",
    "Frozen food and freezer meal plan providers (direct sales)",
    "Gourmet food stores",
    "Meal assembly retailers",
    "Specialty food stores",
    "Take-and-bake meal stores",
    "Take-and-bake pizza shops",
  ],
  "530": ["Spice stores"],
  "531": [
    "Athletic equipment and supply stores (includes uniforms)",
    "Athletic uniform stores",
    "Bowling equipment and supply stores",
    "Camping and hiking equipment stores",
    "Diving equipment stores",
    "Exercise equipment stores",
    "Fishing supply stores",
    "Fishing tackle shops",
    "Gun shops",
    "Hunting equipment stores",
    "Outdoor sporting equipment stores",
    "Saddlery stores",
    "Specialty sports footwear stores",
    "Sporting goods stores",
    "Sports gear stores",
    "Sports pro shops",
    "Tack, harness, saddlery shops",
  ],
  "532": [
    "Archery equipment wholesalers",
    "Artificial bait wholesalers",
    "Athletic goods wholesalers",
    "Athletic uniforms wholesalers",
    "Baseball equipment and supplies wholesalers",
    "Bicycle tires and tubes wholesalers",
    "Bicycles wholesalers",
    "Billiard equipment and supplies wholesalers",
    "Bowling equipment and supplies wholesalers",
    "Camping equipment and supplies wholesalers",
    "Casino supplies wholesalers",
    "Fishing equipment and supplies wholesalers",
    "Fitness equipment and supplies wholesalers",
    "Football equipment and supplies wholesalers",
    "Go-carts wholesalers",
    "Golf equipment and supplies wholesalers",
    "Gymnasium equipment wholesalers",
    "Hockey equipment and supplies wholesalers",
    "Hot tubs wholesalers",
    "Hunting equipment and supplies wholesalers",
    "Jet skis wholesalers",
    "Outboard motors wholesalers",
    "Playground equipment and supplies wholesalers",
    "Pleasure boat supplies wholesalers",
    "Pleasure boats wholesalers",
    "Pleasure marine supplies wholesalers",
    "Pleasure yachts wholesalers",
    "Pool tables and supplies wholesalers ",
    "Recreational equipment and supplies wholesalers",
    "Skiing equipment and supplies wholesalers",
    "Specialty athletic footwear wholesalers",
    "Sporting ammunition wholesalers",
    "Sporting equipment guns wholesalers",
    "Sporting firearms and ammunition wholesalers",
    "Sporting firearms wholesalers",
    "Sporting goods and supplies wholesalers",
    "Sports equipment and supplies wholesalers",
    "Swimming pool and equipment wholesalers",
    "Swimming pools and equipment wholesalers",
    "Tennis equipment and supplies wholesalers",
    "Trapshooting equipment and supplies wholesalers",
  ],
  "534": [
    "Citizens' band (CB) radio stores",
    "Music retailers (non production and publishing)",
    "Music stores (e.g., cassette, compact disc, record, tape)",
    "New prerecorded tape, compact disc, and record mail-order houses",
    "New record stores",
    "Non-automotive audio equipment stores",
    "Non-automotive stereo stores",
  ],
  "535": [
    "Bond brokerages",
    "Bond dealing",
    "Certificate of deposit (CD) brokers' offices",
    "Commercial note brokers' offices",
    "Commercial paper dealing",
    "Dealing of commercial paper",
    "Discount securities brokerages",
    "Investment banking",
    "Making markets for securities",
    "Market making for securities",
    "Mutual fund agencies",
    "Mutual fund agents' offices",
    "Online securities brokerages",
    "Securities brokerages",
    "Securities brokers' offices",
    "Securities dealers",
    "Securities dealing",
    "Securities distributing",
    "Securities floor brokers",
    "Securities floor traders",
    "Securities flotation companies",
    "Securities originating",
    "Securities trading",
    "Securities underwriting",
    "Stock brokerages",
    "Stock brokers' offices",
    "Stock options brokerages",
    "Stock options dealing",
    "Trading securities",
    "Underwriting securities",
  ],
  "536": ["Sunglass stores"],
  "537": ["Sunglasses wholesalers"],
  "538": [
    "Aerial surveying (except geophysical) services",
    "Cadastral surveying services",
    "Cartographic surveying services",
    "Construction surveying services",
    "Geodetic surveying services",
    "Geographic information system (GIS) base mapping services",
    "Geospatial mapping services",
    "Land surveying services",
    "Mapping (except geophysical) services",
    "Photogrammetric mapping services",
    "Surveying and mapping services (except geophysical)",
    "Topographic mapping services",
    "Topographic surveying services",
  ],
  "539": ["Swimming pool (above-ground) and supply stores"],
  "541": ["Dress shops"],
  "542": [
    "Income tax compilation services",
    "Income tax return preparation services",
    "Tax return preparation services",
  ],
  "545": ["Television repair services without retailing new televisions"],
  "549": [
    "Amusement device",
    "Amusement device and ride concession operators",
    "Amusement ride concession operators",
    "Archery ranges",
    "Axe throwing clubs",
    "Ballrooms",
    "Bathing beaches",
    "Beaches, bathing",
    "Billiard parlors",
    "Billiard rooms",
    "Boating clubs without marinas",
    "Boccie ball courts",
    "Boys' day camps",
    "Carnival ride concession operators",
    "Coin- or card-operated nongambling amusement device concession operators",
    "Curling facilities",
    "Dance halls",
    "Day camps",
    "Discotheques",
    "Escape game rooms",
    "Fireworks display services",
    "Fishing guide services",
    "Fishing piers",
    "Girls' day camps",
    "Go-cart raceways",
    "Go-cart tracks",
    "Golf driving ranges",
    "Golf practice ranges",
    "Guide services",
    "Haunted houses and similar fright attractions concession operators",
    "Hookah lounges",
    "Hunting guide services",
    "Jukebox concession operators",
    "Lawn bowling clubs",
    "Miniature golf courses",
    "Miniature golf courses, miniature",
    "Nightclubs without alcoholic beverages",
    "Not operating sports facilities, recreational athletic clubs",
    "Nudist camps without accommodations",
    "Observation towers",
    "Outdoor adventure operations without accommodations",
    "Outfitters",
    "Paintball, laser tag, and similar fields and arenas",
    "Picnic grounds",
    "Pinball machine concession operators",
    "Ping pong parlors",
    "Pitch-n-putt golf courses",
    "Pool halls",
    "Pool parlors",
    "Pool rooms",
    "Recreational amateur sports teams",
    "Recreational aviation clubs",
    "Recreational baseball clubs",
    "Recreational basketball clubs",
    "Recreational beach clubs",
    "Recreational bowling leagues or teams",
    "Recreational boxing clubs",
    "Recreational bridge clubs",
    "Recreational camps without accommodations",
    "Recreational canoeing",
    "Recreational chess or bridge clubs",
    "Recreational day camps",
    "Recreational Esports video gaming clubs, teams, or leagues",
    "Recreational fishing clubs",
    "Recreational flying clubs",
    "Recreational football clubs",
    "Recreational gun clubs",
    "Recreational hockey clubs",
    "Recreational hockey teams",
    "Recreational hunting clubs",
    "Recreational ice hockey clubs",
    "Recreational kayaking",
    "Recreational mountain hiking",
    "Recreational or youth sports teams and leagues",
    "Recreational pack trains",
    "Recreational parasailing",
    "Recreational pickleball clubs",
    "Recreational rifle clubs",
    "Recreational river rafting",
    "Recreational rowing clubs, recreational",
    "Recreational saddle horse rental services",
    "Recreational snowmobiling",
    "Recreational soccer clubs",
    "Recreational sports clubs not operating sports facilities",
    "Recreational sports teams and leagues",
    "Recreational trampoline facilities",
    "Recreational trapshooting facilities",
    "Recreational white water rafting",
    "Sailing clubs without marinas",
    "Sea kayaking, recreational",
    "Shooting clubs, recreational",
    "Shooting galleries",
    "Shooting ranges",
    "Skeet shooting facilities",
    "Slot car racetracks ",
    "Summer day camps",
    "Tourist guide services",
    "Waterslides",
    "Wax figure exhibitions",
    "Yacht clubs without marinas",
    "Youth sports league teams",
    "Zip-line courses",
  ],
  "551": [
    "Amateur theater companies ",
    "Broadway theaters",
    "Burlesque companies",
    "Comedy troupes",
    "Community theaters",
    "Dinner theaters",
    "Improvisational theaters",
    "Live musical theater productions",
    "Live theatrical production theaters",
    "Mime theaters",
    "Musical theater companies or groups",
    "Musical theaters",
    "Opera companies",
    "Puppet theaters",
    "Summer theaters",
    "Theater companies",
    "Theatrical repertory companies",
    "Theatrical road companies",
    "Theatrical stock companies",
    "Vaudeville companies",
  ],
  "553": ["Non hardwood and ceramic floor covering stores"],
  "556": [
    "Real estate settlement offices",
    "Real estate title abstract companies",
    "Real estate title companies",
    "Real estate title search companies",
  ],
  "557": [
    "Cigar stores",
    "Cigarette stands (permanent structure)",
    "Cigarette stands (temporary structure)",
    "Electronic cigarette stores",
    "Medical and recreational marijuana stores",
    "Smokers' supply stores",
    "Tobacco stores",
    "Vape shops",
  ],
  "560": ["Electronic toy stores", "Toy stores"],
  "561": [
    "Language interpretation services",
    "Language services",
    "Language translation services",
    "Sign language services",
    "Speech interpretation services",
  ],
  "562": ["Online travel agencies", "Travel agencies", "Travel management services"],
  "563": ["Trophy and awards shops"],
  "564": ["Trophies wholesalers"],
  "566": [
    "Administrators of private estates",
    "Bank trust offices",
    "Fiduciary agencies",
    "Nondepository trust companies",
    "Personal investments trust administration",
    "Securities custodians",
    "Trust administration, personal investment",
  ],
  "567": [
    "Aircraft upholstery repair",
    "Antique furniture repair and restoration shops",
    "Furniture refinishing shops",
    "Furniture repair shops",
    "Furniture reupholstering shops",
    "Restoration and repair of antique furniture",
    "Upholstery repair services",
  ],
  "568": ["Household vacuum cleaner stores"],
  "569": ["Automatic merchandising machine operators", "Vending machine merchandisers (product sales)"],
  "570": [
    "Pet hospitals",
    "Small animal veterinary services",
    "Testing services for veterinarians",
    "Veterinarians' offices",
    "Veterinarians' practices",
    "Veterinary clinics",
    "Veterinary disease testing services",
    "Veterinary internists' offices, ",
    "Veterinary laboratory testing services",
    "Veterinary services",
    "Veterinary services for pets and other animal specialties",
    "Veterinary surgeons' offices, veterinary",
    "Veterinary surgery services",
    "Veterinary testing laboratories",
    "Veterinary vaccination services",
  ],
  "572": [
    "Computer video game rental",
    "Prerecorded video cassette rental",
    "Video disc (DVD) rental for home electronic equipment",
    "Video game rental",
    "Video laser disc rental",
    "Video tape (VCR) rental for home electronic equipment",
    "Video tape rental stores",
  ],
  "574": ["Commercial event videography services", "Videotaping services for legal depositions"],
  "579": ["Clocks wholesalers", "Coins wholesalers", "Watchcases wholesalers", "Watches and parts wholesalers"],
  "580": ["Clock repair shops", "Watch repair shops without retailing new watches"],
  "581": ["Watch shops"],
  "582": ["Bottled water wholesalers"],
  "584": [
    "Ammunition wholesalers",
    "Bulletproof vests wholesalers",
    "Coin- or card-operated game machines wholesalers",
    "Coin- or card-operated games wholesalers",
    "Durable goods general merchandise wholesalers",
    "Fire extinguisher sales combined with rental and/or service wholesalers",
    "Fire extinguishers wholesalers",
    "Firearms wholesalers",
    "Firewood wholesalers",
    "Forest products wholesalers",
    "Gas lighting equipment wholesalers",
    "Gas lighting fixtures wholesalers",
    "Guns wholesalers",
    "Logs wholesalers",
    "Mailboxes wholesalers",
    "Ordnance and accessories wholesalers",
    "Personal safety devices and supplies wholesalers",
    "Phonograph records wholesalers",
    "Prerecorded audio and video cassettes wholesalers",
    "Prerecorded audio and video tapes and discs wholesalers",
    "Prerecorded audio and video tapes and disks wholesalers",
    "Prerecorded compact discs (CDs) wholesalers",
    "Prerecorded digital video discs (DVDs) wholesalers",
    "Prerecorded video tapes wholesalers",
    "Prerecorded, audio or video tapes wholesalers",
    "Pulpwood wholesalers",
    "Roundwood wholesalers",
    "Safety deposit boxes wholesalers",
    "Synthetic or artificial turf wholesalers",
    "Timber and timber products wholesalers",
    "Wood railroad ties wholesalers",
    "Wood products wholesalers",
    "Wood ties wholesalers",
  ],
  "585": ["Wig and hairpiece stores"],
  "592": [
    "Home health agencies",
    "Home health care agencies",
    "Home infusion therapy services",
    "Home nursing services",
    "Medical home care of elderly",
    "Nursing agencies primarily providing home nursing services",
    "Visiting nurse associations",
  ],
  "594": ["In-home hospice care services"],
  "597": [
    "Home health furniture and equipment rental",
    "Home health furniture rental",
    "Hospital bed rental and leasing",
    "Hospital equipment rental",
    "Hospital furniture and equipment rental",
    "Invalid crutches rental",
    "Invalid equipment rental",
    "Invalid walker rental",
    "Oxygen equipment rental",
    "Wheelchair rental",
  ],
  "599": [
    "Abrasive and nonabrasive buffing and polishing wheels manufacturing",
    "Abrasive wheels manufacturing",
    "Abrasive, natural and artificial grains manufacturing",
    "Diamond dressing wheels manufacturing",
    "Grinding wheels manufacturing",
    "Polishing and grinding wheels manufacturing",
    "Polishing wheels manufacturing",
  ],
  "600": [
    "Abrasive products manufacturing",
    "Abrasive-coated cloth made from purchased cloth",
    "Abrasive-coated paper made from purchased paper",
    "Aluminum oxide abrasives manufacturing",
    "Ceramic grinding balls manufacturing",
    "Pumice and pumicite abrasives manufacturing",
    "Sandpaper manufacturing",
    "Silicon carbide abrasives manufacturing",
    "Steel shot abrasives manufacturing",
  ],
  "602": [
    "Adhesive pastes manufacturing",
    "Adhesives manufacturing",
    "Epoxy adhesives manufacturing",
    "Mucilage adhesives manufacturing",
    "Tile adhesives manufacturing",
  ],
  "603": ["Adhesive tape manufacturing", "Duct tape manufacturing"],
  "604": [
    "Aerial advertising",
    "Billboard display advertising services",
    "Bus card advertising services",
    "Bus display advertising services",
    "Indoor display advertising services",
    "Indoor or outdoor display advertising services",
    "Outdoor display advertising services",
    "Out-of-home media advertising services",
    "Store display advertising services",
    "Subway card display advertising services",
    "Taxicab card advertising services",
    "Transit advertising services",
  ],
  "605": [
    "Light gauge metal aerosol cans manufacturing",
    "Light gauge metal beer cans manufacturing",
    "Light gauge metal can lids and ends manufacturing",
    "Light gauge metal cans manufacturing",
    "Light gauge metal  cans manufacturing",
    "Light gauge metal soup cans manufacturing",
    "Light gauge metal steel cans manufacturing",
    "Light gauge metal tin plate cans manufacturing",
    "Soft drink cans manufacturing",
  ],
  "606": ["Aerosol tire inflators manufacturing"],
  "607": [
    "Air-conditioner units manufacturing",
    "Air-conditioning and warm air heating combination units manufacturing",
    "Air-conditioning compressors manufacturing",
    "Air-conditioning condensers and condensing units manufacturing",
    "Air-conditioning equipment manufacturing",
    "Room air-conditioners manufacturing",
  ],
  "608": [
    "Air purification scrubber installation",
    "Air system balancing and testing",
    "Air vent installation",
    "Air-conditioning system installation",
    "Central air-conditioning equipment installation",
    "Central cooling equipment and piping installation",
    "Cooling tower installation",
  ],
  "609": [
    "Alarm system installation",
    "Building automation system installation",
    "Security and fire system installation",
    "Smoke detection system installation",
    "Surveillance system installation",
  ],
  "615": [
    "Cattle wholesalers",
    "Goats wholesalers",
    "Hogs wholesalers",
    "Livestock auction markets wholesalers",
    "Livestock wholesalers",
    "Sheep wholesalers",
    "Swine wholesalers",
  ],
  "618": [
    "Baby food meat canning manufacturing",
    "Baby foods (including meats) canning",
    "Baked beans canning",
    "Bouillon canning",
    "Broth canning",
    "Canning soups",
    "Chili con carne canning",
    "Chinese foods canning",
    "Dry beans canning",
    "Gravy canning",
    "Infant and junior food canning",
    "Italian foods canning",
    "Mexican foods canning",
    "Nationality specialty foods canning, bottling and jarring",
    "Pasta based products canning",
    "Pork and beans canning",
    "Soups canning",
    "Spaghetti canning",
  ],
  "620": [
    "Bagels made in commercial bakeries",
    "Biscuits made in commercial bakeries",
    "Bread and bread rolls made in commercial bakeries",
    "Cakes made in commercial bakeries",
    "Commercial bakeries",
    "Communion wafer manufacturing",
    "Croissants made in commercial bakeries",
    "Croutons and bread crumbs made in commercial bakeries",
    "Crullers made in commercial bakeries",
    "Doughnuts made in commercial bakeries",
    "Fresh bakery products made in commercial bakeries",
    "Fresh pies made in commercial bakeries",
    "Frozen bread and bread rolls made in commercial bakeries",
    "Knishes made in commercial bakeries",
    "Matzo baking made in commercial bakeries",
    "Pastries fresh made in commercial bakeries",
    "Rolls and buns made in commercial bakeries",
    "Soft pretzels made in a commercial bakery",
    "Soft pretzels manufacturing",
    "Sweet yeast goods manufacturing",
    "Unleavened bread made in commercial bakeries",
  ],
  "621": [
    "Annular ball bearings manufacturing",
    "Ball and roller bearings manufacturing",
    "Ball or roller bearing flange units manufacturing",
    "Ball or roller bearings races manufacturing",
    "Cylindrical roller bearings manufacturing",
    "Linear ball bearings manufacturing",
    "Linear roller bearings manufacturing",
    "Needle roller bearings manufacturing",
    "Pillow blocks with ball or roller bearings manufacturing",
    "Tapered roller bearings manufacturing",
    "Thrust roller bearings manufacturing",
  ],
  "625": [
    "Artificially carbonated water manufacturing",
    "Artificially carbonated waters manufacturing",
    "Carbonated soda manufacturing",
    "Carbonated soft drinks manufacturing",
    "Soda pop manufacturing",
  ],
  "629": [
    "Flavored water manufacturing",
    "Fruit and vegetable drinks, cocktails and beverages manufacturing",
    "Fruit drinks manufacturing",
    "Iced coffee manufacturing",
    "Iced tea manufacturing",
    "Soft drink beverages (including artificially carbonated waters) manufacturing",
    "Soft drinks manufacturing",
    "Water, flavored, manufacturing",
  ],
  "630": [
    "Bicycles and parts manufacturing",
    "Children's metal vehicle manufacturing",
    "Electric bicycles manufacturing",
    "Metal tricycles manufacturing",
    "Mopeds and parts manufacturing",
    "Motor scooters manufacturing",
    "Motorcycles and parts manufacturing",
  ],
  "631": ["Billiard equipment and supplies manufacturing"],
  "633": [
    "Air boat building",
    "Boat yards",
    "Boats manufacturing",
    "Cabin cruiser building",
    "Dinghies manufacturing",
    "Dories building",
    "Hovercraft building",
    "Inboard or outboard motorboat building",
    "Inflatable heavy-duty plastic boats manufacturing",
    "Inflatable heavy-duty rubber boats manufacturing",
    "Inflatable plastics boats manufacturing",
    "Inflatable rife rafts manufacturing",
    "Pleasure boats manufacturing",
    "Rigid inflatable boats (RIBs) manufacturing",
    "Sailboat building not done in shipyards",
    "Underwater remotely operated vehicles (ROVs) manufacturing in boat yards",
    "Unmanned and robotic watercraft manufacturing in boat yards",
    "Yacht building not done in shipyards",
  ],
  "634": [
    "Barge building",
    "Cargo ship building",
    "Commercial fishing boat building",
    "Container ship building",
    "Dredge building",
    "Ferryboat building",
    "Fireboat building",
    "Floating drydock building",
    "Floating oil and gas drilling and production platforms building",
    "Hydrofoil vessel building and repairing in shipyard",
    "Naval ship building",
    "Oil and gas offshore floating platforms manufacturing",
    "Passenger ship building",
    "Patrol boat building",
    "Sailing ships, commercial, manufacturing",
    "Ship dismantling at shipyards",
    "Ship repair done in a shipyard",
    "Ship scaling services done at a shipyard",
    "Ships manufacturing",
    "Shipyard",
    "Submarine building",
    "Towboat building and repairing",
    "Tugboat building",
    "Underwater remotely operated vehicles (ROVs) manufacturing in shipyards",
    "Unmanned and robotic watercraft manufacturing in shipyards",
    "Yachts built in shipyards",
  ],
  "636": ["Boiler chipping, cleaning and scaling", "Heating boiler installation"],
  "637": [
    "Hooks, metal screw manufacturing",
    "Metal bolts manufacturing",
    "Metal cotter pins manufacturing",
    "Metal dowel pins manufacturing",
    "Metal hook and eye latches manufacturing",
    "Metal hooks manufacturing",
    "Metal hose clamps manufacturing",
    "Metal lock washers manufacturing",
    "Metal machine keys manufacturing",
    "Metal nuts manufacturing",
    "Metal rivets manufacturing",
    "Metal screw eyes manufacturing",
    "Metal screws manufacturing",
    "Metal spring pins manufacturing",
    "Metal spring washers manufacturing",
    "Metal toggle bolts manufacturing",
    "Metal turnbuckles manufacturing",
    "Metal washers manufacturing",
  ],
  "641": [
    "Glass beer bottles manufacturing",
    "Glass beverage containers manufacturing",
    "Glass bottles manufacturing",
    "Glass containers for packaging, bottling and canning manufacturing",
    "Glass food packaging manufacturing",
    "Glass jars for packaging, bottling and canning manufacturing",
    "Glass packaging containers manufacturing",
    "Glass soda bottles manufacturing",
  ],
  "642": ["Plastic bottles manufacturing"],
  "645": [
    "Barrel heading and staves manufacturing",
    "Box cleats, wood, manufacturing",
    "Box shook manufacturing",
    "Cooperage manufacturing",
    "Cooperage stock manufacturing",
    "Cooperage stock mills",
    "Coopered hot tubs manufacturing",
    "Coopered wood hogsheads manufacturing",
    "Fruits and vegetables baskets manufacturing",
    "Hogsheads tobacco hogsheads manufacturing",
    "Plywood drums manufacturing",
    "Plywood pails manufacturing",
    "Round stave baskets (e.g., fruit, vegetable) manufacturing",
    "Sawed barrel staves manufacturing",
    "Splint baskets manufacturing",
    "Split wood hoops manufacturing",
    "Tobacco hogshead stock manufacturing",
    "Vegetable baskets manufacturing",
    "Veneer berry cups manufacturing",
    "Wood ammunition boxes manufacturing",
    "Wood barrel heading manufacturing",
    "Wood barrels manufacturing",
    "Wood baskets manufacturing",
    "Wood berry crates manufacturing",
    "Wood boxes manufacturing",
    "Wood buckets manufacturing",
    "Wood carrier trays manufacturing",
    "Wood casks manufacturing",
    "Wood chicken coops manufacturing",
    "Wood cigar boxes manufacturing",
    "Wood containers manufacturing",
    "Wood coopered tubs manufacturing",
    "Wood crates manufacturing",
    "Wood egg cases manufacturing",
    "Wood firkins and kits manufacturing",
    "Wood fruit crates, wood manufacturing",
    "Wood greenhouse flats manufacturing",
    "Wood jewelry boxes manufacturing",
    "Wood kegs manufacturing",
    "Wood packing cases manufacturing",
    "Wood packing crates manufacturing",
    "Wood pails manufacturing",
    "Wood pallet containers manufacturing",
    "Wood pallet parts manufacturing",
    "Wood pallets manufacturing",
    "Wood shipping cases and drums manufacturing",
    "Wood shipping cases manufacturing",
    "Wood shipping crates manufacturing",
    "Wood shipping drums manufacturing",
    "Wood skids and pallets manufacturing",
    "Wood tanks manufacturing",
    "Wood tool chests manufacturing",
    "Wood vegetable crates manufacturing",
  ],
  "646": [
    "Adobe bricks manufacturing",
    "Bauxite brick manufacturing",
    "Carbon brick manufacturing",
    "Clay bricks manufacturing",
    "Clay paving brick manufacturing",
    "Clay refractories firebrick manufacturing",
    "Clay refractory bricks manufacturing",
    "Nonclay refractory bricks manufacturing",
    "Vitrified clay liner brick and plates manufacturing",
  ],
  "647": [
    "Brushes manufacturing",
    "Floor and dust mops manufacturing",
    "Hairbrushes manufacturing",
    "Hand and machine brooms manufacturing",
    "Paint brushes manufacturing",
    "Paint rollers manufacturing",
    "Shaving brushes manufacturing",
  ],
  "648": [
    "Buckles and buckle parts manufacturing",
    "Buttons manufacturing",
    "Cuff links manufacturing",
    "Fasteners manufacturing",
    "Hook and eye fasteners manufacturing",
    "Metal eyelets manufacturing",
    "Slide fasteners manufacturing",
    "Zippers manufacturing",
  ],
  "649": ["Light gauge metal aluminum cans manufacturing"],
  "650": ["Candles manufacturing"],
  "651": [
    "Candied fruits and fruit peel manufacturing",
    "Candy covered popcorn popped manufacturing",
    "Chewing gum base manufacturing",
    "Chewing gum manufacturing",
    "Confectionery cake ornaments manufacturing",
    "Corn confections manufacturing",
    "Cough drops manufacturing",
    "Covered nuts manufacturing",
    "Crystallized fruits and fruit peel manufacturing",
    "Fruit peel products (e.g., candied, crystallized, glace, glazed) manufacturing",
    "Fruits (e.g., candied, crystallized, glazed) manufacturing",
    "Halvah manufacturing",
    "Hard candies manufacturing",
    "Jelly candies manufacturing",
    "Licorice candy manufacturing",
    "Marshmallow creme manufacturing",
    "Marshmallows manufacturing",
    "Marzipan manufacturing",
    "Nonchocolate candy bars manufacturing",
    "Nonchocolate candy stores",
    "Nonchocolate confectionery manufacturing",
    "Nonchocolate covered breakfast bars manufacturing",
    "Nonchocolate fudge manufacturing",
    "Nonchocolate granola bars and clusters manufacturing",
    "Nonmedicated lozenges candy manufacturing",
    "Popcorn balls manufacturing",
    "Sugared and stuffed dates manufacturing",
    "Synthetic chocolate manufacturing",
    "Toffee manufacturing",
  ],
  "652": [
    "Artist's canvas board manufacturing",
    "Artist's canvas manufacturing",
    "Carbon paper manufacturing",
    "Framed blackboards manufacturing",
    "Framed chalkboards manufacturing",
    "Frames for artist's canvases manufacturing",
    "Inked ribbons manufacturing",
    "Marker boards manufacturing",
    "Stencil paper manufacturing",
    "Typewriter ribbons manufacturing",
  ],
  "654": ["Carpenters", "Carpentry work"],
  "655": ["Ship joinery contractors"],
  "656": [
    "Automobile and aircraft carpet and rug manufacturing",
    "Bath mats and bath sets made in carpet mills",
    "Carpets and rugs made from textile materials",
    "Doormats (except entirely of rubber or plastics) manufacturing",
    "Finishing (e.g., dyeing) rugs and carpets",
    "Rugs and carpets made from textile materials",
    "Synthetic or artificial turf manufacturing",
    "Weaving rugs, carpets, and mats",
  ],
  "657": [
    "Carpet cleaning on customers' premises",
    "Furniture cleaning on customers' premises",
    "Upholstery cleaning on customers' premises",
  ],
  "658": [
    "Caulking compounds manufacturing",
    "Construction adhesives manufacturing",
    "Dextrin glues manufacturing",
    "Glues manufacturing",
    "Joint compounds manufacturing",
    "Pipe sealing compounds manufacturing",
    "Plumbers' putty manufacturing",
    "Rubber cement manufacturing",
    "Rubber cements manufacturing",
    "Sealing compounds for pipe threads and joints manufacturing",
    "Starch glues manufacturing",
  ],
  "662": [
    "Central-mixed concrete manufacturing",
    "Concrete batch plants",
    "Ready-mix concrete manufacturing and distributing",
    "Truck-mixed concrete manufacturing",
  ],
  "663": ["Chimney cleaning services", "Chimney sweep services"],
  "664": [
    "Advanced and technical ceramic products manufacturing",
    "Alumina porcelain insulators manufacturing",
    "Architectural sculptures, clay, manufacturing",
    "Beryllia porcelain insulators manufacturing",
    "Bone china manufacturing",
    "Ceramic electrical insulators manufacturing",
    "Ceramic electrical supplies manufacturing",
    "Ceramic or ferrite permanent magnets manufacturing",
    "Chemical porcelain manufacturing",
    "Chemical stoneware manufacturing",
    "China cooking ware manufacturing",
    "China cookware manufacturing",
    "Clay and ceramic statuary manufacturing",
    "Clay ecclesiastical statuary manufacturing",
    "Clay sculptures manufacturing",
    "Coarse earthenware kitchen articles manufacturing",
    "Coarse earthenware table and kitchen articles manufacturing",
    "Coarse earthenware table articles manufacturing",
    "Cooking ware manufacturing",
    "Crockery manufacturing",
    "Decalcomania on china and glass for the trade",
    "Decorating china for the trade",
    "Earthenware pyrometric cones manufacturing",
    "Eartherware table articles manufacturing",
    "Earthware pyrometric cones manufacturing",
    "Electrical porcelain insulators manufacturing",
    "Fine earthenware table articles manufacturing",
    "Fine earthware cooking ware manufacturing",
    "Firing china for the trade",
    "Garden pottery manufacturing",
    "Molded electrical and electronic device porcelain parts manufacturing",
    "Porcelain spark plug insulators manufacturing",
    "Porcelain textile guides manufacturing",
    "Pottery ashtrays manufacturing",
    "Pottery dishes manufacturing",
    "Pottery filtering media manufacturing",
    "Pottery forms for dipped rubber products manufacturing",
    "Pottery lamp bases manufacturing",
    "Pottery made and sold on site ",
    "Pottery products manufacturing",
    "Pottery stationery articles manufacturing",
    "Pottery vases manufacturing",
    "Pyrometer tubes manufacturing",
    "Red earthenware flower pots manufacturing",
    "Red earthware florists' articles manufacturing",
    "semivitreous commercial and household earthenware manufacturing",
    "Semivitreous earthenware kitchenware manufacturing",
    "Semivitreous household earthenware manufacturing",
    "Steatite porcelain insulators manufacturing",
    "Stoneware manufacturing",
    "Titania porcelain insulators manufacturing",
    "Vitreous china and earthenware bathroom accessories manufacturing",
    "Vitreous china and earthenware faucet handles manufacturing",
    "Vitreous china and earthenware soap dishes manufacturing",
    "Vitreous china and earthware faucet handles manufacturing",
    "Vitreous china and earthware towel bar holders manufacturing",
    "Vitreous china bidets manufacturing",
    "Vitreous china drinking fountains manufacturing",
    "Vitreous china flush tanks manufacturing",
    "Vitreous china hotel tableware and kitchen articles manufacturing",
    "Vitreous china household tableware and kitchen articles manufacturing",
    "Vitreous china kitchenware manufacturing",
    "Vitreous china lavatories manufacturing",
    "Vitreous china plumbing fixtures manufacturing",
    "Vitreous china sinks manufacturing",
    "Vitreous china table articles manufacturing",
    "Vitreous china tableware manufacturing",
    "Vitreous china toilet fixtures manufacturing",
    "Vitreous china urinals manufacturing",
    "Vitreous china water closet bowls manufacturing",
  ],
  "665": [
    "Alumina fused refractories manufacturing",
    "Aluminous refractory cement manufacturing",
    "Architectural terra cotta manufacturing",
    "Ceramic floor tile manufacturing",
    "Ceramic mosaic tile manufacturing",
    "Ceramic wall and floor tile manufacturing",
    "Ceramic wall tile manufacturing",
    "Clay building tile manufacturing",
    "Clay castable refractories manufacturing",
    "Clay drain tile manufacturing",
    "Clay glasshouse floaters manufacturing",
    "Clay insulating firebrick and shapes manufacturing",
    "Clay kiln furniture manufacturing",
    "Clay quarry tiles manufacturing",
    "Clay refractories manufacturing",
    "Clay refractory cement manufacturing",
    "Clay refractory glasshouse pots manufacturing",
    "Clay refractory mortars  manufacturing",
    "Clay roofing and drain tile manufacturing",
    "Clay roofing tile manufacturing",
    "Clay sewer pipe and fittings manufacturing",
    "Clay sewer tile manufacturing",
    "Clay stove lining manufacturing",
    "Clay structural tile manufacturing",
    "Clay wall coping manufacturing",
    "Clue flue lining manufacturing",
    "Fire clay blocks manufacturing",
    "Fire clay crucibles manufacturing",
    "Floor and wall ceramic tiles manufacturing",
    "Glasshouse refractories manufacturing",
    "Graphite, magnesite, chrome, silica, or other nonclay materials crucibles manufacturing",
    "Magnesia refractory cement manufacturing",
    "Nonclay castable refractories manufacturing",
    "Nonclay refractories manufacturing",
    "Nonclay refractory cement manufacturing",
    "Refractory clay tile manufacturing",
    "Structural clay tile manufacturing",
    "Vitrified clay conduit manufacturing",
  ],
  "669": [
    "Academic caps and gowns (except apparel contractors)",
    "Aprons women's and girls' (except apparel contractors)",
    "Aprons, work men's and boys' (except apparel contractors)",
    "Athletic clothing men's and boys' (except apparel contractors)",
    "Athletic clothing womens', girls', and infants' (except apparel contractors)",
    "Athletic uniforms (except apparel contractors)",
    "Band uniforms (except apparel contractors)",
    "Band uniforms cut and sewn from purchased fabric (except apparel contractors)",
    "Beachwear men's and boys' (except apparel contractors)",
    "Beachwear women's, girls', and infants' (except apparel contractors)",
    "Burial garments (except apparel contractors)",
    "clerical vestments(except contractors)",
    "Coats women's, girls', and infants' (except apparel contractors)",
    "Coats, men's and boys' (except apparel contractors)",
    "Costumes (except apparel contractors)",
    "Diaper covers infants' (except apparel contractors)",
    "Dresses women's and girls' (except apparel contractors)",
    "Gowns (except apparel contractors)",
    "Hosiery, women's and girls' (except apparel contractors)",
    "Infants clothing (except apparel contractors)",
    "Jackets men's and boys' (except apparel contractors)",
    "Jackets women's, girls', and infants' (except apparel contractors)",
    "Jeans men's and boys' (except apparel contractors)",
    "Jeans women's and girls' (except apparel contractors)",
    "Leather apparel (except apparel contractors)",
    "Leggings women's, girls', and infants'  (except apparel contractors)",
    "Lingerie (except apparel contractors)",
    "Mackinaws men's and boys' (except apparel contractors)",
    "Nightwear men's and boys' (except apparel contractors)",
    "Nightwear women's, girls', and infants' (except contractors)",
    "Outerwear men's and boys' (except apparel contractors)",
    "Outwear women's, girls', and infants' (except apparel contractors)",
    "Pants men's and boys' (except apparel contractors)",
    "Pants women's, girls', and infants'(except apparel contractors)",
    "Plastics gowns (except apparel contractors)",
    "Prayer shawls (except apparel contractors)",
    "Raincoats (except apparel contractors)",
    "Riding clothes men's and boys' (except apparel contractors)",
    "Robes men's and boys' (except apparel contractors)",
    "Robes women's, girls', and infants' (except apparel contractors)",
    "Rubber pants manufacturing",
    "Service apparel men's and boys' (except apparel contractors)",
    "Service apparel women's and girls' (except apparel contractors)",
    "Shirts men's and boys' (except apparel contractors)",
    "Shirts women's, girls', and infants' (except apparel contractors)",
    "Shorts men's and boys' (except apparel contractors)",
    "Skirts girls', and infants' (except apparel contractors)",
    "Suits men's and boys' (except apparel contractors)",
    "Suits women's, girls', and infants' (except apparel contractors)",
    "Sweat bands (except apparel contractors)",
    "Sweaters men's and boys' (except apparel contractors)",
    "Sweaters women's, girls', and infants' (except apparel contractors)",
    "Sweatshirts men's and boys' (except apparel contractors)",
    "Training pants infants' (except apparel contractors)",
    "Tuxedos (except apparel contractors)",
    "Underwear women's, girls', and infants' (except apparel contractors)",
    "Underwear men's and boys' (except apparel contractors)",
    "Uniforms, men's and boys' (except apparel contractors)",
    "Vests men's and boys' (except apparel contractors)",
    "Vests women's and girls' (except apparel contractors)",
    "Women's uniforms (except apparel contractors)",
  ],
  "670": [
    "Burial caskets and cases manufacturing",
    "Burial caskets manufacturing",
    "Burial vaults manufacturing",
    "Vaults manufacturing",
  ],
  "671": [
    "Alpha and beta naphthol manufacturing",
    "Aminoanthraquinone manufacturing",
    "Aminoazobenzene manufacturing",
    "Aminoazotoluene manufacturing",
    "Aminophenol manufacturing",
    "Aniline manufacturing",
    "Annatto extract manufacturing",
    "Anthracene manufacturing",
    "Azobenzene manufacturing",
    "Benzaldehyde manufacturing",
    "Benzoic acid manufacturing",
    "Brazilwood extract manufacturing",
    "Brewers' pitch made by distillation of wood",
    "Charcoal manufacturing",
    "Chestnut extract manufacturing",
    "Chlorobenzene manufacturing",
    "Chloronaphthalene manufacturing",
    "Chlorophenol manufacturing",
    "Chlorotoluene manufacturing",
    "Coal tar distillates manufacturing",
    "Creosote made by distillation of coal tar",
    "Creosote made by distillation of wood tar",
    "Cresols made by distillation of coal tar",
    "Cresylic acids made from refined petroleum or natural gas",
    "Cyclic crudes made by distillation of coal tar",
    "Cyclic intermediates manufacturing",
    "Cyclohexane manufacturing",
    "Cyclopentane made from refined petroleum or natural gas",
    "Cyclopropane made from refined petroleum or natural gas",
    "Cycloterpenes manufacturing",
    "Diethylcyclohexane manufacturing",
    "Diphenylamine manufacturing",
    "Dragon's blood manufacturing",
    "Fustic wood extract manufacturing",
    "Gambier extract manufacturing",
    "Gum and wood chemicals manufacturing",
    "Gum and wood naval stores manufacturing",
    "Halogenated aromatic hydrocarbon derivatives manufacturing",
    "Hardwood distillates manufacturing",
    "Hemlock extract manufacturing",
    "Hydroquinone manufacturing",
    "Isocyanates manufacturing",
    "Logwood extract manufacturing",
    "Maleic anhydride manufacturing",
    "Mangrove extract manufacturing",
    "Methyl acetone manufacturing",
    "Myrobalans extract manufacturing",
    "Naphtha made by distillation of coal tar",
    "Naphthalene made from refined petroleum or natural gas",
    "Naphthalenesulfonic acid manufacturing",
    "Naphthenic acids made from refined petroleum or natural gas",
    "Naphthol sulfonic acids manufacturing",
    "Natural acetate of lime manufacturing",
    "Natural acetone manufacturing",
    "Natural camphor manufacturing",
    "Natural dyeing and tanning extracts manufacturing",
    "Natural dyes manufacturing",
    "Natural ethyl acetate manufacturing",
    "Natural methyl alcohol manufacturing",
    "Natural tanning extracts and materials manufacturing",
    "Natural wood alcohol manufacturing",
    "Nitrated hydrocarbon derivatives manufacturing",
    "Nitroaniline manufacturing",
    "Nitrobenzene manufacturing",
    "Nitrophenol manufacturing",
    "Nitrosated hydrocarbon derivatives manufacturing",
    "Oak extract manufacturing",
    "Oils made by distillation of coal tar",
    "Orthodichlorobenzene manufacturing",
    "Pentachlorophenol manufacturing",
    "Phenol manufacturing",
    "Phthalic anhydride manufacturing",
    "Pine oil manufacturing",
    "Pinene manufacturing",
    "Pitch made by distillation of coal tar",
    "Pyroligneous acids manufacturing",
    "Quebracho extracts manufacturing",
    "Quercitron extracts manufacturing",
    "Resorcinol manufacturing",
    "Rosins made by distillation of pine gum or pine wood",
    "Softwood distillates manufacturing",
    "Solvent naphtha made by distillation of coal tar",
    "Sulfonated naphthalene manufacturing",
    "Sulphonated derivatives manufacturing",
    "Sumac extract manufacturing",
    "Tall oil manufacturing",
    "Tannic acid manufacturing",
    "Tar and tar oils made by distillation of wood",
    "Tar made by distillation of coal tar",
    "Toluidines manufacturing",
    "Turpentine made by distillation of pine gum or pine wood",
    "Valonia extract manufacturing",
    "Wattle extract manufacturing",
    "Witch hazel extract manufacturing",
    "Wood charcoal briquettes manufacturing",
    "Wood distillates manufacturing",
    "Wood oils made by distillation of wood",
    "Wood oils manufacturing",
    "Wood pitch manufacturing",
  ],
  "672": [
    "Communication equipment installation",
    "Electronic system installation",
    "Environmental control system installation",
    "Fiber optic cable  installation",
    "Home standby power generator installation",
    "Home theater installation",
    "Humidity control system installation",
    "Intercom system installation",
    "Photovoltaic panels installation",
    "Public address system installation",
    "Sound equipment installation",
    "Temperature control system installation",
  ],
  "673": [
    "Alarm system central monitoring equipment manufacturing",
    "Alarm systems and equipment manufacturing",
    "Automotive theft alarm systems manufacturing",
    "Burglar alarm systems and equipment manufacturing",
    "Car alarm manufacturing",
    "Carbon monoxide detectors manufacturing",
    "Fire detection and alarm systems manufacturing",
    "Glass breakage detection and signaling devices",
    "Intercom systems and equipment manufacturing",
    "Motion alarms manufacturing",
    "Motion detectors, security system manufacturing",
    "Portable intrusion detection and signaling devices manufacturing",
    "Railroad signaling equipment manufacturing",
    "Remote control units manufacturing",
    "Signals manufacturing",
    "Sirens manufacturing",
    "Smoke detectors manufacturing",
    "Street light controlling equipment manufacturing",
    "Theft prevention signaling devices manufacturing",
    "Traffic advisory and signaling systems manufacturing",
    "Traffic signals manufacturing",
    "Video-based stadium displays manufacturing",
  ],
  "674": [
    "Amplifiers manufacturing",
    "Automobile radio receivers manufacturing",
    "Camcorders manufacturing",
    "Car stereos manufacturing",
    "Clock radios manufacturing",
    "Coin- or card-operated jukebox manufacturing",
    "Compact disc players manufacturing",
    "Digital video disc players manufacturing",
    "DVD players manufacturing",
    "E-book readers manufacturing",
    "Home stereo systems manufacturing",
    "Home tape recorders and players manufacturing",
    "Home theater audio and video equipment manufacturing",
    "Household tape players and recorders manufacturing",
    "Household video cameras manufacturing",
    "Household video projectors manufacturing",
    "Jukeboxes manufacturing",
    "Loudspeakers manufacturing",
    "Microphones manufacturing",
    "Personal audio and video headsets, including virtual reality headsets manufacturing",
    "Portable stereo systems manufacturing",
    "Projection television manufacturing",
    "Public address systems and equipment manufacturing",
    "Radio headphones manufacturing",
    "Radio receiving sets manufacturing",
    "Speaker systems manufacturing",
    "Television sets manufacturing",
    "TV sets manufacturing",
    "VCR manufacturing",
  ],
  "675": ["Mineral wool products manufacturing"],
  "676": ["Transit-mixed concrete manufacturing"],
  "677": [
    "Concrete chimney construction",
    "Concrete finishing",
    "Concrete floor surfacing",
    "Concrete pouring",
    "Concrete pumping",
    "Concrete repair",
    "Concrete resurfacing",
    "Footing and foundation concrete contractors",
    "Grouting",
    "Mud-jacking contractors",
    "Poured concrete foundation building contractors",
    "Poured concrete retaining wall construction",
  ],
  "678": [
    "Concrete furniture manufacturing",
    "Ornamental and statuary precast concrete products manufacturing",
    "Precast concrete and terrazzo burial vaults manufacturing",
    "Precast concrete garbage incinerators manufacturing",
    "Precast concrete garden furniture manufacturing",
    "Precast concrete incinerators manufacturing",
    "Precast concrete products manufacturing",
    "Precast concrete silos manufacturing",
    "Precast terrazzo products manufacturing",
    "Prestressed concrete products manufacturing",
  ],
  "679": [
    "Concrete cast stone manufacturing",
    "Concrete chimney caps manufacturing",
    "Concrete copings manufacturing",
    "Concrete lintels manufacturing",
    "Concrete poles manufacturing",
    "Concrete posts manufacturing",
    "Concrete railroad ties manufacturing",
    "Concrete roofing tile manufacturing",
    "Concrete sills manufacturing",
    "Concrete tanks manufacturing",
    "Precast concrete architectural wall panels manufacturing",
    "Precast concrete girders and beams manufacturing",
    "Precast concrete girders manufacturing",
  ],
  "682": [
    "Abutment construction",
    "Airport runway construction",
    "Airport runway line painting",
    "Bridge approach construction",
    "Bridge construction",
    "Bridge decking construction",
    "Causeway construction",
    "Culverts construction",
    "Elevated highway construction",
    "Guardrail construction",
    "Airport runway grading",
    "Pothole filling",
    "Logging road construction",
    "Oil field road construction",
    "Overpass construction",
    "Painting traffic lanes or parking lots",
    "Parking lot marking and line painting",
    "Parkway construction",
    "Public sidewalk construction",
    "Road construction",
    "Street construction",
    "Tarring roads",
    "Traffic lane painting",
    "Trestle construction",
    "Underpass construction",
  ],
  "689": [
    "Aqueduct construction",
    "Artesian well construction",
    "Capping of water wells",
    "Fire hydrant installation",
    "Geothermal drilling",
    "Hydrant and flushing hydrant installation",
    "Irrigation project construction",
    "Pipe and connection sewer main construction",
    "Reservoir construction",
    "Sanitary sewer construction",
    "Sewage collection and disposal line construction",
    "Sewage disposal plant construction",
    "Sewage treatment lagoon construction",
    "Sewage treatment plant construction",
    "Sewer and water construction distribution line",
    "Sewer construction",
    "Storm sewer construction",
    "Utility line construction",
    "Water and sewage system pumping station construction",
    "Water and sewage treatment plant construction management",
    "Water and sewer line construction management",
    "Water desalination plant construction",
    "Water filtration plant construction",
    "Water main and line construction",
    "Water pumping or lift station construction",
    "Water sampling station installation",
    "Water system storage tank and tower construction",
    "Water treatment plant construction",
    "Water well pump and well piping system installation",
  ],
  "690": [
    "Highway construction",
    "Highway line painting",
    "Highway, road, street and bridge construction management",
    "Highway, road, street, or bridge sign erection",
    "Painting lines on highways, streets and bridges",
  ],
  "692": [
    "Addition, alteration and renovation of commercial and institutional building",
    "Addition, alteration and renovation of commercial warehouse",
    "Addition, alteration and renovation of hotel and motel",
    "Addition, alteration and renovation of industrial warehouse",
    "Addition, alteration and renovation, hotel and motel",
    "Administration building construction",
    "Airport building construction",
    "Airport terminal construction",
    "Amusement facility construction",
    "Animal shelter and clinic construction",
    "Arena construction",
    "Armory construction",
    "Auditorium construction",
    "Bank building construction",
    "Barber shop construction",
    "Barrack construction",
    "Beauty salon construction",
    "Broadcasting station construction",
    "Bunkhouse construction",
    "Bus shelter construction",
    "Bus terminal construction",
    "Casino construction",
    "Cinema construction",
    "Civic center construction",
    "Clinic construction",
    "Cold storage plant construction",
    "Commercial and institutional building",
    "Commercial and institutional building construction management",
    "Commercial and institutional building handyman construction service",
    "Commercial and institutional building speculative builders",
    "Commercial building construction",
    "Data center construction",
    "Dormitory construction",
    "Dry cleaning plant construction",
    "Educational building construction",
    "Farm building construction",
    "Fire and flood restoration of commercial and institutional buildings",
    "Fire station construction",
    "Garage and service station, commercial, construction",
    "Grain bin construction",
    "Grain elevator construction",
    "Hangar construction",
    "Hatchery construction",
    "Health and athletic club construction",
    "Hospital construction",
    "Hotel construction",
    "Indoor athletic court construction",
    "Indoor ice rink construction",
    "Indoor swimming facility construction",
    "Indoor swimming pool construction",
    "Indoor tennis court construction",
    "Industrial warehouse construction",
    "Institutional building construction",
    "Institutional building construction general contractors",
    "Jail construction",
    "Laboratory construction",
    "Library construction",
    "Logging camp construction",
    "Mausoleum construction",
    "Monument construction",
    "Motel construction",
    "Museum construction",
    "Office building construction",
    "Parking garage construction",
    "Penitentiary construction",
    "Post office construction",
    "Prefabricated commercial building erection",
    "Prefabricated institutional building erection",
    "Prison construction",
    "Public warehouse construction",
    "Radio and television broadcast studio construction",
    "Radio station construction",
    "Railway station construction",
    "Recreational facility building construction",
    "Religious building (e.g., church, synagogue, mosque, temple) construction",
    "Restaurant construction",
    "Salon construction",
    "School building construction",
    "Self-storage facility construction",
    "Service station construction",
    "Shopping center construction",
    "Shopping mall construction",
    "Silo construction",
    "Stadium and arena construction",
    "Storage elevator construction",
    "Store construction",
    "Television station construction",
    "Theater construction",
    "Truck terminal construction",
  ],
  "693": [
    "Automobile dead storage",
    "Bonded warehousing",
    "Bulk petroleum storage",
    "Document storage and warehousing",
    "Lumber storage terminals",
    "Private warehousing and storage",
    "Public warehousing and storage",
    "Warehousing",
    "Whiskey warehousing",
  ],
  "694": [
    "After-shave preparations manufacturing",
    "Baby powder and baby oil manufacturing",
    "Bath salts manufacturing",
    "Blending and compounding perfume bases",
    "Bubble bath preparations manufacturing",
    "Colognes manufacturing",
    "Cosmetic creams, lotions and oils manufacturing",
    "Cosmetic rouge manufacturing",
    "Dental floss manufacturing",
    "Denture adhesives manufacturing",
    "Depilatory preparations manufacturing",
    "Effervescent denture cleaners manufacturing",
    "Eye makeup manufacturing",
    "Face blushes manufacturing",
    "Face creams manufacturing",
    "Foundations manufacturing",
    "Hair coloring preparations manufacturing",
    "Hair preparations manufacturing",
    "Hair shampoos and conditioners manufacturing",
    "Hair sprays manufacturing",
    "Hair tints, dyes, and rinses manufacturing",
    "Hand lotions manufacturing",
    "Lipsticks manufacturing",
    "Lotions manufacturing",
    "Makeup manufacturing",
    "Manicure preparations manufacturing",
    "Mouthwashes manufacturing",
    "Nail polish remover manufacturing",
    "Nail polishes manufacturing",
    "Perfumes manufacturing",
    "Permanent wave preparations manufacturing",
    "Personal antiperspirants manufacturing",
    "Personal deodorants manufacturing",
    "Powders manufacturing",
    "Premoistened towelettes manufacturing",
    "Scented sachet manufacturing",
    "Shaving preparations manufacturing",
    "Sunscreen lotions and oils manufacturing",
    "Suntan lotions and oils manufacturing",
    "Talcum powders manufacturing",
    "Toilet preparations manufacturing",
    "Toilet water manufacturing",
  ],
  "695": ["Cotton ginning", "Ginning cotton"],
  "696": [
    "Barber's scissors manufacturing",
    "Blades, knife and razor manufacturing",
    "Butcher's knives manufacturing",
    "Carving sets manufacturing",
    "Cleavers manufacturing",
    "Cooking utensils fabricated metal manufacturing",
    "Cookware fabricated metal manufacturing",
    "Cutlery nonprecious and precious plated metal manufacturing",
    "Enameled metal cutting utensils",
    "Fingernail and toenail clippers manufacturing",
    "Fishing knives manufacturing",
    "Flatware nonprecious and precious plated metal manufacturing",
    "Household pressure cookers manufacturing",
    "Hunting knives manufacturing",
    "Kitchen cutlery, nonprecious and precious plated metal manufacturing",
    "Kitchen utensils fabricated metal manufacturing",
    "Knife blades manufacturing",
    "Knife blanks manufacturing",
    "Knives manufacturing",
    "Nonelectric hair clippers for human use manufacturing",
    "Nonelectric household shears manufacturing",
    "Nonelectric scissors manufacturing",
    "Nonelectric tailors' scissors manufacturing",
    "Nonstick metal cooking utensils",
    "Plated metal cutlery manufacturing",
    "Plated metal flatware manufacturing",
    "Pocket knives manufacturing",
    "Potato mashers manufacturing",
    "Pots and pans fabricated metal manufacturing",
    "Razor blades manufacturing",
    "Razors manufacturing",
    "Safety razor blades manufacturing",
    "Safety razors manufacturing",
    "Straight razors manufacturing",
    "Swords nonprecious and precious plated metal manufacturing",
    "Table cutlery nonprecious and precious plated metal manufacturing",
    "Table forks nonprecious and precious plated metal manufacturing",
    "Table spoons nonprecious and precious plated metal manufacturing",
    "Teakettles and coffee pots fabricated metal manufacturing",
  ],
  "697": [
    "Buttermilk manufacturing",
    "Chocolate milk based drink manufacturing",
    "Chocolate milk manufacturing",
    "Cottage cheese manufacturing",
    "Cream manufacturing",
    "Eggnog manufacturing",
    "Flavored milk drinks manufacturing",
    "Fluid milk substitutes processing",
    "Milk based beverages manufacturing",
    "Milk based drinks manufacturing",
    "sour cream based dips manufacturing",
    "Sour cream manufacturing",
    "Sour cream substitutes manufacturing",
    "Soy milk manufacturing",
    "Whipped topping manufacturing",
    "Whipping cream manufacturing",
    "Yogurt manufacturing",
  ],
  "698": [
    "Container trucking services (local)",
    "Contract bulk mail truck transportation (local)",
    "General freight transfer trucking services (local)",
    "General freight trucking (local)",
    "General motor freight carrier (local)",
  ],
  "699": [
    "Detergents manufacturing",
    "Dishwasher detergents manufacturing",
    "Hand sanitizers manufacturing",
    "Presoaks manufacturing",
    "Teeth whiteners manufacturing",
    "Toothpastes, gels and tooth powders manufacturing",
  ],
  "700": ["Dentifrices manufacturing", "Scouring cleansers manufacturing"],
  "701": [
    "Aluminum die-casting foundries",
    "Aluminum die-castings foundries",
    "Aluminum die-castings manufacturing",
    "Beryllium die-castings manufacturing",
    "Brass die-castings manufacturing",
    "Bronze die-castings manufacturing",
    "Copper die-casting foundries",
    "Copper die-castings manufacturing",
    "Lead die-castings manufacturing",
    "Magnesium die-castings manufacturing",
    "Nickel alloy die-castings manufacturing",
    "Nickel die-castings manufacturing",
    "Nonferrous die-casting foundries",
    "Nonferrous metals die-castings foundries",
    "Titanium die-castings manufacturing",
    "Zinc die-castings manufacturing",
  ],
  "702": [
    "Aromatic wood gathering",
    "Balsam needles gathering",
    "Bark gathering",
    "Cherry gum gathering",
    "Chestnut gum gathering",
    "Forest nurseries for reforestation",
    "Gathering of forest products",
    "Gathering, extracting and selling tree seeds",
    "Ginseng gathering",
    "Gum (i.e., forest product) gathering",
    "Harvesting berries or nuts from native and non-cultivated plants",
    "Hemlock gum gathering",
    "Huckleberry greens gathering",
    "Moss gathering",
    "Nurseries for reforestation growing trees",
    "Pine gum extracting",
    "Spanish moss gathering",
    "Sphagnum moss gathering",
    "Spruce gum gathering",
    "Teaberries gathering",
    "Tree seed extracting",
    "Tree seed gathering",
    "Tree seed growing for reforestation",
    "Truffles gathering",
  ],
  "705": [
    "Door and window frame construction",
    "Metal frame residential window installation",
    "Prefabricated door and window installation",
    "Prefabricated sash and door installation",
    "Residential aluminum door and window installation",
    "Residential garage door installation",
    "Residential hermetically sealed window unit installation",
    "Residential overhead door installation",
    "Residential prefabricated window and door of any material  installation",
    "Window installation",
  ],
  "706": ["Dredging"],
  "707": [
    "Blasting services, metal mining, on a contract basis",
    "Boring test holes for metal mining on a contract basis",
    "Draining or pumping of metal mines on a contract basis",
    "Drilling services for metal mining on a contract basis",
    "Exploration services for metal on a contract basis",
    "Metal mining support services on a contract basis",
    "Mine development for metal mining on a contract basis",
    "Mine shaft sinking services for metal mining on a contract basis",
    "Mine tunneling services for metal mining on a contract basis",
    "Overburden removal for metal mining on a contract basis",
    "Prospect and test drilling services for metal mining on contract basis",
    "Pumping or draining metal mines on a contract basis",
    "Removal of overburden for metal mining on a contract basis",
    "Shaft sinking for metal mines on a contract basis",
    "Sinking shafts for metal mining on a contract basis",
    "Stripping overburden services for metal mining on a contract basis",
    "Test drilling for metal mining on a contract basis",
  ],
  "708": ["Drilling water wells", "Water well drilling, digging, boring or sinking"],
  "710": [
    "Light gauge metal air cargo containers manufacturing",
    "Light gauge metal ammunition boxes manufacturing",
    "Light gauge metal barrels manufacturing",
    "Light gauge metal beer kegs manufacturing",
    "Light gauge metal bins manufacturing",
    "Light gauge metal boxes manufacturing",
    "Light gauge metal cash boxes manufacturing",
    "Light gauge metal collapsible tubes manufacturing",
    "Light gauge metal containers manufacturing",
    "Light gauge metal drums manufacturing",
    "Light gauge metal fluid milk shipping containers manufacturing",
    "Light gauge metal garbage cans manufacturing",
    "Light gauge metal hoppers manufacturing",
    "Light gauge metal ice chests or coolers manufacturing",
    "Light gauge metal laundry hampers manufacturing",
    "Light gauge metal lunch boxes manufacturing",
    "Light gauge metal mailboxes manufacturing",
    "Light gauge metal shipping barrels, drums, kegs, and pails manufacturing",
    "Light gauge metal tool boxes manufacturing",
    "Light gauge metal vacuum bottles and jugs manufacturing",
    "Light gauge metal vacuum bottles manufacturing",
    "Light gauge metal vacuum jugs manufacturing",
    "Light gauge metal vats manufacturing",
  ],
  "711": [
    "Plastics drums manufacturing",
    "Plastics trash containers manufacturing",
    "Plastics utility containers manufacturing",
  ],
  "712": [
    "Drop ceiling installation",
    "Drywall contractors",
    "Drywall finishing",
    "Drywall hanging",
    "Drywall installation",
  ],
  "715": [
    "Atom smashers manufacturing",
    "Betatrons manufacturing",
    "Cathodic protection equipment manufacturing",
    "Cyclotrons manufacturing",
    "Electric bells manufacturing",
    "Electric chimes manufacturing",
    "Electric fence chargers manufacturing",
    "Electric gongs manufacturing",
    "Electrical door opening and closing devices manufacturing",
    "Electrochemical generators fuel cells manufacturing",
    "Electrochemical generators manufacturing",
    "Electron linear accelerators manufacturing",
    "Electrostatic particle accelerators manufacturing",
    "Garage door openers manufacturing",
    "High-voltage particle accelerators manufacturing",
    "Linear accelerators manufacturing",
    "Magnetic, pulse, and maser amplifiers manufacturing",
    "Maser amplifiers manufacturing",
    "Mercury arc rectifiers manufacturing",
    "Rectifiers manufacturing",
    "Regulated and unregulated power supplies manufacturing",
    "Semiconductor battery chargers manufacturing",
    "Semiconductor high-voltage power supplies manufacturing",
    "Series capacitors manufacturing",
    "Solid-state battery chargers manufacturing",
    "Solid-state inverters manufacturing",
    "Static power converter units manufacturing",
    "Surge suppressors manufacturing",
    "Thermoelectric generators manufacturing",
    "Ultrasonic cleaning equipment manufacturing",
    "Ultrasonic generators manufacturing",
    "Uninterruptible power supplies (UPS) manufacturing",
  ],
  "716": [
    "Armature rewinding on a factory basis",
    "Coils for motors and generators manufacturing",
    "Collector rings for motors and generators manufacturing",
    "Dynamotors manufacturing",
    "Electric dynamos manufacturing",
    "Electric motor commutators manufacturing",
    "Electric motors, electric manufacturing",
    "Electric torque motors manufacturing",
    "Fractional horsepower electric motors manufacturing",
    "Frequency converters manufacturing",
    "Generating apparatus and parts manufacturing",
    "Generators and sets manufacturing",
    "Generators for gas-electric and oil-electric vehicles manufacturing",
    "Generators for storage battery chargers manufacturing",
    "Ground Power Units (GPU) manufacturing",
    "Industrial armatures manufacturing",
    "Integral horsepower electric motors manufacturing",
    "Inverters, rotating electrical, manufacturing",
    "Land transportation motors and generators manufacturing",
    "Motor and generator exciter assemblies manufacturing",
    "Motor generator sets manufacturing",
    "Phase and rotary electrical equipment converters manufacturing",
    "Phase converters manufacturing",
    "Power generators manufacturing",
    "Prime mover generator sets manufacturing",
    "Railway motors and control equipment manufacturing",
    "Resolvers manufacturing",
    "Rotor retainers and housings manufacturing",
    "Rotors manufacturing",
    "Servomotors manufacturing",
    "Slip rings for motors and generators manufacturing",
    "Stators for motors manufacturing",
    "Storage battery chargers manufacturing",
    "Synchronous condensers and timing motors manufacturing",
    "Synchronous condensers manufacturing",
    "Synchronous motors manufacturing",
    "Synchronous timing motors manufacturing",
  ],
  "717": [
    "Appliance cords made from purchased insulated wire",
    "Extension cords made from purchased insulated wire",
    "Fixed and variable capacitors manufacturing",
    "Fixed and variable condensers manufacturing",
    "Photovoltaic panels made from purchased cells",
  ],
  "718": [
    "Airport lighting transformers manufacturing",
    "Arc-welding transformers manufacturing",
    "Autotransformers for switchboards manufacturing",
    "Autotransformers manufacturing",
    "Ballasts manufacturing",
    "Burner ignition transformers manufacturing",
    "Control transformers manufacturing",
    "Current limiting reactors manufacturing",
    "Distribution transformers manufacturing",
    "Electric furnace transformers manufacturing",
    "Feeder voltage boosters manufacturing",
    "Feeder voltage regulators and boosters manufacturing",
    "Fluorescent ballasts manufacturing",
    "Fluorescent lighting transformers manufacturing",
    "Generator voltage regulators manufacturing",
    "Instrument transformers for metering or protective relaying use manufacturing",
    "Isolation transformers manufacturing",
    "Lamp ballasts manufacturing",
    "Lighting transformers manufacturing",
    "Line voltage regulators manufacturing",
    "Luminous tube transformers manufacturing",
    "Machine tool transformers manufacturing",
    "Power transformers manufacturing",
    "Regulating transformers manufacturing",
    "Regulators manufacturing",
    "Saturable transformers manufacturing",
    "Signaling transformers manufacturing",
    "Specialty transformers manufacturing",
    "Street and airport lighting transformers manufacturing",
    "Substation transformers manufacturing",
    "Transformers manufacturing",
    "Transformers, ignition manufacturing",
    "Transmission and distribution voltage regulators manufacturing",
    "Voltage regulating transformers manufacturing",
    "Voltage regulators, transmission and distribution manufacturing",
  ],
  "719": [
    "Aircraft and automotive wire or cable made from purchased copper",
    "Alloying purchased copper",
    "Alloying purchased copper metals",
    "Apparatus wire or cord made from purchased copper in wire drawing plants",
    "Armored cable made from purchased copper in wire drawing plants",
    "Armored cable, copper made in integrated secondary smelting and drawing plants",
    "Copper alloys made from purchased metal or scrap",
    "Copper and copper alloy bar made in integrated secondary smelting and rolling, drawing or extruding plants",
    "Copper and copper alloy cartridge cups, discs and sheets made in integrated secondary smelting and rolling, drawing or extruding plants",
    "Copper and copper alloy mechanical wire made in integrated secondary smelting and rolling, drawing or extruding plants",
    "Copper and copper alloy plate made in integrated secondary smelting and rolling, drawing or extruding plants",
    "Copper and copper alloy rod made in integrated secondary smelting and rolling, drawing or extruding plants",
    "Copper and copper alloy sheets made in integrated secondary smelting and rolling, drawing or extruding plants",
    "Copper and copper alloy strips made in integrated secondary smelting and rolling, drawing or extruding plants",
    "Copper and copper alloy tubbing made in integrated secondary smelting and rolling, drawing or extruding plants",
    "Copper and copper-based shapes made from purchased metal or scrap",
    "Copper cable made in integrated secondary smelting and wire drawing plants",
    "Copper cable, copper made from purchased copper in wire drawing plants",
    "Copper coaxial cable made from purchased copper in wire drawing plants",
    "Copper communications wire or cable made from purchased copper in wire drawing plants",
    "Copper energy wire or cable made from purchased copper in wire drawing plants",
    "Copper foil from purchased metal or scrap",
    "Copper foil made from purchased metal or scrap",
    "Copper insulated wire or cable made in integrated secondary smelting and wire drawing plants",
    "Copper powder, flakes, and paste made from purchased copper",
    "Copper products made by drawing purchased copper",
    "Copper products made by rolling, drawing, or extruding purchased copper",
    "Copper products made in integrated secondary smelting and extruding mills",
    "Copper products made in integrated secondary smelting mills and drawing plants",
    "Copper secondary smelting and alloying",
    "Copper secondary smelting and refining from purchased metal or scrap",
    "Copper wire made from purchased copper in wire drawing plants",
    "Copper wire made in integrated secondary smelting and wire drawing plants",
    "Extruded and drawn, brass, bronze and copper pipe made in integrated secondary smelting and rolling, drawing or extruding plants",
    "Flexible cord sets made from purchased copper in wire drawing plants",
    "Insulated magnet wire made from purchased copper in wire drawing plants",
    "Insulated wire or cable made from purchased copper in wire drawing plants",
    "Mesh, wire, made from purchased copper in wire drawing plants",
    "Metal powder and flake made from purchased copper",
    "Paste made from purchased copper",
    "Powder made from purchased copper",
    "Rolling, drawing, or extruding brass products",
    "Rolling, drawing, or extruding bronze products",
    "Secondary refining copper",
    "Shipboard cable made from purchased copper in wire drawing plants",
    "Signal and control cable made from purchased copper in wire drawing plants",
    "Weatherproof wire or cable made from purchased copper in wire drawing plants",
    "Wire cloth made from purchased copper in wire drawing plants",
  ],
  "720": ["Electrical wiring contractors", "Electrician"],
  "721": [
    "Cathode ray tubes (CRT) manufacturing",
    "Cathode ray tubes manufacturing",
    "Computer cable sets (e.g., monitor, printer) manufacturing",
    "CRT (cathode ray tube) manufacturing",
    "Electron tube parts manufacturing",
    "Electron tubes manufacturing",
    "Electronic application quartz crystals manufacturing",
    "electronic component rectifiers manufacturing",
    "Electronic crystals and crystal assemblies manufacturing",
    "Electronic resonant reed devices manufacturing",
    "Electronic rheostats manufacturing",
    "Electronic tubes manufacturing",
    "Filters, electronic component, manufacturing",
    "Harness assemblies for electronic use manufacturing",
    "Heads manufacturing",
    "Klystron tubes manufacturing",
    "LCD unit screens manufacturing",
    "Magnetron tubes manufacturing",
    "Microwave components manufacturing",
    "Phonograph and styli needles manufacturing",
    "Piezoelectric crystals manufacturing",
    "Piezoelectric devices manufacturing",
    "Printed circuit laminates manufacturing",
    "Radio frequency identification (RFID) devices manufacturing",
    "Solenoids for electronic applications manufacturing",
    "Switches for electronic applications manufacturing",
    "Television picture tubes manufacturing",
    "Transducers manufacturing",
    "Traveling wave tubes manufacturing",
    "Vacuum tubes manufacturing",
  ],
  "722": [
    "Anodizing metals and metal products for the trade",
    "Buffing metals and metal products for the trade",
    "Chrome plating metals and metal products for the trade",
    "Cleaning and descaling metals and metal products for the trade",
    "Coloring metals and metal products for the trade",
    "Depolishing metals and metal products for the trade",
    "Electroplating metals and formed products for the trade",
    "Gold and silver plating metals and metal products for the trade",
    "Laminating metals and metal formed products without fabricating",
    "Pickling metals and metal products for the trade",
    "Plastic, glass, or other media blasting services",
    "Plating metals and metal products for the trade",
    "Polishing metals and metal products for the trade",
    "Sandblasting metals and metal products for the trade",
    "Tumbling metal and metal products for the trade",
  ],
  "723": [
    "Excavating, earthmoving or land clearing contractors",
    "Excavating, earthmoving or land clearing, mining",
    "Excavation contractors",
    "Grave excavation contractors",
  ],
  "724": [
    "Dumbbells manufacturing",
    "Fitness equipment manufacturing",
    "Gymnasium and playground equipment manufacturing",
  ],
  "725": [
    "Intercity courier network local letter and parcel delivery services",
    "Non universal service obligation air courier services",
    "Non universal service obligation courier services",
    "Non universal service obligation express delivery services",
  ],
  "726": [
    "Alfalfa manufacturing",
    "Alfalfa meal manufacturing",
    "Alfalfa prepared as feed for animals",
    "Animal feed concentrates manufacturing",
    "Animal feed kelp meal and pellets manufacturing",
    "Animal feed mills manufacturing",
    "Animal feed premixes manufacturing",
    "Animal feeds manufacturing",
    "Barley feed chopped, crushed or ground manufacturing",
    "Bird feed manufacturing",
    "Blending animal feed",
    "Bone meal prepared as feed for animals and fowls",
    "Bone meal prepared as feed for animals and fowls manufacturing",
    "Cattle feeds manufacturing",
    "Chicken feeds manufacturing",
    "Citrus pulp cattle feed manufacturing",
    "Complete feed livestock manufacturing",
    "Cubed hay manufacturing",
    "Custom milling of animal feed",
    "Dairy cattle feeds manufacturing",
    "Dehydrated alfalfa meal manufacturing",
    "Earthworm food and bedding manufacturing",
    "Feed (e.g., guinea pig, mice, mink) manufacturing",
    "Feed supplements manufacturing",
    "Fish food for feeding fish manufacturing",
    "Grain grinding for animal feed",
    "Grain mills, animal feed",
    "Laboratory animal feed manufacturing",
    "Livestock feeds manufacturing",
    "Micro and macro premixes livestock manufacturing",
    "Mineral feed supplements manufacturing",
    "Mineral supplements animal manufacturing",
    "Mobile feed mill",
    "Pet food manufacturing",
    "Poultry feeds and concentrates manufacturing",
    "Rabbit food manufacturing",
    "Shell crushing and grinding for animal feed",
    "Shell crushing for feed",
    "Swine feed manufacturing",
    "Turkey feeds manufacturing",
  ],
  "727": [
    "Fiber bobbins manufacturing",
    "Fiber cans and drums manufacturing",
    "Fiber cones manufacturing",
    "Fiber cores manufacturing",
    "Fiber drums manufacturing",
    "Fiber paper mailing cases and tubes manufacturing",
    "Fiber spools manufacturing",
    "Fiber tubes manufacturing",
    "Fiber wastebaskets manufacturing",
  ],
  "728": [
    "Acetate fibers and filaments manufacturing",
    "Acrylic fibers and filaments manufacturing",
    "Acrylonitrile fibers and filaments manufacturing",
    "Anidex fibers and filaments manufacturing",
    "Casein fibers and filaments manufacturing",
    "Cellophane film or sheet manufacturing",
    "Cellulosic fiber cigarette tow manufacturing",
    "Cellulosic fiber yarn manufacturing and texturizing",
    "Cellulosic fibers and filament manufacturing and texturizing",
    "Cellulosic fibers and filaments manufacturing",
    "Cellulosic filament yarn manufacturing",
    "Cellulosic staple fibers manufacturing",
    "Elastomeric fibers and filaments manufacturing",
    "Fluorocarbon fibers and filaments manufacturing",
    "Linear esters fibers and filaments manufacturing",
    "Manmade cellulosic fibers manufacturing",
    "Manmade noncellulosic fibers and filaments manufacturing",
    "Modacrylic fibers and filaments manufacturing",
    "Nitrocellulose fibers manufacturing",
    "Noncellulosic fibers and filaments manufacturing and texturizing",
    "Noncellulosic fiber and filament yarn manufacturing",
    "Noncellulosic fibers and filaments manufacturing",
    "Noncellulosic filament yarn manufacturing",
    "Noncellulosic staple fibers and filaments manufacturing",
    "Nylon fibers and filaments manufacturing",
    "Olefin fibers and filaments manufacturing",
    "Organic noncellulosic fibers and filaments manufacturing",
    "Polyester fibers and filaments manufacturing",
    "Polyethylene terephthalate (PET) fibers and filaments manufacturing",
    "Polyolefin fibers and filaments manufacturing",
    "Polyvinyl ester fibers and filaments manufacturing",
    "Polyvinylidene chloride fibers and filaments manufacturing",
    "Protein fibers and filaments manufacturing",
    "Rayon fibers and filaments manufacturing",
    "Regenerated cellulosic fibers manufacturing",
    "Saran fibers and filaments manufacturing",
    "Soybean fibers and filaments manufacturing",
    "Spandex fiber, filaments, and yarn manufacturing",
    "Texturizing cellulosic yarn made in the same establishment",
    "Texturizing noncellulosic yarn made in the same establishment",
    "Throwing cellulosic yarn made in the same establishment",
    "Throwing noncellulosic yarn made in the same establishment",
    "Triacetate fibers and yarns manufacturing",
    "Vinyl fibers and filaments manufacturing",
    "Vinylidene chloride fiber and filament manufacturing",
    "Viscose fibers, bands, strips and yarn manufacturing",
    "Yarn, noncellulosic fiber and filament, manufacturing and texturizing",
    "Zein fibers and filaments manufacturing",
  ],
  "729": [
    "Artificial fruits made in glass making plants",
    "Automotive glass made in glass making plants",
    "Cooking ware made in glass making plants",
    "Decorative glassware art and novelty made in glass making plants",
    "Decorative glassware made in glass making plants",
    "Fiberglass yarn made in glass making plants",
    "Glass and glass ceramic cooking utensils made in glass making plants",
    "Glass and glass ceramic teakettles made in glass making plants",
    "Glass ashtrays made in glass making plants",
    "Glass blanks electron tube parts made in glass making plants",
    "Glass blanks for electric light bulbs made in glass making plants",
    "Glass blocks made in glass making plants",
    "Glass bricks made in glass making plants",
    "Glass Christmas tree ornaments made in glass making plants",
    "Glass electrical insulators made in glass making plants",
    "Glass fiber made in glass making plants",
    "Glass making and blowing by hand",
    "Glass ovenware made in glass making plants",
    "Glass photomask blanks made in glass making plants",
    "Glass products made in a glass making plants",
    "Glass stemware made in glass making plants",
    "Glass textile fibers made in glass making plants",
    "Glass vacuum tube blanks made in glass making plants",
    "Glass vases made in glass making plants",
    "Glassware for industrial, scientific, and technical use made in glass making plants",
    "Glassware for lighting fixtures made in glass making plants",
    "Industrial glassware and glass products made in glass making plants",
    "Laboratory glassware made in glass making plants",
    "Lamp shades made in glass making plants",
    "Medical glassware made in glass making plants",
    "Optical and ophthalmic lens blanks made in glass making plants",
    "Ophthalmic lens and optical glass blanks made in glass making plants",
    "Optical glass fiber made in glass making plants",
    "Scientific glassware made in glass making plants",
    "Tableware made in glass making plants",
    "Technical glassware and glass products made in glass making plants",
    "Textile glass fibers made in glass making plants",
    "Textile type glass fiber made in glass making plants",
  ],
  "731": [
    "Cultured marble products manufacturing",
    "Cultured stone products manufacturing",
    "Fiberglass ladders manufacturing",
    "Fiberglass swimming pools manufacturing",
    "Floor tiles manufacturing",
    "Linoleum floor coverings manufacturing",
    "Plastic doormats manufacturing",
    "Resilient floor coverings manufacturing",
    "Rubber floor coverings manufacturing",
    "Vinyl floor coverings manufacturing",
    "Vinyl window sashes manufacturing",
    "Vinyl windows and window frames manufacturing",
  ],
  "733": [
    "Artificially drying grapes",
    "Bouillon made in dehydration plants",
    "Dehydrating fruits and vegetables",
    "Dried dates made in dehydration plants",
    "Dried olives made in dehydration plants",
    "Dried prunes made in dehydration plants",
    "Dry salad dressing mixes made in dehydration plants",
    "Dry sauce mixes made in dehydration plants",
    "Freeze-dried fruits and vegetables food processing",
    "Fruits dehydrating",
    "Noodle mixes made in dehydration plants",
    "Potato products (e.g., flakes, granules) dehydrating",
    "Raisins made in dehydration plants",
    "Rice mixes (i.e., uncooked and packaged with other ingredients) made in dehydration plants",
    "Soup mixes made in dehydration plants",
    "Sulphured fruits and vegetables manufacturing",
    "Vegetables dehydrating",
  ],
  "734": [
    "Frozen chop suey manufacturing",
    "Frozen chow mein manufacturing",
    "Frozen dinners (except seafood-based) manufacturing",
    "Frozen dinners(except seafood-based) manufacturing",
    "Frozen food entrees manufacturing",
    "Frozen french toast manufacturing",
    "Frozen macaroni manufacturing",
    "Frozen meatless patties manufacturing",
    "Frozen Mexican foods manufacturing",
    "Frozen nationality specialty foods manufacturing",
    "Frozen pancakes manufacturing",
    "Frozen pizza manufacturing",
    "Frozen pot pies manufacturing",
    "Frozen rice dishes manufacturing",
    "Frozen side dishes manufacturing",
    "Frozen soups (except seafood) manufacturing",
    "Frozen vegetable patties manufacturing",
    "Frozen waffles manufacturing",
    "Frozen whipped topping manufacturing",
  ],
  "736": [
    "Almond pastes manufacturing",
    "Artificially flavored syrup manufacturing",
    "Baking powder manufacturing",
    "Baking powders manufacturing",
    "Bread crumbs not made in bakeries",
    "Cake frosting manufacturing",
    "Cake or pie fillings manufacturing",
    "Canned dessert puddings manufacturing",
    "Corn syrup manufacturing",
    "Corn syrups made from purchased sweeteners",
    "Desiccated and shredded coconut manufacturing",
    "Drink powder mixes manufacturing",
    "Dry cocktail mixes manufacturing",
    "Dry soup mixes made from purchased dry ingredients",
    "Egg substitutes manufacturing",
    "Fried chinese noodles manufacturing",
    "Fried noodles manufacturing",
    "Frosting manufacturing",
    "Gelatin dessert preparations manufacturing",
    "Gelatin for cooking manufacturing",
    "Honey processing",
    "Maple syrup mixing into other products",
    "Noodle mixes made from purchased dry ingredients",
    "Pancake syrups manufacturing",
    "Pasta mixes made from purchased dry ingredients",
    "Potato mixes made from purchased dry ingredients",
    "Powdered drink mixes manufacturing",
    "Processed eggs manufacturing",
    "Puddings, dessert manufacturing",
    "Rice mixes made from purchased rice and dry ingredients",
    "Sorghum syrup manufacturing",
    "Sweetening syrup manufacturing",
    "Sweetening syrups manufacturing",
    "Yeast manufacturing",
  ],
  "737": [
    "Cold forgings made from purchased iron or steel",
    "Drop forgings made from purchased iron or steel",
    "Ferrous forged horseshoes made from purchased iron or steel",
    "Ferrous forgings made from purchased iron or steel",
    "Forgings made from purchased iron or steel",
    "Gun forgings made from purchased iron or steel",
    "Hammer forgings made from purchased iron or steel",
    "Hot forgings made from purchased iron or steel",
    "Iron forgings made from purchased iron",
    "Press forgings made from purchased iron or steel",
    "Steel forgings made from purchased steel",
    "Upset forgings made from purchased iron or steel",
  ],
  "738": ["Customs brokers", "Freight forwarding", "Marine shipping agency", "Shipping agents"],
  "739": [
    "Blast freezing on a contract basis",
    "Frozen drinks and cocktail mixes manufacturing",
    "Frozen citrus pulp manufacturing",
    "Frozen fruit and vegetable processing",
    "Frozen fruit or vegetable juice concentrates manufacturing",
    "Frozen fruit or vegetable juice manufacturing",
    "Frozen fruits and vegetable manufacturing",
    "Frozen pre-cooked french fries manufacturing",
  ],
  "740": ["Fur apparel (except apparel contractors) manufacturing"],
  "742": [
    "Wood children's high chairs manufacturing",
    "Wood children's playpens manufacturing",
    "Wood china closets manufacturing",
    "Wood coffee tables manufacturing",
    "Wood computer furniture manufacturing",
    "Wood cots manufacturing",
    "Wood cradles manufacturing",
    "Wood cribs manufacturing",
    "Wood desks manufacturing",
    "Wood dining room chairs manufacturing",
    "Wood dining room furniture manufacturing",
    "Wood dormitory beds manufacturing",
    "Wood dressers manufacturing",
    "Wood dressing tables manufacturing",
    "Wood end tables manufacturing",
    "Wood framed hammocks manufacturing",
    "Wood garden furniture manufacturing",
    "Wood headboards manufacturing",
    "Wood home entertainment centers manufacturing",
    "Wood hotel beds manufacturing",
    "Wood juvenile furniture manufacturing",
    "Wood kitchen chairs manufacturing",
    "Wood kitchen furniture manufacturing",
    "Wood lawn furniture manufacturing",
    "Wood living room furniture manufacturing",
    "Wood magazine racks manufacturing",
    "Wood night stands manufacturing",
    "Wood nursery furniture manufacturing",
    "Wood not upholstered chairs manufacturing",
    "Wood not upholstered household furniture manufacturing",
    "Wood porch furniture manufacturing",
    "Wood rockers manufacturing",
    "Wood room dividers manufacturing",
    "Wood serving carts manufacturing",
    "Wood shelves manufacturing",
    "Wood stools manufacturing",
    "Wood tables manufacturing",
    "Wood TV stands manufacturing",
    "Wood vanities manufacturing",
    "Wood wardrobes manufacturing",
    "Wood water bed manufacturing",
  ],
  "743": [
    "Bamboo furniture manufacturing",
    "Bookcases manufacturing",
    "Cane chairs manufacturing",
    "Children's metal playpens manufacturing",
    "Fiber furniture manufacturing",
    "Indoor and outdoor furniture manufacturing",
    "Infant car seats manufacturing",
    "Juvenile furniture manufacturing",
    "Lawn furniture manufacturing",
    "Malacca furniture manufacturing",
    "Metal audio and television cabinets manufacturing",
    "Metal beds manufacturing",
    "Metal cabinets manufacturing",
    "Metal camp furniture manufacturing",
    "Metal card table sets manufacturing",
    "Metal chairs manufacturing",
    "Metal computer furniture manufacturing",
    "Metal cots manufacturing",
    "Metal cribs manufacturing",
    "Metal dinette sets manufacturing",
    "Metal dining room chairs manufacturing",
    "Metal dining room furniture manufacturing",
    "Metal dressers manufacturing",
    "Metal dressing tables manufacturing",
    "Metal end tables manufacturing",
    "Metal framed hammocks manufacturing",
    "Metal freestanding cabinets manufacturing",
    "Metal home entertainment centers manufacturing",
    "Metal kitchen chairs manufacturing",
    "Metal kitchen furniture manufacturing",
    "Metal living room furniture manufacturing",
    "Metal medicine cabinets manufacturing",
    "Metal nursery furniture manufacturing",
    "Metal porch swings, metal, manufacturing",
    "Metal serving carts manufacturing",
    "Metal stools manufacturing",
    "Metal tables manufacturing",
    "Metal TV stands manufacturing",
    "Metal vanities manufacturing",
    "Metal wardrobes manufacturing",
    "Metal water bed frames manufacturing",
    "Plastics dining room chairs manufacturing",
    "Plastics furniture manufacturing",
    "Plastics kitchen chairs manufacturing",
    "Plastics TV stands and similar stands manufacturing",
    "Rattan and reed juvenile furniture manufacturing",
    "Rattan furniture manufacturing",
    "Reed and rattan bassinets manufacturing",
    "Reed and rattan camp furniture manufacturing",
    "Reed furniture manufacturing",
    "Reed, wicker, rattan laundry hampers manufacturing",
    "Wicker furniture manufacturing",
    "Willow furniture manufacturing",
    "Wrought iron furniture manufacturing",
  ],
  "744": [
    "Cedar chests manufacturing",
    "Custom wood furniture manufacturing",
    "Freestanding wood cabinets manufacturing",
    "Futon frames manufacturing",
    "Outdoor wood furniture manufacturing",
    "Unassembled or knock-down wood furniture manufacturing",
    "Unfinished wood furniture manufacturing",
    "Wood bed frames manufacturing",
    "Wood bedroom furniture manufacturing",
    "Wood beds manufacturing",
    "Wood bookcases manufacturing",
    "Wood buffets manufacturing",
    "Wood camp furniture manufacturing",
    "Wood card table sets manufacturing",
  ],
  "745": [
    "Compost dumps",
    "Dumps, compost",
    "Lawn waste disposal facilities",
    "Nonhazardous waste treatment and disposal facilities",
    "Waste treatment facilities",
  ],
  "746": [
    "Diamond cutting and polishing",
    "Precious metal cutlery manufacturing",
    "Precious metal table cutlery manufacturing",
  ],
  "747": [
    "Flat glass manufacturing",
    "Glass plate made in glass making plants",
    "Insulating glass made in glass making plants",
    "Laminated glass made in glass making plants",
    "Stained glass and stained glass products made in glass making plants",
  ],
  "748": [
    "Aerial or picker truck rental with operator construction",
    "Backhoe rental with operator",
    "Blast hole drilling",
    "Boring for building construction",
    "Building and structure demolition",
    "Building blasting demolition",
    "Building interior gutting and stripping",
    "Building or other structure wrecking",
    "Bulldozer rental with operator",
    "Caisson (i.e., drilled building foundations) construction",
    "Cesspool construction",
    "Communication and pipe line right of way clearance power",
    "Concrete breaking and cutting for demolition",
    "Construction backfilling",
    "Construction equipment rental with operator",
    "Construction site blasting",
    "Core drilling and test boring for construction",
    "Crawler tractor rental with operator",
    "Culvert or bridge removal",
    "Cutting new rights of way",
    "Demolition contractor",
    "Dewatering contractors",
    "Digging foundations",
    "Dirt moving for construction",
    "Dismantling engineering structures",
    "Drainage system installation",
    "Drilled pier contractors",
    "Drilled shaft construction",
    "Dry well construction",
    "Equipment rental with operator construction",
    "Exterior demolition contractors",
    "Foundation digging",
    "Foundation drilling contractors",
    "Grading construction sites",
    "Ground thawing for construction site digging",
    "Helical pier (i.e., drilled building foundations) construction",
    "House demolishing",
    "House razing",
    "Hydrodemolition contractors",
    "Interior demolition contractors",
    "Land clearing",
    "Land leveling contractors",
    "Line slashing or cutting",
    "Machinery rental with operator construction",
    "Mine site preparation construction contractors",
    "Pile driving",
    "Piling contractors",
    "Power shovel rental with operator construction",
    "Removal of dams, dikes and other heavy and civil engineering constructions",
    "Right of way cutting",
    "Road decommissioning",
    "Septic system contractors",
    "Septic tank and weeping tile installation",
    "Soil compacting",
    "Soil test drilling",
    "Test boring for construction",
    "Trenching",
    "Underground tank removal",
    "Weeping tile installation",
    "Whole building deconstruction",
  ],
  "749": [
    "Farm product warehousing and storage",
    "Farm products bonded warehousing",
    "Farm products private warehousing and storage",
    "Farm products public warehousing and storage",
    "Grain elevators (storage only)",
  ],
  "751": ["Gunite contractors", "Gunning shotcrete", "Shotcrete contractors"],
  "753": ["Wood stoves manufacturing"],
  "754": [
    "Dehumidifiers manufacturing",
    "Electric warm air furnaces manufacturing",
    "Evaporative condensers manufacturing",
    "Heat pumps manufacturing",
    "Heating and air-conditioning combination units manufacturing",
    "Humidifying equipment manufacturing",
    "Warm air furnaces manufacturing",
    "Warm air heating equipment manufacturing",
  ],
  "755": ["Kerosene space heaters manufacturing"],
  "756": [
    "Baseboard heating equipment manufacturing",
    "Baseboard heating units manufacturing",
    "Combination gas-oil burners manufacturing",
    "Fireplace inserts manufacturing",
    "Floor and wall furnaces manufacturing",
    "Gas fireplace manufacturing",
    "Gas fireplaces manufacturing",
    "Gas space heaters manufacturing",
    "Heating boilers manufacturing",
    "Heating burners manufacturing",
    "Heating furnaces manufacturing",
    "Heating gas burners manufacturing",
    "Heating oil burners manufacturing",
    "Hot water heating equipment manufacturing",
    "Hydronic heating equipment manufacturing",
    "Radiators manufacturing",
    "Room heaters manufacturing",
    "Solar energy heating equipment manufacturing",
    "Solar heating systems manufacturing",
    "Space heaters manufacturing",
    "Steam heating equipment manufacturing",
    "Swimming pool heaters manufacturing",
    "Unit heaters manufacturing",
  ],
  "758": [
    "Central heating equipment and piping installation",
    "Cooling and dry heating blower or fan installation",
    "Dry heating equipment installation",
    "Heating and cooling duct work installation",
    "Heating and ventilation system component installation",
    "Heating contractors",
    "Heating equipment installation",
    "Heating, ventilation and air-conditioning (HVAC) contractors",
    "Solar heating equipment installation",
    "Warm air heating system installation",
    "Water heater installation",
  ],
  "759": ["Whetstones manufacturing"],
  "762": [
    "Bronze printing inks manufacturing",
    "Duplicating inks manufacturing",
    "Flexographic inks manufacturing",
    "Gold printing inks manufacturing",
    "Gravure inks manufacturing",
    "Inkjet cartridges manufacturing",
    "Inkjet inks manufacturing",
    "Letterpress inks manufacturing",
    "Lithographic inks manufacturing",
    "Offset inks manufacturing",
    "Printing inks manufacturing",
    "Screen process inks manufacturing",
    "Stencil inks manufacturing",
    "Textile printing inks manufacturing",
  ],
  "763": ["Inner tubes manufacturing"],
  "764": [
    " Industrial process gas flow instrumentation manufacturing",
    "Combustion control instruments manufacturing",
    "Controllers for process variables manufacturing",
    "Digital displays of process variables manufacturing",
    "Electrodes used in industrial process measurement manufacturing",
    "Electromagnetic flowmeters manufacturing",
    "Filled system industrial process thermometers manufacturing",
    "Fluidic devices, circuits, and systems for process control manufacturing",
    "Industrial display relay and solid-state types annunciators manufacturing",
    "Industrial power and marine boiler controls manufacturing",
    "Industrial process buoyancy instruments manufacturing",
    "Industrial process chromatographs manufacturing",
    "Industrial process control indicators manufacturing",
    "Industrial process control instruments manufacturing",
    "Industrial process control measuring instruments manufacturing",
    "Industrial process control meters manufacturing",
    "Industrial process control transmitters manufacturing",
    "Industrial process control type analyzers manufacturing",
    "Industrial process coulometric analyzers manufacturing",
    "Industrial process data loggers manufacturing",
    "Industrial process density and specific gravity instruments manufacturing",
    "Industrial process differential pressure instruments manufacturing",
    "Industrial process display instruments manufacturing",
    "Industrial process draft gauges manufacturing",
    "Industrial process electric and electronic controllers manufacturing",
    "Industrial process electrolytic conductivity instruments manufacturing",
    "Industrial process flow instruments manufacturing",
    "Industrial process gas analyzers manufacturing",
    "Industrial process gas and liquid analysis instruments manufacturing",
    "Industrial process gas chromatographic instruments manufacturing",
    "Industrial process gauges manufacturing",
    "Industrial process humidity instruments manufacturing",
    "Industrial process hydrometers manufacturing",
    "Industrial process hygrometers manufacturing",
    "Industrial process infrared instruments manufacturing",
    "Industrial process level and bulk measuring instruments manufacturing",
    "Industrial process liquid analysis instruments manufacturing",
    "Industrial process liquid concentration instruments manufacturing",
    "Industrial process liquid level instruments manufacturing",
    "Industrial process magnetic flow meters manufacturing",
    "Industrial process manometers manufacturing",
    "Industrial process mechanical measuring instruments manufacturing",
    "Industrial process moisture meters manufacturing",
    "Industrial process potentiometric instruments manufacturing",
    "Industrial process pressure gauges manufacturing",
    "Industrial process pressure instruments manufacturing",
    "Industrial process pyrometers manufacturing",
    "Industrial process refractometers manufacturing",
    "Industrial process resistance thermometers and bulbs manufacturing",
    "Industrial process telemetering instruments manufacturing",
    "Industrial process temperature instruments manufacturing",
    "Industrial process thermal conductivity instruments manufacturing",
    "Industrial process thermistors manufacturing",
    "Industrial process thermocouples manufacturing",
    "Industrial process time cycle and program controllers manufacturing",
    "Industrial process turbidity instruments manufacturing",
    "Industrial process turbine flow meters manufacturing",
    "Industrial process type absorption analyzers manufacturing",
    "Industrial process type acidity instrument, manufacturing",
    "Industrial process type pneumatic controllers manufacturing",
    "Industrial process variable control instruments manufacturing",
    "Industrial process viscosimeters manufacturing",
    "Industrial process viscosity instruments manufacturing",
    "Instruments for industrial process control manufacturing",
    "Nanofluidic measurement and control devices manufacturing",
    "Primary elements for process flow measurement manufacturing",
    "Primary process temperature sensors manufacturing",
    "Process programmers manufacturing",
    "Receiver industrial process panelboard indicators, recorders, and controllers manufacturing",
    "Recorders, industrial process control, manufacturing",
    "Water quality monitoring and control systems manufacturing",
  ],
  "767": [
    "Fiberglass insulating batts, fills or blankets manufacturing",
    "Fiberglass insulation products manufacturing",
    "Mineral wool insulation materials manufacturing",
  ],
  "768": [
    "Bagasse board manufacturing",
    "Cellular fiber or hard pressed wood insulation board manufacturing",
    "Chipboard (i.e., particle core, wood chip face) manufacturing",
    "Compression modified wood manufacturing",
    "Densified wood manufacturing",
    "Fiber lath manufacturing",
    "Fiberboard manufacturing",
    "Flakeboard manufacturing",
    "Hardboard manufacturing",
    "MDF (medium density fiberboard) manufacturing",
    "Medium density fiberboard (MDF) manufacturing",
    "Oriented strandboard (OSB) manufacturing",
    "Oriented strandboard manufacturing",
    "Particle board manufacturing",
    "Particleboard manufacturing",
    "Reconstituted wood panels manufacturing",
    "Reconstituted wood sheets and boards manufacturing",
    "Waferboard manufacturing",
  ],
  "769": [
    "Foam plastics insulation and cushioning manufacturing",
    "Foam plastics packaging manufacturing",
    "Foam plastics products manufacturing",
    "Foam plastics seat cushions manufacturing",
    "Foam plastics shipping pads and shaped cushioning manufacturing",
    "Foam plastics vacuum jugs manufacturing",
    "Polyurethane foam products manufacturing",
    "Urethane and other foam plastics cushions, carpet and rug manufacturing",
    "Urethane foam products manufacturing",
    "Urethane or other plastics foam ice buckets manufacturing",
    "Urethane or other plastics foam Ice chests or coolers manufacturing",
  ],
  "772": [
    "Attic space insulating",
    "Blown-in insulation installation",
    "Building fireproofing contractors",
    "Building insulation contractors",
    "Ceiling tile installation",
    "Cellulosic fiber insulation installation",
    "Exterior insulation finish system installation",
    "Finishing drywall contractors",
    "Fireproof flooring installation",
    "Fireproofing buildings",
    "Firestop contractors ",
    "Foam insulation installation",
    "Fresco contractors",
    "Glass fiber insulation installation",
    "Gypsum board installation",
    "Insulation contractors",
    "Lathing contractors",
    "Mineral wool insulation installation",
    "Noise insulating fabric wall system installation",
    "Plastering contractors",
    "Polystyrene board insulation installation",
    "Roof insulation contractor",
    "Styrofoam insulation installation",
    "Suspended ceiling installation",
    "Taping and finishing drywall",
    "Urethane foam insulation application",
    "Wall cavity and attic space insulation installation",
  ],
  "774": [
    "Anchored earth retention contractors",
    "Anchored retaining walls construction",
    "Athletic field construction",
    "Avalanche, rockslide, mudslide or roadside protection construction",
    "Breakwater construction",
    "Bridle path construction",
    "Bulkhead wall or embankment construction",
    "Caisson construction",
    "Canal construction",
    "Channel construction",
    "Cofferdam construction",
    "Cribbing construction",
    "Dam construction",
    "Dam construction management",
    "Dike and other flood control structure construction",
    "Dock construction",
    "Drainage canal and ditch construction",
    "Drainage project construction",
    "Drive-in movie facility construction",
    "Earth retention system construction",
    "Earth-filled dam construction",
    "Embankment construction",
    "Farm drainage tile installation",
    "Flood control project construction",
    "Floodwater spillway construction",
    "Floodway canal and ditch construction",
    "Gabion construction",
    "Golf course construction",
    "Harbor construction",
    "Helical pier construction",
    "Horizontal drilling",
    "Hydroelectric electricity generating plant construction",
    "Hydroelectric generating facility construction",
    "Hydroelectric power plant construction",
    "Ice rink construction",
    "Jetty construction",
    "Land drainage contractors",
    "Levee construction",
    "Light rail system construction",
    "Lock and waterway construction",
    "Marine construction",
    "Marine pile driving",
    "Marine structure construction management",
    "Mass transit construction management",
    "Micro tunneling contractors",
    "Missile facility construction",
    "Monorail construction",
    "Nuclear waste disposal site construction",
    "Open area recreation area construction",
    "Outdoor recreation facility construction",
    "Outdoor recreation facility construction management",
    "Outdoor tennis courts construction",
    "Park and recreational open space improvement construction",
    "Pier construction",
    "Pipe-jacking contractors",
    "Playground construction",
    "Port facility construction",
    "Railroad construction",
    "Railway construction",
    "Railway roadbed construction",
    "Recreational vehicle park construction",
    "Revetment construction",
    "Riprap installation",
    "Sediment control system construction",
    "Ski tow construction",
    "Sports field construction",
    "Streetcar line construction",
    "Subway construction",
    "Trail construction",
    "Tunnel construction",
    "Tunnel construction management",
    "Underwater rock removal",
    "Underwater timber removal",
    "Underwater trenching",
    "Wave protection seawall construction",
    "Wharf construction",
  ],
  "775": [
    "Canal irrigation",
    "Impounding reservoirs irrigation",
    "Irrigation system operation",
    "Water distribution",
    "Water distribution for irrigation",
    "Water filtration plant",
    "Water filtration plant operation",
    "Water supply systems",
    "Water treatment and distribution",
    "Water treatment plants",
  ],
  "776": [
    "Cigar and cigarette lighters clad with precious metal manufacturing",
    "Costume jewelry manufacturing",
    "Costume pearls manufacturing",
    "Drilling pearls",
    "Engraving and etching precious metal flatware",
    "Engraving and etching precious metal jewelry",
    "Engraving and/or etching costume jewelry",
    "Jeweler's findings and materials manufacturing",
    "Jeweler's findings manufacturing",
    "Lapidary work manufacturing",
    "Men's or women's precious metal pocketbooks manufacturing",
    "Metal jewelry cases manufacturing",
    "Metal personal goods manufacturing",
    "Metal watch bands manufacturing",
    "Natural or cultured pearls jewelry manufacturing",
    "Pearl drilling, peeling, or sawing",
    "Pewter ware manufacturing",
    "Precious metal bracelets manufacturing",
    "Precious metal card cases manufacturing",
    "Precious metal chains or necklace manufacturing",
    "Precious metal checkbook covers manufacturing",
    "Precious metal cigar cases manufacturing",
    "Precious metal cigarette cases manufacturing",
    "Precious metal coin purses manufacturing",
    "Precious metal comb cases manufacturing",
    "Precious metal compacts manufacturing",
    "Precious metal cosmetic bags manufacturing",
    "Precious metal cuff links manufacturing",
    "Precious metal handbags manufacturing",
    "Precious metal hollowware manufacturing",
    "Precious metal jewel settings and mountings manufacturing",
    "Precious metal jewelry manufacturing",
    "Precious metal key cases manufacturing",
    "Precious metal novelties manufacturing",
    "Precious metal or clad with precious metal purses manufacturing",
    "Precious metal pins and brooches manufacturing",
    "Precious metal rosaries and other small religious articles manufacturing",
    "Precious metal sewing cases manufacturing",
    "Precious metal tobacco pouches manufacturing",
    "Precious metal toilet kits and cases manufacturing",
    "Precious metal trophies manufacturing",
    "Precious metal wallets manufacturing",
    "Precious metal watch straps manufacturing",
    "Precious metal, precious stones, semiprecious stones buttons manufacturing",
    "Stamping coins",
  ],
  "777": [
    "Aluminum ladders manufacturing",
    "Metal ladder jacks manufacturing",
    "Metal stepladders manufacturing",
    "Portable metal ladders manufacturing",
  ],
  "778": [
    "Wood extension ladders manufacturing",
    "Wood ladder jacks manufacturing",
    "Wood ladders manufacturing",
    "Wood stepladders manufacturing",
  ],
  "779": ["Plastics lamp shades manufacturing"],
  "780": [
    "Airport lighting fixtures manufacturing",
    "Arc lighting fixtures manufacturing",
    "Automotive light bulbs manufacturing",
    "Christmas tree light bulbs manufacturing",
    "Christmas tree lighting sets manufacturing",
    "Coating purchased light bulbs",
    "Decorative area lighting fixtures manufacturing",
    "Electric and nonelectric searchlights manufacturing",
    "Electric area and sports luminaries manufacturing",
    "Electric Christmas tree ornaments manufacturing",
    "Electric fireplace logs manufacturing",
    "Electric insect lamps manufacturing",
    "Electrical flytraps manufacturing",
    "Electrodes, cold cathode fluorescent lamp, manufacturing",
    "Electrotherapeutic lamp bulbs for ultraviolet and infrared radiation manufacturing",
    "Flashlights manufacturing",
    "Floodlights manufacturing",
    "Fluorescent lamp electrodes, cold cathode manufacturing",
    "Fountain lighting fixtures manufacturing",
    "Gas lighting fixtures manufacturing",
    "Infrared lamp fixtures manufacturing",
    "Lanterns manufacturing",
    "Lead-in wires, electric lamp manufacturing",
    "Metal reflectors for lighting equipment manufacturing",
    "Miner's lamps manufacturing",
    "Nonelectric lighting fixtures manufacturing",
    "Prewired poles brackets and accessories for electric lighting manufacturing",
    "Spotlights manufacturing",
    "Stage lighting equipment manufacturing",
    "Street lighting fixtures manufacturing",
    "Strobotrons manufacturing",
    "Swimming pool lighting fixtures manufacturing",
    "Trouble lights manufacturing",
    "Ultraviolet lamp fixtures manufacturing",
    "Underwater lighting fixtures manufacturing",
    "Vapor lamps manufacturing",
  ],
  "782": [
    "Garden maintenance services",
    "Hydroseeding services",
    "Landscape care and maintenance services",
    "Landscape contractors",
    "Landscape installation services",
    "Landscaping services",
    "Line slash maintenance services",
    "Ornamental tree and shrub services",
    "Plant maintenance services",
    "Seasonal property maintenance services",
    "Shrub services",
    "Snow plowing services combined with landscaping services",
    "Tropical plant maintenance services",
  ],
  "783": [
    "Cemetery plot care services",
    "Fertilizing lawns",
    "Lawn care services",
    "Lawn fertilizing services",
    "Lawn irrigation services",
    "Lawn maintenance services",
    "Lawn mowing services",
    "Lawn mulching services",
    "Lawn seeding services",
    "Lawn spraying services",
    "Mowing services",
    "Seeding lawns",
    "Sod laying services",
    "Spraying lawns",
    "Turf installation services",
    "Weed control and fertilizing services",
  ],
  "784": [
    "Athletic bags manufacturing",
    "Attache cases manufacturing",
    "Bags (i.e., luggage) manufacturing",
    "Billfolds manufacturing",
    "Binocular cases manufacturing",
    "Boot and shoe findings manufacturing",
    "Boot and shoe leather soles manufacturing",
    "Briefcases manufacturing",
    "Camera carrying bags manufacturing",
    "Card cases manufacturing",
    "Checkbook covers manufacturing",
    "Cigar cases manufacturing",
    "Cigarette cases manufacturing",
    "Coin purses manufacturing",
    "Collars and collar pads (i.e., harness) manufacturing",
    "Comb cases manufacturing",
    "Cosmetic bags manufacturing",
    "Cut stock for boots and shoes manufacturing",
    "Dog collars manufacturing",
    "Dog furnishings (e.g., collars, harnesses, leashes, muzzles) manufacturing",
    "Dog harnesses manufacturing",
    "Dog leashes manufacturing",
    "Eyeglass cases manufacturing",
    "Handbags manufacturing",
    "Hat boxes (except paper or paperboard) manufacturing",
    "Jewelry cases manufacturing",
    "Key cases manufacturing",
    "Lashes (i.e., whips) manufacturing",
    "Leather aprons (e.g., blacksmith's, welder's) manufacturing",
    "Leather aprons for textile machinery manufacturing",
    "Leather belt laces manufacturing",
    "Leather belting for machinery manufacturing",
    "Leather belting manufacturing",
    "Leather blacksmith's aprons manufacturing",
    "Leather boot and shoe cut stock and findings manufacturing",
    "Leather boot and shoe linings manufacturing",
    "Leather boot and shoe manufacturing",
    "Leather box toes (i.e., shoe cut stock) manufacturing",
    "Leather boxes manufacturing",
    "Leather counters (i.e., shoe cut stock) manufacturing",
    "Leather cut stock for shoe and boot manufacturing",
    "Leather desk sets manufacturing",
    "Leather handbags and purses manufacturing",
    "Leather handles (e.g., luggage, whip) manufacturing",
    "Leather heel lifts manufacturing",
    "Leather heels, boot and shoe manufacturing",
    "Leather holsters manufacturing",
    "Leather Laces (e.g., shoe) manufacturing",
    "Leather lifts, heel manufacturing",
    "Leather luggage corners manufacturing",
    "Leather luggage manufacturing",
    "Leather mill strapping for textile mills manufacturing",
    "Leather novelties (e.g., cigarette lighter covers, key fobs) manufacturing",
    "Leather or metal caps heel manufacturing",
    "Leather quarters (i.e., shoe cut stock) manufacturing",
    "Leather rands (i.e., shoe cut stock) manufacturing",
    "Leather safety belts manufacturing",
    "Leather seatbelts manufacturing",
    "Leather shoe bows manufacturing",
    "Leather shoe clasps manufacturing",
    "Leather shoe pegs manufacturing",
    "Leather shoe shanks manufacturing",
    "Leather shoe soles manufacturing",
    "Leather shoe stays manufacturing",
    "Leather shoe tips manufacturing",
    "Leather shoe tongues manufacturing",
    "Leather shoe trimmings manufacturing",
    "Leather soles, boot and shoe manufacturing",
    "Leather spats manufacturing",
    "Leather straps manufacturing",
    "Leather taps shoes manufacturing",
    "Leather toe caps manufacturing",
    "Leather transmission belting manufacturing",
    "Leather uppers (i.e., shoe cut stock) manufacturing",
    "Leather vamps manufacturing",
    "Leather vanity cases manufacturing",
    "Leather welders' aprons manufacturing",
    "Leather welders' jackets manufacturing",
    "Leather welder's leggings manufacturing",
    "Leather welder's sleeves manufacturing",
    "Leather welting manufacturing",
    "Luggage cases manufacturing",
    "Luggage manufacturing",
    "Musical instrument cases manufacturing",
    "Purses manufacturing",
    "Razor strops manufacturing",
    "Riding crops manufacturing",
    "Sample cases manufacturing",
    "Satchels manufacturing",
    "Sewing cases manufacturing",
    "Shoe kits (i.e., cases) manufacturing",
    "Small personal leather goods (e.g., coin purses, eyeglass cases, key cases) manufacturing",
    "Solid leather compacts manufacturing",
    "Suitcases manufacturing",
    "Textile leathers (e.g., apron picker leather, mill strapping) manufacturing",
    "Tobacco pouches manufacturing",
    "Toilet kits and cases (except metal) manufacturing",
    "Traveling bags manufacturing",
    "Trunks (i.e., luggage)manufacturing",
    "Valises manufacturing",
    "Wallets manufacturing",
    "Wardrobe bags (i.e., luggage) manufacturing",
    "Watch bands manufacturing",
    "Watch straps manufacturing",
    "Whipstocks manufacturing",
  ],
  "785": [
    "Cold cathode fluorescent lamp tubes manufacturing",
    "Compact fluorescent light bulbs manufacturing",
    "Complete electric light bulbs manufacturing",
    "Complete incandescent filament lamp bulbs manufacturing",
    "Complete light bulbs manufacturing",
    "Decorative lamp bulbs manufacturing",
    "Electric lamp bulb parts manufacturing",
    "Filaments for electric lamp bulbs manufacturing",
    "Flashlight bulb manufacturing",
    "Fluorescent lamp tubes manufacturing",
    "Glow lamp bulbs manufacturing",
    "Halogen light bulbs manufacturing",
    "High intensity lamp bulbs manufacturing",
    "Infrared and ultraviolet radiation health lamp bulbs and tubes manufacturing",
    "Infrared and ultraviolet radiation health lamp bulbs manufacturing",
    "Infrared lamp bulbs manufacturing",
    "Lamp bulb parts manufacturing",
    "Lamp bulbs and tubes manufacturing",
    "Light bulbs manufacturing",
    "Mercury halide lamp bulbs manufacturing",
    "Photoflash and photoflood lamp bulbs and tubes manufacturing",
    "Photographic flash bulbs manufacturing",
    "Sealed beam automotive light bulbs manufacturing",
    "Sodium vapor lamp bulbs manufacturing",
    "Ultraviolet lamp bulbs manufacturing",
  ],
  "786": [
    "Commercial lighting fixtures manufacturing",
    "Emergency lighting manufacturing",
    "Illuminated indoor lighting fixtures manufacturing",
    "Industrial ceiling lighting fixtures manufacturing",
    "Industrial chandeliers manufacturing",
    "Industrial desk lamps manufacturing",
    "Industrial fluorescent lighting fixtures manufacturing",
    "Industrial grow light fixtures manufacturing",
    "Industrial lamps manufacturing",
    "Industrial lighting fixtures manufacturing",
    "Industrial mercury lighting fixtures manufacturing",
    "Industrial pendant lamps manufacturing",
    "Industrial recessed lighting housings and trim manufacturing",
    "Industrial solar lighting fixtures manufacturing",
    "Industrial wall lamps manufacturing",
    "Institutional lighting fixtures manufacturing",
    "Luminous panel ceilings manufacturing",
  ],
  "787": [
    "Alcoholic beverages distilling",
    "Alcoholic eggnog manufacturing",
    "Alcoholic mixed drinks manufacturing",
    "Blending distilled beverages",
    "Distilled spirits manufacturing",
    "Distilleries",
    "Distilling alcoholic beverages",
    "Distilling and blending liquors",
    "Distilling potable liquor",
    "Grain alcohol beverage manufacturing",
    "Liquors beverages manufacturing",
    "Neutral spirit beverages manufacturing",
    "Potable ethyl alcohol manufacturing",
  ],
  "788": [
    "Cutting and transporting timber",
    "Cutting timber",
    "Log harvesting",
    "Logging",
    "Pulpwood logging camps",
    "Rough wood rails manufacturing",
    "Stump removing in the field",
    "Timber piling",
    "Timber pole cutting",
    "Tree chipping in the field",
    "Wood chipping in the field",
  ],
  "791": [
    "ATMs (automated teller machines) installation",
    "Automated and revolving door installation",
    "Automatic gate installation",
    "Boiler and pipe insulation installation",
    "Boiler covering installation",
    "Bowling alley equipment installation",
    "Built-in vacuum cleaning system installation",
    "Church bell and tower clock installation",
    "Commercial door installation",
    "Commercial kitchen food preparation equipment installation",
    "Commercial- or industrial door installation",
    "Commercial- or industrial overhead door installation",
    "Conveyor system installation",
    "Deodorization system installation",
    "Dismantling large-scale machinery and equipment",
    "Duct insulation installation",
    "Duct pipe and boiler insulation",
    "Dumbwaiter installation",
    "Elevator installation",
    "Escalator installation",
    "Garage door installation",
    "Hoisting and placement of large-scale apparatus",
    "Household antenna installation",
    "Incinerators installation",
    "Insulation, boiler, duct and pipe installation",
    "Large-scale machinery and equipment installation",
    "Lightning protection equipment installation",
    "Lightning rod and conductor installation",
    "Machine rigging",
    "Material handling equipment installation",
    "Mechanical equipment insulation",
    "Millwrights",
    "Motor vehicle garage and service station mechanical equipment installation",
    "Moving sidewalk installation",
    "Pipe covering",
    "Pneumatic tube conveyor system installation",
    "Power boiler installation only",
    "Power generating equipment installation",
    "Revolving door installation",
    "Rigging large-scale equipment",
    "Rubber door installation",
    "Safe vault and banking machine installation",
    "Satellite dish installation",
    "Service station gasoline pump installation",
    "Vehicle lift installation",
    "Vending machine installation",
    "Water pipe insulating",
  ],
  "793": [
    "Electric hedge trimmers manufacturing",
    "Lawn and garden carts manufacturing",
    "Lawn and garden chippers  manufacturing",
    "Lawn and garden equipment manufacturing",
    "Lawn and garden mulchers manufacturing",
    "Lawn and garden seeders manufacturing",
    "Lawn and garden snow plow attachments manufacturing",
    "Lawn and garden spreaders manufacturing",
    "Lawn and garden string trimmers manufacturing",
    "Lawn and garden tillers manufacturing",
    "Lawn and garden tractors and attachments manufacturing",
    "Lawn and garden wagons manufacturing",
    "Leaf blowers manufacturing",
    "Powered garden machinery and equipment manufacturing",
    "Powered hedge trimmers manufacturing",
    "Powered lawn and garden attachments manufacturing",
    "Powered lawn and garden cultivators manufacturing",
    "Powered lawn and garden grass mowing equipment manufacturing",
    "Powered lawn edgers manufacturing",
    "Powered lawnmowers manufacturing",
    "Residential snowblowers and throwers manufacturing",
    "Yard vacuums manufacturing",
  ],
  "794": [
    "Aggregate spreaders manufacturing",
    "Asphalt roofing construction machinery manufacturing",
    "Augers manufacturing",
    "Backhoes manufacturing",
    "Bituminous planers manufacturing",
    "Blades for graders, scrapers, bulldozers, and snowplows manufacturing",
    "Bucket and scarifier teeth manufacturing",
    "Bulldozers manufacturing",
    "Cabs for construction machinery manufacturing",
    "Commercial portable chippers manufacturing",
    "Concrete finishing machinery manufacturing",
    "Concrete gunning equipment manufacturing",
    "Concrete vibrators manufacturing",
    "Construction and surface mining rock drill bits manufacturing",
    "Construction and surface mining rock drills manufacturing",
    "Construction cranes manufacturing",
    "Construction land preparation machinery manufacturing",
    "Construction machinery manufacturing",
    "Construction plows, construction manufacturing",
    "Construction scrapers manufacturing",
    "Construction tractors and attachments manufacturing",
    "Crawler draglines manufacturing",
    "Crawler tractors manufacturing",
    "Dredging machinery manufacturing",
    "Excavating buckets manufacturing",
    "Excavators manufacturing",
    "Grader attachments manufacturing",
    "Highway line marking machinery manufacturing",
    "Jack hammers manufacturing",
    "Off-highway trucks manufacturing",
    "Paving machinery manufacturing",
    "Pile-driving equipment manufacturing",
    "Piling extractors manufacturing",
    "Portable concrete mixers manufacturing",
    "Portable concrete mixing machinery manufacturing",
    "Portable crushing machinery manufacturing",
    "Portable crushing, pulverizing, and screening machinery manufacturing",
    "Portable hammer mill machinery manufacturing",
    "Portable log debarking machinery manufacturing",
    "Portable log splitters manufacturing",
    "Portable mortar mixers manufacturing",
    "Portable rock crushing machinery manufacturing",
    "Portable screening machinery manufacturing",
    "Portable slag mixers manufacturing",
    "Power shovels manufacturing",
    "Powered post hole diggers manufacturing",
    "Powered tampers manufacturing",
    "Road construction and maintenance machinery rollers manufacturing",
    "Road construction and road maintenance equipment drags manufacturing",
    "Road graders manufacturing",
    "Road scarifiers manufacturing",
    "Sand mixers manufacturing",
    "Self-propelled ditchers and trenchers manufacturing",
    "Shovel loaders manufacturing",
    "Snow plow attachments manufacturing",
    "Surface mining machinery manufacturing",
    "Trenching machines manufacturing",
  ],
  "795": [
    "Agriculture harvesting machinery and equipment manufacturing",
    "Agriculture irrigation equipment manufacturing",
    "Bale throwers manufacturing",
    "Berry harvesting machines manufacturing",
    "Cabs for agricultural machinery manufacturing",
    "Cattle feeding and watering equipment manufacturing",
    "Chicken brooders manufacturing",
    "Chicken feeders manufacturing",
    "Combines manufacturing",
    "Corn heads for combines manufacturing",
    "Corn pickers and shellers manufacturing",
    "Cotton balers and presses manufacturing",
    "Cotton ginning machinery manufacturing",
    "Cotton picker and stripper harvesting machinery manufacturing",
    "Crop driers manufacturing",
    "Electric hair clippers for animal use manufacturing",
    "Farm balers manufacturing",
    "Farm cream separators manufacturing",
    "Farm cultivators manufacturing",
    "Farm dusters manufacturing",
    "Farm equipment drags manufacturing",
    "Farm feed processing equipment manufacturing",
    "Farm fertilizing machinery manufacturing",
    "Farm grading, cleaning, and sorting machinery manufacturing",
    "Farm hulling machinery manufacturing",
    "Farm nut shellers manufacturing",
    "Farm planting machines manufacturing",
    "Farm plows manufacturing",
    "Farm presses manufacturing",
    "Farm rotary tillers manufacturing",
    "Farm seeders manufacturing",
    "Farm shredders manufacturing",
    "Farm sprayers and dusters manufacturing",
    "Farm spreaders manufacturing",
    "Farm tractors and attachments manufacturing",
    "Farm transplanters manufacturing",
    "Farm wagons manufacturing",
    "Farm weeding machines manufacturing",
    "Farm windmills manufacturing",
    "Forage blowers manufacturing",
    "Fruit harvesting machines manufacturing",
    "Grain drills manufacturing",
    "Grain stackers manufacturing",
    "Grass mowing equipment manufacturing",
    "Harrows manufacturing",
    "Hay balers and presses manufacturing",
    "Hay rakes manufacturing",
    "Haying machines manufacturing",
    "Hog feeding and watering equipment manufacturing",
    "Milking machines manufacturing",
    "Peanut combines manufacturing",
    "Potato diggers, harvesters, and planters manufacturing",
    "Poultry brooders, feeders, and waterers manufacturing",
    "Poultry incubators manufacturing",
    "Powered agricultural lawnmowers manufacturing",
    "Powered shears for use on animals manufacturing",
    "Powered sheep shears manufacturing",
    "Rotary hoes manufacturing",
    "Sod harvesting machines manufacturing",
    "Stalk choppers manufacturing",
    "Tobacco curers manufacturing",
    "Tobacco harvester machines manufacturing",
    "Tomato harvesting machines manufacturing",
    "Tree shakers manufacturing",
  ],
  "796": [
    "Aircraft carrier catapults manufacturing",
    "Automatic fire sprinkler systems manufacturing",
    "Baby scales manufacturing",
    "Balances including laboratory manufacturing",
    "Baling machinery manufacturing",
    "Bathroom scales manufacturing",
    "Bridge and gate lifting machinery manufacturing",
    "Cremating ovens manufacturing",
    "Delicatessen scales manufacturing",
    "Gas separating machinery manufacturing",
    "Gate and bridge lifting machinery manufacturing",
    "General purpose gas generating machinery manufacturing",
    "General purpose sieves and screening equipment manufacturing",
    "Hydraulic and pneumatic jacks manufacturing",
    "Industrial and commercial ice crushers manufacturing",
    "Industrial and general purpose filters manufacturing",
    "Industrial and laboratory centrifuges manufacturing",
    "Industrial bellows manufacturing",
    "Industrial robot cells manufacturing",
    "Industrial robots manufacturing",
    "Industrial scales manufacturing",
    "Metal baling presses manufacturing",
    "Motor truck scales manufacturing",
    "Parcel post scales manufacturing",
    "Pipeline strainers manufacturing",
    "Railroad track scales manufacturing",
    "Retail scales manufacturing",
    "Scales manufacturing",
    "Screening and sifting machinery for general industrial use manufacturing",
    "Sludge tables manufacturing",
    "Steam separating machinery manufacturing",
    "Vapor separating machinery manufacturing",
    "Vehicle scales manufacturing",
    "Weather vanes manufacturing",
  ],
  "797": [
    "Assembly machines manufacturing",
    "Cradle assembly machinery manufacturing",
    "Draw bench machines manufacturing",
    "Galvanizing machinery manufacturing",
    "Metal marking machines manufacturing",
    "Metalworking bar mill machinery manufacturing",
    "Metalworking billet mill machinery manufacturing",
    "Metalworking blooming and slabbing mill machinery manufacturing",
    "Metalworking coil winding and cutting machinery manufacturing",
    "Metalworking cold rolling mill machinery manufacturing",
    "Metalworking hot strip mill machinery manufacturing",
    "Metalworking hot-rolling mill machinery manufacturing",
    "Metalworking picklers and pickling machinery manufacturing",
    "Metalworking pipe and tube rolling mill machinery manufacturing",
    "Metalworking plate rolling mill machinery manufacturing",
    "Metalworking rod rolling mill machinery manufacturing",
    "Metalworking rolling mill machinery and equipment manufacturing",
    "Metalworking rolling mill roll machines manufacturing",
    "Metalworking scarfing units, rolling mill machinery manufacturing",
    "Metalworking screwdowns and boxes machinery manufacturing",
    "Metalworking structural rolling mill machinery manufacturing",
    "Metalworking tube rolling mill machinery manufacturing",
    "Propeller straightening presses manufacturing",
    "Screwdriving machines manufacturing",
    "Wire drawing and fabricating machinery and equipment manufacturing",
  ],
  "798": [
    "Anatomical models manufacturing",
    "Artificial Christmas trees manufacturing",
    "Artificial flower arrangements assembled from purchased components",
    "Artificial flowers manufacturing",
    "Artificial fruits manufacturing",
    "Artificial trees and plants manufacturing",
    "Artificial wreaths manufacturing",
    "Atomizers manufacturing",
    "Bone novelties manufacturing",
    "Christmas tree ornaments manufacturing",
    "Cigar and cigarette lighters manufacturing",
    "Cigarette holders manufacturing",
    "Cigarette lighter flints manufacturing",
    "Cigarette lighters manufacturing",
    "Coin- or card-operated amusement machines manufacturing",
    "Coin- or card-operated games manufacturing",
    "Coin- or card-operated pinball machines manufacturing",
    "Coin-or card-operated gambling devices manufacturing",
    "Decalcomania work",
    "Electronic cigarettes manufacturing",
    "Feather dusters manufacturing",
    "Fly swatters manufacturing",
    "Geographical globes manufacturing",
    "Hairpieces manufacturing",
    "Hat blocks manufacturing",
    "Lighter flints manufacturing",
    "Mannequins manufacturing",
    "Marionettes manufacturing",
    "Mirror and picture frames manufacturing",
    "Music boxes manufacturing",
    "Novelties manufacturing",
    "Parasols manufacturing",
    "Pipe cleaners manufacturing",
    "Portable fire extinguishers manufacturing",
    "Potpourri manufacturing",
    "Preparing feathers ",
    "Puppets manufacturing",
    "Sewing and mending kits assembling",
    "Shell novelties",
    "Shoe patterns manufacturing",
    "Slot machines manufacturing",
    "Smoker's pipes manufacturing",
    "Theatrical scenery manufacturing",
    "Tinsel manufacturing",
    "Tobacco pipes manufacturing",
    "Wax figures manufacturing",
  ],
  "800": ["Hydrographic mapping services", "Hydrographic surveying services"],
  "801": [
    "Masonry contractors",
    "Masonry fireplace installation",
    "Masonry pointing, cleaning or caulking",
    "Masonry retaining wall construction",
  ],
  "802": [
    "Assembled bed and box springs manufacturing",
    "Assembled box springs manufacturing",
    "Felt, foam rubber, urethane and similar materials mattresses manufacturing",
    "Innerspring cushions manufacturing",
    "Mattresses manufacturing",
    "Sleep-system ensembles beds manufacturing",
    "Water bed mattresses manufacturing",
  ],
  "803": [
    "Beef stew made from purchased meats ",
    "Bologna made from purchased meats ",
    "Boxed beef made from purchased meats",
    "Boxed meat produced  from purchased meats",
    "Canned ham made from purchased meats",
    "Collage sausage casings made from purchased hides",
    "Collagen sausage casings made from purchased hides",
    "Cooked and pickled pig's feet made from purchased meats",
    "Cooked meats made from purchased meats ",
    "Corned meats made from purchased meats",
    "Cured meats (e.g., brined, dried, and salted) made from purchased meats ",
    "Cured or smoked meats made from purchased meats",
    "Dried meats made from purchased meats",
    "Edible organs variety meats made from purchased meats",
    "Fresh, chilled or frozen meats made from purchased meats ",
    "Frozen meat pies (i.e., tourtiere) made from purchased meats",
    "Hot dogs made from purchased meats",
    "Luncheon meat made from purchased meats",
    "Meat canning made from purchased meats",
    "Meat extracts made from purchased meats",
    "Meat made sandwich spreads from purchased meats",
    "Pastrami made from purchased meats",
    "Potted meats made from purchased meats",
    "Preserved ham made from purchased meats",
    "Primal and sub-primal cuts beef made from purchased meats ",
    "Primal and sub-primal cuts pork made from purchased meats",
    "Primal and sub-primal cuts veal made from purchased meats ",
    "Primal and sub-primal lamb cuts made from purchased meats",
    "Processed meats manufacturing",
    "Salted meats made from purchased meats",
    "Sausage and similar cased products made from purchased meats ",
    "Slab and sliced bacon made from purchased meats",
    "Smoked meats made from purchased meats",
  ],
  "819": ["Concrete beam, slab or other component installation"],
  "820": [
    "Concrete reinforcement placement",
    "Placing and tying reinforcing rod at a construction site",
    "Precast concrete balcony installation",
    "Precast concrete curtain wall installation",
    "Precast concrete panel, slab, or form installation",
    "Precast concrete stairway installation",
    "Rebar contractors",
    "Reinforcing rod, bar, mesh and cage installation",
    "Steel reinforcing contractors",
    "Structural precast, structural prestressed installation",
  ],
  "821": ["Bleacher installation", "Metal ceiling installation"],
  "822": ["Erecting structural steel", "Structural steel erecting or iron work contractor"],
  "823": [
    "Aluminum foil made by flat rolling purchased aluminum",
    "Aluminum foil made in integrated secondary smelting and flat rolling mills",
    "Aluminum plate made by continuous casting purchased aluminum",
    "Aluminum plate made by flat rolling purchased aluminum",
    "Aluminum plate made in integrated secondary smelting and continuous casting mills",
    "Aluminum plate made in integrated secondary smelting and flat rolling mills",
    "Aluminum sheet made by flat rolling purchased aluminum",
    "Aluminum sheet made in integrated secondary smelting and flat rolling mills",
    "Aluminum welded tube made by flat rolling purchased aluminum",
    "Aluminum welded tube made in integrated secondary smelting and flat rolling mills",
    "Aluminum wielded tube made by flat rolling purchased aluminum",
    "Welded aluminum tube made in integrated secondary smelting and flat rolling mills",
  ],
  "824": [
    "Aluminum freezer foil not made in rolling mills",
    "Andirons manufacturing",
    "Chain fittings manufacturing",
    "Copper foil not made in rolling mills",
    "Fireplace fixtures and equipment manufacturing",
    "Flexible metal hose and tubing manufacturing",
    "Flexible metal hose manufacturing",
    "flexible metal tubing manufacturing",
    "Foil containers manufacturing",
    "Foil not made in rolling mills",
    "Foundry cores manufacturing",
    "Gold beating",
    "Gold foil and leaf not made in rolling mills",
    "Hand bellows manufacturing",
    "Handcuffs manufacturing",
    "Horse bits manufacturing",
    "Iron leg cuffs manufacturing",
    "Lead foil not made in rolling mills",
    "Magnesium foil not made in rolling mills",
    "Magnetic metal drain plugs manufacturing",
    "Meta pallets manufacturing",
    "Metal angle irons manufacturing",
    "Metal animal traps manufacturing",
    "Metal aquarium accessories manufacturing",
    "Metal badges manufacturing",
    "Metal bank chests manufacturing",
    "Metal barricades, metal, manufacturing",
    "Metal bathroom fixtures manufacturing",
    "Metal bathtubs manufacturing",
    "Metal book ends manufacturing",
    "Metal car seals manufacturing",
    "Metal cargo stabilizing bars manufacturing",
    "Metal combs manufacturing",
    "Metal compressed air or steam marine horns manufacturing",
    "Metal drill stands manufacturing",
    "Metal drinking fountains manufacturing",
    "Metal fire or burglary resistive chests manufacturing",
    "Metal flush tanks manufacturing",
    "Metal foil containers manufacturing",
    "Metal fountains manufacturing",
    "Metal hair curlers manufacturing",
    "Metal handbag and luggage frames and handles manufacturing",
    "Metal handles manufacturing",
    "Metal harness parts manufacturing",
    "Metal hoops manufacturing",
    "Metal identification plates manufacturing",
    "Metal ironing boards manufacturing",
    "Metal lamp shade frames manufacturing",
    "Metal lamp shades frames manufacturing",
    "Metal laundry and bath tubs manufacturing",
    "Metal laundry tubs manufacturing",
    "Metal lavatories manufacturing",
    "Metal leaf manufacturing",
    "Metal machine bases manufacturing",
    "Metal magnetic drain plugs manufacturing",
    "Metal military insignia manufacturing",
    "Metal money chests manufacturing",
    "Metal name plate blanks manufacturing",
    "Metal paint sticks manufacturing",
    "Metal pallet parts manufacturing",
    "Metal pipe hangers and supports manufacturing",
    "Metal plumbing fixtures manufacturing",
    "Metal portable chemical toilets manufacturing",
    "Metal reels manufacturing",
    "Metal saddlery parts manufacturing",
    "Metal sanitary ware manufacturing",
    "Metal sater closets manufacturing",
    "Metal scouring sponges manufacturing",
    "Metal shims manufacturing",
    "Metal shower receptors manufacturing",
    "Metal shower rods manufacturing",
    "Metal shower stalls manufacturing",
    "Metal sinks manufacturing",
    "Metal skids manufacturing",
    "Metal soap dispensers manufacturing",
    "Metal strappings manufacturing",
    "Metal tablets manufacturing",
    "Metal toilet fixtures manufacturing",
    "Metal umbrella and parasol frames manufacturing",
    "Metal urinals manufacturing",
    "Metal vault doors and linings manufacturing",
    "Metal vaults manufacturing",
    "Metallic permanent magnets manufacturing",
    "Nickel foil not made in rolling mills",
    "Nonprecious and precious plated metal trophies manufacturing",
    "Nonprecious metal and precious plated novelties and specialties manufacturing",
    "Plated ware manufacturing",
    "Platinum foil and leaf not made in rolling mills",
    "Precious plated metal ecclesiastical ware manufacturing",
    "Precious plated metal hollowware manufacturing",
    "Precious plated metal toilet ware manufacturing",
    "Rope fittings manufacturing",
    "Sand foundry cores manufacturing",
    "Ship and boat propellers manufacturing",
    "Silver beating",
    "Silver foil and leaf not made in rolling mills",
    "Soap impregnated scouring pads manufacturing",
    "Steel wool manufacturing",
    "Thimbles for wire rope manufacturing",
    "Tinfoil not made in rolling mills",
    "Zinc foil and leaf not made in rolling mills",
  ],
  "825": [
    "Bottle caps and tops metal stamping",
    "Caps and tops, bottle metal stamping",
    "Closures metal stamping",
    "Crowns metal stamping",
    "Home canning lids and rings metal stamping",
    "Jar lids stamping",
    "Metal stampings manufacturing",
    "Spinning unfinished metal products",
  ],
  "826": [
    "Annealing metals and metal products for the trade",
    "Brazing metals and metal products for the trade",
    "Burning metals and metal products for the trade",
    "Cold treating metals for the trade",
    "Cryogenic treating metals for the trade",
    "Hardening metals and metal products for the trade",
    "Heat treating metals and metal products for the trade",
    "Shot peening metal and metal products for the trade",
    "Tempering metals and metal products for the trade",
  ],
  "827": [
    "Metal balcony railings manufacturing",
    "Ornamental brasswork manufacturing",
    "Ornamental metal bank fixtures manufacturing",
    "Ornamental metal grillwork manufacturing",
    "Ornamental metal partitions manufacturing",
    "Ornamental metalwork manufacturing",
    "Wrought iron fences manufacturing",
  ],
  "828": [
    "Architectural metalwork manufacturing",
    "Barn stanchions and standards manufacturing",
    "Expanded metal lath manufacturing",
    "Furring metal channels manufacturing",
    "Gratings manufacturing",
    "Metal acoustical suspension systems manufacturing",
    "Metal air registers manufacturing",
    "Metal bannisters manufacturing",
    "Metal chain ladders manufacturing",
    "Metal corrals manufacturing",
    "Metal curtain wall manufacturing",
    "Metal elevator guide rails manufacturing",
    "Metal fences and gates manufacturing",
    "Metal fire escapes manufacturing",
    "Metal flagpoles manufacturing",
    "Metal gates manufacturing",
    "Metal permanently installed ladders manufacturing",
    "Metal pipe bannisters manufacturing",
    "Metal pipe guards manufacturing",
    "Metal pipe railings manufacturing",
    "Metal purlins manufacturing",
    "Metal railings manufacturing",
    "Metal scaffolds manufacturing",
    "Metal stair railings manufacturing",
    "Metal stair treads manufacturing",
    "Metal staircases manufacturing",
    "Metal stairs manufacturing",
    "Metal stalls manufacturing",
    "Open steel flooring manufacturing",
    "Sheet metal furring channels manufacturing",
    "Sheet metal grills and grillwork manufacturing",
    "Sheet metal guards, bannisters, and railings manufacturing",
    "Sheet metal holding gates manufacturing",
  ],
  "829": ["Mica processing beyond beneficiation"],
  "830": [
    "Butter wholesalers",
    "Buttermilk wholesalers",
    "Cheese wholesalers",
    "Cream stations wholesalers",
    "Cream wholesalers",
    "Creamery products wholesalers",
    "Dairy depots wholesalers",
    "Dairy desserts wholesalers",
    "Dairy products wholesalers",
    "Frozen dairy prepared foods wholesalers",
    "Frozen dairy products wholesalers",
    "Frozen yogurt wholesalers",
    "Ice cream and ices wholesalers",
    "Ice cream wholesalers",
    "Ice milk wholesalers",
    "Milk wholesalers",
    "Raw milk wholesalers",
    "Yogurt wholesalers",
  ],
  "831": [
    "Almond milk manufacturing",
    "Milk manufacturing",
    "Milk pasteurizing",
    "Milk processing (e.g., bottling, homogenizing, pasteurizing, vitaminizing) manufacturing",
    "Milk substitutes manufacturing",
    "Nondairy creamers manufacturing",
    "Nondairy yogurt manufacturing",
    "Oat milk manufacturing",
    "Pasteurizing milk manufacturing",
    "Rice milk manufacturing",
  ],
  "832": [
    "Film preservation services libraries",
    "Film restoration services",
    "Motion picture  or video productions booking agencies",
    "Motion picture booking agencies",
    "Motion picture film laboratories",
    "Motion picture film libraries",
    "Motion picture film processing laboratories",
    "Motion picture film reproduction for theatrical distribution",
    "Motion picture laboratories",
    "Motion picture laboratory services",
    "Motion picture or video, stock footage film libraries",
    "Motion picture stock footage film libraries",
    "Reproduction of motion picture films for theatrical distribution",
    "Stock footage film libraries",
    "Stock footage video tape libraries",
    "Video tape stock footage video tape",
  ],
  "833": [
    "Animated cartoon distribution",
    "Commercial distribution motion picture film libraries",
    "Film distribution agencies",
    "Film distribution, motion picture and video",
    "Film libraries, commercial distribution",
    "Motion picture distribution exclusive of production",
    "Motion picture film distributors",
    "Tape distribution for television",
    "Television show syndicators",
  ],
  "834": [
    "Animated cartoon production",
    "Animated cartoon production and distribution",
    "Film studios producing films",
    "Films, motion picture production and distribution",
    "Instructional video production",
    "Motion picture and video production",
    "Motion picture and video production and distribution",
    "Motion picture production",
    "Motion picture production and distribution",
    "Motion picture production payroll agents",
    "Motion picture studios producing motion pictures",
    "Movie production and distribution",
    "Music video production",
    "Music video production and distribution",
    "Program producing, television",
    "Television commercial production",
    "Television commercials production",
    "Television show production",
    "Video production",
    "Video production and distribution",
  ],
  "835": [
    "Accordions and parts manufacturing",
    "Autophones manufacturing",
    "Banjos and parts manufacturing",
    "Bassoons manufacturing",
    "Bells manufacturing",
    "Calliopes manufacturing",
    "Cellos and parts manufacturing",
    "Chimes and parts manufacturing",
    "Clarinets and parts manufacturing",
    "Cornets and parts manufacturing",
    "Cymbals and parts manufacturing",
    "Drums parts and accessories manufacturing",
    "Electric and nonelectric guitars and parts manufacturing",
    "Electric musical instruments manufacturing",
    "Flutes and parts manufacturing",
    "Fretted instruments and parts manufacturing",
    "Harmonicas manufacturing",
    "Harps and parts manufacturing",
    "Harpsichords manufacturing",
    "Mandolins manufacturing",
    "Mouthpieces for musical instruments manufacturing",
    "Music synthesizers manufacturing",
    "Musical instrument accessories manufacturing",
    "Musical instrument reeds manufacturing",
    "Musical instrument strings manufacturing",
    "Musical instruments manufacturing",
    "Oboes manufacturing",
    "Ocarinas manufacturing",
    "Octophones manufacturing",
    "Organ knobs manufacturing",
    "Organ pipes manufacturing",
    "Percussion musical instruments manufacturing",
    "Perforated music rolls manufacturing",
    "Piano hammers manufacturing",
    "Piano or organ keyboards manufacturing",
    "Piano parts and materials manufacturing",
    "Piccolos and parts manufacturing",
    "Saxophones and parts manufacturing",
    "Trombones and parts manufacturing",
    "Trumpets and parts manufacturing",
    "Tuning forks manufacturing",
    "Ukuleles and parts manufacturing",
    "Vibraphones manufacturing",
    "Violas and parts manufacturing",
    "Violins and parts manufacturing",
    "Xylophones and parts manufacturing",
    "Zithers and parts manufacturing",
  ],
  "836": [
    "Iron or steel horseshoe nails made in wire drawing plants",
    "Iron or steel nails made in wire drawing plants",
    "Iron or steel spikes made in wire drawing plants",
  ],
  "837": ["Hairpins manufacturing", "Needles manufacturing", "Pins manufacturing", "Safety pins manufacturing"],
  "840": ["Post office scales manufacturing"],
  "842": [
    "Absorbent cotton and cotton balls manufacturing",
    "Adhesive tape, medical, manufacturing",
    "Artificial limbs manufacturing",
    "Biohazard protective clothing and accessories manufacturing",
    "Bulletproof vests manufacturing",
    "Bunsen burners manufacturing",
    "Cervical collars manufacturing",
    "Clean room suits and accessories manufacturing",
    "Colostomy appliances manufacturing",
    "Corn remover and bunion pad manufacturing",
    "Cotton tipped applicators manufacturing",
    "Crutches and walkers manufacturing",
    "Disposable surgical drapes manufacturing",
    "Elastic orthopedic hosiery manufacturing",
    "Firefighting suits and accessories manufacturing",
    "Firefighting suits manufacturing",
    "Gas masks manufacturing",
    "Gynecological supplies and appliances manufacturing",
    "Hard hats manufacturing",
    "Hospital and surgical sterilizers manufacturing",
    "Hospital beds manufacturing",
    "Hydrotherapy equipment manufacturing",
    "Infant incubators manufacturing",
    "Intraocular lenses manufacturing",
    "Intrauterine devices manufacturing",
    "Laboratory blood testing apparatus manufacturing",
    "Laboratory dryers manufacturing",
    "Laboratory incubators manufacturing",
    "Laboratory sterilizers manufacturing",
    "Laboratory-type autoclaves manufacturing",
    "Laboratory-type evaporation apparatus manufacturing",
    "Laboratory-type sample preparation apparatus manufacturing",
    "Life preservers manufacturing",
    "Medical adhesive tapes manufacturing",
    "Metal fabric and mesh safety gloves manufacturing",
    "Nose and ear plugs manufacturing",
    "Operating room tables manufacturing",
    "Orthopedic arch supports manufacturing",
    "Orthopedic canes manufacturing",
    "Orthopedic device manufacturing and sale in retail environment",
    "Orthopedic devices manufacturing",
    "Orthopedic elastic hosiery manufacturing",
    "Orthopedic extension shoes manufacturing",
    "Orthopedic foot appliances manufacturing",
    "Orthopedic support hosiery manufacturing",
    "Orthopedic supports manufacturing",
    "Patient restraints manufacturing",
    "Personal noise protectors manufacturing",
    "Personal safety devices manufacturing",
    "Prosthetic appliances and supplies manufacturing",
    "Radiation shielding aprons, gloves, and sheeting manufacturing",
    "Respiratory protection mask manufacturing",
    "Rubber gloves, rubber manufacturing",
    "Safety helmets manufacturing",
    "Slitting medical adhesive tapes for the trade",
    "Snake bite or burn kits First-aid manufacturing",
    "Space suits manufacturing",
    "Splints manufacturing",
    "Stretchers, medical, manufacturing",
    "Surgical and medical masks manufacturing",
    "Surgical and orthopedic bandages and dressings manufacturing",
    "Surgical corsets manufacturing",
    "Surgical dressings manufacturing",
    "Surgical gauze manufacturing",
    "Surgical implants manufacturing",
    "Surgical supplies manufacturing",
    "Surgical sutures manufacturing",
    "Tongue depressors manufacturing",
    "Traction apparatus manufacturing",
    "Vacuum pumps, laboratory-type, manufacturing",
    "Welder's hoods manufacturing",
    "Wheelchairs manufacturing",
    "Whirlpool baths manufacturing",
  ],
  "843": [
    "Alpaca production",
    "Bison production",
    "Buffalo production",
    "Combination livestock farming",
    "Deer production",
    "Elk production",
    "General combination animal farming",
  ],
  "844": [
    "Architectural coatings manufacturing",
    "Calcimines manufacturing",
    "Emulsion paints manufacturing",
    "Enamel paints manufacturing",
    "Epoxy coatings made from purchased resins",
    "Frit manufacturing",
    "Glaziers' putty manufacturing",
    "Industrial product finishes and coatings manufacturing",
    "Lacquers manufacturing",
    "Latex paint manufacturing",
    "Marine paints manufacturing",
    "Motor vehicle paints manufacturing",
    "Oil and alkyd vehicle paints manufacturing",
    "Paint and varnish driers manufacturing",
    "Paint and varnish removers manufacturing",
    "Paint dopes and lacquer manufacturing",
    "Paint primers manufacturing",
    "Paint thinner and reducer preparations manufacturing",
    "Paintbrush cleaners manufacturing",
    "Paints manufacturing",
    "Pigments dispersions manufacturing",
    "Plastic wood fillers manufacturing",
    "Plastisol coating compounds manufacturing",
    "Polyurethane coatings manufacturing",
    "Powder coatings manufacturing",
    "Shellac manufacturing",
    "Stains manufacturing",
    "Varnishes manufacturing",
    "Water-repellent coatings for wood, concrete and masonry manufacturing",
    "Wood fillers manufacturing",
  ],
  "845": [
    "Electrostatic painting on-site contractors",
    "Engineering structure painting",
    "Paperhanging and removal contractors",
    "Rustproofing",
    "Wall covering or removal contractors",
    "Wallpaper hanging and removal contractors",
    "Wallpaper stripping",
  ],
  "846": ["House painting", "Painting contractors", "Whitewashing contractors"],
  "849": ["Ship painting contractors"],
  "851": ["Bridge painting"],
  "852": [
    "Book paper manufacturing",
    "Coated board manufacturing",
    "Coated book paper manufacturing",
    "Coated groundwood paper manufacturing",
    "Coated paper bags manufacturing",
    "Coating paper for nonpackaging applications manufacturing",
    "Coating purchased papers for packaging applications",
    "Flexible packaging sheet materials manufacturing",
    "Foil bags manufacturing",
    "Gift wrap manufacturing",
    "Grocers' bags and sacks manufacturing",
    "Gummed paper products (e.g., labels, sheets, tapes) manufacturing",
    "Gummed pressure sensitive tapes manufacturing",
    "Laminated or surface coated cardboard manufacturing",
    "Laminated or surface coated chipboard manufacturing",
    "Laminating foil for flexible packaging applications manufacturing",
    "Laminating foil sheets for nonpackaging applications manufacturing",
    "Laminating foil sheets for packaging applications manufacturing",
    "Laminating or coating foil or paper bags manufacturing",
    "Laminating papers for nonpackaging applications manufacturing",
    "Laminating papers for packaging applications manufacturing",
    "Laminating purchased paperboard manufacturing",
    "Leatherboard manufacturing",
    "Masking tape manufacturing",
    "Milk carton board stock manufacturing",
    "Multiwall bags manufacturing",
    "Multiwall sacks manufacturing",
    "Pasted, lined, laminated or surface coated paperboard manufacturing",
    "Pressure sensitive paper and tape manufacturing",
    "Slitting adhesive tapes other than medical types for the trade manufacturing",
    "Surface coating paperboard manufacturing",
    "Uncoated paper bags manufacturing",
    "Wallpaper manufacturing",
    "Waxed paper for nonpackaging applications manufacturing",
    "Waxed paper for packaging applications manufacturing",
  ],
  "853": [
    "Disposable diapers made in paper mills",
    "Facial tissues made in paper mills",
    "Paper towels made in paper mills",
    "Sanitary napkins and tampons made in paper mills",
    "Sanitary products made in paper mills",
    "Saturated felts made in paper mills",
    "Table napkins made in paper mills",
    "Tablets (e.g., memo, note, writing) made in paper mills",
  ],
  "854": [
    "Absorbent paper stock manufacturing",
    "Asphalt felts made in paper mills",
    "Asphalt paper made in paper mills",
    "Bond paper made in paper mills",
    "Bristols paper stock manufacturing",
    "Building and roofing tar paper made in paper mills",
    "Building paper stock manufacturing",
    "Cigarette paper made in paper mills",
    "Coated book paper made in paper mills",
    "Coated groundwood paper made in paper mills",
    "Coated, laminated or treated paper made in paper mills",
    "Cotton fiber paper stock manufacturing",
    "Glassine wrapping paper made in paper mills",
    "Groundwood paper products made in newsprint mills",
    "Kraft paper stock manufacturing",
    "Looseleaf fillers and paper made in paper mills",
    "Newsprint and uncoated groundwood paper manufacturing",
    "Newsprint groundwood paper  made in paper mills",
    "Newsprint mills",
    "Newsprint paper manufacturing",
    "Newsprint paper mills",
    "Office paper made in paper mills",
    "Paper manufacturing",
    "Paper mills",
    "Paper products made in paper mills",
    "Paper stock for conversion into paper products manufacturing",
    "Pulp and newsprint combined manufacturing",
    "Pulp and paper combined manufacturing",
    "Pulp mills producing newsprint paper",
    "Pulp mills producing paper",
    "Sanitary paper products made in paper mills",
    "Sanitary paper stock manufacturing",
    "School and art construction paper made in paper mills",
    "Sheathing paper made in paper mills",
    "Tissue paper stock manufacturing",
    "Toilet paper made in paper mills",
    "Uncoated and untreated pulp mills and groundwood paper manufacturing",
    "Uncoated groundwood paper mills",
    "Writing paper made in paper mills",
  ],
  "857": ["Foundry pattern making", "Industrial pattern manufacturing", "Industrial patterns manufacturing"],
  "858": ["Pattern and plan publishers", "Pattern and plan publishers and printing combined"],
  "859": [
    "Artist's paint manufacturing",
    "Artist's paints manufacturing",
    "Artist's palettes manufacturing",
    "Artist's supplies manufacturing",
    "Artist's water colors manufacturing",
    "Ball point pens manufacturing",
    "Branding irons manufacturing",
    "Chalk manufacturing",
    "Crayons manufacturing",
    "Drafting materials manufacturing",
    "Easels manufacturing",
    "Embossing stamps manufacturing",
    "Felt tip markers manufacturing",
    "Fountain pens manufacturing",
    "Hand operated date stamps manufacturing",
    "Hand operated hole punchers manufacturing",
    "Hand operated seal presses manufacturing",
    "Hand operated stamping devices manufacturing",
    "Hand operated stamps manufacturing",
    "Handheld label making equipment manufacturing",
    "List finders and roledex address files manufacturing",
    "Marking devices manufacturing",
    "Mechanical pencil refills manufacturing",
    "Mechanical pencils manufacturing",
    "Metal hand stamps manufacturing",
    "Modeling clay manufacturing",
    "Nibs manufacturing",
    "Office paper cutters manufacturing",
    "Pen refills and cartridges manufacturing",
    "Pencil leads manufacturing",
    "Pencil sharpeners manufacturing",
    "Pencils manufacturing",
    "Pens manufacturing",
    "Rubber stamps manufacturing",
    "Stamp pads manufacturing",
    "Staple removers manufacturing",
    "Staplers manufacturing",
    "Stencils for painting and marking manufacturing",
    "Tape dispensers manufacturing",
  ],
  "860": [
    "Cat food manufacturing",
    "Dog and cat feed supplements manufacturing",
    "Dog and cat food manufacturing",
    "Dog and cat pet food meat canning",
    "Dog food manufacturing",
    "Meat processing for dog and cat food",
  ],
  "871": [
    "Camera and projector tripods manufacturing",
    "Camera lens hoods manufacturing",
    "Camera lenses manufacturing",
    "Cameras manufacturing",
    "Digital cameras manufacturing",
    "Film developing equipment manufacturing",
    "Lens coating ",
    "Lens grinding",
    "Lens mounting",
    "Lens polishing",
    "Lenses manufacturing",
    "Photoflash equipment manufacturing",
    "Photographic developing, fixing, and washing tanks manufacturing",
    "Photographic driers manufacturing",
    "Photographic enlargers manufacturing",
    "Photographic equipment manufacturing",
    "Photographic exposure meters manufacturing",
    "Photographic flash apparatus manufacturing",
    "Photographic lenses manufacturing",
    "Photographic light meters manufacturing",
    "Photographic projection equipment manufacturing",
    "Photographic range finders manufacturing",
    "Photographic sensitometers manufacturing",
    "Photographic printing and processing trays manufacturing",
  ],
  "872": ["Microfilm equipment manufacturing"],
  "873": [
    "Mechanical and hypodermic sizes tubes made from purchased steel",
    "Pipe made from purchased iron or steel",
    "Tube made from purchased iron or steel",
    "Welded and lock joint conduit made from purchased iron or steel",
    "Well casings made from purchased iron or steel",
    "Wrought boiler tubes made from purchased iron",
  ],
  "874": [
    "PVC pipe manufacturing",
    "Rigid plastics pipe fittings and unions manufacturing",
    "Rigid plastics pipe fittings manufacturing",
    "Rigid plastics pipe manufacturing",
  ],
  "875": ["Panel or rigid board insulation installation"],
  "876": [
    "Acetal resins manufacturing",
    "Acrylic resins manufacturing",
    "Acrylonitrile-butadiene-styrene (ABS) resins manufacturing",
    "Alkyd resins manufacturing",
    "Allyl resins manufacturing",
    "Amino resins manufacturing",
    "Amino-aldehyde resins manufacturing",
    "Butadiene copolymers containing less than 50 percent butadiene manufacturing",
    "Carbohydrate plastics manufacturing",
    "Casein plastics manufacturing",
    "Cellulose acetate resins manufacturing",
    "Cellulose nitrate resins manufacturing",
    "Cellulose propionate resins manufacturing",
    "Cellulose resins manufacturing",
    "Cellulose xanthate (viscose) manufacturing",
    "Coal tar resins manufacturing",
    "Coumarone-indene resins manufacturing",
    "Cresol resins manufacturing",
    "Cresol-furfural resins manufacturing",
    "Dicyandiamine resins manufacturing",
    "Diisocyanate resins manufacturing",
    "Elastomers manufacturing",
    "Epichlorohydrin bisphenol manufacturing",
    "Epichlorohydrin diphenol manufacturing",
    "Epoxy resins manufacturing",
    "Ester gum manufacturing",
    "Ethylcellulose plastics manufacturing",
    "Ethylene-vinyl acetate resins manufacturing",
    "Fluorohydrocarbon resins manufacturing",
    "Fluoro-polymer resins manufacturing",
    "Ion exchange resins manufacturing",
    "Ionomer resins manufacturing",
    "Isobutylene polymer resins manufacturing",
    "Lignin plastics manufacturing",
    "Melamine resins manufacturing",
    "Methyl acrylate resins manufacturing",
    "Methyl cellulose resins manufacturing",
    "Methyl methacrylate resins manufacturing",
    "Nitrocellulose resins manufacturing",
    "Nylon resins manufacturing",
    "Petroleum polymer resins manufacturing",
    "Phenol-formaldehyde resins manufacturing",
    "Phenol-furfural resins manufacturing",
    "Phenolic resins manufacturing",
    "Phenoxy resins manufacturing",
    "Phthalic alkyd resins manufacturing",
    "Phthalic anhydride resins manufacturing",
    "Plastics and synthetic resins regenerating, precipitating and coagulating",
    "Plastics resins manufacturing",
    "Polyacrylonitrile resins manufacturing",
    "Polyamide resins manufacturing",
    "Polycarbonate resins manufacturing",
    "Polyester resins manufacturing",
    "Polyethylene resins manufacturing",
    "Polyethylene terephthalate (PET) resins manufacturing",
    "Polyhexamethylenediamine adipamide resins manufacturing",
    "Polyisobutylene resins manufacturing",
    "Polymethacrylate resins manufacturing",
    "Polypropylene resins manufacturing",
    "Polystyrene resins manufacturing",
    "Polytetrafluoroethylene resins manufacturing",
    "Polyurethane resins manufacturing",
    "Polyvinyl alcohol resins manufacturing",
    "Polyvinyl chloride (PVC) resins manufacturing",
    "Polyvinyl halide resins manufacturing",
    "Polyvinyl resins manufacturing",
    "Propylene resins manufacturing",
    "Protein plastics manufacturing",
    "Pyroxylin resins manufacturing",
    "Rosins manufacturing",
    "Silicone resins manufacturing",
    "Soybean plastics manufacturing",
    "Styrene resins manufacturing",
    "Styrene-acrylonitrile resins manufacturing",
    "Tar acid resins manufacturing",
    "Thermoplastic resins and plastics materials manufacturing",
    "Thermosetting plastics resins manufacturing",
    "Urea resins manufacturing",
    "Urea-formaldehyde resins manufacturing",
    "Vinyl acetate resins manufacturing",
    "Vinyl chloride resins manufacturing",
    "Vinyl resins manufacturing",
    "Vinylidene resins manufacturing",
  ],
  "877": ["Plastic rulers manufacturing"],
  "878": [
    "Plastic windows and window frames manufacturing",
    "Plastic windshields manufacturing",
    "Plastics  artificial fruits and vegetables manufacturing",
    "Plastics air mattresses manufacturing",
    "Plastics badges manufacturing",
    "Plastics balloons manufacturing",
    "Plastics bathroom and toilet accessories manufacturing",
    "Plastics bolts, nuts, and rivets manufacturing",
    "Plastics bowls and bowl covers manufacturing",
    "Plastics bubble packaging materials manufacturing",
    "Plastics building materials manufacturing",
    "Plastics bushings manufacturing",
    "Plastics clothes hangers manufacturing",
    "Plastics clothespins manufacturing",
    "Plastics combs manufacturing",
    "Plastics coolers or ice chests manufacturing",
    "Plastics corrugated and flat building panels manufacturing",
    "Plastics countertops manufacturing",
    "Plastics credit and identification card stock manufacturing",
    "Plastics cups manufacturing",
    "Plastics dinnerware manufacturing",
    "Plastics doors and door frames manufacturing",
    "Plastics footwear parts manufacturing",
    "Plastics gloves manufacturing",
    "Plastics gutters and down spouts manufacturing",
    "Plastics hampers manufacturing",
    "Plastics handles manufacturing",
    "Plastics hardware manufacturing",
    "Plastics ice buckets manufacturing",
    "Plastics ice chests or coolers manufacturing",
    "Plastics cigar and cigarette holders manufacturing",
    "Plastics jars manufacturing",
    "Plastics kitchen utensils manufacturing",
    "Plastics microwaveware manufacturing",
    "Plastics motor vehicle moldings and extrusions manufacturing",
    "Plastics netting manufacturing",
    "Plastics nozzles, aerosol spray manufacturing",
    "Plastics ophthalmic or optical lens blanks manufacturing",
    "Plastics or fiberglass ownings manufacturing",
    "Plastics or fiberglass septic tanks manufacturing",
    "Plastics or fiberglass storage tanks manufacturing",
    "Plastics organizers for closets, drawers and shelves manufacturing",
    "Plastics packaging, plastics manufacturing",
    "Plastics pails manufacturing",
    "Plastics paint sticks manufacturing",
    "Plastics picnic jugs manufacturing",
    "Plastics prefabricated buildings manufacturing",
    "Plastics radio housings manufacturing",
    "Plastics reels manufacturing",
    "Plastics reflectors manufacturing",
    "Plastics shoe parts manufacturing",
    "Plastics shutters manufacturing",
    "Plastics siding manufacturing",
    "Plastics sponges manufacturing",
    "Plastics swimming pool covers and liners manufacturing",
    "Plastics television housings manufacturing",
    "Plastics tires manufacturing",
    "Plastics tumblers manufacturing",
    "Plastics watch crystals manufacturing",
    "Plastics watering cans manufacturing",
    "Plastics window screening manufacturing",
  ],
  "879": ["Plastics bottle caps and lids manufacturing"],
  "880": [
    "Bathroom plumbing fixture and sanitary ware installation",
    "Kitchen sink and hardware installation",
    "Plumbers",
    "Plumbing and heating contractors",
    "Plumbing contractors",
    "Plumbing fixture installation",
  ],
  "882": [
    "Aerosol valves manufacturing",
    "Firefighting nozzles manufacturing",
    "Inline plumbing and heating valves manufacturing",
    "Lawn hose nozzles and lawn sprinklers manufacturing",
    "Lawn sprinklers manufacturing",
    "Metal hose couplings manufacturing",
    "Metal pipe elbows manufacturing",
    "Metal pipe flanges and flange unions manufacturing",
    "Metal pipe unions manufacturing",
    "Metal steam fittings manufacturing",
    "Plumbing and heating boiler couplings and drains manufacturing",
    "Plumbing vacuum breakers manufacturing",
    "Water traps manufacturing",
  ],
  "883": [
    "Hardwood faced plywood manufacturing",
    "Hardwood plywood composites manufacturing",
    "Hardwood plywood manufacturing",
    "Hardwood plywood panels manufacturing",
    "Hardwood veneer mills",
    "Hardwood veneer or plywood manufacturing",
    "Prefinished hardwood plywood manufacturing",
    "Reconstituted hardwood veneer manufacturing",
  ],
  "885": [
    "Cabin construction general contractors",
    "Condominium construction general contractors",
    "Cottage construction general contractors",
    "Duplex construction general contractors",
    "Log home construction general contractors",
    "Modular single-family housing assembled on site by general contractors",
    "Panelized single-family housing assembled on site by general contractors",
    "Precut single-family housing assembled on site by general contractors",
    "Prefabricated residential building erection, single-family",
    "Premanufactured single-family housing assembled on site by general contractors",
    "Row house construction general contractors",
    "Single-family attached housing construction general contractors",
    "Single-family builders construction management",
    "Single-family construction general contractors",
    "Single-family detached housing construction general contractors",
    "Single-family home builders ",
    "Single-family home custom builders ",
    "Single-family homes built on land owned by others",
    "Single-family house construction by general contractors",
    "Single-family housing construction general contractors",
    "Single-family residential construction general contractors",
    "Single-family vacation home construction by general contractors",
    "Time-share condominium construction general contractors",
    "Town house construction by general contractors",
  ],
  "886": [
    "Prefabricated wood building panels manufacturing",
    "Prefabricated wood building sections manufacturing",
    "Prefabricated wood buildings manufacturing",
    "Prefabricated wood chicken coops manufacturing",
    "Prefabricated wood corn cribs manufacturing",
    "Prefabricated wood farm buildings manufacturing",
    "Prefabricated wood garages manufacturing",
    "Prefabricated wood geodesic domes manufacturing",
    "Prefabricated wood homes manufacturing",
    "Prefabricated wood houses manufacturing",
    "Prefabricated wood log cabins manufacturing",
    "Prefabricated wood sauna rooms manufacturing",
    "Prefabricated wood sheds (e.g., garden, storage, utility) manufacturing",
  ],
  "890": [
    "Centrifugal pumps manufacturing",
    "Dispensing and measuring pumps manufacturing",
    "Gasoline measuring and dispensing pumps manufacturing",
    "General purpose pumps manufacturing",
    "Industrial and commercial general purpose pumps manufacturing",
    "Measuring and dispensing pumps manufacturing",
    "Oil field or well pumps manufacturing",
    "Oil measuring and dispensing pumps manufacturing",
    "Oil well and oil field pumps manufacturing",
    "Pumps for railroad equipment lubrication systems manufacturing",
    "Residential sump or water pumps manufacturing",
    "Residential sump pumps manufacturing",
  ],
  "891": [
    "Beer cooling and dispensing equipment manufacturing",
    "Cooling towers manufacturing",
    "Ice making machinery manufacturing",
    "Industrial and commercial freezing equipment manufacturing",
    "Industrial and commercial refrigeration equipment manufacturing",
    "Laboratory freezers manufacturing",
    "Mechanical water coolers manufacturing",
    "Refrigerated counters and display cases manufacturing",
    "Refrigerated display cases manufacturing",
    "Refrigerated drinking fountains manufacturing",
    "Refrigerated lockers manufacturing",
    "Refrigerated showcases manufacturing",
    "Refrigeration compressors manufacturing",
    "Refrigeration coolers manufacturing",
    "Snow making machinery manufacturing",
    "Soda fountain cooling and dispensing equipment manufacturing",
    "Truck refrigeration units manufacturing",
    "Water coolers manufacturing",
  ],
  "892": [
    "Commercial freezer installation",
    "Commercial refrigeration system installation",
    "Refrigeration system installation",
    "Warehouse refrigeration system installation",
  ],
  "894": [
    "Cold rolling steel shapes made from purchased steel",
    "Concrete reinforcing (rebar) bar made from purchased steel in steel rolling mills",
    "Concrete reinforcing bar (rebar) made from purchased steel in cold rolling mills ",
    "Concrete reinforcing bar (rebar) made from purchased steel in steel rolling mills",
    "Corrugating iron or steel in cold rolling mills made from purchased iron or steel",
    "Ferrous metal powder, paste, and flake made from purchased iron or steel",
    "Flakes made from purchased iron or steel",
    "Flat bright steel strip made in cold rolling mills made from purchased steel",
    "Flat rolled strip wire made in cold rolling mills",
    "Hot-rolling purchased steel",
    "Iron or steel nut rods made in cold rolling mills",
    "Metal powder and flake made from purchased iron or steel",
    "Paste made from purchased iron or steel",
    "Powder made from purchased iron or steel",
    "Razor blade strip steel made in cold rolling mills",
    "Steel bars made from purchased steel in cold rolling mills",
  ],
  "895": [
    "Asphalt roof shingle installation",
    "Built-up tar and gravel roofing installation",
    "Copper roofing installation",
    "Corrugated metal roofing installation",
    "Galvanized iron roofing installation",
    "Low slope roofing installation",
    "Roll roofing installation",
    "Roof application of solar reflecting coating",
    "Roof membrane installation",
    "Roof painting, spraying or coating",
    "Roof shake and shingle installation",
    "Roofing contractors",
    "Skylight installation",
    "Steep slope roofing installation",
    "Treating roofs",
  ],
  "896": ["Fabricated rope products (e.g., nets, slings) made in cordage or twine mills", "Ropes manufacturing"],
  "897": [
    "Acrylate rubber manufacturing",
    "Acrylate-butadiene rubber manufacturing",
    "Acrylic rubber manufacturing",
    "Butadiene copolymers containing more than 50 percent butadiene manufacturing",
    "Butadiene rubber manufacturing",
    "Butyl rubber manufacturing",
    "Chloroprene rubber manufacturing",
    "Chlorosulfonated polyethylenes manufacturing",
    "Elastomers, synthetic rubber, manufacturing",
    "Epichlorohydrin elastomers manufacturing",
    "Ethylene-propylene rubber manufacturing",
    "Ethylene-propylene-nonconjugated diene (EPDM) rubber manufacturing",
    "Fluoro rubbers manufacturing",
    "Fluorocarbon derivative rubbers manufacturing",
    "Isobutylene-isoprene rubber manufacturing",
    "Isocyanate rubber manufacturing",
    "Neoprene manufacturing",
    "Nitrile rubber manufacturing",
    "Nitrile-butadiene rubber manufacturing",
    "Nitrile-chloroprene rubbers manufacturing",
    "N-type rubber manufacturing",
    "Polyethylene rubber manufacturing",
    "Polyisobutylene rubber manufacturing",
    "Polyisobutylene-isoprene rubber manufacturing",
    "Polymethylene rubber manufacturing",
    "Polysulfide rubber manufacturing",
    "Silicone rubber manufacturing",
    "Stereo rubber manufacturing",
    "S-type rubber manufacturing",
    "Styrene-butadiene rubber containing less than 50 percent styrene manufacturing",
    "Styrene-chloroprene rubber manufacturing",
    "Styrene-isoprene rubber manufacturing",
    "Synthetic chlorinated rubber manufacturing",
    "Synthetic cyclo rubber manufacturing",
    "Synthetic latex rubber manufacturing",
    "Synthetic rubber manufacturing",
    "Thermosetting vulcanizable elastomers manufacturing",
    "Thiol rubber manufacturing",
    "Urethane rubber manufacturing",
    "Vulcanized oils manufacturing",
  ],
  "898": [
    "Birth control devices manufacturing",
    "Condom manufacturing",
    "Hard rubber rods manufacturing",
    "Hard rubber tobacco pipe bits and stems manufacturing",
    "Latex foam rubber manufacturing",
    "Latex foam rubber products manufacturing",
    "Prophylactics manufacturing",
    "Reclaiming rubber from waste or scrap",
    "Rubber air mattresses manufacturing",
    "Rubber balloons manufacturing",
    "Rubber bands manufacturing",
    "Rubber bath mats manufacturing",
    "Rubber brushes manufacturing",
    "Rubber combs manufacturing",
    "Rubber diaphragms manufacturing",
    "Rubber doormats manufacturing",
    "Rubber floor mats manufacturing",
    "Rubber footwear parts manufacturing",
    "Rubber fuel bladders manufacturing",
    "Rubber grips and handles manufacturing",
    "Rubber grommets manufacturing",
    "Rubber hair care products manufacturing",
    "Rubber hair curlers manufacturing",
    "Rubber hairpins manufacturing",
    "Rubber hot water bottles manufacturing",
    "Rubber inflatable rafts manufacturing",
    "Rubber mattress protectors manufacturing",
    "Rubber nipples and teething rings manufacturing",
    "Rubber or rubber and abrasive combined erasers manufacturing",
    "Rubber pacifiers manufacturing",
    "Rubber rolls and roll coverings manufacturing",
    "Rubber sheeting manufacturing",
    "Rubber shoe and boot parts manufacturing",
    "Rubber spatulas manufacturing",
    "Rubber sponges manufacturing",
    "Rubber stair treads manufacturing",
    "Rubber stoppers manufacturing",
    "Rubber thread, rubber manufacturing",
    "Rubber tubing manufacturing",
    "Ruber roofing manufacturing",
  ],
  "900": [
    "Feed bags for horses manufacturing",
    "Horse boots and muzzles manufacturing",
    "Horse whips manufacturing",
    "Leather Harnesses and harness parts manufacturing",
    "Leather saddles and parts manufacturing",
  ],
  "901": [
    "Metal chests, safe deposit manufacturing",
    "Metal safe and vault doors manufacturing",
    "Metal safe and vault linings manufacturing",
    "Metal safe deposit boxes and chests manufacturing",
    "Metal safe doors and linings manufacturing",
    "Metal safes, metal, manufacturing",
  ],
  "902": ["Sails made from purchased fabrics"],
  "903": [
    "Activated carbon manufacturing",
    "Activated carbon or charcoal manufacturing",
    "Activated charcoal manufacturing",
    "Additive preparations for gasoline manufacturing",
    "Aerosol can filling on a job-order or contract basis",
    "Aerosol packaging services",
    "Anise oil manufacturing",
    "Antifreeze preparations manufacturing",
    "Antiscaling compounds manufacturing",
    "Bay oil manufacturing",
    "Cannabidiol (CBD oil) manufacturing",
    "Caps for toy pistols manufacturing",
    "Carburetor cleaners manufacturing",
    "Cat litter manufacturing",
    "Cedar oil manufacturing",
    "Citronella oil manufacturing",
    "Clove oil manufacturing",
    "Concrete additive preparations manufacturing",
    "Correction fluids manufacturing",
    "Crankcase additive preparations manufacturing",
    "Defoamers and antifoaming agents manufacturing",
    "Degreasing preparations for machinery parts manufacturing",
    "Deicing preparations manufacturing",
    "Desalination kits manufacturing",
    "Distilled water manufacturing",
    "Drawing inks manufacturing",
    "Drilling mud compounds, conditioners and additives  manufacturing",
    "Electronic cigarette vapor liquids manufacturing",
    "Embalming fluids manufacturing",
    "Empty gelatin capsules manufacturing",
    "Engine degreasers manufacturing",
    "Engine starting fluids manufacturing",
    "Essential oils manufacturing",
    "Eucalyptus oil manufacturing",
    "Fire extinguisher chemical preparations manufacturing",
    "Fire retardant chemical preparations manufacturing",
    "Fireworks manufacturing",
    "Flares manufacturing",
    "Fluxes manufacturing",
    "Foundry core oil manufacturing",
    "Gelatin manufacturing",
    "Grapefruit oil manufacturing",
    "Household clothing dye preparations manufacturing",
    "Household tint and dye preparations manufacturing",
    "Incense manufacturing",
    "Indelible inks manufacturing",
    "India inks manufacturing",
    "Industrial salt manufacturing",
    "Inhibitors manufacturing",
    "Insulating oils manufacturing",
    "Lemon oil manufacturing",
    "Lighter fluids manufacturing",
    "Lime oil manufacturing",
    "Lubricating, synthetic oils manufacturing",
    "Matches and match books manufacturing",
    "Napalm manufacturing",
    "Oil additive preparations manufacturing",
    "Orange oil manufacturing",
    "Orris oil manufacturing",
    "Ossein manufacturing",
    "Packer's fluids manufacturing",
    "Peppermint oil manufacturing",
    "Pyrotechnics manufacturing",
    "Radiator additive preparations manufacturing",
    "Recycling services for degreasing solvents manufacturing",
    "Retarders manufacturing",
    "Rubber processing preparations manufacturing",
    "Rust preventive preparations manufacturing",
    "Salt manufacturing",
    "Soil testing kits manufacturing",
    "Spearmint oil manufacturing",
    "Stamp pad ink manufacturing",
    "Swimming pool chemical preparations manufacturing",
    "Synthetic brake fluid manufacturing",
    "Synthetic cutting oils manufacturing",
    "Synthetic hydraulic fluids manufacturing",
    "Synthetic lubricating greases manufacturing",
    "Synthetic lubricating oils and greases manufacturing",
    "Synthetic motor oils manufacturing",
    "Synthetic oils manufacturing",
    "Synthetic penetrating fluids manufacturing",
    "Synthetic transmission fluids manufacturing",
    "Wintergreen oil manufacturing",
    "Writing inks manufacturing",
  ],
  "904": [
    "Alkalies manufacturing",
    "Aluminum chloride manufacturing",
    "Aluminum compounds manufacturing",
    "Aluminum hydroxide manufacturing",
    "Aluminum sulfate manufacturing",
    "Alums manufacturing",
    "Ammonium chloride manufacturing",
    "Ammonium compounds manufacturing",
    "Ammonium hydroxide manufacturing",
    "Ammonium molybdate manufacturing",
    "Ammonium perchlorate manufacturing",
    "Ammonium thiosulfate manufacturing",
    "Animal black manufacturing",
    "Antimony oxide manufacturing",
    "Arsenates manufacturing",
    "Arsenic compounds manufacturing",
    "Barium compounds manufacturing",
    "Barium hydroxide manufacturing",
    "Beryllium oxide manufacturing",
    "Bleaching agents, inorganic, manufacturing",
    "Bone black manufacturing",
    "Borax manufacturing",
    "Boric acid manufacturing",
    "Boron compounds manufacturing",
    "Borosilicate manufacturing",
    "Bromine manufacturing",
    "Calcium carbide, chloride, and hypochlorite manufacturing",
    "Calcium hypochlorite manufacturing",
    "Calcium inorganic compounds manufacturing",
    "Carbides manufacturing",
    "Carbon black manufacturing",
    "Carbon disulfide manufacturing",
    "Carbon inorganic compounds manufacturing",
    "Carbonic acid manufacturing",
    "Caustic potash manufacturing",
    "Caustic soda manufacturing",
    "Cerium salts manufacturing",
    "Cesium and cesium compounds manufacturing",
    "Channel black manufacturing",
    "Chloride of lime manufacturing",
    "Chlorine compounds manufacturing",
    "Chlorine dioxide manufacturing",
    "Chlorine manufacturing",
    "Chlorosulfonic acid manufacturing",
    "Chromic acid manufacturing",
    "Chromium compounds manufacturing",
    "Chromium oxide manufacturing",
    "Chromium salts manufacturing",
    "Cobalt chloride manufacturing",
    "Cobalt compounds manufacturing",
    "Cobalt sulfate manufacturing",
    "Copper chloride manufacturing",
    "Copper compounds manufacturing",
    "Copper iodide manufacturing",
    "Copper sulfate manufacturing",
    "Crude or resublimed iodine manufacturing",
    "Cyanides manufacturing",
    "Deuterium oxide (i.e., heavy water) manufacturing",
    "Dichromates manufacturing",
    "Enriched uranium manufacturing",
    "Ferric chloride manufacturing",
    "Ferric oxide manufacturing",
    "Ferrocyanides manufacturing",
    "Fluoboric acid manufacturing",
    "Fluorine manufacturing",
    "Furnace black manufacturing",
    "Glauber's salt manufacturing",
    "Heavy water manufacturing",
    "Hydrazine manufacturing",
    "Hydrochloric acid manufacturing",
    "Hydrocyanic acid manufacturing",
    "Hydrofluoric acid manufacturing",
    "Hydrofluosilicic acid manufacturing",
    "Hydrogen peroxide manufacturing",
    "Hydrogen sulfide manufacturing",
    "Hydrosulfites manufacturing",
    "Hypochlorites manufacturing",
    "Hypophosphites manufacturing",
    "Indium chloride manufacturing",
    "Inorganic solid fuel propellants manufacturing",
    "Inorganic tanning agents manufacturing",
    "Iodides manufacturing",
    "Iron compounds manufacturing",
    "Iron sulphate manufacturing",
    "Lamp black manufacturing",
    "Lead oxides manufacturing",
    "Lead silicate manufacturing",
    "Lithium compounds manufacturing",
    "Magnesium carbonate manufacturing",
    "Magnesium chloride manufacturing",
    "Magnesium compounds manufacturing",
    "Manganese dioxide manufacturing",
    "Mercury chloride manufacturing",
    "Mercury compounds manufacturing",
    "Mercury oxide manufacturing",
    "Nickel ammonium sulfate manufacturing",
    "Nickel carbonate manufacturing",
    "Nickel compounds manufacturing",
    "Nickel sulfate manufacturing",
    "Nuclear fuel scrap reprocessing",
    "Nuclear fuels, inorganic, manufacturing",
    "Oleum manufacturing",
    "Perchloric acid manufacturing",
    "Peroxides, inorganic, manufacturing",
    "Phosphorus compounds manufacturing",
    "Phosphorus oxychloride manufacturing",
    "Potassium aluminum sulfate manufacturing",
    "Potassium bichromate and chromate manufacturing",
    "Potassium bromide manufacturing",
    "Potassium carbonate manufacturing",
    "Potassium chlorate manufacturing",
    "Potassium chloride manufacturing",
    "Potassium cyanide manufacturing",
    "Potassium hydroxide manufacturing",
    "Potassium hypochlorate manufacturing",
    "Potassium inorganic compounds manufacturing",
    "Potassium iodide manufacturing",
    "Potassium nitrate manufacturing",
    "Potassium permanganate manufacturing",
    "Potassium salts manufacturing",
    "Potassium sulfate manufacturing",
    "Radioactive cobalt manufacturing",
    "Radioactive elements manufacturing",
    "Radioactive isotopes manufacturing",
    "Radium chloride manufacturing",
    "Radium luminous compounds manufacturing",
    "Rare earth compounds manufacturing",
    "Rare earth salts manufacturing",
    "Sal soda manufacturing",
    "Selenium compounds manufacturing",
    "Selenium dioxide manufacturing",
    "Silica gel manufacturing",
    "Silica, amorphous, manufacturing",
    "Silicofluorides manufacturing",
    "Silver bromide manufacturing",
    "Silver chloride manufacturing",
    "Silver compounds manufacturing",
    "Silver nitrate manufacturing",
    "Soda ash manufacturing",
    "Sodium aluminate manufacturing",
    "Sodium aluminum sulfate manufacturing",
    "Sodium antimoniate manufacturing",
    "Sodium arsenate manufacturing",
    "Sodium bicarbonate manufacturing",
    "Sodium bichromate and chromate manufacturing",
    "Sodium borate manufacturing",
    "Sodium borohydride manufacturing",
    "Sodium bromide manufacturing",
    "Sodium carbonate manufacturing",
    "Sodium chlorate manufacturing",
    "Sodium cyanide manufacturing",
    "Sodium hydrosulfite manufacturing",
    "Sodium hydroxide manufacturing",
    "Sodium hypochlorite manufacturing",
    "Sodium inorganic compounds manufacturing",
    "Sodium molybdate manufacturing",
    "Sodium perborate manufacturing",
    "Sodium peroxide manufacturing",
    "Sodium phosphate manufacturing",
    "Sodium polyphosphate manufacturing",
    "Sodium silicate manufacturing",
    "Sodium silicofluoride manufacturing",
    "Sodium stannate manufacturing",
    "Sodium sulfate manufacturing",
    "Sodium tetraborate manufacturing",
    "Sodium thiosulfate manufacturing",
    "Sodium tungstate manufacturing",
    "Sodium uranate manufacturing",
    "Solid inorganic fuel propellants manufacturing",
    "Stannic and stannous chloride manufacturing",
    "Strontium carbonate manufacturing",
    "Strontium compounds manufacturing",
    "Strontium nitrate manufacturing",
    "Sulfides and sulfites manufacturing",
    "Sulfocyanides manufacturing",
    "Sulfur and sulfur compounds manufacturing",
    "Sulfur chloride manufacturing",
    "Sulfur dioxide manufacturing",
    "Sulfur hexafluoride gas manufacturing",
    "Sulfur recovering or refining ",
    "Sulfuric acid manufacturing",
    "Thiocyanate manufacturing",
    "Tin chloride manufacturing",
    "Tin compounds manufacturing",
    "Tin oxide manufacturing",
    "Tin salts manufacturing",
    "Titanium dioxide manufacturing",
    "Tungstates manufacturing",
    "Tungsten compounds manufacturing",
    "Uranium compounds manufacturing",
    "Uranium oxide manufacturing",
    "Zinc ammonium chloride manufacturing",
    "Zinc chloride manufacturing",
    "Zinc compounds manufacturing",
    "Zinc hydrosulfite manufacturing",
    "Zinc oxide manufacturing",
    "Zinc sulfide manufacturing",
  ],
  "905": [
    "Common sand quarrying and/or beneficiating",
    "Construction grade sand quarrying and/or beneficiating",
    "Construction sand and gravel beneficiating",
    "Construction sand or gravel dredging",
    "Gravel quarrying and/or beneficiating",
    "Pebbles mining and/or beneficiating",
    "Sand and gravel quarrying and/or beneficiating",
  ],
  "906": [
    "Ceiling lumber made from logs or bolts",
    "Chipper mills",
    "Custom sawmills",
    "Dimension lumber made from logs or bolts",
    "Dressed lumber siding manufacturing",
    "Flitches (i.e., veneer stock) made in sawmills",
    "Hardwood dimension lumber made from logs or bolts",
    "Lumber (i.e., rough, dressed) made from logs or bolts",
    "Pressure treated lumber made from logs or bolts and treated",
    "Railroad ties made from logs or bolts",
    "Sawdust and shavings (i.e., sawmill byproducts) manufacturing",
    "Sawed lumber made in sawmills",
    "Sawmills",
    "Shakes (i.e., hand split shingles) manufacturing",
    "Softwood dimension lumber made from logs or bolts",
    "Stud mills",
    "Timbers made from logs or bolts",
    "Wood beams made from logs or bolts",
    "Wood boards made from logs or bolts",
    "Wood chips made in sawmills",
    "Wood poles made from log or bolts",
    "Wood shingle mills",
    "Wood shingles manufacturing",
    "Wood siding mills",
    "Wood ties made from logs or bolts",
  ],
  "909": [
    "3D printer machinery manufacturing",
    "Additive manufacturing machinery manufacturing",
    "Anodizing equipment manufacturing",
    "Beaming machinery for yarn manufacturing",
    "Bindery machinery manufacturing",
    "Blank printing plates manufacturing",
    "Bleaching machinery for textiles manufacturing",
    "Blow molding machinery for plastics manufacturing",
    "Bookbinding machines manufacturing",
    "Boot making and repairing machinery manufacturing",
    "Braiding machinery for textiles manufacturing",
    "Buttonhole and eyelet machinery manufacturing",
    "Calendering machinery for plastics manufacturing",
    "Calendering machinery for textiles manufacturing",
    "Camelback machinery manufacturing",
    "Carbonizing equipment for processing wool manufacturing",
    "Carding machinery for textiles manufacturing",
    "Cement kilns manufacturing",
    "Chemical fractionating equipment manufacturing",
    "Chemical kilns manufacturing",
    "Chemical preparation sieves and screening equipment manufacturing",
    "Chemical processing machinery and equipment manufacturing",
    "Chemical rectifying equipment manufacturing",
    "Chip placement machinery manufacturing",
    "Cigarette making machinery manufacturing",
    "Circuit board making machinery manufacturing",
    "Circular knitting machinery manufacturing",
    "Clay working and tempering machinery manufacturing",
    "Cloth spreading machinery manufacturing",
    "Collating machinery for printing and bookbinding manufacturing",
    "Combing machinery for textiles manufacturing",
    "Compression molding machinery for plastics manufacturing",
    "Concrete products forming machinery manufacturing",
    "Cordage and rope making machines manufacturing",
    "Digital printing presses manufacturing",
    "Distilling including laboratory equipment manufacturing",
    "Drawing machinery for textiles manufacturing",
    "Drying machinery for textiles manufacturing",
    "Dyeing machinery for textiles manufacturing",
    "Electron tube machinery manufacturing",
    "Electroplating machinery and equipment manufacturing",
    "Electrotyping machinery manufacturing",
    "Embroidery machinery manufacturing",
    "Extruding machinery for plastics and rubber manufacturing",
    "Extruding machinery for yarn manufacturing",
    "Fermentation equipment, chemical, manufacturing",
    "Finishing machinery for textile manufacturing",
    "Flexographic printing presses manufacturing",
    "Footwear making or repairing machinery manufacturing",
    "Frames for textile making machinery manufacturing",
    "Garnetting machinery for textiles manufacturing",
    "Gas liquefying machinery manufacturing",
    "Gem stone processing machinery manufacturing",
    "Glass making machinery manufacturing",
    "Granulator and pelletizer machinery for plastics manufacturing",
    "Gravure printing presses manufacturing",
    "Hosiery machines manufacturing",
    "Injection molding machinery for plastics manufacturing",
    "Jacquard card cutting machinery manufacturing",
    "Kilns manufacturing",
    "Knitting machinery manufacturing",
    "Knot tying machinery for textiles manufacturing",
    "Lace and net making machinery manufacturing",
    "Leather working machinery manufacturing",
    "Letterpress printing presses manufacturing",
    "Light bulb and tube machinery manufacturing",
    "Linotype machines manufacturing",
    "Lithographic printing presses manufacturing",
    "Loom bobbins manufacturing",
    "Loom reeds manufacturing",
    "Looms for textiles manufacturing",
    "Loopers for textiles manufacturing",
    "Lumber drying kilns manufacturing",
    "Mercerizing machinery manufacturing",
    "Metal casting machinery and equipment manufacturing",
    "Napping machinery for textiles manufacturing",
    "Needles for knitting machinery manufacturing",
    "Net and lace making machinery manufacturing",
    "Newspaper inserting equipment manufacturing",
    "Offset printing presses manufacturing",
    "Optical lens making and grinding machinery manufacturing",
    "Petroleum refining machinery manufacturing",
    "Photoengraving machinery manufacturing",
    "Picker machinery for textiles manufacturing",
    "Picker sticks for looms manufacturing",
    "Plastics working machinery manufacturing",
    "Printed circuit board loading through-hole machinery manufacturing",
    "Printing machinery for textiles manufacturing",
    "Printing plate engraving machinery manufacturing",
    "Printing press rollers manufacturing",
    "Printing presses manufacturing",
    "Recycling sorting machinery manufacturing",
    "Roving machinery for textiles manufacturing",
    "Rubber working machinery manufacturing",
    "Schiffli machinery manufacturing",
    "Sewing machines manufacturing",
    "Shoe making and repairing machinery manufacturing",
    "Shuttles for textile weaving machinery manufacturing",
    "Silk screens for textile fabrics manufacturing",
    "Spindles for textile machinery manufacturing",
    "Spinning machinery for textiles manufacturing",
    "Spools for textile machinery manufacturing",
    "Stitchers and trimmers bookbinding equipment manufacturing",
    "Stone working machinery manufacturing",
    "Tannery machinery manufacturing",
    "Textile finishing machinery manufacturing",
    "Textile machinery bobbins manufacturing",
    "Textile making machinery manufacturing",
    "Textile printing machinery manufacturing",
    "Texturizing machinery for textiles manufacturing",
    "Thermoforming machinery for plastics manufacturing",
    "Thread making machinery manufacturing",
    "Tile making machinery manufacturing",
    "Tire making machinery manufacturing",
    "Tire recapping machinery manufacturing",
    "Tire shredding machinery manufacturing",
    "Tobacco processing machinery manufacturing",
    "Tufting machinery for textiles manufacturing",
    "Typesetting machinery manufacturing",
    "Vulcanizing machinery manufacturing",
    "Warping machinery manufacturing",
    "Weaving machinery manufacturing",
    "Winding machinery for textiles manufacturing",
    "Wire and cable insulating machinery manufacturing",
    "Wood drying kilns manufacturing",
    "Wool and worsted finishing machinery manufacturing",
    "Yarn texturizing machines manufacturing",
    "Zipper making machinery manufacturing",
  ],
  "911": ["Sheet metal roofing installation"],
  "912": [
    "Sheet metal air cowls manufacturing",
    "Sheet metal awnings manufacturing",
    "Sheet metal canopies manufacturing",
    "Sheet metal casings manufacturing",
    "Sheet metal coal chutes manufacturing",
    "Sheet metal concrete forms manufacturing",
    "Sheet metal cornices manufacturing",
    "Sheet metal cowls manufacturing",
    "Sheet metal culverts manufacturing",
    "Sheet metal dampers manufacturing",
    "Sheet metal door hoods manufacturing",
    "Sheet metal downspouts manufacturing",
    "Sheet metal ducts manufacturing",
    "Sheet metal eaves manufacturing",
    "Sheet metal elbows for conductor pipe, hot air ducts, and stovepipe manufacturing",
    "Sheet metal elevator troughs manufacturing",
    "Sheet metal flooring manufacturing",
    "Sheet metal flumes manufacturing",
    "Sheet metal furnace casings manufacturing",
    "Sheet metal furnace flues manufacturing",
    "Sheet metal gutters manufacturing",
    "Sheet metal highway guardrails manufacturing",
    "Sheet metal irrigation pipe manufacturing",
    "Sheet metal joists manufacturing",
    "Sheet metal laundry hampers manufacturing",
    "Sheet metal light wells manufacturing",
    "Sheet metal louvers manufacturing",
    "Sheet metal machine guards manufacturing",
    "Sheet metal mail chutes manufacturing",
    "Sheet metal pile shells manufacturing",
    "Sheet metal pipe manufacturing",
    "Sheet metal radiator shields and enclosures manufacturing",
    "Sheet metal range hoods manufacturing",
    "Sheet metal roof deck manufacturing",
    "Sheet metal roof ventilators manufacturing",
    "Sheet metal roofing manufacturing",
    "Sheet metal siding manufacturing",
    "Sheet metal spouts manufacturing",
    "Sheet metal stove and furnace flues manufacturing",
    "Sheet metal stove boards manufacturing",
    "Sheet metal stove pipes and flues manufacturing",
    "Sheet metal studs manufacturing",
    "Sheet metal work manufacturing",
  ],
  "915": [
    "Arctics manufacturing",
    "Athletic footwear manufacturing",
    "Ballet slippers manufacturing",
    "Boots manufacturing",
    "Canvas shoes manufacturing",
    "Casual shoes manufacturing",
    "Children's shoes manufacturing",
    "Climbing shoes manufacturing",
    "Dress shoes manufacturing",
    "Footholds manufacturing",
    "Footwear manufacturing ",
    "Gaiters manufacturing",
    "Galoshes manufacturing",
    "Golf shoes manufacturing",
    "Hiking boots manufacturing",
    "Infant's shoes manufacturing",
    "Leather footwear manufacturing",
    "Men's shoes manufacturing",
    "Moccasins manufacturing",
    "Orthopedic shoes manufacturing",
    "Overshoes manufacturing",
    "Pacs manufacturing",
    "Protective footwear manufacturing",
    "Pumps (i.e., dress shoes) manufacturing",
    "Rubber shoes manufacturing",
    "Sandals manufacturing",
    "Shoes manufacturing",
    "Slippers manufacturing",
    "Water shoes manufacturing",
    "Women's shoes manufacturing",
    "Work shoes manufacturing",
  ],
  "918": ["Electrical signs manufacturing", "Neon signs manufacturing"],
  "919": [
    "Billboards manufacturing",
    "Displays manufacturing",
    "Letters for signs manufacturing",
    "Scoreboards manufacturing",
    "Signs and signboards manufacturing",
    "Traffic signs manufacturing",
  ],
  "920": [
    "Advertising specialty distribution services",
    "Display lettering services",
    "Mannequin decorating services",
    "Merchandise demonstration services ",
    "Sign lettering and painting services",
    "Store window dressing or trimming services",
    "Welcoming services",
  ],
  "922": ["Slate products manufacturing", "Slate unframed blackboards manufacturing"],
  "923": [
    "Bar soaps manufacturing",
    "Hand soaps manufacturing",
    "Laundry soap, chips and powder manufacturing",
    "Mechanic's hand soaps and pastes manufacturing",
    "Natural glycerin manufacturing",
    "Soaps manufacturing",
    "Toilet soaps manufacturing",
    "Waterless hand soaps manufacturing",
  ],
  "935": [
    "above ground swimming pools manufacturing",
    "Archery bows manufacturing",
    "Archery equipment manufacturing",
    "Artificial fishing bait manufacturing",
    "Artificial fishing files manufacturing",
    "Athletic goods manufacturing",
    "Athletic helmets manufacturing",
    "Athletic leather gloves manufacturing",
    "Automatic bowling pin machines manufacturing",
    "Badminton equipment manufacturing",
    "Baseball equipment and supplies manufacturing",
    "Baseball, basketball, football, golf, tennis, pool, and bowling balls manufacturing",
    "Basketball equipment and supplies manufacturing",
    "Bobsleds manufacturing",
    "Boomerangs manufacturing",
    "Boxing equipment manufacturing",
    "Caddy carts manufacturing",
    "Croquet sets manufacturing",
    "Exercise machines manufacturing",
    "Fencing equipment manufacturing",
    "Fishing hooks manufacturing",
    "Fishing reels manufacturing",
    "Fishing rods and rod parts manufacturing",
    "Fishing tackle and equipment manufacturing",
    "Fishing tackle manufacturing",
    "Football equipment and supplies manufacturing",
    "Golf bags manufacturing",
    "Golfing equipment manufacturing",
    "Hockey equipment manufacturing",
    "Hockey skates manufacturing",
    "Ice and roller skates and parts manufacturing",
    "Ice skates manufacturing",
    "Jogging machines manufacturing",
    "Punching bugs manufacturing",
    "Roller skates manufacturing",
    "Sailboards manufacturing",
    "Scuba diving equipment manufacturing",
    "Skateboards manufacturing",
    "Skis and skiing equipment manufacturing",
    "Snowshoes manufacturing",
    "Sport and athletic gloves manufacturing",
    "Sporting goods clubs manufacturing",
    "Sporting goods manufacturing",
    "Sports protectors manufacturing",
    "Sports sticks manufacturing",
    "Squash equipment manufacturing",
    "Surfboards manufacturing",
    "Tennis goods manufacturing",
    "Toboggans manufacturing",
    "Track and field athletic equipment manufacturing",
    "Wet suits manufacturing",
  ],
  "936": ["Riding stables"],
  "938": [
    "Activated clay desiccants manufacturing",
    "Barite processing beyond beneficiation",
    "Barium processing beyond beneficiation",
    "Blast furnace slag processing",
    "Crude magnesite manufacturing",
    "Diatomaceous earth processing beyond beneficiation",
    "Exfoliated vermiculite manufacturing",
    "Expanded perlite manufacturing",
    "Expanded shale manufacturing",
    "Feldspar processing beyond beneficiation",
    "Flint processing beyond beneficiation",
    "Fuller's earth processing beyond beneficiating",
    "Ground, refined or blended black lead manufacturing",
    "Natural graphite manufacturing",
    "Perlite aggregates manufacturing",
    "Processing kaolin beyond beneficiation",
    "Pumice processing beyond beneficiation",
    "Pyrophyllite processing beyond beneficiation",
    "Soapstone processing beyond beneficiation",
    "Talc processing beyond beneficiation",
    "Ultra high purity silicon manufacturing",
  ],
  "939": [
    "Cut stone baptismal fonts manufacturing",
    "Cut stone bases manufacturing",
    "Cut stone furniture  manufacturing",
    "Cut stone monuments and tombstones manufacturing",
    "Cut stone products manufacturing",
    "Dimension stone dressing and manufacturing",
    "Dimension stone for buildings manufacturing",
    "Flagstones cutting",
    "Granite and stone curbing manufacturing",
    "Marble statuary manufacturing",
    "Marble table tops manufacturing",
    "Stone architectural sculptures manufacturing",
    "Stone burial vaults manufacturing",
    "Stone countertops manufacturing",
    "Stone ecclesiastical statuary manufacturing",
    "Stone garden furniture manufacturing",
  ],
  "941": [
    "Asphalt paving",
    "Concrete paving",
    "Curbs and street gutters construction",
    "Airport runway repair",
    "Airport runway resurfacing",
    "Highway, road, street, bridge or airport runway pavement construction",
  ],
  "943": ["Cleaning swimming pools", "Swimming pool cleaning and maintenance services"],
  "946": ["Covers (e.g., boat, swimming pool, truck) made from purchased fabrics"],
  "948": [
    "Fabricated metal boiler plate smokestacks manufacturing",
    "Fabricated metal plate airlocks manufacturing",
    "Fabricated metal plate annealing covers manufacturing",
    "Fabricated metal plate baffles manufacturing",
    "Fabricated metal plate bins manufacturing",
    "Fabricated metal plate breechings manufacturing",
    "Fabricated metal plate buoys manufacturing",
    "Fabricated metal plate chutes manufacturing",
    "Fabricated metal plate culverts manufacturing",
    "Fabricated metal plate cupolas manufacturing",
    "Fabricated metal plate ducting manufacturing",
    "Fabricated metal plate floating covers manufacturing",
    "Fabricated metal plate flumes manufacturing",
    "Fabricated metal plate fumigating chambers manufacturing",
    "Fabricated metal plate hoppers manufacturing",
    "Fabricated metal plate industrial cyclones manufacturing",
    "Fabricated metal plate industrial hoods manufacturing",
    "Fabricated metal plate industrial jackets manufacturing",
    "Fabricated metal plate industrial troughs manufacturing",
    "Fabricated metal plate ladles manufacturing",
    "Fabricated metal plate nuclear shielding manufacturing",
    "Fabricated metal plate penstocks manufacturing",
    "Fabricated metal plate pile shells manufacturing",
    "Fabricated metal plate pipe manufacturing",
    "Fabricated metal plate work manufacturing",
    "Fabricated metal plate racks manufacturing",
    "Fabricated metal plate reactor containment vessels manufacturing",
    "Fabricated metal plate rocket casings manufacturing",
    "Fabricated metal plate scroll casings manufacturing",
    "Fabricated metal plate space simulation chambers manufacturing",
    "Fabricated metal plate sterilizing chambers manufacturing",
    "Fabricated metal plate trash racks manufacturing",
    "Fabricated metal plate tunnel lining manufacturing",
    "Fabricated metal plate work industrial liners manufacturing",
    "Fabricated metal plate work ladle bails manufacturing",
    "Fabricated metal plate work wind tunnels manufacturing",
    "Fabricated plate work manufacturing",
    "Metal truss plates manufacturing",
    "Weldments manufacturing",
  ],
  "953": [
    "Bag leather manufacturing",
    "Belting butts manufacturing",
    "Bookbinder's leather manufacturing",
    "Bridle leather manufacturing",
    "Case leather manufacturing",
    "Chamois leather manufacturing",
    "Coloring leather",
    "Currying furs",
    "Currying leather",
    "Cutting of leather",
    "Dressed furs (e.g., bleached, curried, dyed, scraped, tanned) manufacturing",
    "Dressing hides",
    "Dyeing furs",
    "Dyeing leather",
    "Embossing leather",
    "Exotic leathers manufacturing",
    "Fancy leathers manufacturing",
    "Finishing hides and skins on a contract basis",
    "Finishing leather",
    "Fur stripping",
    "Garment leather manufacturing",
    "Glove leather manufacturing",
    "Handbag leather manufacturing",
    "Harness leather manufacturing",
    "Hides, tanning, currying, dressing and finishing",
    "Japanning of leather",
    "Lace leather manufacturing",
    "Latigo leather manufacturing",
    "Leather belts manufacturing",
    "Leather collar manufacturing",
    "Leather coloring, cutting, embossing and japanning",
    "Leather converters",
    "Leather leshers manufacturing",
    "Leather splits manufacturing",
    "Leather tanning, currying and finishing",
    "Lether skivers manufacturing",
    "Lining leather manufacturing",
    "Mechanical leather manufacturing",
    "Parchment leather manufacturing",
    "Patent leather manufacturing",
    "Pelts bleaching, currying, dyeing, scraping and tanning",
    "Rawhide manufacturing",
    "Roller leather manufacturing",
    "Russet buffings manufacturing",
    "Saddlery leather manufacturing",
    "Shearling (i.e., prepared sheepskin) manufacturing",
    "Skirting leather manufacturing",
    "Slitting leather for the trade",
    "Sole leather manufacturing",
    "Specialty leathers manufacturing",
    "Strap leather manufacturing",
    "Sweatband leather manufacturing",
    "Tannery leather manufacturing",
    "Tanning and currying furs",
    "Tanning skins",
    "Upholstery leather manufacturing",
    "Upper leather manufacturing",
    "Vellum leather manufacturing",
    "Welting leather manufacturing",
    "Wet skys manufacturing",
  ],
  "954": [
    "Audio broadcasting exclusively on Internet",
    "Cable broadcasting networks",
    "Cable television networks",
    "Feature syndicates",
    "Internet blog sites,",
    "Internet broadcasting",
    "Internet entertainment broadcasting sites",
    "Internet gaming sites",
    "Internet radio stations",
    "Internet social network sites",
    "Internet sports broadcasting sites",
    "Internet video broadcast sites",
    "Internet wiki sites",
    "Media streaming distribution services",
    "Network radio broadcasting",
    "Network television broadcasting",
    "News picture gathering and distributing services",
    "News reporting services",
    "News service syndicates",
    "News syndicates",
    "News ticker services",
    "News wire services",
    "Newspaper feature syndicates",
    "Pay television networks",
    "Pay-per-view cable programming",
    "Radio broadcasting network services",
    "Radio broadcasting networks",
    "Radio broadcasting syndicates",
    "Radio network broadcasting service",
    "Road traffic reporting services",
    "Satellite radio networks",
    "Satellite television networks",
    "Specialty television cable networks",
    "Stand-alone streaming services",
    "Subscription television networks",
    "Subscription Video on Demand (SVOD) services",
    "Television broadcasting networks",
    "Television subscription services",
    "Video broadcasting exclusively on Internet",
    "Video on Demand (VOD) programming",
    "Video on Demand (VOD) streaming distribution services",
    "Virtual Multichannel Video Programming Distributors (vMVPDs)",
    "Web broadcasting",
    "Wire photo services",
  ],
  "956": [
    "Airborne radio communications equipment manufacturing",
    "Amplifiers, broadcast and studio equipment manufacturing",
    "Automobile antennas manufacturing",
    "Broadcast and studio equipment microphones manufacturing",
    "Broadcast equipment for radio and television manufacturing",
    "Cable decoders manufacturing",
    "Cable television transmission and receiving equipment manufacturing",
    "CB radios manufacturing",
    "Cellular telephones manufacturing",
    "Closed-circuit equipment television manufacturing",
    "Closed-circuit television equipment manufacturing",
    "Communications space satellites manufacturing",
    "Earth station communications equipment manufacturing",
    "Global positioning system (GPS) equipment manufacturing",
    "Marine radio communications equipment manufacturing",
    "Microwave communications equipment manufacturing",
    "Mobile and microwave communications equipment manufacturing",
    "Mobile communications equipment manufacturing",
    "Pagers manufacturing",
    "Radio and television broadcasting studio equipment manufacturing",
    "Radio transmitting antennas and ground equipment manufacturing",
    "Receiver-transmitter units manufacturing",
    "Satellite antennas manufacturing",
    "Satellite communications equipment manufacturing",
    "Smartphones manufacturing",
    "Smartwatches manufacturing",
    "Television cables manufacturing",
    "Television transmitting antennas and ground equipment manufacturing",
    "Transceivers manufacturing",
    "Transmitting and receiving antennas manufacturing",
    "Video cameras manufacturing",
    "Video projectors manufacturing",
  ],
  "957": [
    "Blanket bags manufacturing",
    "Canvas bags manufacturing",
    "Canvas products made from purchased canvas or canvas substitutes",
    "Feed bags made from purchased woven or knitted materials",
    "Flour bags made from purchased woven or knitted materials",
    "Garment storage bags manufacturing",
    "Knapsacks (e.g., backpacks, book bags) manufacturing",
    "Knapsacks made from purchased woven or knitted materials",
    "Laundry bags made from purchased woven or knitted materials",
    "Outdoor awnings and canopies made from purchased fabrics",
    "Plastic bags made from purchased woven plastics",
    "Rubberized fabric bags manufacturing",
    "Seed bags made from purchased woven or knitted materials",
    "Shipping bags made from purchased woven or knitted materials",
    "Tarpaulins made from purchased fabrics",
    "Tents made from purchased fabrics",
    "Textile bags made from purchased woven or knitted materials",
  ],
  "959": [
    "Artificial leather made from purchased fabric",
    "Laminating purchased textiles",
    "Metallizing purchased textiles",
    "Oilcloth manufacturing",
    "Oiling of purchased textiles and apparel",
    "Plastics coating of textiles and apparel",
    "Raincoats waterproofing",
    "Rubberizing purchased capes",
    "Rubberizing purchased cloaks",
    "Rubberizing purchased clothing",
    "Rubberizing purchased coats",
    "Rubberizing purchased textiles and apparel",
    "Textile waterproofing",
    "Varnished and coated tapes made from purchased fabric",
    "Varnishing purchased textiles and apparel",
    "Vinyl coated fabrics manufacturing",
    "Waterproofing apparel, fabrics and textile products",
    "Waxing purchased textiles and apparel",
  ],
  "961": [
    "Aircraft tie-down strap assemblies manufacturing",
    "Apparel fillings (e.g., cotton mill waste, kapok) manufacturing",
    "Appliqueing on textile products",
    "Automotive pad and upholstery filling manufacturing",
    "Banners made from purchased fabrics",
    "Batts and batting manufacturing",
    "Beading on textile products for the trade",
    "Belting made from purchased fabrics",
    "Bias bindings made from purchased fabrics",
    "Binding carpets and rugs for the trade",
    "Bindings made from purchased fabrics",
    "Bows made from purchased fabrics",
    "Burnt-out lace manufacturing",
    "Burnt-out laces manufacturing",
    "Carpet cutting and binding",
    "Casket linings manufacturing",
    "Cotton battings manufacturing",
    "Crochet ware made from purchased materials",
    "Decorative stitching contractors on apparel",
    "Decorative stitching on textile articles and apparel",
    "Diapers made from purchased fabrics",
    "Dust cloths made from purchased fabrics",
    "Fabric badges manufacturing",
    "Fabric hammocks manufacturing",
    "Fire hose made from purchased materials",
    "Fishing nets made from purchased materials",
    "Furniture trimmings made from purchased fabrics",
    "Garnetting of textile waste and rags",
    "Laundry nets made from purchased materials",
    "Luggage linings manufacturing",
    "Military insignia manufacturing",
    "Padding and wadding manufacturing",
    "Parachutes manufacturing",
    "Powder puffs and mitts manufacturing",
    "Processing of textile mill waste and recovering fibers",
    "Quilting of textiles",
    "Reclaimed wool processing",
    "Recovered fibers processing",
    "Ribbons made from purchased fabrics",
    "Sleeping bags manufacturing",
    "Tassels manufacturing",
    "Textile fire hose made from purchased material",
    "Textile flags manufacturing",
    "Textile recovery fiber from textile mill waste and rags",
    "Upholstering filling manufacturing",
    "Upholstery filling manufacturing",
    "Weatherstripping made from purchased textiles",
    "Wool waste processing",
  ],
  "964": [
    "Aircraft tire manufacturing",
    "Camelback manufacturing",
    "Motor vehicle tires manufacturing",
    "Tire repair materials manufacturing",
    "Tire retreading materials manufacturing",
    "Tires manufacturing",
    "Tread rubber manufacturing",
  ],
  "965": [
    "Angle rings manufacturing",
    "Arbors manufacturing",
    "Bits and knives for metalworking lathes, planers, and shapers manufacturing",
    "Blanks, cutting tool, manufacturing",
    "Broaches manufacturing",
    "Cams manufacturing",
    "Chasers manufacturing",
    "Chucks manufacturing",
    "Clamps manufacturing",
    "Collars manufacturing",
    "Collets manufacturing",
    "Countersinks manufacturing",
    "Cutting tool inserts manufacturing",
    "Dies and taps manufacturing",
    "Files manufacturing",
    "Hobs manufacturing",
    "Honing heads manufacturing",
    "Hopper feed devices manufacturing",
    "Knives and bits for metalworking lathes, planers, and shapers manufacturing",
    "Letter pins manufacturing",
    "Machine tool attachments and accessories manufacturing",
    "Mandrels manufacturing",
    "Measuring attachments for machine tool manufacturing",
    "Metal cutting machine knives manufacturing",
    "Metal milling cutters manufacturing",
    "Metalworking counterbores manufacturing",
    "Metalworking drill bits manufacturing",
    "Pushers manufacturing",
    "Reamers manufacturing",
    "Rotary tables indexing manufacturing",
    "Sine bars manufacturing",
    "Taps and dies manufacturing",
    "Thread cutting dies manufacturing",
    "Toolholders manufacturing",
    "Tools and accessories for machine tools manufacturing",
    "Tracer and tapering machine tool attachments manufacturing",
  ],
  "966": [
    "All types saw blades manufacturing",
    "Awls manufacturing",
    "Axes manufacturing",
    "Bearing pullers, handtools, manufacturing",
    "Blow torches manufacturing",
    "Can openers manufacturing",
    "Carpenter's levels manufacturing",
    "C-clamps manufacturing",
    "Chain saw blades manufacturing",
    "Chisels manufacturing",
    "Coordinate and contour measuring machines, machinists' precision tools manufacturing",
    "Cutting dies manufacturing",
    "Drawknives manufacturing",
    "Fish wire manufacturing",
    "Garden and mason's hoes handtools manufacturing",
    "Glass cutters manufacturing",
    "Hammers handtools manufacturing",
    "Handheld files manufacturing",
    "Handheld rasps manufacturing",
    "Handheld shovels manufacturing",
    "Handheld spades and shovels manufacturing",
    "Handtool metal blades manufacturing",
    "Handtools forks manufacturing",
    "Handtools gear pullers manufacturing",
    "Handtools pliers manufacturing",
    "Handtools wheel pullers manufacturing",
    "Hatchets manufacturing",
    "Hooks, handtools manufacturing",
    "Jacks manufacturing",
    "Knives and bits for woodworking lathes, planers and shapers manufacturing",
    "Machetes manufacturing",
    "Machine knives manufacturing",
    "Machinist's measuring tools manufacturing",
    "Machinists' precision dial indicators tools manufacturing",
    "Machinists' precision handtools manufacturing",
    "Machinists' precision measuring tools manufacturing",
    "Machinists' precision tools calipers and dividers manufacturing",
    "Machinists' precision tools dividers manufacturing",
    "Machinists' precision tools gauge blocks manufacturing",
    "Machinists' precision tools gauges manufacturing",
    "Machinist's precision tools manufacturing",
    "Machinist's precision tools micrometers manufacturing",
    "Mallets manufacturing",
    "Mason's handtools manufacturing",
    "Mattocks manufacturing",
    "Metal blade handtools manufacturing",
    "Metal carpenters' squares manufacturing",
    "Metal cutting saw blades manufacturing",
    "Metal mauls manufacturing",
    "Metal rulers manufacturing",
    "Metal scoops manufacturing",
    "Metal tape measures manufacturing",
    "Metal yardsticks manufacturing",
    "Miter boxes manufacturing",
    "Motor vehicle mechanics' handtools manufacturing",
    "Nonelectric carpenter's handtools manufacturing",
    "Nonelectric clippers for animal use manufacturing",
    "Nonelectric hair clippers for animal use manufacturing",
    "Nonelectric handheld drills manufacturing",
    "Nonelectric handheld edge tools manufacturing",
    "Nonelectric hedge shears and trimmers manufacturing",
    "Nonelectric hedge trimmers manufacturing",
    "Nonelectric jeweler's handtools manufacturing",
    "Nonelectric screwdrivers manufacturing",
    "Nonelectric tool shears manufacturing",
    "Nonpowered agricultural handtools manufacturing",
    "Nonpowered augers manufacturing",
    "Nonpowered caulking guns manufacturing",
    "Nonpowered engraver's handtools manufacturing",
    "Nonpowered handheld planes manufacturing",
    "Nonpowered handheld tools manufacturing",
    "Nonpowered handtool punches manufacturing",
    "Nonpowered handtool rakes manufacturing",
    "Nonpowered handtools wrenches manufacturing",
    "Nonpowered lawn and garden grass mowing equipment manufacturing",
    "Nonpowered lawn edgers manufacturing",
    "Nonpowered lawnmowers manufacturing",
    "Nonpowered mechanic's handtools manufacturing",
    "Nonpowered plumbers' handtools manufacturing",
    "Nonpowered post hole diggers manufacturing",
    "Nonpowered ratchets manufacturing",
    "Nonpowered stonecutters' handtools manufacturing",
    "Nonpowered swimming pool leaf skimmers and rakes manufacturing",
    "Nonpowered, hand saws manufacturing",
    "Picks manufacturing",
    "Pruners manufacturing",
    "Pry bars manufacturing",
    "Putty knives manufacturing",
    "Saw blades manufacturing",
    "Screwjacks manufacturing",
    "Scythes manufacturing",
    "Sickles manufacturing",
    "Sledgehammers manufacturing",
    "Sockets and socket sets manufacturing",
    "Soldering guns and irons manufacturing",
    "Soldering iron tips and tiplets manufacturing",
    "Steel rule dies manufacturing",
    "Stone cutting saw blades manufacturing",
    "Tinners' snips manufacturing",
    "Trowels manufacturing",
    "Vises manufacturing",
    "Wood cutting saw blades manufacturing",
    "Woodworking counterbores and countersinking bits manufacturing",
    "Woodworking drill bits manufacturing",
    "Woodworking edge tool bits manufacturing",
    "Woodworking edge tools manufacturing",
    "Woodworking gouges manufacturing",
  ],
  "967": [
    "Cartridge handheld power-driven tools manufacturing",
    "Handheld pneumatic paint spray guns manufacturing",
    "Handheld power-driven buffing machines manufacturing",
    "Handheld power-driven chain saws manufacturing",
    "Handheld power-driven chisels manufacturing",
    "Handheld power-driven circular saws manufacturing",
    "Handheld power-driven drills manufacturing",
    "Handheld power-driven grinders manufacturing",
    "Handheld power-driven handtools manufacturing",
    "Handheld power-driven impact wrenches manufacturing",
    "Handheld power-driven jigsaws manufacturing",
    "Handheld power-driven nail guns manufacturing",
    "Handheld power-driven nailers and staplers manufacturing",
    "Handheld power-driven planers manufacturing",
    "Handheld power-driven polishers manufacturing",
    "Handheld power-driven riveting guns manufacturing",
    "Handheld power-driven routers manufacturing",
    "Handheld power-driven sanders manufacturing",
    "Handheld power-driven saws manufacturing",
    "Handheld power-driven screwdrivers and nut drivers manufacturing",
    "Handheld power-driven shears and nibblers manufacturing",
    "Handheld power-driven staplers and nailers manufacturing",
    "Handheld power-driven tools manufacturing",
    "Powder actuated handheld power tools manufacturing",
    "Power-driven handtools manufacturing",
  ],
  "968": [
    "Crankshaft grinding machines metal cutting type manufacturing",
    "Cylinder boring machines metal cutting type manufacturing",
    "Electrode discharge metal cutting machines manufacturing",
    "Electrolytic metal cutting machines manufacturing",
    "Electron-discharge metal cutting machines manufacturing",
    "Forging machinery and hammers manufacturing",
    "Home workshop metal cutting machine tools manufacturing",
    "Metal cutting machine tools manufacturing",
    "Metal cutting type automatic screw machines manufacturing",
    "Metal cutting type honing and lapping machines manufacturing",
    "Metal cutting type key cutting machines manufacturing",
    "Metal deposit forming machines manufacturing",
    "Metal forging and shaping drop hammers manufacturing",
    "Metal forming machine tools manufacturing",
    "Metal forming presses manufacturing",
    "Metal forming shearing machines manufacturing",
    "Metalworking  rifling machines manufacturing",
    "Metalworking arbor presses manufacturing",
    "Metalworking automatic chucking machines manufacturing",
    "Metalworking beader machines manufacturing",
    "Metalworking bending and forming machines manufacturing",
    "Metalworking boring machines manufacturing",
    "Metalworking boring, drilling, and milling machine combinations manufacturing",
    "Metalworking broaching machines manufacturing",
    "Metalworking brushing machines manufacturing",
    "Metalworking buffing and polishing machines manufacturing",
    "Metalworking burnishing machines manufacturing",
    "Metalworking can forming machines manufacturing",
    "Metalworking centering machines manufacturing",
    "Metalworking chamfering machines manufacturing",
    "Metalworking chemical milling machines manufacturing",
    "Metalworking custom roll forming machines manufacturing",
    "Metalworking cut-off machines manufacturing",
    "Metalworking cutting machines manufacturing",
    "Metalworking deburring machines manufacturing",
    "Metalworking die sinking machines manufacturing",
    "Metalworking die-casting machines manufacturing",
    "Metalworking drill presses manufacturing",
    "Metalworking drilling machines manufacturing",
    "Metalworking duplicating machines manufacturing",
    "Metalworking electrochemical milling machines manufacturing",
    "Metalworking extruding machines manufacturing",
    "Metalworking facing machines manufacturing",
    "Metalworking filing machines manufacturing",
    "Metalworking flange facing machines manufacturing",
    "Metalworking forming machines manufacturing",
    "Metalworking gear cutting and finishing machines manufacturing",
    "Metalworking gear rolling machines manufacturing",
    "Metalworking grinding machines manufacturing",
    "Metalworking knurling machines manufacturing",
    "Metalworking laser boring, drilling, and cutting machines manufacturing",
    "Metalworking lathes manufacturing",
    "Metalworking magnetic forming machines manufacturing",
    "Metalworking milling machines manufacturing",
    "Metalworking pipe cutting and threading machines manufacturing",
    "Metalworking planers manufacturing",
    "Metalworking polishing and buffing machines manufacturing",
    "Metalworking press brakes manufacturing",
    "Metalworking punching machines manufacturing",
    "Metalworking reaming machines manufacturing",
    "Metalworking riveting machines manufacturing",
    "Metalworking sawing machines manufacturing",
    "Metalworking screw and nut slotting machines manufacturing",
    "Metalworking shaving machines manufacturing",
    "Metalworking slotting machines manufacturing",
    "Metalworking spinning machines manufacturing",
    "Metalworking spline rolling machines manufacturing",
    "Metalworking spring winding and forming machines manufacturing",
    "Metalworking stamping machines manufacturing",
    "Metalworking stretching machines manufacturing",
    "Metalworking swaging machines manufacturing",
    "Metalworking tapping machines manufacturing",
    "Metalworking thread rolling machines manufacturing",
    "Metalworking threading machines manufacturing",
    "Metalworking turning machines manufacturing",
    "Metalworking turret lathes manufacturing",
    "Metalworking ultrasonic boring, drilling, and cutting machines manufacturing",
    "Metalworking valve grinding machines manufacturing",
    "Nail heading machines manufacturing",
    "Numerically controlled metal cutting machine tools manufacturing",
    "Plasma jet spray metal forming machines manufacturing",
    "Plasma process metal cutting machines manufacturing",
    "Powder metal forming presses manufacturing",
    "Sheet metal forming machines manufacturing",
    "Ultrasonic metal forming machines manufacturing",
    "Upsetters manufacturing",
  ],
  "969": [
    "Action figures manufacturing",
    "Baby carriages manufacturing",
    "Baby strollers manufacturing",
    "Baby walkers manufacturing",
    "Checkers and checkerboards manufacturing",
    "Chessmen and chessboards manufacturing",
    "Children's and adult games manufacturing",
    "Children's automobiles manufacturing",
    "Children's go-carts manufacturing",
    "Children's scooters manufacturing",
    "Children's sleds manufacturing",
    "Children's vehicles manufacturing",
    "Children's wagons manufacturing",
    "Craft and hobby kits and sets manufacturing",
    "Darts and dart games manufacturing",
    "Doll carriages and carts manufacturing",
    "Doll carriages manufacturing",
    "Doll clothing manufacturing",
    "Dolls, doll parts, and doll clothing manufacturing",
    "Electric or mechanical toy trains and equipment manufacturing",
    "Electronic toys and games manufacturing",
    "Embroidery kits manufacturing",
    "Gaming consoles manufacturing",
    "Handheld electronic video games manufacturing",
    "Hobby and toy railroad models manufacturing",
    "Hobbyhorses manufacturing",
    "Kites manufacturing",
    "Marbles manufacturing",
    "Model kits manufacturing",
    "Model railroad manufacturing",
    "Rocking horses manufacturing",
    "Rubber balls manufacturing",
    "Science kits manufacturing",
    "Structural toy sets manufacturing",
    "Stuffed toys manufacturing",
    "Toy and hobby models manufacturing",
    "Toy dishes manufacturing",
    "Toy furniture and household-type equipment manufacturing",
    "Toy musical instruments manufacturing",
    "Toy rifles manufacturing",
    "Toys manufacturing",
    "Tricycles manufacturing",
    "Video game devices manufacturing",
    "Video game machines manufacturing",
  ],
  "970": [
    "Boat transporter trailers, multi-unit, manufacturing",
    "Commercial flatbed trailers manufacturing",
    "Demountable cargo containers manufacturing",
    "Dump trailers manufacturing",
    "Fifth-wheel horse trailers manufacturing",
    "Fifth-wheel trailers for transporting horses manufacturing",
    "Liquid and dry bulk tank trailers manufacturing",
    "Logging trailers manufacturing",
    "Multi-car automobile transporter trailers manufacturing",
    "Semi-trailers manufacturing",
    "Truck trailers manufacturing",
  ],
  "971": [
    "Arborist services",
    "Overhead utility line tree and brush trimming",
    "Pruning services, ornamental tree and shrub",
    "Tree pruning services",
    "Tree removal services",
    "Tree services",
    "Tree surgery services",
    "Tree trimming services",
  ],
  "972": [
    "Cord (except wire) manufacturing",
    "Cord for reinforcing rubber tires, industrial belting, and fuel cells manufacturing",
    "Cordage (except wire) manufacturing",
    "Fabrics for reinforcing rubber tires, industrial belting and fuel cells manufacturing",
    "Industrial belting reinforcement, cord and fabric, manufacturing",
    "Strings manufacturing",
    "Tire cord and fabric manufacturing",
    "Twines manufacturing",
  ],
  "973": [
    "Beach umbrellas manufacturing",
    "Canes manufacturing",
    "Garden umbrellas manufacturing",
    "Umbrellas manufacturing",
  ],
  "975": [
    "Complete fire hydrants manufacturing",
    "Compressed gas cylinder valves manufacturing",
    "Fire hydrant valves manufacturing",
    "Flushing hydrant manufacturing",
    "Industrial angle valves manufacturing",
    "Industrial automatic valves manufacturing",
    "Industrial ball valves manufacturing",
    "Industrial boiler gauge cocks manufacturing",
    "Industrial butterfly valves manufacturing",
    "Industrial check valves manufacturing",
    "Industrial control valves manufacturing",
    "Industrial cross valves manufacturing",
    "Industrial gas valves manufacturing",
    "Industrial gate valves manufacturing",
    "Industrial globe valves manufacturing",
    "Industrial plug valves manufacturing",
    "Industrial pressure control valves manufacturing",
    "Industrial safety manufacturing",
    "Industrial solenoid valves manufacturing",
    "Industrial steam traps manufacturing",
    "Industrial stop valves manufacturing",
    "Industrial straightway valves manufacturing",
    "Industrial thermostatic traps manufacturing",
    "Industrial valves manufacturing",
    "Nuclear application valves manufacturing",
    "Valves for nuclear applications manufacturing",
    "Valves for water works and municipal water systems manufacturing",
    "Water sampling station manufacturing",
    "Waterworks and municipal water system valves manufacturing",
  ],
  "976": [
    "Corn crude and refined oil made by wet milling corn",
    "Corn oil cake and meal manufacturing",
    "Corn oil mills",
    "Corn table oil made by wet milling",
    "Crude and refined corn oil made by wet milling corn",
    "Crude corn oil manufacturing",
    "Margarine and other corn oils made by wet milling corn",
  ],
  "979": ["Venetian blinds manufacturing"],
  "980": [
    "Cold storage locker services",
    "Cold storage warehousing",
    "Fur storage warehousing for the trade",
    "Refrigerated bonded warehousing",
    "Refrigerated farm product warehousing and storage",
    "Refrigerated private warehousing and storage",
    "Refrigerated public warehousing and storage",
    "Refrigerated warehousing",
  ],
  "981": [
    "Bonded general merchandise warehousing",
    "General merchandise warehousing (including foreign trade zones)",
    "General merchandise warehousing and storage",
    "Private general merchandise warehousing and storage",
    "Public general merchandise warehousing and storage",
  ],
  "987": ["Water softener installation"],
  "988": [
    "Caulking contractors",
    "Coating concrete structures with plastics",
    "Concrete coating, glazing or sealing",
    "Damp proofing contractors",
    "Foundation damp proofing",
    "Lead lining walls for X-ray room contractors",
    "Waterproofing contractors",
    "Weatherproofing concrete",
    "Weatherstripping installation",
  ],
  "992": [
    "Absorbent sweeping compounds manufacturing",
    "Air fresheners manufacturing",
    "Automobile polishes and cleaners manufacturing",
    "Beeswax polishes and waxes manufacturing",
    "Bleaches manufacturing",
    "Brass polishes manufacturing",
    "Buffing compounds manufacturing",
    "Copper cleaners manufacturing",
    "Deodorants manufacturing",
    "Drain pipe cleaners manufacturing",
    "Drycleaning preparations manufacturing",
    "Dusting and polishing chemically treated clothes manufacturing",
    "Fabric softeners manufacturing",
    "Furniture polishes and waxes manufacturing",
    "Glass and tile cleaning preparations manufacturing",
    "Gun cleaning preparations",
    "Household ammonia manufacturing",
    "Household and industrial disinfectants manufacturing",
    "Household Degreasing preparations manufacturing",
    "Household lye manufacturing",
    "Ink eradicators manufacturing",
    "Kitchen degreasing and cleaning preparations manufacturing",
    "Laundry bluing manufacturing",
    "Laundry starches manufacturing",
    "Metal polishes manufacturing",
    "Oven cleaners manufacturing",
    "Polishes manufacturing",
    "Polishing preparations manufacturing",
    "Polishing waxes manufacturing",
    "Recycling drycleaning fluids",
    "Rug cleaning preparations manufacturing",
    "Rust removers manufacturing",
    "Saddle soaps manufacturing",
    "Shoe polishes and cleaners manufacturing",
    "Silver polishes manufacturing",
    "Soot removing chemicals manufacturing",
    "Spot removers manufacturing",
    "Toilet bowl cleaners manufacturing",
    "Tub and tile cleaning preparations manufacturing",
    "Wallpaper cleaners manufacturing",
    "Wax removers manufacturing",
    "Window cleaning preparations manufacturing",
    "Windshield washer fluid manufacturing",
  ],
  "993": ["Floor polishes and waxes manufacturing"],
  "994": [
    "Bridge, tunnel, and highway operations",
    "Highway and bridge snow clearing road transportation",
    "Highway snow removal",
    "highway toll road operations",
    "Independent bus terminal operation",
    "Independent driving services",
    "Independently operated trucking terminals",
    "Loading and unloading at truck terminals",
    "non owner-operator independent truck driver",
    "Pilot car services",
    "Shunting of trailers in truck terminals",
    "Street cleaning service",
    "Truck cargo surveyors",
    "Truck inspection or weighing services",
    "Truck weighing station operation",
  ],
  "996": [
    "Hair nets made from purchased netting",
    "Toupees manufacturing",
    "Wigs, wiglets, toupees, hairpieces manufacturing",
  ],
  "1003": ["Champagne method sparkling wine, manufacturing"],
  "1004": [
    "Applejack distilling",
    "Beverages, wines and brandies manufacturing",
    "Blending brandy",
    "Blending wines",
    "Brandy distilling",
    "Cider, alcoholic, manufacturing",
    "Cooking wines manufacturing",
    "Distilling and blending brandy liquors",
    "Distilling brandy",
    "Fortified wines manufacturing",
    "Grape farming and making wine",
    "Ice wine",
    "Nonalcoholic wines manufacturing",
    "Sake manufacturing",
    "Sparkling wines manufacturing",
    "Vermouth manufacturing",
    "Wine coolers manufacturing",
    "Wineries",
    "Wines manufacturing",
  ],
  "1006": [
    "Drawing iron or steel wire from purchased iron or steel",
    "Wire or cut iron or steel brads made in wire drawing plants",
  ],
  "1007": [
    "Drawing iron or steel wire from purchased wire products",
    "Form ties made in wire drawing plants",
    "Iron or steel baskets made in wire drawing plants",
    "Iron or steel chain link fencing made in wire drawing plants",
    "Iron or steel fence gates, posts, and fittings made in wire drawing plants",
    "Iron or steel paper clips made in wire drawing plants",
    "Iron or steel staples made in wire drawing plants",
    "Iron or steel tacks made in wire drawing plants",
    "Iron or steel wire cages made in wire drawing plants",
    "Iron or steel wire carts made in wire drawing plants",
    "Iron or steel wire cloth made in wire drawing plants",
    "Iron or steel wire garment hangers made in wire drawing plants",
    "Iron or steel wire made in wire drawing plants",
    "Iron or steel wire products made in wire drawing plants",
    "Tie wires made in wire drawing plants",
    "Welded iron or steel wire fabric made in wire drawing plants",
    "Wire mesh made in wire drawing mills",
  ],
  "1008": [
    "Barbed and twisted wire made in wire drawing plants",
    "Iron or steel cable insulated or armored, made in wire drawing plants",
  ],
  "1009": [
    "Creosoting of wood",
    "Creosoting purchased wood products",
    "Cutting and treating round wood pilings",
    "Cutting and treating round wood poles",
    "Cutting and treating round wood posts",
    "Preserving purchased wood and wood products",
    "Pressure treated lumber made from purchased lumber",
    "Treated wood mine ties manufacturing",
    "Treating crossties",
    "Treating foundation and marine construction pilings",
    "Treating millwork",
    "Treating mine props",
    "Treating purchased wood and wood products",
    "Treating railroad ties (i.e., bridge, cross, switch) ",
    "Treating structural timber",
    "Treating structural lumber and timber",
    "Treating wood block flooring",
    "Treating wood bridge and trestle parts",
    "Treating wood fence (i.e., pickets, poling, rails)",
    "Treating wood pilings",
    "Treating wood poles",
    "Treating wood posts",
    "Treating wood products with creosote or other preservatives",
    "Treating wood railroad bridge, cross, and switch ties",
    "Treating wood trestle parts",
  ],
  "1010": [
    "Bentwood (steam bent) products manufacturing",
    "Boot and shoe display forms manufacturing",
    "Boot and shoe lasts manufacturing",
    "Bottle corks manufacturing",
    "Burnt wood articles manufacturing",
    "Cane, reed and rattan webbing manufacturing",
    "Clothes poles, wood, manufacturing",
    "Cork buoys manufacturing",
    "Cork carpets manufacturing",
    "Cork insulating materials manufacturing",
    "Cork products manufacturing",
    "Cork tile manufacturing",
    "Cork stoppers manufacturing",
    "Excelsior (e.g., pads, wrappers) manufacturing",
    "Excelsior wrappers manufacturing",
    "Finished wood boot and shoe heels manufacturing",
    "Firewood and fuel wood containing fuel binder manufacturing",
    "Furniture inlays manufacturing",
    "Household woodenware manufacturing",
    "Inlaid veneer work manufacturing",
    "Kiln drying lumber",
    "Kitchen and household woodenware manufacturing",
    "Last sole patterns manufacturing",
    "Plywood reels  manufacturing",
    "Pressed logs of sawdust and other wood particles manufacturing",
    "Ratan seat covers manufacturing",
    "Rattan ware manufacturing",
    "Rattan, reed, and willow table pads manufacturing",
    "Reed ware manufacturing",
    "Regrinding sawdust",
    "Shoe display forms manufacturing",
    "Shoe stretchers manufacturing",
    "Shoe trees manufacturing",
    "Snow fence manufacturing",
    "Straw baskets manufacturing",
    "Willow ware manufacturing",
    "Willow, rattan and reed bottle covers manufacturing",
    "Willow, rattan, and reed bottle covers manufacturing",
    "Wood and cork bulletin boards manufacturing",
    "Wood applicators manufacturing",
    "Wood audio cabinets manufacturing",
    "Wood boards manufacturing",
    "Wood bowls manufacturing",
    "Wood bungs manufacturing",
    "Wood bushings manufacturing",
    "Wood cabinets manufacturing",
    "Wood clipboards manufacturing",
    "Wood cloth winding manufacturing",
    "Wood clothes dryers manufacturing",
    "Wood clothespins manufacturing",
    "Wood curtain stretchers manufacturing",
    "Wood dishes manufacturing",
    "Wood dowels manufacturing",
    "Wood excelsior pads manufacturing",
    "Wood extension planks manufacturing",
    "Wood fence rails manufacturing",
    "Wood fencing manufacturing",
    "Wood fiber novelties manufacturing",
    "Wood flagpoles manufacturing",
    "Wood flour manufacturing",
    "Wood garment hangers manufacturing",
    "Wood gavels manufacturing",
    "Wood grain measures manufacturing",
    "Wood handles manufacturing",
    "Wood heel blocks manufacturing",
    "Wood heels manufacturing",
    "Wood hubs manufacturing",
    "Wood ironing boards manufacturing",
    "Wood kitchenware manufacturing",
    "Wood knobs manufacturing",
    "Wood mallets manufacturing",
    "Wood marquetry manufacturing",
    "Wood masts manufacturing",
    "Wood mauls manufacturing",
    "Wood meat hammers manufacturing",
    "Wood oars manufacturing",
    "Wood or cork bulletin boards manufacturing",
    "Wood paddles manufacturing",
    "Wood paint sticks manufacturing",
    "Wood pastry boards manufacturing",
    "Wood pellets manufacturing",
    "Wood pencil slats manufacturing",
    "Wood plugs manufacturing",
    "Wood potato mashers manufacturing",
    "Wood pulleys manufacturing",
    "Wood reels manufacturing",
    "Wood rollers manufacturing",
    "Wood rolling pins manufacturing",
    "Wood rulers and rules manufacturing",
    "Wood saddle trees manufacturing",
    "Wood sewing machine cabinets manufacturing",
    "Wood skewers manufacturing",
    "Wood spars manufacturing",
    "Wood speaker cabinets (i.e., housings) manufacturing",
    "Wood spigots manufacturing",
    "Wood spokes manufacturing",
    "Wood spools manufacturing",
    "Wood stereo cabinets manufacturing",
    "Wood surveyor's stakes manufacturing",
    "Wood tackle blocks manufacturing",
    "Wood tailors' pressing blocks manufacturing",
    "Wood tailor's pressing blocks manufacturing",
    "Wood television cabinets manufacturing",
    "Wood tent poles manufacturing",
    "Wood toilet seats manufacturing",
    "Wood tool handles manufacturing",
    "Wood toothpicks manufacturing",
    "Wood trophy bases manufacturing",
    "Wood washboards manufacturing",
    "Wood wool manufacturing",
    "Wood woos scoops manufacturing",
    "Wood yardsticks manufacturing",
    "Wood, wicker and bagasse trays manufacturing",
    "Wooden garment hangers manufacturing",
  ],
  "1012": [
    "Acetate spun yarns made from purchased fiber",
    "Acrylic and modacrylic filament yarn throwing, twisting, texturizing or winding",
    "Acrylic spun yarns made from purchased fiber",
    "Animal fiber yarn twisting or winding of purchased yarn",
    "Beaming yarn",
    "Carded yarn manufacturing",
    "Carpet and rug yarn spinning",
    "Carpet and rug yarn spun from purchased fiber",
    "Cotton cordage spun yarns made from purchased fiber",
    "Cotton spun yarns made from purchased fiber",
    "Cotton thread manufacturing",
    "Crochet spun yarns (e.g., cotton, manmade fiber, silk, wool) made from purchased fiber",
    "Darning thread (e.g., cotton, manmade fibers, silk, wool) manufacturing",
    "Embroidery spun yarns (e.g., cotton, manmade fiber, silk, wool) made from purchased fiber",
    "Embroidery thread (e.g., cotton, manmade fibers, silk, wool) manufacturing",
    "Flax spun yarns made from purchased fiber",
    "Hard fiber spun yarns made from purchased fiber",
    "Hard fiber thread manufacturing",
    "Hemp bags made from purchased fiber",
    "Hemp ropes made from purchased fiber",
    "Hemp spun yarns made from purchased fiber",
    "Knitting and crocheting thread manufacturing",
    "Knitting spun yarns (e.g., cotton, manmade fiber, silk, wool) made from purchased fiber",
    "Manmade fiber thread manufacturing",
    "Manmade staple spun yarns made from purchased fiber",
    "Modacrylic spun yarns made from purchased fiber",
    "Mohair yarn twisting or winding of purchased yarn",
    "Natural fiber (i.e., hemp, linen, ramie) thread manufacturing",
    "Natural fiber spun yarns (i.e., hemp, jute, ramie, flax) made from purchased fiber",
    "Nylon spun yarns made from purchased fiber",
    "Nylon thread manufacturing",
    "Nylon yarn twisting or winding of purchased yarn",
    "Paper yarn manufacturing",
    "Polyester filament yarn throwing, twisting, texturizing or winding of purchased yarn",
    "Polyester spun yarns made from purchased fiber",
    "Polyester thread manufacturing",
    "Polypropylene filament yarn throwing, twisting, texturizing or winding",
    "Polypropylene spun yarns made from purchased fiber",
    "Ramie spun yarns made from purchased fiber",
    "Rayon spun yarns made from purchased fiber",
    "Rayon thread manufacturing",
    "Rayon yarn throwing, twisting, texturizing or winding purchased filament",
    "Sewing threads manufacturing",
    "Silk spun yarns made from purchased fiber",
    "Silk thread manufacturing",
    "Silk throwing, spooling, twisting or winding of purchased yarn",
    "Spinning carpet and rug yarn from purchased fiber",
    "Spinning yarn from purchased fiber",
    "Spooling of yarn",
    "Spooling of yarns for the trade",
    "Texturizing purchased yarn",
    "Thread mills",
    "Thread, all fibers, manufacturing",
    "Throwing purchased yarn",
    "Winding purchased yarn",
    "Winding, spooling, beaming and rewinding of purchased yarn",
    "Wool spun yarn made from purchased fiber",
    "Wool yarn, twisting or winding of purchased yarn",
    "Yarn spinning mills",
    "Yarn spun from purchased fiber",
    "Yarn throwing, twisting, and winding of purchased yarn",
  ],
  "1018": [
    "Anesthesia apparatus manufacturing",
    "Blood pressure apparatus manufacturing",
    "Blood transfusion equipment manufacturing",
    "Bone drills manufacturing",
    "Bone plates and screws manufacturing",
    "Bone rongeurs manufacturing",
    "Bronchoscopes manufacturing",
    "Cannulae manufacturing",
    "Catheters manufacturing",
    "Corneal microscopes manufacturing",
    "Cystoscopes manufacturing",
    "Eye examining instruments and apparatus manufacturing",
    "Gastroscopes manufacturing",
    "Hypodermic and suture needles manufacturing",
    "Hypodermic needles and syringes manufacturing",
    "Hypodermic syringes manufacturing",
    "Inhalation therapy equipment manufacturing",
    "IV apparatus manufacturing",
    "Mechanical microsurgical instruments manufacturing",
    "Medical retractors manufacturing",
    "Medical thermometers manufacturing",
    "Medical tonometers manufacturing",
    "Ophthalmic instruments and apparatus manufacturing",
    "Ophthalmometers and ophthalmoscopes manufacturing",
    "Optometers manufacturing",
    "Oxygen tents manufacturing",
    "Pelvimeters manufacturing",
    "Physiotherapy equipment manufacturing",
    "Retinoscopes manufacturing",
    "Skin grafting equipment manufacturing",
    "Speculums manufacturing",
    "Sphygmomanometers manufacturing",
    "Stethoscopes manufacturing",
    "Suction therapy apparatus manufacturing",
    "Surgical and medical inhalators manufacturing",
    "Surgical clamps manufacturing",
    "Surgical forceps manufacturing",
    "Surgical knife blades and handles manufacturing",
    "Surgical knives manufacturing",
    "Surgical probes manufacturing",
    "Surgical saws manufacturing",
    "Surgical stapling devices manufacturing",
    "Trocars manufacturing",
    "Veterinarians' instruments and apparatus manufacturing",
  ],
  "1019": [
    "Artificial limbs wholesalers",
    "Crutches wholesalers",
    "Dental and medical instruments wholesalers",
    "Dental and medical laboratory equipment wholesalers",
    "Dental chairs wholesalers",
    "Dental equipment and supplies wholesalers",
    "Dental teeth wholesalers",
    "Dentists' professional supplies wholesalers",
    "Electromedical equipment wholesalers",
    "First-aid kits wholesalers",
    "Home health care supplies wholesalers",
    "Hospital beds wholesalers",
    "Hospital equipment and supplies wholesalers",
    "Hospital furniture wholesalers",
    "Hospital gowns wholesalers",
    "Hospital whirlpool baths wholesalers",
    "Medical and dental X-ray machines and parts wholesalers",
    "Medical and surgical tapes wholesalers",
    "Medical diagnostic equipment wholesalers",
    "Medical dressings wholesalers",
    "Medical equipment wholesalers",
    "Medical furniture wholesalers",
    "Medical glassware wholesalers",
    "Medical instruments wholesalers",
    "Medical supplies wholesalers",
    "Medical ultrasound equipment wholesalers",
    "Orthopedic equipment and supplies wholesalers",
    "Patient monitoring equipment wholesalers",
    "Physicians' equipment and supplies wholesalers",
    "Prosthetic appliances and supplies wholesalers",
    "Surgical and medical masks wholesalers",
    "Surgical appliances wholesalers",
    "Surgical dressings wholesalers",
    "Surgical instruments and apparatus wholesalers",
    "Surgical supplies wholesalers",
    "Surgical towels wholesalers",
    "Therapy equipment wholesalers",
    "Thermometers wholesalers",
    "Wheelchairs wholesalers",
  ],
  "1021": [
    "Adrenal medicinal preparations manufacturing",
    "Analgesic preparations manufacturing",
    "Anesthetic preparations manufacturing",
    "Angiourographic diagnostic preparations manufacturing",
    "Antacid preparations manufacturing",
    "Anthelmintic preparations manufacturing",
    "Antibacterial preparations manufacturing",
    "Antibiotic preparations manufacturing",
    "Antidepressant preparations manufacturing",
    "Antihistamine preparations manufacturing",
    "Antineoplastic preparations manufacturing",
    "Antipyretic preparations manufacturing",
    "Antiseptic preparations manufacturing",
    "Antispasmodic preparations manufacturing",
    "Astringent preparations manufacturing",
    "Barbiturate preparations manufacturing",
    "Barium in-vivo diagnostic substances manufacturing",
    "Belladonna preparations manufacturing",
    "Birth control pills manufacturing",
    "Botanical extract preparations manufacturing",
    "Cardiac preparations manufacturing",
    "Cathartic preparations manufacturing",
    "Central nervous system stimulant preparations manufacturing",
    "Coagulation in-vivo diagnostic substances manufacturing",
    "Cold remedies manufacturing",
    "Compounded dietary supplements manufacturing",
    "Compounded herbal supplements manufacturing",
    "Contraceptive preparations manufacturing",
    "Contrast media in-vivo diagnostic substances manufacturing",
    "Cough drops, medicated, manufacturing",
    "Cough medicines manufacturing",
    "Cyclopropane medicinal preparations manufacturing",
    "Dermatological preparations manufacturing",
    "Diagnostic biological preparations manufacturing",
    "Digestive system preparations manufacturing",
    "Digitalis medicinal preparations manufacturing",
    "Diuretic preparations manufacturing",
    "Effervescent salts manufacturing",
    "Electrolyte in-vivo diagnostic substances manufacturing",
    "Eye and ear preparations manufacturing",
    "Fever remedy preparations manufacturing",
    "Glandular medicinal preparations manufacturing",
    "Hematology in-vivo diagnostic substances manufacturing",
    "Hormone preparations manufacturing",
    "Insulin preparations manufacturing",
    "Intravenous (IV) solution preparations manufacturing",
    "In-vivo diagnostic substances manufacturing",
    "Iodinated in-vivo diagnostic substances manufacturing",
    "Laxative preparations manufacturing",
    "Lip balms manufacturing",
    "Metabolite in-vivo diagnostic substances manufacturing",
    "Mouthwashes, medicated, manufacturing",
    "Muscle relaxant preparations manufacturing",
    "Nonprescription drug preparations manufacturing",
    "Nuclear medicine preparations manufacturing",
    "Oral contraceptive preparations manufacturing",
    "Patent medicine preparations manufacturing",
    "Penicillin preparations manufacturing",
    "Pharmaceutical preparations manufacturing",
    "Pituitary gland preparations manufacturing",
    "Radioactive in-vivo diagnostic substances manufacturing",
    "Sedative preparations manufacturing",
    "Sodium chloride pharmaceutical preparations manufacturing",
    "Sodium salicylate preparations manufacturing",
    "Suppositories manufacturing",
    "Technetium medicinal preparations manufacturing",
    "Thyroid preparations manufacturing",
    "Tincture of iodine preparations manufacturing",
    "Tranquilizer preparations manufacturing",
    "Vermifuge preparations manufacturing",
    "Veterinary medicinal preparations manufacturing",
    "Vitamin preparations manufacturing",
    "Water decontamination or purification tablets manufacturing",
    "Zinc oxide medicinal preparations manufacturing",
  ],
  "1023": [
    "Acetylsalicylic acid manufacturing",
    "Agar-agar manufacturing",
    "Atropine and derivatives manufacturing",
    "Barbituric acid manufacturing",
    "Brucine manufacturing",
    "Caffeine and derivatives manufacturing",
    "Cinchona and derivatives manufacturing",
    "Cocaine and derivatives manufacturing",
    "Codeine and derivatives manufacturing",
    "Ephedrine and derivatives manufacturing",
    "Ergot alkaloids manufacturing",
    "Grinding and milling botanicals ",
    "Medicinal gelatins manufacturing",
    "Morphine and derivatives manufacturing",
    "Nicotine and derivatives manufacturing",
    "N-methylpiperazine manufacturing",
    "Opium and opium derivatives manufacturing",
    "Pharmaceutical use enzyme proteins manufacturing",
    "Physostigmine and derivatives manufacturing",
    "Procaine and derivatives manufacturing",
    "Quinine and derivatives manufacturing",
    "Reserpines manufacturing",
    "Strychnine and derivatives manufacturing",
    "Theobromine and derivatives manufacturing",
    "Uncompounded adrenal derivatives manufacturing",
    "Uncompounded amphetamines manufacturing",
    "Uncompounded anesthetics manufacturing",
    "Uncompounded antibiotics manufacturing",
    "Uncompounded anticholinergics manufacturing",
    "Uncompounded anticonvulsants manufacturing",
    "Uncompounded antidepressants manufacturing",
    "Uncompounded ascorbic acid manufacturing",
    "Uncompounded barbiturates manufacturing",
    "Uncompounded cephalosporin manufacturing",
    "Uncompounded concentrated medicinal chemicals manufacturing",
    "Uncompounded cortisone manufacturing",
    "Uncompounded dietary supplements manufacturing",
    "Uncompounded digitoxin manufacturing",
    "Uncompounded endocrine products manufacturing",
    "Uncompounded glandular derivatives manufacturing",
    "Uncompounded glycosides manufacturing",
    "Uncompounded herbal supplements manufacturing",
    "Uncompounded hormones and derivatives manufacturing",
    "Uncompounded hypnotic drugs manufacturing",
    "Uncompounded Insulin manufacturing",
    "Uncompounded medicinal chemicals manufacturing",
    "Uncompounded medicinal cod liver oil manufacturing",
    "Uncompounded medicinal fish liver oils manufacturing",
    "Uncompounded medicinal magnesia manufacturing",
    "Uncompounded medicinal salicylic acid manufacturing",
    "Uncompounded medicinal vegetable and animal oils manufacturing",
    "Uncompounded ophthalmic agents manufacturing",
    "Uncompounded penicillin manufacturing",
    "Uncompounded pituitary gland derivatives manufacturing",
    "Uncompounded steroids manufacturing",
    "Uncompounded sulfa drugs manufacturing",
    "Uncompounded sulfonamides manufacturing",
    "Uncompounded tetracycline manufacturing",
    "Uncompounded vitamins manufacturing",
    "Vegetable alkaloids manufacturing",
  ],
  "1031": [
    "Finish carpentry",
    "Millwork installation",
    "Paneling installation",
    "Residential deck construction",
    "Trim and finish carpentry contractors",
    "Wood or plastic molding or trim installation",
  ],
  "1032": [
    "Built-in wood cabinets constructed on site",
    "Cabinet work performed at the construction site",
    "Cabinetry work performed at the construction site",
    "Kitchen cabinets and counters constructed on site",
    "Residential prefabricated kitchen and bath cabinet installation",
    "Wood built-in cabinets constructed on site",
  ],
  "1033": ["Drapery fixture installation", "Window shade and blind installation"],
  "1034": ["Metal cabinet or countertop fabrication on site", "Metal countertop and cabinet installation"],
  "1035": [
    "Acoustical ceiling tile and panel installation",
    "Acoustical foam installation",
    "Soundproofing contractors",
  ],
  "1040": [
    "Folding door installation",
    "Wood shelving constructed on site",
    "Wood stairway installation",
    "Wood window installation",
  ],
  "1043": [
    "Cable television hookup contractors",
    "Computer and network cable installation",
    "Telephone equipment and building wiring installation",
    "Telephone installation contractors",
  ],
  "1044": [
    "Airport runway lighting contractors",
    "Railroad signaling equipment installation",
    "Solar panel installation",
    "Traffic signal installation",
    "Tunnel lighting contractors",
  ],
  "1047": [
    "Building fixture and fitting installation",
    "Closet organizer system installation",
    "Laboratory furniture and equipment installation",
    "Metal shelving constructed on site",
    "Modular furniture system attachment and installation",
    "Modular system office furniture installation",
    "Moveable and/or demountable partition installation",
    "Museum exhibit installation and dismantling contractors",
    "Spectator seating installation",
    "Trade show exhibit installation and dismantling contractors",
    "Ventilated wire shelving installation",
  ],
  "1049": [
    "Decorative glass and mirror installation",
    "Glass cladding installation",
    "Glass coating and tinting contractors",
    "Glass curtain wall installation",
    "Glass installation contractors",
    "Glass partitions installation",
    "Glazing contractors",
    "Hermetically sealed window unit installation",
    "Mirror installation",
    "Stained glass installation",
    "Window pane or sheet installation",
  ],
  "1050": ["Hydronic heating system installation", "Radiant floor heating equipment installation"],
  "1052": [
    "Building exterior cleaning services",
    "Building gas systems conversion services",
    "Cleaning building exteriors ",
    "Cleaning washing driveways and parking lots",
    "Commercial kitchen exhaust hood cleaning services",
    "Drain cleaning services",
    "Driveway cleaning services",
    "Duct cleaning services",
    "Gutter cleaning services",
    "Lighting maintenance services",
    "Parking lot cleaning services",
    "Power washing building exteriors",
    "Pressure washing services",
    "Snow plowing driveways and parking lots",
    "Steam cleaning building exteriors",
    "Ventilation duct cleaning services",
  ],
  "1061": [
    "Brick veneer installation",
    "Brick, block or stone chimney contractors",
    "Bricklaying contractors",
    "Cement block laying",
    "Cinder block installation",
    "Concrete block laying",
    "Exterior granite contractors",
    "Field stone installation",
    "Foundation contractors",
    "Glass block laying",
    "Glass unit masonry",
    "Marble, granite and slate exterior contractors",
    "Refractory brick contractors",
    "Slate contractors",
    "Stonework contractors",
    "Stucco contractors",
    "Tuck pointing contractors",
  ],
  "1062": ["Metal panel installation", "Metal partition installation"],
  "1064": ["Paint and wallpaper stripping", "Painting and wallpapering"],
  "1068": ["Lawn sprinkler system installation "],
  "1069": [
    "Ceramic tile installation",
    "Interior granite installation",
    "Interior slate installation",
    "Marble or stone mantel installation",
    "Marble, granite and slate interior installation contractors",
    "Mosaic work",
    "Residential countertop installation",
    "Stone flooring installation",
    "Terrazzo and tile refinishing",
    "Terrazzo contractors",
    "Tile contractors",
    "Tile laying and setting",
  ],
  "1087": [
    "Air bag assemblies manufacturing",
    "Air bag initiators manufacturing",
    "Bumpers and bumperettes assembly manufacturing",
    "Catalytic converters, engine exhaust manufacturing",
    "Convertible automotive tops manufacturing",
    "Exhaust and tail pipes manufacturing",
    "Exhaust systems and parts manufacturing",
    "Framed automotive mirrors manufacturing",
    "Gas tanks assembly manufacturing",
    "Hitches, trailer manufacturing",
    "Luggage racks manufacturing",
    "Motor vehicle air filters manufacturing",
    "Motor vehicle air-conditioners manufacturing",
    "Motor vehicle air-conditioning compressors manufacturing",
    "Motor vehicle filters manufacturing",
    "Motor vehicle oil filters manufacturing",
    "Motor vehicle racks manufacturing",
    "Motor vehicle rims manufacturing",
    "Motor vehicle sunroofs and parts manufacturing",
    "Motor vehicle thermostats manufacturing",
    "Motor vehicle wheels manufacturing",
    "Mufflers and resonators manufacturing",
    "Radiators and cores manufacturing",
    "Towing bars and systems manufacturing",
    "Trailer hitches, motor vehicle, manufacturing",
    "Transmission coolers manufacturing",
    "Truck and trailer liftgates manufacturing",
    "Windshield wiper blades and refills manufacturing",
    "Wipers, windshield manufacturing",
  ],
  "1088": ["LED light bulbs manufacturing"],
  "1089": ["Wind powered turbine generator sets manufacturing", "Wind turbines manufacturing"],
  "1091": [
    "Electric power generation windmills manufacturing",
    "Gas turbine generator set units manufacturing",
    "Gas turbines manufacturing",
    "Hydraulic turbine generator set units manufacturing",
    "Hydraulic turbines manufacturing",
    "Steam governors manufacturing",
    "Steam turbine generator set units manufacturing",
    "Steam turbines manufacturing",
    "Turbine generator set units manufacturing",
    "Turbine generator sets manufacturing",
    "Turbines manufacturing",
    "Turbo generators motor generator sets manufacturing",
    "Water turbines manufacturing",
  ],
  "1128": ["Animal shelters", "Dog pounds"],
  "1131": ["Animal cemeteries", "Pet cemeteries"],
  "1132": ["Pet crematories"],
  "1136": ["Recreational horseback riding", "Recreational riding clubs", "Recreational trail riding"],
  "1138": [
    "Aviaries",
    "Bird production",
    "Breeding of butterflies",
    "Breeding of pets",
    "Cat production",
    "Companion animals production",
    "Cricket production",
    "Dog production",
    "Earthworm hatcheries",
    "Kennels, breeding and raising stock for sale",
    "Laboratory animal production ",
    "Llama production",
    "Raising swans, peacocks, flamingos, or other adornment birds",
    "Rattlesnake production",
    "Worm production",
  ],
  "2002": [
    "Computer program or software development",
    "Computer programming services",
    "Computer software analysis and design services",
    "Computer software programming services",
    "Computer software support services",
    "Machine vision software design and development services",
    "Material handling robot applications/software design and development services",
    "Robotics applications/software design and development services",
    "Robotics process automation software design and development services",
    "Search engine optimization (SEO) services",
    "Web page design services",
    "Welding robot applications/software design and development services",
  ],
  "2005": [
    "Application hosting",
    "Cloud computing services",
    "Cloud storage services",
    "Colocation services",
    "Computer data storage services",
    "Computing infrastructure provision",
    "Computing platform infrastructure provision",
    "Data capture imaging services",
    "Media streaming data storage services",
    "Web hosting",
  ],
  "2006": [
    "Automated data processing services",
    "Data entry services",
    "Data processing computer services",
    "Data processing services",
    "Electronic data processing services",
  ],
  "2009": [
    "Computer systems or data processing management and operation services facilities",
    "Computer systems or data processing support services facilities",
    "Data processing facilities management and operation services",
  ],
  "2015": [
    "Cable program distribution operators",
    "Cable television distribution services",
    "Cable TV providers",
    "Closed-circuit television (CCTV) services",
    "Closed-circuit television operations",
    "Direct broadcast satellite (DBS) services",
    "Direct-to-home satellite system (DTH) services",
    "Internet service providers",
    "Multichannel multipoint distribution services (MMDS)",
    "Online access service providers",
    "Satellite master antenna television service (SMATV)",
    "Satellite television distribution systems",
    "VoIP service providers",
    "Wired local telephone carriers",
    "Wired long-distance telephone carriers",
    "Wired telecommunications carriers",
  ],
  "2019": ["Television broadcasting stations"],
  "2020": [
    "Analog computers manufacturing",
    "Computer workstations manufacturing",
    "Computers manufacturing",
    "Digital computers manufacturing",
    "Hybrid computers manufacturing",
    "Laptop computers manufacturing",
    "Microcomputers manufacturing",
    "Minicomputers manufacturing",
    "Notebook computers manufacturing",
    "Personal computers manufacturing",
    "Portable computers manufacturing",
    "Tablet computers manufacturing",
  ],
  "2021": [
    "Carrier equipment modems manufacturing",
    "Carrier line repeater and transceiver equipment manufacturing",
    "Coin- or card-operated telephone manufacturing",
    "Cordless telephones manufacturing",
    "Data communications equipment manufacturing",
    "Local area network (LAN) communications equipment (manufacturing",
    "PBX (private branch exchange) equipment manufacturing",
    "Private branch exchange (PBX) equipment manufacturing",
    "Stand-alone facsimile equipment manufacturing",
    "Telephone answering machines manufacturing",
    "Telephone carrier equipment manufacturing",
    "Telephone carrier line equipment manufacturing",
    "Telephone carrier switching equipment manufacturing",
    "Telephone central office and switching equipment manufacturing",
    "Telephone communications headgear manufacturing",
    "Telephone keysets manufacturing",
    "Telephone multiplex equipment manufacturing",
    "Telephone subscriber loop equipment manufacturing",
    "Telephone switching equipment manufacturing",
    "Telephone toll switching equipment manufacturing",
    "Telephones manufacturing",
    "Wide area network (WAN) communications equipment manufacturing",
  ],
  "2025": ["Computer servers manufacturing", "Mainframe computers manufacturing"],
  "2026": [
    "Abrasion testing machines manufacturing",
    "Accelerometers manufacturing",
    "Aircraft engine fuel densitometers manufacturing",
    "Aircraft engine fuel mixture indicators manufacturing",
    "Aircraft engine fuel totalizers manufacturing",
    "Aircraft engine instruments manufacturing",
    "Aircraft engine pressure and vacuum indicators manufacturing",
    "Aircraft engine thrust power indicators manufacturing",
    "Aircraft fuel system instruments manufacturing",
    "Alarm clocks manufacturing",
    "Appliance timers manufacturing",
    "Automotive emissions testing equipment manufacturing",
    "Auxiliary instrumentation for reactor controls manufacturing",
    "Barographs manufacturing",
    "Barometers manufacturing",
    "Breath alcohol testing devices manufacturing",
    "Breathalyzers manufacturing",
    "Ceilometers manufacturing",
    "Chronographs manufacturing",
    "Chronometers manufacturing",
    "Clock materials and parts manufacturing",
    "Clocks assembling",
    "Clocks assembling from purchased components",
    "Clockwork mechanism timers for industrial use manufacturing",
    "Clockwork operated device mechanism manufacturing",
    "Clockwork timing mechanisms manufacturing",
    "Commercial timing mechanisms manufacturing",
    "Dating devices and machines manufacturing",
    "Dosimetry devices manufacturing",
    "Drafting instruments manufacturing",
    "Drafting templates manufacturing",
    "Dynamometers manufacturing",
    "Evaporation meters manufacturing",
    "Gas leak detectors manufacturing",
    "Geiger counters manufacturing",
    "Geophysical galvanometers manufacturing",
    "Geophysical instruments manufacturing",
    "Hardness testing equipment manufacturing",
    "Household timing mechanisms manufacturing",
    "Humidity instruments manufacturing",
    "Hydrometers manufacturing",
    "Hygrometers manufacturing",
    "Hygrothermographs manufacturing",
    "Industrial, mechanical fatigue testing machines manufacturing",
    "Ion chambers manufacturing",
    "Kinematic test and measuring equipment manufacturing",
    "Lie detectors manufacturing",
    "Liquid-in-glass and bimetal types thermometer manufacturing",
    "Magnetometers manufacturing",
    "Metal detectors manufacturing",
    "Meteorologic tracking systems manufacturing",
    "Meteorological actinometers manufacturing",
    "Meteorological instruments manufacturing",
    "Modules for clocks and watches manufacturing",
    "Movements, watch or clock, manufacturing",
    "Nephoscopes manufacturing",
    "Nonelectric fire detector systems manufacturing",
    "Nuclear instrument modules manufacturing",
    "Nuclear monitoring pulse analyzers manufacturing",
    "Nuclear radiation count rate meters manufacturing",
    "Nuclear radiation sample changers manufacturing",
    "Nuclear radiation scalers manufacturing",
    "Nucleat whole body counters manufacturing",
    "Physical properties testing and inspection equipment manufacturing",
    "Pitometers manufacturing",
    "Polygraph machines manufacturing",
    "Portable magnetic compasses manufacturing",
    "Precision clock or watch springs manufacturing",
    "Pressure transducers manufacturing",
    "Pyrheliometers manufacturing",
    "RADIAC equipment manufacturing",
    "Radiation detection and monitoring instruments manufacturing",
    "Radiation level gauges manufacturing",
    "Rain gauges manufacturing",
    "Scintillation detectors manufacturing",
    "Seismographs manufacturing",
    "Seismometers manufacturing",
    "Seismoscopes manufacturing",
    "Slide rules manufacturing",
    "Solarimeters manufacturing",
    "Springs, clock and watch manufacturing",
    "Surveying alidades manufacturing",
    "Surveying instruments manufacturing",
    "Surveying levels and tapes manufacturing",
    "Surveying sextants manufacturing",
    "Surveying theodolites manufacturing",
    "Surveying transits manufacturing",
    "Surveyor's chains manufacturing",
    "Surveyor's rods manufacturing",
    "Surveyor's tapes manufacturing",
    "Tensile strength testing equipment manufacturing",
    "Testers for checking hydraulic controls on aircraft manufacturing",
    "Testing equipment manufacturing",
    "Thermocouples manufacturing",
    "Time clocks and time recording devices manufacturing",
    "Time locks manufacturing",
    "Time stamps containing clock mechanisms manufacturing",
    "Timing mechanism operated hand stamps manufacturing",
    "Torsion testing equipment manufacturing",
    "T-squares manufacturing",
    "Turntable indicator testers manufacturing",
    "Ultrasonic testing equipment manufacturing",
    "Ultrasonic thickness gauging instruments manufacturing",
    "Vibration meters, analyzers, and calibrators manufacturing",
    "Viscosimeters manufacturing",
    "Wall clocks manufacturing",
    "Watch jewels manufacturing",
    "Watchcase manufacturing",
    "Watches and parts manufacturing",
    "Water leak detectors manufacturing",
    "Weather tracking equipment manufacturing",
    "Wind direction indicators manufacturing",
  ],
  "2028": [
    "Atomic force microscopes manufacturing",
    "Blood bank process equipment manufacturing",
    "Chronoscopes manufacturing",
    "Electron and proton microscopes manufacturing",
    "Electron microscopes manufacturing",
    "Electron paramagnetic spin apparatus manufacturing",
    "Electrophoresis instruments manufacturing",
    "Elemental analyzers manufacturing",
    "Flame photometers manufacturing",
    "Hematology instruments manufacturing",
    "Laboratory immunology instruments manufacturing",
    "Laboratory monochrometers manufacturing",
    "Laboratory amino acid analyzers manufacturing",
    "Laboratory analysis acidity measuring equipment manufacturing",
    "Laboratory analytical densitometers manufacturing",
    "Laboratory analytical instruments manufacturing",
    "Laboratory automatic chemical analyzers manufacturing",
    "Laboratory chromatographic instruments manufacturing",
    "Laboratory colorimeters manufacturing",
    "Laboratory coulometric analyzers manufacturing",
    "Laboratory differential thermal analysis instruments manufacturing",
    "Laboratory electrolytic conductivity instruments manufacturing",
    "Laboratory electron microprobes manufacturing",
    "Laboratory gas analyzers manufacturing",
    "Laboratory gas chromatographic instruments manufacturing",
    "Laboratory infrared analytical instruments manufacturing",
    "Laboratory liquid chromatographic instruments manufacturing",
    "Laboratory moisture analyzers manufacturing",
    "Laboratory particle beam excitation instruments manufacturing",
    "Laboratory protein analyzers manufacturing",
    "Laboratory refractometers manufacturing",
    "Laboratory specific ion measuring instruments manufacturing",
    "Laboratory thermal analysis instruments manufacturing",
    "Laboratory turbidometers manufacturing",
    "Mass spectrometers manufacturing",
    "Mass spectroscopy instrumentation manufacturing",
    "Microbiology instruments manufacturing",
    "Microprobes manufacturing",
    "MRI type apparatus manufacturing",
    "Nanomanipulator equipment manufacturing",
    "Nanosensor instruments manufacturing",
    "Nephelometers manufacturing",
    "Neutron activation analysis instruments manufacturing",
    "Osmometers manufacturing",
    "Particle size analyzers manufacturing",
    "Photometer manufacturing",
    "Photonexcitation analyzers manufacturing",
    "Polariscopes manufacturing",
    "Polarizers manufacturing",
    "Polarographic equipment manufacturing",
    "Redox instruments manufacturing",
    "Resonance instruments manufacturing",
    "Sample analysis instruments manufacturing",
    "Scanning tunneling microscopes manufacturing",
    "Spectrofluorometers manufacturing",
    "Spectrographs manufacturing",
    "Spectrometers manufacturing",
    "Spectrophotometers manufacturing",
    "Surface area analyzers manufacturing",
    "Thermal conductivity instruments and sensors manufacturing",
    "Thermogravimetric analyzers manufacturing",
    "Titrimeters manufacturing",
    "Ultraviolet analytical instruments manufacturing",
  ],
  "2031": [
    "Bare circuit boards, printed manufacturing",
    "Bare flexible wiring boards manufacturing",
    "Bare printed circuit boards manufacturing",
  ],
  "2105": ["Distribution of electric power", "Electric power brokers", "Electric power distribution systems"],
  "7157": [
    "Check cashing services",
    "Loan servicing",
    "Money order issuance services",
    "Payday lending services",
    "Travelers' check issuance services",
  ],
  "7158": ["Money transmission services"],
  "7162": [
    "Brokerages, loan",
    "Loan agencies",
    "Loan brokerages",
    "Loan brokers' offices",
    "Loan brokers' or agents' offices",
  ],
  "7170": [
    "Employee benefit pension funds",
    "Employee benefit pension plans",
    "Pension funds",
    "Pension plans",
    "Retirement pension plans",
    "Union pension funds",
  ],
  "7172": [
    "Charitable trusts",
    "Charitable trusts awarding grants",
    "Corporate foundations awarding grants",
    "Educational trusts awarding grants",
    "Philanthropic trusts awarding grants",
    "Religious trusts awarding grants",
    "Scholarship trusts",
  ],
  "7180": [
    "Construction lending",
    "Farm mortgage lending",
    "Federal Land Banks",
    "Home equity credit lending",
    "Loan correspondents",
    "Real estate credit lending",
  ],
  "7194": ["Electrical equipment and appliance installation"],
  "7266": [
    "Beverage bases manufacturing",
    "Beverage flavorings manufacturing",
    "Beverage syrups manufacturing",
    "Concentrates flavoring manufacturing",
    "Drink concentrates manufacturing",
    "Flavoring concentrates manufacturing",
    "Flavoring pastes, powders and syrups for soft drink manufacturing",
    "Fruit syrups flavoring manufacturing",
    "Soda fountain syrups manufacturing",
    "Soft drink concentrates manufacturing",
    "Syrup flavoring manufacturing",
    "Syrup manufacturing",
  ],
  "7302": [
    "Contact lenses manufacturing",
    "Corrective vision-type magnifiers manufacturing",
    "Glass and plastics eyes manufacturing",
    "Goggles manufacturing",
    "Ophthalmic eyeglass frames manufacturing",
    "Ophthalmic lens coating",
    "Ophthalmic lens grinding",
    "Ophthalmic lens mounts manufacturing",
    "Ophthalmic lenses manufacturing",
    "Ophthalmic polishing lens",
    "Ophthalmic temples and fronts manufacturing",
    "Sunglasses and goggles manufacturing",
  ],
  "7393": [
    "Account collection services",
    "Accounts collection agencies",
    "Adjustment agencies",
    "Bill collection services",
    "Collection agencies",
    "Credit arrears collection services",
    "Debt collection services",
    "Delinquent account collection services",
    "Tax collection services on a contract or fee basis",
  ],
  "7407": [
    "Livestock veterinary services",
    "Veterinary livestock inspecting and testing services",
    "Veterinary livestock services",
  ],
  "7558": ["Full service pizzerias"],
};
