import Autocomplete, { Suggestion } from "./Autocomplete";
import React, { useReducer } from "react";
import { cb } from "../../utils/cd";

export const SelectAutocomplete = ({
  suggestions,
  onSelect,
  value,
  placeholder,
}: {
  suggestions: Suggestion[];
  onSelect: (v: any) => void;
  value: any;
  placeholder?: string;
}) => {
  const [opMenu, toggleOpMenu] = useReducer((_: boolean, c: boolean) => c, false);

  if (!opMenu) {
    return (
      <div onClick={cb(toggleOpMenu, true)} className={"flex items-center gap-2 w-full cursor-pointer "}>
        {value || placeholder}
        <span className={"text-xs"}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='size-4'
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' />
          </svg>
        </span>
      </div>
    );
  }

  return <Autocomplete focus suggestions={suggestions} toggle={cb(toggleOpMenu, false)} onSelect={onSelect} />;
};
