import { useCallback, useEffect } from "react";
import { NoData } from "../util/Empty";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 } from "uuid";
import UploadCSV from "./UploadCSV";
import { useAppState } from "../../state/State";
import { cb } from "../../utils/cd";

export const Questionnaire = () => {
  const {
    state: { questionnaires, currentQuestionnaire },
    list,
    create,
    remove,
    recoverQuestionnaire,
    publishQuestionnaire,
  } = useAppState();

  const [search, change] = useSearchParams();

  useEffect(() => {
    list();
  }, [list]);

  const navigate = useNavigate();

  const createQuestionnaire = useCallback(() => {
    const id = v4();
    create({
      id,
      name: `New Questionnaire ${Date.now()}`,
      subText: "",
      title: `New Questionnaire ${Date.now()}`,
    });
    search.set("id", id);
    change(search);
  }, [change, create, search]);

  useEffect(() => {
    const current = search.get("id");
    if (current && current === currentQuestionnaire?.id) {
      navigate(current);
    }
  }, [search, currentQuestionnaire, navigate]);

  return (
    <div className={"w-full flex flex-col p-4 gap-2"}>
      <div className={"flex items-center justify-between border-b border-gray-700 pb-2"}>
        <h1 className={"text-lg text-gray-700"}>Questionnaires</h1>
        <button className={"w-48 py-1 bg-gray-700 text-white"} onClick={createQuestionnaire}>
          Create New
        </button>
      </div>
      <UploadCSV />

      <div className={"flex flex-col"}>
        {questionnaires.length ? (
          questionnaires.map(item => (
            <div key={item.id} className={"flex justify-between border-b border-gray-200 px-2 py-[2px] pb-1"}>
              <h3 className={"flex gap-2"}>
                {item.name}{" "}
                {!item.lineOfBusinessId ? (
                  <div className={"text-red-500"}>(Questionnaire is not associated to any product)</div>
                ) : (
                  <div className={"border px-4 rounded-full mx-3 text-xs flex items-center gap-3 "}>
                    Product: <span className={"font-semibold"}>{item.lineOfBusiness?.shortName}</span>
                  </div>
                )}
              </h3>
              <div className={"flex gap-4"}>
                {(item as any).deletedAt ? (
                  <>
                    <button
                      className={"w-32 py-[2px] bg-teal-600 text-white"}
                      onClick={cb(recoverQuestionnaire, item.id)}
                    >
                      Recover
                    </button>
                    <button className={"w-32 py-[2px] bg-red-400 text-white"} onClick={cb(remove, item.id)}>
                      Delete
                    </button>
                  </>
                ) : (
                  <>
                    {item?.lineOfBusiness?.activeQuestionnaireId !== item.id ? (
                      <button
                        disabled={!item?.lineOfBusiness}
                        className={
                          "w-32 py-[2px] bg-teal-600 text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                        }
                        onClick={cb(publishQuestionnaire, item.id)}
                      >
                        Publish
                      </button>
                    ) : (
                      <div
                        className={
                          "text-yellow-600 px-4 flex items-center border rounded-full text-xs border-yellow-500"
                        }
                      >
                        Published
                      </div>
                    )}
                    <button className={"w-32 py-[2px] bg-red-400 text-white"} onClick={cb(remove, item.id)}>
                      Remove
                    </button>
                    <button
                      className={"w-32 py-[2px] bg-sky-400 text-white"}
                      onClick={() => navigate(`${item.id}?groupPart=q-details`)}
                    >
                      Edit
                    </button>
                    <button
                      className={"w-32 py-[2px] bg-green-600 text-white"}
                      onClick={() => navigate(`${item.id}/preview`)}
                    >
                      Preview
                    </button>
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};
