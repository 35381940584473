import { OpArgument } from "./OpArgument";
import React, { useMemo } from "react";
import { useOp } from "./OpUI";
import { Val } from "@Savus-Inc/dsl/dist/types";

export function OpArgumentDisplay() {
  const { signature, op, updateArg, context, addArg } = useOp();

  const args = useMemo(() => {
    if (!signature.argSignature?.length && !op.args.length && signature.maxArgs) {
      return [{ arg: null, signature: { value: signature.resolvingType || Val.String } }];
    } else if (op.args.length > signature.argSignature.length) {
      return op.args.map((arg, i) => ({
        arg,
        signature: signature.argSignature
          ? signature.argSignature[i] || signature.argSignature[signature.argSignature.length - 1]
          : { value: signature.resolvingType || Val.String },
      }));
    } else {
      return signature.argSignature.map((signature, i) => ({
        signature,
        arg: op.args[i] || null,
      }));
    }
  }, [op, signature]);

  const allowAddAdditionalArgs = useMemo(() => {
    if (!signature.minArgs) return false;

    return (signature.maxArgs || 1000) > op.args.length && op.args.length >= signature.argSignature?.length;
  }, [op.args, signature]);

  return (
    <>
      {args.map((arg, i) => (
        <OpArgument
          key={i}
          argIdx={i}
          arg={arg.arg}
          argSignature={arg.signature}
          allowSynthetic={signature.allowSynthetic}
          label={(signature.argumentLabel || [])[i]}
          update={updateArg(i)}
          context={context}
        />
      ))}
      {allowAddAdditionalArgs && (
        <span className={"text-sky-400 bg-gray-100 font-bold rounded-full p-1.5 cursor-pointer"} onClick={addArg}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='3'
            stroke='currentColor'
            className='size-3.5'
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M12 4.5v15m7.5-7.5h-15' />
          </svg>
        </span>
      )}
    </>
  );
}
