import { Questionnaire } from "@Savus-Inc/questionnaire-types";
import { QuestionnaireExport } from "./QuestionnaireExport";

export function QuestionnaireHeader({
  item,
  preview,
  back,
}: {
  back: () => void;
  item: Questionnaire;
  preview: () => void;
}) {
  return (
    <div className={"flex items-center justify-between border-b border-gray-300  sticky -top-2 bg-white p-2 shadow "}>
      <button className={"w-32 py-[2px] text-gray-500"} onClick={back}>
        {" < "}Go Back
      </button>
      <h1 className={"text-lg text-gray-700 flex flex-col text-center"}>
        <span>
          Questionnaire: <span className={"font-semibold"}>{item?.title || "New"}</span>
        </span>
        {!item.lineOfBusinessId && (
          <span className={"text-red-500 text-sm font-semibold"}>Questionnaire is not associated to the product</span>
        )}
      </h1>

      <div className={"flex items-center  gap-10"}>
        <button className={"w-32 py-1 bg-sky-500 text-white rounded-xl shadow"} onClick={preview}>
          Preview
        </button>
        <div className={"flex gap-1"}>
          <div className={"flex gap-2 items-center"}>
            <QuestionnaireExport />
          </div>
          <a
            className='py-1 px-3 rounded-xl border border-gray-200 text-sky-600 '
            href={`data:text/json;charset=utf-8,${encodeURIComponent(
              JSON.stringify({ ...item, carrierQuestionnaires: undefined }),
            )}`}
            download={`${Date.now()}-questionnaire.json`}
          >
            Export JSON
          </a>
        </div>
      </div>
    </div>
  );
}
