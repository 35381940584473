import { useCallback, useMemo } from "react";
import classNames from "classnames";
import { cb } from "../../utils/cd";
import {
  InputType,
  QuestionValueAutocompleteIntegration,
  QuestionValueFormatting,
  ValidationAlertLevel,
} from "@Savus-Inc/questionnaire-types";
import { useQuestionAnswer } from "@Savus-Inc/runtime-ngn/dist/ngn";
import { AnswerValue, QuestionValidationResult } from "@Savus-Inc/runtime-ngn/dist/ngn/types/answers";
import { unpack } from "../../utils/unpack";
import { AddIcon } from "../util/AddNew";
import { RemoveIcon } from "../util/ItemActions";
import MultiSelect from "../util/MultiSelect";

export function ValidationMessages({
  validationMessages,
}: {
  validationMessages: QuestionValidationResult | QuestionValidationResult[];
}) {
  return (
    <div className={"flex flex-col"}>
      {validationMessages.flat().map(v => (
        <div
          key={v.message}
          className={classNames("", {
            "text-red-400": v.alertLevel === ValidationAlertLevel.Error,
            "bg-orange-100 px-2 py-[2px] mt-1 w-fit test-orange-800 border-l-4 border-orange-800":
              v.alertLevel === ValidationAlertLevel.Warning,
          })}
        >
          {v.message}
        </div>
      ))}
    </div>
  );
}

export const AnswerValueInput = ({
  idx = 0,
  me,
  parentPath,
}: {
  idx?: number;
  me: string;
  parentPath: (string | number)[];
}) => {
  const { answer, value: val, question } = useQuestionAnswer(me, parentPath);

  const value = useMemo(() => {
    if ([null, undefined].includes(idx as never)) {
      return val?.value;
    } else if (Array.isArray(val?.value)) {
      return val?.value[idx];
    } else {
      return val?.value;
    }
  }, [val, idx]);

  const isSelected = useCallback(
    (v: AnswerValue) => {
      if (Array.isArray(val?.value) && val?.multiple) {
        return (val.value as AnswerValue[]).includes(v);
      } else if (val?.value) {
        return v === val?.value;
      }
    },
    [val],
  );

  switch (question?.autocompleteIntegration) {
    case QuestionValueAutocompleteIntegration.OwnValues:
      switch (question?.inputType) {
        case InputType.Select:
          if (question?.multipleAnswers) {
            return (
              <MultiSelect
                options={question?.values.map(v => ({ label: v.label, value: v.value.value })) as never}
                value={(value as string[]) || []}
                onChange={answer(idx) as never}
              />
            );
          }
          return (
            <select
              value={value?.toString() || ""}
              onChange={unpack(answer(idx))}
              className='w-full min-w-96 border border-gray-300 px-1 py-1 text-base rounded'
            >
              <option value={question?.placeholder || ""}></option>
              {question?.values.map(v => (
                <option value={v.value.value as never} key={v.id} selected={isSelected(v.value.value as never)}>
                  {v.label}
                </option>
              ))}
            </select>
          );
        case InputType.RadioGroup:
          return (
            <div className={"flex gap-4 flex-wrap"}>
              {question?.values.map(v => (
                <label key={v.id} className='flex items-center space-x-2'>
                  <input
                    type='radio'
                    name={question?.id} // Ensure all radios in the group have the same name
                    value={v.value.value as never}
                    checked={isSelected(v.value.value as never)}
                    onChange={unpack(answer(idx))}
                    className='form-radio h-4 w-4 text-sky-600'
                  />
                  <span>{v.label}</span>
                </label>
              ))}
            </div>
          );
        case InputType.YesNo:
          return (
            <div className={"flex"}>
              {question?.values.map(val => (
                <div
                  key={val.id}
                  className={classNames(
                    "cursor-pointer px-1 py-0.5 w-full min-w-32  border border-gray-300 text-base text-center hover:bg-sky-500 hover:text-white flex items-center justify-center",
                    { "bg-sky-500 text-white": isSelected(val.value.value as never) },
                  )}
                  onClick={cb(answer(idx), val.value.value as AnswerValue)}
                >
                  {val.label}
                </div>
              ))}
            </div>
          );
        case InputType.Checkbox:
          return (
            <div className={"flex flex-col gap-1"}>
              {question?.values.map((v, i) => (
                <label key={v.id} htmlFor={question?.id} className='flex items-center space-x-2'>
                  <input
                    type='checkbox'
                    name={question?.id} // Same name for all checkboxes if you want grouping behavior
                    value={v.value?.value as string}
                    checked={isSelected(v?.value?.value as string)}
                    onChange={unpack(answer(i))} // Handle checkbox changes
                    className='form-checkbox h-4 w-4 text-sky-600'
                  />
                  <span>{v.label}</span>
                </label>
              ))}
            </div>
          );
        case InputType.RichText:
          return (
            <textarea
              value={value?.toString() || ""}
              onChange={unpack(answer(idx))}
              className='w-full border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]'
              placeholder={question?.placeholder || ""}
              rows={20}
            />
          );
        default: {
          if (question?.values.length)
            return (
              <div className={"flex justify-start gap-10 flex-wrap"}>
                {question?.values.map((val, idx) => (
                  <div
                    key={val.id}
                    className={classNames(
                      "cursor-pointer px-1 py-0.5 w-full min-w-40 rounded-lg border border-sky-800 text-base text-center hover:bg-sky-500 hover:text-white",
                      { "bg-sky-500 text-white": isSelected(val.value.value as never) },
                    )}
                    onClick={cb(answer(idx), val.value.value as AnswerValue)}
                  >
                    {val.label}
                  </div>
                ))}
              </div>
            );
          return (
            <input
              type={"text"}
              value={value?.toString() || ""}
              onChange={unpack(answer(idx))}
              className='w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]'
              placeholder={question?.placeholder || ""}
            />
          );
        }
      }
    case QuestionValueAutocompleteIntegration.GoogleLocation:
      return (
        <input type='text' id='name' className='w-max min-w-96 border border-gray-300 rounded-md px-1 py-1 text-base' />
      );
  }

  switch (question?.inputType) {
    case InputType.Header: {
      return (
        <input
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className='w-full px-1 bg-transparent py-1 text-base rounded min-w-[378px] focus:outline-none'
          placeholder={question?.placeholder || ""}
        />
      );
    }
    case InputType.RichText:
      return (
        <textarea
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className='w-full border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]'
          placeholder={question?.placeholder || ""}
          rows={20}
        />
      );
  }

  switch (question?.format) {
    case QuestionValueFormatting.PasswordMask:
    case QuestionValueFormatting.FEIN:
    case QuestionValueFormatting.SSN:
      return (
        <input
          type={"password"}
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className='w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]'
          placeholder={question?.placeholder || ""}
        />
      );
    case QuestionValueFormatting.DateFormat_Year:
      return (
        <input
          type={"number"}
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className='w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]'
          placeholder={question?.placeholder || ""}
        />
      );
    case QuestionValueFormatting.DateFormat_MonthYear:
      return (
        <input
          type={"month"}
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className='w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]'
          placeholder={question?.placeholder || ""}
        />
      );
    case QuestionValueFormatting.DateFormat_FullDate:
      return (
        <input
          type={"date"}
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className='w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]'
          placeholder={question?.placeholder || ""}
        />
      );
    case QuestionValueFormatting.TimeFormat_12H:
      return (
        <div className={"flex gap-4"}>
          <label>Hours:</label>
          <select>
            {Array.from(Array(13).keys()).map((_, i) => (
              <option value={i}>{i}</option>
            ))}
          </select>

          <label>Minutes:</label>
          <select>
            {Array.from(Array(61).keys()).map((_, i) => (
              <option value={i}>{i}</option>
            ))}
          </select>

          <label htmlFor='ampm'>AM/PM:</label>
          <select id='ampm'>
            <option value='AM'>AM</option>
            <option value='PM'>PM</option>
          </select>
        </div>
      );
    case QuestionValueFormatting.TimeFormat_24H:
      return (
        <div className={"flex gap-4"}>
          <label>Hours:</label>
          <select>
            {Array.from(Array(24).keys()).map((_, i) => (
              <option value={i}>{i}</option>
            ))}
          </select>

          <label>Minutes:</label>
          <select>
            {Array.from(Array(61).keys()).map((_, i) => (
              <option value={i}>{i}</option>
            ))}
          </select>

          <label htmlFor='ampm'>AM/PM:</label>
          <select id='ampm'>
            <option value='AM'>AM</option>
            <option value='PM'>PM</option>
          </select>
        </div>
      );
    // question	questionnaire_name	stateIncluded	stateExcluded	stateProductExclude	client	product	carrier	application	userFlow	pageId	pageOrder	pageDisplay	pageTitle	pageSubText	sectionId	sectionOrder	sectionDisplay	sectionTitle	sectionSubText	subSection	questionId	questionOrder	questionContainerId	subQuestionDisplay	fieldLabel	fieldInputType	questionKind	fieldMultipleAnswers	actionButton	minAnswers	maxAnswers	questionFormat	questionCode	answerDisplay	autocompleteIntegration	valueId	answerOrder	answerValue	answerLabel	defaultValue	fieldPlaceholder	fieldSubText	fieldTooltip	visibility	Required	warning	classCodeInclude	classCodeExclude	decline	jsonNode	prefill

    case QuestionValueFormatting.CurrencyFormat:
      return (
        <div className={"flex items-center gap-1"}>
          <span className={"font-bold"}>$</span>
          <input
            type={"number"}
            value={value?.toString() || ""}
            onChange={unpack(answer(idx))}
            className='w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]'
            placeholder={question?.placeholder || ""}
          />
        </div>
      );
    case QuestionValueFormatting.Percentage:
      return (
        <div className={"flex items-center gap-1"}>
          <input
            type={"number"}
            value={value?.toString() || ""}
            onChange={unpack(answer(idx))}
            className='w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]'
            placeholder={question?.placeholder || ""}
          />
          <span className={"font-bold"}>%</span>
        </div>
      );
    default:
      return (
        <input
          type={question?.kind === "timestamp" ? "date" : question?.kind === "bool" ? "checkbox" : question?.kind}
          value={value?.toString() || ""}
          onChange={unpack(answer(idx))}
          className='w-max border border-gray-300 px-1 py-1 text-base rounded min-w-[400px]'
          placeholder={question?.placeholder || ""}
        />
      );
  }
};

export const ValuePreview = ({ me, parentPath }: { me: string; parentPath: (string | number)[] }) => {
  const {
    answer,
    value,
    validationMessages,
    defaultValue,
    displayMultipleInputs,
    canRemoveAnswers,
    canAddMoreAnswers,
    question,
  } = useQuestionAnswer(me, parentPath);

  return (
    <div className={classNames("flex flex-col")}>
      {displayMultipleInputs && value?.value && value?.multiple ? (
        <div className={"flex items-center gap-2 bg-re"}>
          {value?.value?.map((v, i) => (
            <div key={`${i}`} className={"flex items-center"}>
              <AnswerValueInput idx={i} me={me} parentPath={parentPath} />
              {canRemoveAnswers && (
                <div className={"text-red-400"} onClick={cb(answer(i), null)}>
                  <RemoveIcon />
                </div>
              )}
            </div>
          ))}
          {canAddMoreAnswers && question?.inputType !== InputType.Header && (
            <div
              onClick={cb(answer(value.value.length), defaultValue)}
              className={"border rounded cursor-pointer border-gray-700 p-1 text-gray-700"}
            >
              <AddIcon />
            </div>
          )}
        </div>
      ) : (
        <AnswerValueInput me={me} parentPath={parentPath} />
      )}
      <ValidationMessages validationMessages={validationMessages} />
    </div>
  );
};
