import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import { useMemo } from "react";
import { NoData } from "../util/Empty";
import { RuleGroups } from "../Rules/RuleGroup";
import { RuleGroupKind } from "@Savus-Inc/questionnaire-types";

export const CarrierMappingRules = () => {
  const { carrierMappingRuleId, item, selectedCarrier } = useBuilderNGN();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rules = useMemo(() => selectedCarrier?.rules || [], [item, selectedCarrier]);
  const rule = useMemo(() => {
    if (carrierMappingRuleId) {
      return rules.find(r => r.id === carrierMappingRuleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules, carrierMappingRuleId, item]);

  if (!rule) return <NoData message={"Rule does not exists under the carrier mapping"} />;

  return <RuleGroups groups={[rule]} groupType={"knockout"} kind={RuleGroupKind.Knockout} />;
};
