export const NoData = ({ message = "No data available" }) => {
  return (
    <div className='p-8 flex flex-col items-center justify-center h-64 bg-gray-100 rounded-lg text-center'>
      <svg
        className='w-12 h-12 mb-4 text-gray-500'
        fill='none'
        stroke='currentColor'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        {/* Replace with a suitable "empty state" icon */}
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
        />
      </svg>
      <p className='text-lg text-gray-700 font-medium'>{message}</p>
    </div>
  );
};
