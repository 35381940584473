import { QuestionnaireQuestionEntity, QuestionnaireQuestionGroupEntity } from "@Savus-Inc/questionnaire-types";
import { useBuilderNGN } from "./BuilderNGN";
import classNames from "classnames";
import { cb } from "../../utils/cd";
import { RemoveAction } from "../util/ItemActions";
import React from "react";
import { AccordionItem, TitleWithAction } from "../util/Accourdion";
import { NoData } from "../util/Empty";
import { Move } from "../util/Move";

export const QuestionGroupQuestionItem = ({
  question: q,
  groupId,
  groupQuestions,
  questions,
}: {
  question: QuestionnaireQuestionEntity;
  groupId: string;
  groupQuestions: QuestionnaireQuestionEntity[];
  questions?: QuestionnaireQuestionEntity[];
}) => {
  const { resort, activateGroupPart, removeQuestion, selectedQuestion } = useBuilderNGN();

  return (
    <div
      key={q.id}
      className={classNames(
        "flex gap-2 items-center justify-between border-sky-200 border-b py-[2px] cursor-pointer px-2 rounded w-full text-left hover:bg-sky-100",
        {
          "bg-sky-100": selectedQuestion?.id === q.id,
        },
      )}
    >
      <div
        className={classNames("", {
          "w-full max-w-[300px] overflow-ellipsis overflow-hidden whitespace-nowrap": !q.parentId,
          "flex-1": q.parentId,
        })}
        onClick={cb(activateGroupPart, {
          group: groupId,
          groupPart: "g-questions",
          questionId: q.id,
        })}
      >
        <span className={"font-bold"}>Q{q.partOrder}</span>: {q.question?.label}{" "}
        {q.question?.externalId ? `(${q.question?.externalId})` : ""}
      </div>
      <div className={"flex items-center gap-1"}>
        {!!groupQuestions.length && (
          <div className={"bg-gray-600 text-white rounded text-xs px-2 flex items-center justify-center"}>
            {groupQuestions.length}
          </div>
        )}
        <Move
          onChange={d => resort("question", { groupId: groupId, questionId: q.id })(questions || [], q.partOrder, d)}
        />
        <RemoveAction remove={cb(removeQuestion, groupId, q.id)} small invert />
      </div>
    </div>
  );
};
export const QuestionsGroupItem = ({
  group: q,
  groupId,
}: {
  group: QuestionnaireQuestionGroupEntity;
  groupId: string;
}) => {
  const { resort, item, groupPartActive, currentGroup, activateGroupPart, insertQuestion, removeGroup } =
    useBuilderNGN();

  return (
    <AccordionItem
      title={
        <TitleWithAction
          title={
            <span>
              <span className={"font-bold"}>P{q.partOrder}</span>: {q.title}
            </span>
          }
          remove={cb(removeGroup, groupId)}
          move={d => resort("page", { groupId: q.id })(item?.groups || [], q.partOrder, d)}
        />
      }
      selected={groupPartActive.startsWith("g-") && currentGroup?.id === q?.id}
      key={q.id}
      initial={groupPartActive.startsWith("g-") && currentGroup?.id === q?.id}
    >
      <button
        className={classNames(
          "w-full flex justify-between items-center px-2 p-0.5 my-1 border-b border-purple-100 font-medium focus:outline-none",
          {
            "bg-gray-100 text-gray-800": groupPartActive === "g-details" && currentGroup?.id === q.id,
            "text-gray-700": groupPartActive !== "g-details" && currentGroup?.id === q.id,
          },
        )}
        onClick={cb(activateGroupPart, { group: groupId, groupPart: "g-details" })}
      >
        Base Details
      </button>
      <button
        className={classNames(
          "w-full flex justify-between items-center px-2 p-0.5 my-1 border-b border-purple-100 font-medium focus:outline-none",
          {
            "bg-gray-100 text-gray-800": groupPartActive === "g-rules" && currentGroup?.id === q.id,
            "text-gray-700": groupPartActive !== "g-rules" && currentGroup?.id === q.id,
          },
        )}
        onClick={cb(activateGroupPart, { group: groupId, groupPart: "g-rules" })}
      >
        Group Rules
      </button>
      <AccordionItem
        title={<TitleWithAction title={"Questions"} add={cb(insertQuestion, groupId)} />}
        initial={groupPartActive === "g-questions" && currentGroup?.id === q.id}
        selected={groupPartActive === "g-questions" && currentGroup?.id === q.id}
      >
        {!q?.questions?.length && <NoData message={"No Questions Found"} />}
        <QuestionsGroupQuestions questions={q?.questions || []} groupId={groupId} />
      </AccordionItem>
    </AccordionItem>
  );
};
export const QuestionsGroupQuestions = ({
  groupId,
  questions,
  container,
}: {
  groupId: string;
  questions: QuestionnaireQuestionEntity[];
  container?: boolean;
}) => {
  return (
    <div
      className={classNames("flex flex-col gap-3", {
        "px-2 pb-2": container,
      })}
    >
      {questions
        .sort((a, b) => a.partOrder - b.partOrder)
        .filter(q => container || !q.parentId)
        .map(question => (
          <QuestionGroupQuestionItem
            question={question}
            groupId={groupId}
            groupQuestions={questions.filter(q => q.parentId === question?.id)}
            questions={questions}
          />
        ))}
    </div>
  );
};
