import { ValuePreview } from "./ValuePreview";
import { useQuestionAnswer } from "@Savus-Inc/runtime-ngn/dist/ngn";
import classNames from "classnames";
import Tooltip from "../util/Tooltip";
import { AddIcon } from "../util/AddNew";
import { cb } from "../../utils/cd";
import { DisplayDirection, InputType, Question, QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";
import { RemoveIcon } from "../util/ItemActions";

export const SubQuestionPreview = ({
  parentPath,
  subquestions,
  idx,
  question,
  removeQuestion,
}: {
  parentPath: (string | number)[];
  subquestions: QuestionnaireQuestionEntity[];
  idx?: number;
  question: Question;
  removeQuestion?: () => void;
}) => {
  const hasHeader = subquestions.some(q => q.question?.inputType === InputType.Header);

  return (
    <>
      <div className={"border border-gray-200 rounded w-fit relative h-fit"}>
        <div
          className={classNames("flex gap-2 relative p-4", {
            "justify-evenly": question.subQuestions === DisplayDirection.Row,
            "flex-col": !question.subQuestions || question.subQuestions === DisplayDirection.Col,
            "flex-wrap gap-y-1 gap-x-8": question.subQuestions === DisplayDirection.RowWrap,
            "pt-16": hasHeader,
          })}
        >
          {hasHeader && (
            <div
              className={
                "p-2 w-full absolute top-0 left-0 rounded-t flex justify-end items-center gap-2 border-b h-16 shadow bg-gray-50"
              }
            >
              {removeQuestion && (
                <span className={"cursor-pointer text-red-500"} onClick={removeQuestion}>
                  <RemoveIcon />
                </span>
              )}
            </div>
          )}
          {subquestions.map(q => (
            <div className={"flex-1"} key={q.id}>
              <QuestionPreview
                questionId={q.id}
                parentPath={
                  idx !== undefined
                    ? [...parentPath, "value", idx]
                    : [...parentPath, ...(question.multipleAnswers ? ["value", 0] : [])]
                }
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export function QuestionPreview({
  questionId,
  parentPath = [],
}: {
  questionId: string;
  parentPath?: (string | number)[];
}) {
  const { isGhost, canAddMoreAnswers, isContainerQuestion, value, answer, subquestions, canRemoveAnswers, question } =
    useQuestionAnswer(questionId, parentPath);

  if (isGhost || !question) {
    return <></>;
  }

  return (
    <div
      className={classNames("rounded  flex flex-col flex-1 min-w-[400px] top-0", {
        "max-w-[420px] w-min py-2 ": !question.multipleAnswers,
        "w-max py-2 ": question.multipleAnswers,
        "absolute py-0 max-w-[420px]": question.inputType === InputType.Header,
      })}
    >
      <div className={"flex items-center gap-4"}>
        {question.inputType !== InputType.Header && (
          <h3 className={"text-lg font-semibold text-wrap w-max"}>{question.label}</h3>
        )}
        {!!question.tooltip && (
          <Tooltip content={question.tooltip}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='size-5'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z'
              />
            </svg>
          </Tooltip>
        )}
      </div>
      <p className={"text-gray-700 text-base"}>{question.subText}</p>

      {!isContainerQuestion && (
        <div className={"flex flex-col mt-1"}>
          <ValuePreview me={questionId} parentPath={parentPath} />
        </div>
      )}

      {isContainerQuestion && (
        <div
          className={classNames("flex gap-4", {
            "justify-evenly overflow-x-auto max-w-[100%]": question.answerDisplay === DisplayDirection.Row,
            "flex-col": question.answerDisplay === DisplayDirection.Col,
            "flex-wrap gap-y-1 gap-x-8": question.answerDisplay === DisplayDirection.RowWrap,
          })}
        >
          <>
            {subquestions.map((v, i) => (
              <div className={"border border-gray-200 rounded w-fit relative h-fit"}>
                <SubQuestionPreview
                  key={JSON.stringify(v)}
                  parentPath={[...parentPath, questionId]}
                  subquestions={v}
                  idx={i}
                  question={question}
                  removeQuestion={canRemoveAnswers ? cb(answer(i), null) : undefined}
                />
              </div>
            ))}
          </>

          {canAddMoreAnswers && (
            <div
              className={"bg-gray-100 w-24 flex justify-center items-center cursor-pointer"}
              onClick={cb(answer(Array.isArray(value?.value) ? value?.value.length || 0 : 0), {} as never)}
            >
              <AddIcon className={"h-8 w-8"} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
