import useAsyncFn from "../../utils/useAsyncFn";
import { getCoverage } from "../../http/app";
import { useEffect } from "react";
import { Modal } from "../util/Modal";

export const Coverages = ({ applicationId, quoteStateId }: { applicationId: string; quoteStateId: string }) => {
  const { exec, loading, data } = useAsyncFn(getCoverage);

  useEffect(() => {
    if (data?.ok) {
      console.log(data);
    }
  }, [data]);

  useEffect(() => {
    exec(applicationId, quoteStateId);
  }, [exec, applicationId, quoteStateId]);

  return (
    <Modal isOpen={true} onClose={() => window.location.reload()}>
      <div
        className={"bg-sky-500 px-4 py-1 mb-4 w-64 rounded text-white cursor-pointer shadow text-center"}
        onClick={() => exec(applicationId, quoteStateId)}
      >
        Refresh
      </div>

      {loading && <div>Fetching new data</div>}
      {!loading && data?.ok && <div>Current Status: {data.data.status}</div>}
      {!loading && data?.ok && (
        <pre className={"text-sm max-h-[80vh] overflow-y-auto"}>
          <code>{JSON.stringify(data.data.submissions, null, 2)}</code>
        </pre>
      )}
    </Modal>
  );
};
