import React, { useState, useRef, useEffect } from "react";
import { cb } from "../../utils/cd";

interface TooltipProps {
  children: React.ReactNode;
  content?: string | null;
  position?: "top" | "right" | "bottom" | "left"; // Default is 'top'
}

const Tooltip: React.FC<TooltipProps> = ({ children, content, position = "top" }) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const trigger = triggerRef.current;
    const tooltip = tooltipRef.current;

    if (!trigger || !tooltip || !content) return;
  }, [content]);

  return (
    <div
      ref={triggerRef}
      className='relative inline-block'
      onMouseEnter={cb(setIsVisible, true)}
      onMouseLeave={cb(setIsVisible, false)}
    >
      {children}

      {content && isVisible && (
        <div
          ref={tooltipRef}
          // className={`absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm  ${
          //   position === "top" && "-top-6 left-1/2 transform -translate-x-1/2"
          // } ${position === "right" && "top-1/2 -right-6 transform -translate-y-1/2"} ${
          //   position === "bottom" && "-bottom-6 left-1/2 transform -translate-x-1/2"
          // } ${position === "left" && "top-1/2 -left-6 transform -translate-y-1/2"}`}
          className={"bg-black p-4 rounded-xl text-white w-96 absolute z-50 transition-all"}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
