import { useEffect, useState } from "react";
import { CommonValidationEnum } from "../../utils/commonValidations";
import DateValidation from "./DateValidation";
import NumberValidation from "./NumberValidation";
import StringValidation from "./StringValidation";

type ValidationArgumentsProps = {
  onChange: (v: ValidationData) => void;
  onClick: () => void;
  data: ValidationData;
};

export type StringData = {
  name: CommonValidationEnum.String;
  params: {
    selectType: "Min Max" | "Exact Length";
    min?: number;
    max?: number;
    length: number;
  };
};

export type NumberData = {
  name: CommonValidationEnum.Number;
  params: {
    selectType: "Min Max Digits" | "Exact Length" | "Min Max";
    min?: number;
    max?: number;
    length: number;
  };
};

export type DateData = {
  name: CommonValidationEnum.Date;

  params: {
    amount: number;
    unit: "year" | "month" | "day" | "";
    selectType:
      | "Any Past Date"
      | "Any Future Date"
      | "Custom Date Range"
      | "Custom Date Before"
      | "Custom Date After"
      | "";
    selectWhenFrom: "Today" | "Before Today" | "After Today" | "";
    selectWhenTo: "Today" | "Before Today" | "After Today" | "";
    amountFrom?: number;
    unitFrom?: "year" | "month" | "day" | "";
    amountTo?: number;
    unitTo?: "year" | "month" | "day" | "";
    selectWhen1: "";
  };
};

export type ValidationData = DateData | NumberData | StringData;

const ValidationArguments: React.FC<ValidationArgumentsProps> = ({ data, onChange, onClick }) => {
  const [inputErr, setInputErr] = useState<{ [key: string]: string }>({});
  const [isOn, setIsOn] = useState<boolean>(false);
  const [isOn2, setIsOn2] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const handleToggle = () => {
    setIsOn(!isOn);
  };

  useEffect(() => {
    validateInput();
  }, [data.params]);

  const validateInput = () => {
    const newErrors: { [key: string]: string } = {};

    if (
      data &&
      (data.name === CommonValidationEnum.Number || data.name === CommonValidationEnum.String) &&
      "min" in data.params &&
      "max" in data.params
    ) {
      if (!data.params.min) newErrors.min = "This field is required";
      if (!data.params.max) newErrors.max = "This field is required";
      if (isNaN(Number(data.params.min))) newErrors.min = "Numbers only";
      if (isNaN(Number(data.params.max))) newErrors.max = "Numbers only";
      if (Number(data.params.min) < 0) newErrors.min = "Min cannot be negative";
      if (Number(data.params.max) < 0) newErrors.max = "Max cannot be negative";
      if (isOn === false && Number(data.params.min) > Number(data.params.max)) {
        newErrors.max = "Max must be greater than Min";
      }

      if (
        data &&
        (data.name === CommonValidationEnum.Number || data.name === CommonValidationEnum.String) &&
        "length" in data.params
      ) {
        if (!data.params.length) newErrors.length = "This field is required";
        if (isNaN(Number(data.params.length))) newErrors.length = "Numbers only";
        if (Number(data.params.length) < 0) newErrors.length = "Amount cannot be negative";
      }
    }

    if (data && data.name === CommonValidationEnum.Date) {
      if (!data.params.selectType) newErrors.selectType = "Please select a validation type";
      if (data.params.selectType === "Custom Date Range") {
        if (data.params.selectWhenFrom === "Today" && data.params.selectWhenTo === "Today")
          newErrors.selectWhenTo = "Both the start date and end date cannot be set to 'Today'";
        if (!data.params.selectWhenFrom) newErrors.selectWhenFrom = "Please select a 'From' date condition";
        if (!data.params.selectWhenTo) newErrors.selectWhenTo = "Please select a 'To' date condition";
        if (data.params.selectWhenTo === "Today" && !data.params.amountFrom)
          newErrors.amountFrom = "This field is required";
        if (data.params.selectWhenFrom === "Today" && !data.params.amountTo)
          newErrors.amountTo = "This field is required";
        if (data.params.selectWhenTo === "Today" && data.params.selectWhenFrom !== "Today" && !data.params.unitFrom)
          newErrors.unitFrom = "This field is required";
        if (data.params.selectWhenFrom === "Today" && data.params.selectWhenTo !== "Today" && !data.params.unitTo)
          newErrors.unitTo = "This field is required";
        if (data.params.selectWhenTo !== "Today" && !data.params.unitTo) newErrors.unitTo = "This field is required";
        if (data.params.selectWhenFrom !== "Today" && !data.params.unitFrom)
          newErrors.unitFrom = "This field is required";
        if (data.params.selectWhenTo !== "Today" && !data.params.amountTo)
          newErrors.amountTo = "This field is required";
        if (data.params.selectWhenFrom !== "Today" && !data.params.amountFrom)
          newErrors.amountFrom = "This field is required";

        if (isNaN(Number(data.params.amountFrom))) newErrors.amountFrom = "Numbers only";
        if (isNaN(Number(data.params.amountTo))) newErrors.amountTo = "Numbers only";
        if (Number(data.params.amountFrom) < 0) newErrors.amountFrom = "Min cannot be negative";
        if (Number(data.params.amountTo) < 0) newErrors.amountTo = "Max cannot be negative";
      }
      if (data.params.selectType === "Custom Date Before" || data.params.selectType === "Custom Date After") {
        if (!data.params.amount) newErrors.amount = "This field is required";
        if (isNaN(Number(data.params.amount))) newErrors.amount = "Numbers only";
        if (Number(data.params.amount) < 0) newErrors.amount = "Min cannot be negative";
      }
    }

    setInputErr(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (key: string) => (value: string) => {
    setIsSubmitted(false);
    onChange({
      name: data.name,

      params: {
        ...data.params,
        [key]: value,
      },
    } as ValidationData);
  };

  const handleOnClick = () => {
    setIsSubmitted(true);
    if (validateInput()) {
      if (isOn && !isOn2 && "max" in data.params) {
        delete data.params.max;
      } else if (isOn && isOn2 && "min" in data.params) {
        delete data.params.min;
      }
      onClick();
    }
  };

  switch (data.name) {
    case CommonValidationEnum.Date:
      return (
        <DateValidation
          data={data}
          isSubmitted={isSubmitted}
          inputErr={inputErr}
          handleChange={handleChange}
          handleOnClick={handleOnClick}
        />
      );

    case CommonValidationEnum.String:
      return (
        <StringValidation
          data={data}
          isSubmitted={isSubmitted}
          inputErr={inputErr}
          handleChange={handleChange}
          handleOnClick={handleOnClick}
          isOn={isOn}
          isOn2={isOn2}
          handleToggle={handleToggle}
          setIsOn2={setIsOn2}
        />
      );

    case CommonValidationEnum.Number:
      return (
        <NumberValidation
          data={data}
          isSubmitted={isSubmitted}
          inputErr={inputErr}
          handleChange={handleChange}
          handleOnClick={handleOnClick}
          isOn={isOn}
          isOn2={isOn2}
          handleToggle={handleToggle}
          setIsOn2={setIsOn2}
        />
      );

    default:
      return <>Invalid Validation</>;
  }
};

export default ValidationArguments;
