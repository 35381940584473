import { preventDefault } from "../../../utils/preventDefault";
import classNames from "classnames";
import { QuestionActionKind, QuestionVisibility } from "@Savus-Inc/questionnaire-types";

export function GroupVisibilityToggle({
  onChange,
  value,
}: {
  onChange: (v: QuestionVisibility) => void;
  value: QuestionVisibility | { kind: QuestionActionKind; value: string } | null;
}) {
  return (
    <div className={"flex items-center mr-2 ml-2 gap-3"}>
      <div className={"flex"}>
        <button
          onClick={preventDefault(() => onChange(QuestionVisibility.ReadOnly))}
          className={classNames("rounded-r border border-gray-300 py-1 w-24 text-center cursor-pointer", {
            "bg-gray-600 text-white": QuestionVisibility.ReadOnly === value,
          })}
        >
          {QuestionVisibility.ReadOnly}
        </button>
        <button
          onClick={preventDefault(() => onChange(QuestionVisibility.Hidden))}
          className={classNames("rounded-r border border-gray-300 py-1 w-24 text-center cursor-pointer", {
            "bg-gray-600 text-white": QuestionVisibility.Hidden === value,
          })}
        >
          {QuestionVisibility.Hidden}
        </button>

        <button
          onClick={preventDefault(() => onChange(QuestionVisibility.Show))}
          className={classNames("rounded-r border border-gray-300 py-1 w-24 text-center cursor-pointer", {
            "bg-gray-600 text-white": QuestionVisibility.Show === value,
          })}
        >
          {QuestionVisibility.Show}
        </button>
      </div>
    </div>
  );
}
