import React, { useEffect, useMemo, useReducer } from "react";
import { QuestionGroups } from "./QuestionGroups";
import { cb } from "../../utils/cd";
import { BuilderNGNProvider, useBuilderNGN } from "./BuilderNGN";
import { useNavigate, useParams } from "react-router-dom";
import { NoData } from "../util/Empty";
import { CarrierMapping } from "../CarrierMapping/CarrierMapping";
import classNames from "classnames";
import { AccordionItem, TitleWithAction } from "../util/Accourdion";
import { QuestionnaireHeader } from "./QuestionnaireHeader";
import { QuestionnaireDetails } from "./QuestionnaireDetails";
import { QuestionsGroupItem } from "./QuestionsGroupQuestions";
import { useAppState } from "../../state/State";
import { Modal } from "../util/Modal";
import { Spotlight } from "./Spotlight";
import { SelectAutocomplete } from "../util/SelectAutocomplete";
import { CarrierMapItems } from "./CarrierMapItems";

const QuestionnaireBuilder = () => {
  const { item, groupPartActive, activateGroupPart, insertGroup, editQuestionnaireDetails } = useBuilderNGN();
  const navigate = useNavigate();
  const [spotlight, toggleSpotlight] = useReducer(p => !p, false);
  const {
    state: { products: productsRes },
  } = useAppState();

  const products = useMemo(
    () => productsRes.map(v => ({ label: `${v.name} (${v.shortName})`, value: v.id })),
    [productsRes],
  );

  const selectedProduct = useMemo(() => {
    return products.find(v => v.value === item.lineOfBusinessId)?.label;
  }, [item, products]);

  return (
    <div className={"flex flex-col"}>
      <div className={"w-full flex flex-col gap-2"}>
        <QuestionnaireHeader back={() => navigate("/questionnaire")} item={item} preview={() => navigate("preview")} />

        <div className={"flex gap-2"}>
          <div
            className={
              "min-w-[360px] w-[360px] border-r border-r-sky-200 px-1 flex-col items-center gap-1 bg-white rounded shadow overflow-auto h-[93vh]"
            }
          >
            <div className={"w-full pb-2 border-b-2 border-sky-300 mb-4"}>
              <label htmlFor='name' className='w-full font-semibold'>
                Associated Product:
              </label>
              <SelectAutocomplete
                suggestions={products}
                onSelect={editQuestionnaireDetails("lineOfBusinessId")}
                value={selectedProduct || "Please select associated product"}
              />
            </div>
            <div className={"flex items-center"}>
              <button
                className={classNames(
                  "w-full flex justify-between items-center px-2 p-0.5 my-1 border-b border-purple-100 focus:outline-none font-bold",
                  {
                    "bg-gray-100 text-gray-800": groupPartActive === "q-details",
                    "text-gray-700": groupPartActive !== "q-details",
                  },
                )}
                onClick={cb(activateGroupPart, { groupPart: "q-details" })}
              >
                Questionnaire Details
              </button>
              <div className={"text-gray-700 cursor-pointer"} onClick={toggleSpotlight}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='size-5'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z'
                  />
                </svg>
              </div>
            </div>
            <AccordionItem
              title={<TitleWithAction title={<span className={"font-bold"}>Pages</span>} add={insertGroup} />}
              selected={groupPartActive.startsWith("g-")}
              initial={true}
            >
              {item.groups
                .sort((a, b) => a.partOrder - b.partOrder)
                .map(q => (
                  <QuestionsGroupItem group={q} groupId={q.id} />
                ))}
            </AccordionItem>

            {item.lineOfBusinessId ? (
              <AccordionItem
                title={<TitleWithAction title={<span className={"font-bold"}>Carrier Mapping</span>} />}
                selected={groupPartActive.startsWith("c-")}
                initial={groupPartActive.startsWith("c-")}
              >
                <CarrierMapItems />
              </AccordionItem>
            ) : (
              <NoData message={"Questionnaire must be associated to the product in order to access carrier mapping"} />
            )}
          </div>
          <div className={"flex flex-col w-full"}>
            {groupPartActive === "q-details" ? (
              <QuestionnaireDetails />
            ) : groupPartActive.startsWith("c-") ? (
              item.lineOfBusinessId ? (
                <CarrierMapping />
              ) : (
                <NoData
                  message={"Questionnaire must be associated to the product in order to access carrier mapping"}
                />
              )
            ) : (
              <QuestionGroups />
            )}
          </div>
        </div>
      </div>
      <Modal isOpen={spotlight} onClose={toggleSpotlight}>
        <Spotlight close={toggleSpotlight} />
      </Modal>
    </div>
  );
};

export const QuestionnaireEditor = () => {
  const { id } = useParams();
  const {
    state: { currentQuestionnaire },
    get,
  } = useAppState();

  useEffect(() => {
    id && get(id);
  }, []);

  if (!currentQuestionnaire?.id) {
    return (
      <div>
        <NoData message={"Questionnaire not found"} />
      </div>
    );
  }

  return (
    <BuilderNGNProvider questionnaire={currentQuestionnaire}>
      <QuestionnaireBuilder />
    </BuilderNGNProvider>
  );
};
