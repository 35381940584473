import { ComplexOperator, Rule, ValueFunctions } from "@Savus-Inc/dsl/dist/types";
import {
  ValidationAlertLevel,
  QuestionValidityAction,
  RuleGroupEntity,
  RuleGroupKind,
} from "@Savus-Inc/questionnaire-types";
import { v4 } from "uuid";
import { ValidationData } from "../components/Questionaire/ValidationArguments";

export enum CommonValidationEnum {
  DefineNew = "Define New",
  Required = "Required",
  AlphaNumeric = "Alpha Numeric",
  AddressInputCharacters = "Address Input Characters",
  AddressZipCodeFormat = "Address Zip Code Format ",
  NumbersOnly = "Numbers Only",
  YearFormat = "Year Format",
  PercentageFields = "Percentage Fields",
  CurrencyFields = "Currency Fields",
  TextareaFields = "Textarea Fields",
  String = "String",
  Number = "Number",
  Date = "Date",
}
export const modalCommonValidations = {
  String: "String",
  Number: "Number",
  Date: "Date",
};

export enum RuleGroupEnum {
  Future = "Future",
  Past = "Past",
  Range = "Range",
}

export enum StringTypeEnum {
  MinMax = "Min Max",
  ExactLength = "Exact Length",
}

export enum NumberTypeEnum {
  MinMaxDigits = "Min Max Digits",
  ExactLength = "Exact Length",
  MinMax = "Min Max",
}

export enum DateTypeEnum {
  AnyPastDate = "Any Past Date",
  AnyFutureDate = "Any Future Date",
  CustomDateRange = "Custom Date Range",
  CustomDateBefore = "Custom Date Before",
  CustomDateAfter = "Custom Date After",
}

export enum DateWhenEnum {
  Today = "Today",
  AfterToday = "After Today",
  BeforeToday = "Before Today",
}

const validationPatterns = {
  Required: "True",
  AlphaNumeric: "^[a-zA-Z]+$",
  AddressZipCodeFormat: "^.{5,5}$",
  YearFormat: "^.{4,4}$",
  NumbersOnly: "^[d+ ]*$",
  AddressInputCharacters: "^[a-zA-Z0-9s, '#/.-]+(s+[a-zA-Z0-9s,'#/.-]+)*$",
  PercentageFields: "^.{0,3}$",
  CurrencyFields: "^.{0,10}$",
  TextareaFields: "^.{0,250}$",
};

export const resolveValidationGroup = (
  questionId: string,
  validation: CommonValidationEnum,
  modalArguments?: ValidationData,
): RuleGroupEntity<QuestionValidityAction> => {
  let rules: Rule[] = [];
  let errorMessage: string = "";
  let warning = false;

  switch (validation) {
    case CommonValidationEnum.Required:
      rules.push({
        id: v4(),
        value: {
          fn: ValueFunctions.Unanswered,
          args: [{ ref: questionId }, validationPatterns.Required],
        },
        op: ComplexOperator.Standalone,
        not: false,
      });
      errorMessage = "Required";
      break;

    case CommonValidationEnum.AlphaNumeric:
      rules.push({
        id: v4(),
        value: {
          fn: ValueFunctions.RegExpMatches,
          args: [{ ref: questionId }, validationPatterns.AlphaNumeric],
        },
        op: ComplexOperator.Standalone,
        not: true,
      });
      errorMessage = "Invalid characters.";
      break;

    case CommonValidationEnum.YearFormat:
      rules.push({
        id: v4(),
        value: {
          fn: ValueFunctions.RegExpMatches,
          args: [{ ref: questionId }, validationPatterns.YearFormat],
        },
        op: ComplexOperator.Standalone,
        not: true,
      });
      errorMessage = "Invalid year format.";
      break;

    case CommonValidationEnum.AddressZipCodeFormat:
      rules.push({
        id: v4(),
        value: {
          fn: ValueFunctions.RegExpMatches,
          args: [{ ref: questionId }, validationPatterns.AddressZipCodeFormat],
        },
        op: ComplexOperator.Standalone,
        not: true,
      });
      errorMessage = "Invalid zip code format.";
      break;

    case CommonValidationEnum.NumbersOnly:
      rules.push({
        id: v4(),
        value: {
          fn: ValueFunctions.RegExpMatches,
          args: [{ ref: questionId }, validationPatterns.NumbersOnly],
        },
        op: ComplexOperator.Standalone,
        not: true,
      });
      errorMessage = "Numbers only.";
      break;

    case CommonValidationEnum.AddressInputCharacters:
      rules.push({
        id: v4(),
        value: {
          fn: ValueFunctions.RegExpMatches,
          args: [{ ref: questionId }, validationPatterns.AddressInputCharacters],
        },
        op: ComplexOperator.Standalone,
        not: true,
      });
      errorMessage = "Invalid characters or symbols.";
      break;

    case CommonValidationEnum.PercentageFields:
      rules.push({
        id: v4(),
        value: {
          fn: ValueFunctions.RegExpMatches,
          args: [{ ref: questionId }, validationPatterns.PercentageFields],
        },
        op: ComplexOperator.Standalone,
        not: true,
      });
      errorMessage = "Digit limit is 3.";
      break;

    case CommonValidationEnum.CurrencyFields:
      rules.push({
        id: v4(),
        value: {
          fn: ValueFunctions.RegExpMatches,
          args: [{ ref: questionId }, validationPatterns.CurrencyFields],
        },
        op: ComplexOperator.Standalone,
        not: true,
      });
      errorMessage = "Digit limit is 10.";
      break;

    case CommonValidationEnum.TextareaFields:
      rules.push({
        id: v4(),
        value: {
          fn: ValueFunctions.RegExpMatches,
          args: [{ ref: questionId }, validationPatterns.TextareaFields],
        },
        op: ComplexOperator.Standalone,
        not: true,
      });
      errorMessage = "250 character limit.";
      break;

    case CommonValidationEnum.Date:
      if (modalArguments && "selectType" in modalArguments.params) {
        const { selectType } = modalArguments.params;

        switch (selectType) {
          case DateTypeEnum.AnyFutureDate:
            rules.push({
              id: v4(),
              value: {
                fn: ValueFunctions.Before,
                args: [{ ref: questionId }, { fn: ValueFunctions.Today, args: [] }],
              },
              op: ComplexOperator.Standalone,
              not: false,
            });
            errorMessage = "Date must be in the future.";
            break;

          case DateTypeEnum.AnyPastDate:
            rules.push({
              id: v4(),
              value: {
                fn: ValueFunctions.After,
                args: [{ ref: questionId }, { fn: ValueFunctions.Today, args: [] }],
              },
              op: ComplexOperator.Standalone,
              not: false,
            });
            errorMessage = "Date must be in the past.";
            break;

          case DateTypeEnum.CustomDateRange:
            let amount;
            let unit;

            if (
              (modalArguments.params.selectWhenFrom === DateWhenEnum.Today &&
                modalArguments.params.selectWhenTo === DateWhenEnum.AfterToday) ||
              (modalArguments.params.selectWhenFrom === DateWhenEnum.AfterToday &&
                modalArguments.params.selectWhenTo === DateWhenEnum.Today)
            ) {
              if (modalArguments.params.selectWhenFrom === DateWhenEnum.Today) {
                amount = modalArguments.params.amountTo;
                unit = modalArguments.params.unitTo;
              } else {
                amount = modalArguments.params.amountFrom;
                unit = modalArguments.params.unitFrom;
              }

              rules.push(
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.After,
                    args: [{ ref: questionId }, { fn: ValueFunctions.Today, args: [] }],
                  },
                  op: ComplexOperator.Standalone,
                  not: false,
                },
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.Before,
                    args: [
                      { ref: questionId },
                      {
                        fn: ValueFunctions.DateAdd,
                        args: [{ fn: ValueFunctions.Today, args: [] }, `${amount}`, `${unit}`],
                      },
                    ],
                  },
                  op: ComplexOperator.And,
                  not: false,
                },
              );

              errorMessage = `Start date cannot be more than ${amount} ${unit}/s in the future.`;
            } else if (
              (modalArguments.params.selectWhenFrom === DateWhenEnum.Today &&
                modalArguments.params.selectWhenTo === DateWhenEnum.BeforeToday) ||
              (modalArguments.params.selectWhenFrom === DateWhenEnum.BeforeToday &&
                modalArguments.params.selectWhenTo === DateWhenEnum.Today)
            ) {
              if (modalArguments.params.selectWhenFrom === DateWhenEnum.Today) {
                amount = modalArguments.params.amountTo;
                unit = modalArguments.params.unitTo;
              } else {
                amount = modalArguments.params.amountFrom;
                unit = modalArguments.params.unitFrom;
              }
              rules.push(
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.After,
                    args: [{ ref: questionId }, { fn: ValueFunctions.Today, args: [] }],
                  },
                  op: ComplexOperator.Standalone,
                  not: false,
                },
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.After,
                    args: [
                      { ref: questionId },
                      {
                        fn: ValueFunctions.DateSubtract,
                        args: [{ fn: ValueFunctions.Today, args: [] }, `${amount}`, `${unit}`],
                      },
                    ],
                  },
                  op: ComplexOperator.And,
                  not: false,
                },
              );
              errorMessage = `Start date cannot be more than ${modalArguments.params.amountTo} ${modalArguments.params.unitTo}/s in the past.`;
            } else if (
              (modalArguments.params.selectWhenFrom === DateWhenEnum.AfterToday &&
                modalArguments.params.selectWhenTo === DateWhenEnum.BeforeToday) ||
              (modalArguments.params.selectWhenFrom === DateWhenEnum.BeforeToday &&
                modalArguments.params.selectWhenTo === DateWhenEnum.AfterToday)
            ) {
              rules.push(
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.Before,
                    args: [
                      { ref: questionId },
                      {
                        fn: ValueFunctions.DateAdd,
                        args: [
                          { fn: ValueFunctions.Today, args: [] },
                          `${modalArguments.params.amountFrom}`,
                          `${modalArguments.params.unitFrom}`,
                        ],
                      },
                    ],
                  },
                  op: ComplexOperator.Standalone,
                  not: false,
                },
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.After,
                    args: [
                      { ref: questionId },
                      {
                        fn: ValueFunctions.DateSubtract,
                        args: [
                          { fn: ValueFunctions.Today, args: [] },
                          `${modalArguments.params.amountTo}`,
                          `${modalArguments.params.unitTo}`,
                        ],
                      },
                    ],
                  },
                  op: ComplexOperator.And,
                  not: false,
                },
              );
              errorMessage = `Date must be in the range of up to ${modalArguments.params.amountFrom} ${modalArguments.params.unitFrom}/s in the future and ${modalArguments.params.amountTo} ${modalArguments.params.unitTo}/s in the past.`;
            } else if (
              modalArguments.params.selectWhenFrom === DateWhenEnum.AfterToday &&
              modalArguments.params.selectWhenTo === DateWhenEnum.AfterToday
            ) {
              rules.push(
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.Before,
                    args: [
                      { ref: questionId },
                      {
                        fn: ValueFunctions.DateAdd,
                        args: [
                          { fn: ValueFunctions.Today, args: [] },
                          `${modalArguments.params.amountFrom}`,
                          `${modalArguments.params.unitFrom}`,
                        ],
                      },
                    ],
                  },
                  op: ComplexOperator.Standalone,
                  not: false,
                },
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.Before,
                    args: [
                      { ref: questionId },
                      {
                        fn: ValueFunctions.DateAdd,
                        args: [
                          { fn: ValueFunctions.Today, args: [] },
                          `${modalArguments.params.amountTo}`,
                          `${modalArguments.params.unitTo}`,
                        ],
                      },
                    ],
                  },
                  op: ComplexOperator.And,
                  not: false,
                },
              );
              errorMessage = `Date must be in the range of ${modalArguments.params.amountFrom} ${modalArguments.params.unitFrom}/s and ${modalArguments.params.amountTo} ${modalArguments.params.unitTo}/s in the future.`;
            }

            if (
              modalArguments.params.selectWhenFrom === DateWhenEnum.BeforeToday &&
              modalArguments.params.selectWhenTo === DateWhenEnum.BeforeToday
            ) {
              rules.push(
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.After,
                    args: [
                      { ref: questionId },
                      {
                        fn: ValueFunctions.DateSubtract,
                        args: [
                          { fn: ValueFunctions.Today, args: [] },
                          `${modalArguments.params.amountFrom}`,
                          `${modalArguments.params.unitFrom}`,
                        ],
                      },
                    ],
                  },
                  op: ComplexOperator.Standalone,
                  not: false,
                },
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.After,
                    args: [
                      { ref: questionId },
                      {
                        fn: ValueFunctions.DateSubtract,
                        args: [
                          { fn: ValueFunctions.Today, args: [] },
                          `${modalArguments.params.amountTo}`,
                          `${modalArguments.params.unitTo}`,
                        ],
                      },
                    ],
                  },
                  op: ComplexOperator.And,
                  not: false,
                },
              );
              errorMessage = `Date must be in the range of ${modalArguments.params.amountFrom} ${modalArguments.params.unitFrom}/s and ${modalArguments.params.amountTo} ${modalArguments.params.unitTo}/s in the past.`;
            }

            break;

          case DateTypeEnum.CustomDateAfter:
            rules.push({
              id: v4(),
              value: {
                fn: ValueFunctions.Before,
                args: [
                  { ref: questionId },
                  {
                    fn: ValueFunctions.DateAdd,
                    args: [
                      { fn: ValueFunctions.Today, args: [] },
                      `${modalArguments.params.amount}`,
                      `${modalArguments.params.unit}`,
                    ],
                  },
                ],
              },
              op: ComplexOperator.And,
              not: false,
            });
            errorMessage = `The selected date must be after ${modalArguments.params.amount} ${modalArguments.params.unit}/s from today.`;
            break;

          case DateTypeEnum.CustomDateBefore:
            rules.push({
              id: v4(),
              value: {
                fn: ValueFunctions.After,
                args: [
                  { ref: questionId },
                  {
                    fn: ValueFunctions.DateSubtract,
                    args: [
                      { fn: ValueFunctions.Today, args: [] },
                      `${modalArguments.params.amount}`,
                      `${modalArguments.params.unit}`,
                    ],
                  },
                ],
              },
              op: ComplexOperator.Standalone,
              not: false,
            });
            errorMessage = `The selected date must be before ${modalArguments.params.amount} ${modalArguments.params.unit}/s from today.`;

            break;
        }
      }
      break;

    case CommonValidationEnum.String:
      if (modalArguments && "selectType" in modalArguments.params) {
        const { selectType } = modalArguments.params;

        switch (selectType) {
          case StringTypeEnum.MinMax:
            if (modalArguments && "min" in modalArguments.params && "max" in modalArguments.params) {
              rules.push({
                id: v4(),
                value: {
                  fn: ValueFunctions.RegExpMatches,
                  args: [{ ref: questionId }, `^.{${modalArguments?.params.min},${modalArguments?.params.max}}$.`],
                },
                op: ComplexOperator.Standalone,
                not: true,
              });
              errorMessage = `The entered value must be at least ${modalArguments?.params.min} and no more than ${modalArguments?.params.max} characters long.`;
            } else if (modalArguments && "min" in modalArguments.params) {
              rules.push({
                id: v4(),
                value: {
                  fn: ValueFunctions.RegExpMatches,
                  args: [{ ref: questionId }, `^.{${modalArguments.params.min},}$.`],
                },
                op: ComplexOperator.Standalone,
                not: true,
              });
              errorMessage = `The entered value must be at least ${modalArguments.params.min} characters long.`;
            } else if (modalArguments && "max" in modalArguments.params) {
              rules.push({
                id: v4(),
                value: {
                  fn: ValueFunctions.RegExpMatches,
                  args: [{ ref: questionId }, `^.{0,${modalArguments.params.max}}$.`],
                },
                op: ComplexOperator.Standalone,
                not: true,
              });
              errorMessage = `The entered value must not be more than ${modalArguments.params.max} charaters long.`;
            }
            break;

          case StringTypeEnum.ExactLength:
            if (modalArguments && "length" in modalArguments.params) {
              rules.push({
                id: v4(),
                value: {
                  fn: ValueFunctions.RegExpMatches,
                  args: [{ ref: questionId }, `^.{${modalArguments.params.length}}$`],
                },
                op: ComplexOperator.Standalone,
                not: true,
              });
              errorMessage = `The entered value must exactly ${modalArguments?.params.length} characters long.`;
            }
            break;
        }
      }
      break;

    case CommonValidationEnum.Number:
      if (modalArguments && "selectType" in modalArguments.params) {
        const { selectType } = modalArguments.params;

        switch (selectType) {
          case NumberTypeEnum.MinMaxDigits:
            if (modalArguments && "min" in modalArguments.params && "max" in modalArguments.params) {
              rules.push({
                id: v4(),
                value: {
                  fn: ValueFunctions.RegExpMatches,
                  args: [{ ref: questionId }, `^\\d{${modalArguments?.params.min},${modalArguments?.params.max}}$.`],
                },
                op: ComplexOperator.Standalone,
                not: true,
              });
              errorMessage = `The entered value must be at least ${modalArguments?.params.min} and no more than ${modalArguments?.params.max} digits.`;
            } else if (modalArguments && "min" in modalArguments.params) {
              rules.push({
                id: v4(),
                value: {
                  fn: ValueFunctions.RegExpMatches,
                  args: [{ ref: questionId }, `^\\d{${modalArguments.params.min},}$.`],
                },
                op: ComplexOperator.Standalone,
                not: true,
              });
              errorMessage = `The entered value must be at least ${modalArguments.params.min} digits.`;
            } else if (modalArguments && "max" in modalArguments.params) {
              rules.push({
                id: v4(),
                value: {
                  fn: ValueFunctions.RegExpMatches,
                  args: [{ ref: questionId }, `^\\d{0,${modalArguments.params.max}}$.`],
                },
                op: ComplexOperator.Standalone,
                not: true,
              });
              errorMessage = `The entered value must not be more than ${modalArguments.params.max} digits.`;
            }
            break;

          case NumberTypeEnum.ExactLength:
            if (modalArguments && "length" in modalArguments.params) {
              rules.push({
                id: v4(),
                value: {
                  fn: ValueFunctions.RegExpMatches,
                  args: [{ ref: questionId }, `^\\d{${modalArguments.params.length}}$`],
                },
                op: ComplexOperator.Standalone,
                not: true,
              });
              errorMessage = `The entered value must exactly ${modalArguments?.params.length} digits.`;
            }
            break;

          case NumberTypeEnum.MinMax:
            if (modalArguments && "min" in modalArguments.params && "max" in modalArguments.params) {
              rules.push(
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.GreaterThan,
                    args: [{ ref: questionId }, `${modalArguments.params.min}`],
                  },
                  op: ComplexOperator.Standalone,
                  not: true,
                },
                {
                  id: v4(),
                  value: {
                    fn: ValueFunctions.LessThan,
                    args: [{ ref: questionId }, `${modalArguments.params.max}`],
                  },
                  op: ComplexOperator.And,
                  not: true,
                },
              );
              errorMessage = `The entered value must be at least ${modalArguments?.params.min} and no more than ${modalArguments?.params.max}`;
            } else if (modalArguments && "min" in modalArguments.params) {
              rules.push({
                id: v4(),
                value: {
                  fn: ValueFunctions.GreaterThan,
                  args: [{ ref: questionId }, `${modalArguments.params.min}`],
                },
                op: ComplexOperator.Standalone,
                not: true,
              });
              errorMessage = `The entered value must be at least ${modalArguments.params.min}.`;
            } else if (modalArguments && "max" in modalArguments.params) {
              rules.push({
                id: v4(),
                value: {
                  fn: ValueFunctions.LessThan,
                  args: [{ ref: questionId }, `${modalArguments.params.max}`],
                },
                op: ComplexOperator.Standalone,
                not: true,
              });
              errorMessage = `The entered value must be less than ${modalArguments.params.max}.`;
            }
            break;
        }
      }

      break;
  }

  return {
    id: v4(),
    name: validation,
    partOrder: 1,
    kind: RuleGroupKind.Validation,
    op: ComplexOperator.Standalone,
    actionKind: {
      message: errorMessage,
      alertLevel: warning ? ValidationAlertLevel.Warning : ValidationAlertLevel.Error,
    },
    rules: rules,
  };
};
