import { ApiResponse } from "../types/ApiResponse";
import { makeRequest } from "./makeRequest";

export type Credentials = Record<"username" | "password", string>;
export const login = async (credentials: Credentials): Promise<ApiResponse<Record<"token", string>>> => {
  try {
    return await makeRequest("post", `${process.env.REACT_APP_SERVER_URL}/auth/login`, credentials);
  } catch (e: any) {
    return {
      ok: false,
      error: e?.response?.data?.message || e?.message || "Unable to login",
    };
  }
};

export const me = async (token: string): Promise<ApiResponse<any>> => {
  try {
    const d = await makeRequest<any, unknown>("get", `${process.env.REACT_APP_SERVER_URL}/data/agency/user/me`);

    if (
      d.ok &&
      !(d.data?.accessRights.role.permissions || []).some((p: { kind: string }) => p.kind === "manage_referent_data")
    ) {
      return {
        ok: false,
        error: "Insufficient Access Rights",
      };
    }
    return d;
  } catch (e: any) {
    return {
      ok: false,
      error: e.message,
    };
  }
};
