import React, { PropsWithChildren, useEffect } from "react";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const Modal = ({ isOpen, onClose, children }: PropsWithChildren<ModalProps>) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className='fixed top-0 left-0 z-30 bg-gray-400 bg-opacity-50 w-screen h-screen overflow-y-auto flex flex-col items-center justify-center'>
      <div className='flex flex-col w-11/12 max-w-[720px] bg-white z-50 self-center cursor-pointer -mt-10 relative'>
        <div className='px-4 pt-5 pb-4 overflow-y-auto '>{children}</div>
        <div
          className='flex justify-end absolute top-6 right-8 transition-opacity gap-2 z-50 bg-white'
          onClick={onClose}
        >
          Close <span className={"font-extrabold"}>X</span>
        </div>
      </div>
    </div>
  );
};
