import {
  Questionnaire,
  QuestionnaireQuestionEntity,
  QuestionValidityAction,
  QuestionVisibility,
  RuleGroupEntity,
  RuleGroupKind,
} from "@Savus-Inc/questionnaire-types";
import { ComplexOperator, Op } from "@Savus-Inc/dsl/dist/types";
import { opToString, ruleGroupToString } from "@Savus-Inc/dsl/dist/utils/transformer";

const exportToCSV = (questionnaire: Questionnaire) => {
  function getRealIdx(question: QuestionnaireQuestionEntity) {
    return questionnaire.groups.flatMap(q => q.questions).findIndex(q => q.id === question.id);
  }

  const fieldMap: Record<string, string> = {};
  const questions: any[] = [];

  questionnaire.groups.map(group => {
    const gr = {
      questionnaire_name: questionnaire.name,
      title: questionnaire.title,
      product: questionnaire.lineOfBusiness?.shortName,
      subText: questionnaire.subText,
      pageId: `P-${group.partOrder}`,
      pageOrder: group.partOrder,
      pageTitle: group.title,
      pageSubText: group.subText,
      pageDisplay: group.displaySections,
    };

    group.questions.map(question => {
      if (question.question?.externalId) {
        fieldMap[question.id] = question.question?.externalId;
      }

      const section = group.sections?.find(s => s.id === question.sectionId);
      let q: any = {
        ...gr,
      };

      if (section) {
        q.sectionId = `S-${section.partOrder}`;
        q.sectionTitle = section.title;
        q.sectionDisplay = section.display;
        q.sectionOrder = section.partOrder;
      }

      const parent = group.questions.find(q => question.parentId === q.id);

      if (parent) {
        q.questionContainerId = parent.question?.externalId || `Q-${getRealIdx(parent)}`;
      }
      q = {
        ...q,
        questionId:
          question.question?.externalId ||
          `Q-TMP-${group.partOrder}-${
            section?.partOrder ? `${section.partOrder}-${question.partOrder}` : question.partOrder
          }`,
        questionOrder: question.partOrder,
        subQuestionDisplay: question?.question?.subQuestions,
        fieldLabel: question?.question?.label,
        fieldInputType: question?.question?.inputType,
        fieldMultipleAnswers: question?.question?.multipleAnswers,
        questionKind: question?.question?.kind,
        actionButton: question.question?.addMoreIcon,
        actionButtonText: question.question?.addMoreText,
        minAnswers: question?.question?.minAnswers,
        maxAnswers: question?.question?.maxAnswers,
        questionFormat: question?.question?.format,
        answerDisplay: question?.question?.answerDisplay,
        autocompleteIntegration: question?.question?.autocompleteIntegration,
        fieldPlaceholder: question?.question?.placeholder,
        fieldSubText: question?.question?.subText,
        fieldTooltip: question?.question?.tooltip,
      };

      const validationRuleGroups: RuleGroupEntity<QuestionValidityAction>[] = question.ruleGroups.filter(
        v => v.kind === RuleGroupKind.Validation,
      );
      const validationRuleGroupRoots: number[] = validationRuleGroups
        .map((v, i) => (v.op === ComplexOperator.Standalone ? i : null) as never)
        .filter(v => v !== null);

      const validations = validationRuleGroupRoots.reduce((p, v, i, t) => {
        const actionKind = validationRuleGroups[i].actionKind as QuestionValidityAction;

        const res = ruleGroupToString(
          validationRuleGroups.slice(v, t[i + 1]),
          fieldMap,
          `${actionKind.alertLevel}(${actionKind.message})`,
        );

        p.push(res);

        return p;
      }, [] as string[]);

      const visibilityRuleGroups: RuleGroupEntity<QuestionValidityAction>[] = question.ruleGroups.filter(
        v => v.kind === RuleGroupKind.Visibility,
      );
      const visibilityRuleGroupRoots: number[] = visibilityRuleGroups
        .map((v, i) => (v.op === ComplexOperator.Standalone ? i : null) as never)
        .filter(v => v !== null);

      const visibility = visibilityRuleGroupRoots.reduce((p, v, i, t) => {
        const actionKind = visibilityRuleGroups[i].actionKind as QuestionVisibility;
        const res = ruleGroupToString(visibilityRuleGroups.slice(v, t[i + 1]), fieldMap, actionKind);

        p.push(res);

        return p;
      }, [] as string[]);

      const prefillRuleGroups: RuleGroupEntity<QuestionValidityAction>[] = question.ruleGroups.filter(
        v => v.kind === RuleGroupKind.PreFill,
      );
      const prefillRuleGroupRoots: number[] = prefillRuleGroups
        .map((v, i) => (v.op === ComplexOperator.Standalone ? i : null) as never)
        .filter(v => v !== null);

      const prefill = prefillRuleGroupRoots.reduce((p, v, i, t) => {
        const actionKind = prefillRuleGroups[i].actionKind as Op;
        const res = ruleGroupToString(prefillRuleGroups.slice(v, t[i + 1]), fieldMap, opToString(actionKind));

        p.push(res);

        return p;
      }, [] as string[]);

      q.validation = validations.join(";");
      q.visibility = visibility.join(";");
      q.prefill = prefill.join(";");

      if (question?.question?.values?.length) {
        question?.question?.values.forEach(value => {
          const visibilityRuleGroupRoots: number[] | undefined = value.visibility
            ?.map((v, i) => (v.op === ComplexOperator.Standalone ? i : null) as never)
            .filter(v => v !== null);

          const visibility = visibilityRuleGroupRoots?.reduce((p, v, i, t) => {
            const actionKind = (value.visibility || [])[i].actionKind as QuestionVisibility;
            const res = ruleGroupToString((value.visibility || []).slice(v, t[i + 1]), fieldMap, actionKind);

            p.push(res);

            return p;
          }, [] as string[]);

          questions.push({
            ...q,
            answerOrder: value.partOrder,
            answerValue: value.value.value,
            answerLabel: value.label,
            valueId: `V-${value.partOrder}`,
            valueVisibility: visibility?.join(";"),
          });
        });
      } else {
        questions.push(q);
      }

      return questions;
    });

    return gr;
  });

  return questions;
};

export default exportToCSV;
