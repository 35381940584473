// noinspection RegExpRedundantEscape

import { Tag } from "../Rules/Tag";
import { remove } from "../../utils/arr-utils";
import { Suggestion } from "../util/Autocomplete";
import React, { useCallback, useMemo, useState } from "react";
import { AddIcon } from "../util/AddNew";
import { CopyAction, RemoveIcon } from "../util/ItemActions";
import { getAllRefs } from "../Rules/utils/getAvailableRefs";
import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import { Editor } from "../util/Editor";
import {
  getInterpolatedRefId,
  getTextRefParts,
  isRefInterpolated,
} from "@Savus-Inc/runtime-ngn/dist/utils/get-text-ref-parts";
import { transformPathVal } from "../Questionaire/UploadMapping";

const NodeValue = ({
  initial,
  onConfirm,
  bg,
  onRemove,
  suggestions,
}: {
  initial: string;
  bg: string;
  onConfirm: (v: string) => void;
  onRemove: () => void;
  suggestions: Suggestion[];
}) => {
  const [value, setValue] = useState(initial || "");
  const [editable, setEditable] = useState(!initial);

  const confirm = useCallback(() => {
    onConfirm(value);
    setEditable(false);
  }, [value, onConfirm]);

  const finalValue = useMemo(() => {
    return getTextRefParts(initial)
      .map(v => {
        if (isRefInterpolated(v)) {
          const refId = getInterpolatedRefId(v);
          const ref = suggestions.find(v => v.value === refId);

          if (!ref) {
            return v;
          }

          return ref.label;
        } else {
          return v;
        }
      })
      .join("");
  }, [initial, suggestions]);

  return (
    <div>
      {initial && !editable && (
        <div className={"flex cursor-text"} onClick={() => setEditable(true)}>
          <Tag label={finalValue} onRemove={onRemove} color={bg} />
        </div>
      )}
      {editable && (
        <div className={"flex min-w-32"}>
          <Editor initial={value} onChange={v => setValue(v)} refs={suggestions} />
          {value && (
            <div
              className={"bg-green-600 py-0.5 px-2 rounded text-xs text-white flex items-center cursor-pointer"}
              onClick={confirm}
            >
              set
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export const nodesBg = ["bg-black", "bg-sky-500", "bg-orange-500", "bg-purple-500", "bg-green-500"];
export const JsonNodeValue = ({
  item,
  items,
  onChange,
}: {
  item: { jsonNode: string[][] };
  items: { jsonNode: string[][] }[];
  onChange: (v: string[][]) => void;
}) => {
  const { context } = useBuilderNGN();

  const suggestions = useMemo(
    () => getAllRefs(context()).map(v => ({ label: v.label, value: v.referenceId })),
    [context],
  );

  return (
    <div className={"flex flex-col gap-2"} key={item.jsonNode.flat().join("")}>
      {(item.jsonNode || [[]]).map((jsonNode, ni) => (
        <div className={"flex flex-wrap gap-1 items-center text-center w-full"} key={jsonNode.join(".")}>
          {jsonNode?.map((nd, i) => (
            <NodeValue
              key={`${nd}-${i}`}
              initial={nd}
              onRemove={() => {
                remove(jsonNode, i);
                onChange(item.jsonNode);
              }}
              onConfirm={v => {
                jsonNode[i] = v;
                onChange(item.jsonNode);
              }}
              suggestions={suggestions}
              bg={nodesBg[i] || "bg-black"}
            />
          ))}
          <NodeValue
            initial={""}
            key={jsonNode.length}
            onRemove={() => {}}
            onConfirm={v => {
              if (
                // eslint-disable-next-line
                v.split(/[,\.]/).length > 1 &&
                window.confirm("Detected coma or dot separated values, Do you wish to split them?")
              ) {
                // eslint-disable-next-line
                jsonNode.push(...v.split(/[,\.]/).flatMap(transformPathVal));
                onChange(item.jsonNode);
              } else {
                jsonNode.push(v);
                onChange(item.jsonNode);
              }
            }}
            suggestions={suggestions}
            bg={"bg-black"}
          />
          <div
            className={"relative text-red-500"}
            onClick={() => {
              remove(item.jsonNode, ni);
              onChange(item.jsonNode);
            }}
          >
            <RemoveIcon />
          </div>
          <CopyAction value={jsonNode.join(".")} />
        </div>
      ))}
      <div
        className={
          "flex gap-2 items-center text-center bg-black text-white rounded text-sm py-0.5 w-fit px-4 cursor-pointer"
        }
        onClick={() => {
          item.jsonNode.push([]);
          onChange(item.jsonNode);
        }}
      >
        <AddIcon /> Add New Node
      </div>
    </div>
  );
};
