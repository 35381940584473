import { useBuilderNGN } from "./BuilderNGN";
import { useAppState } from "../../state/State";
import { useCallback, useMemo } from "react";
import { cb } from "../../utils/cd";
import { Tag } from "../Rules/Tag";
import { nodesBg } from "../CarrierMapping/JsonNodeValue";
import {
  getInterpolatedRefId,
  getTextRefParts,
  isRefInterpolated,
} from "@Savus-Inc/runtime-ngn/dist/utils/get-text-ref-parts";
import { getAllRefs } from "../Rules/utils/getAvailableRefs";

export const QuestionMapping = () => {
  const { selectedQuestion, context, item, activateGroupPart } = useBuilderNGN();

  const {
    state: { carriers },
  } = useAppState();

  const suggestions = useMemo(
    () => getAllRefs(context()).map(v => ({ label: v.label, value: v.referenceId })),
    [context],
  );

  const open = useCallback(
    (carrier: string, dataMapId: string) => {
      activateGroupPart({ carrier, groupPart: "c-questions", dataMapId });
    },
    [activateGroupPart],
  );

  const mappings = useMemo(() => {
    if (!selectedQuestion) return [];
    return item.carrierQuestionnaires.flatMap(v =>
      v.questions
        .filter(v => v.referencedQuestionId === selectedQuestion?.id)
        .map(r => ({
          ...r,
          jsonNode: r.jsonNode.map(v =>
            v.map(v => {
              return getTextRefParts(v)
                .map(v => {
                  if (isRefInterpolated(v)) {
                    const refId = getInterpolatedRefId(v);
                    const ref = suggestions.find(v => v.value === refId);

                    if (!ref) {
                      return v;
                    }

                    return ref.label;
                  } else {
                    return v;
                  }
                })
                .join("");
            }),
          ),
          carrierId: v.carrierId,
          carrierName: carriers.find(c => c.id === v.carrierId)?.name,
        })),
    );
  }, [item, carriers, selectedQuestion, suggestions]);

  return (
    <div className={"flex items-center gap-2"}>
      <div className={"w-32"}>Mapping</div>

      {mappings.map(mapping => (
        <div
          key={mapping.id}
          className={"flex flex-col gap-1 border rounded px-2 py-1 cursor-pointer "}
          onClick={cb(open, mapping.carrierId, mapping.id)}
        >
          <div className={"font-semibold"}>{mapping.carrierName}</div>
          <div className={"flex flex-col gap-3 "}>
            {mapping.jsonNode.map(v => (
              <div key={v.join(".")} className={"flex gap-0.5 flex-wrap w-full shadow"}>
                {v.map((v, i) => (
                  <div className={"w-fit"}>
                    <Tag label={v} color={nodesBg[i] || "bg-black"} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
