import { ChangeEvent, useCallback, useMemo, useReducer, useState } from "react";
import { v4 } from "uuid";
import { remove } from "../../utils/arr-utils";
import { RemoveAction } from "../util/ItemActions";
import { DateValueKind, Val, Value } from "@Savus-Inc/dsl/dist/types/";
import { useBuilderNGN } from "./BuilderNGN";
import { getAllRefs } from "../Rules/utils/getAvailableRefs";
import { QuestionValueOption, QuestionVisibility, RuleGroupEntity } from "@Savus-Inc/questionnaire-types";
import { Editor } from "../util/Editor";
import { useAppState } from "../../state/State";
import { AddIcon } from "../util/AddNew";
import classNames from "classnames";
import { Modal } from "../util/Modal";
import { cb } from "../../utils/cd";
import { EntityRuleGroups } from "./EntityRuleGroups";

const ValueRuleGroups = ({
  optionId,
  groups,
  toggle,
}: {
  optionId: string;
  groups: RuleGroupEntity<QuestionVisibility>[];
  toggle: () => void;
}) => {
  return (
    <Modal isOpen={true} onClose={toggle}>
      <div className={"min-h-[70vh] pt-2 pb-12"}>
        <EntityRuleGroups ruleGroups={groups} kind={"optionValue"} ownerId={optionId} />
      </div>
    </Modal>
  );
};

const QuestionValue = ({
  value,
  removeValue,
  handleChange,
}: {
  removeValue: () => void;
  value: QuestionValueOption;
  handleChange: (key: "value" | "label", e: ChangeEvent<any>) => void;
}) => {
  const { context, selectedQuestion } = useBuilderNGN();

  const [viewValue, setViewValue] = useState(false);

  const [showRuleGroups, setShowRuleGroups] = useReducer(p => !p, false);
  const availableRefs = useMemo(
    () =>
      getAllRefs(context()).map(v => ({
        label: `${v.label.toString()}`,
        value: v.referenceId,
      })),
    [context],
  );

  const handleRefChange = useCallback(
    (key: "label" | "value") => (value: string) => {
      handleChange(key, { target: { value } } as never);
    },
    [handleChange],
  );

  const shoulShowValue = viewValue || value.label !== value.value?.value || value.label.includes("#each");

  if (!selectedQuestion) {
    return <div />;
  }

  return (
    <div className={"flex items-center w-full max-w-fit min-w-[500px]  gap-1"}>
      <div
        className={classNames("flex items-center gap-1 rounded px-2 text-sm py-1  whitespace-nowrap cursor-pointer", {
          "bg-sky-500 text-white": !value.visibility?.length,
          "bg-orange-300 text-orange-950": !!value.visibility?.length,
        })}
        onClick={setShowRuleGroups}
      >
        <AddIcon className={"h-4"} /> Rules ({value.visibility?.length || 0})
      </div>

      <div className={"flex items-center w-full gap-1"}>
        <Editor refs={availableRefs} initial={value.label || ""} onChange={handleRefChange("label")} />
        {!viewValue && !shoulShowValue && (
          <div className={"border px-2 py-0.5 w-32 cursor-pointer"} onClick={cb(setViewValue, true)}>
            Set Value
          </div>
        )}
        {shoulShowValue && (
          <div className={"flex flex-col gap-1 w-full"}>
            <Editor
              refs={availableRefs}
              initial={(value.value.value as string) || ""}
              onChange={handleRefChange("value")}
            />
            {value.label.includes("#each") && (
              <span className={"text-orange-400 text-xs"}>
                When using an interpolation of dynamic (#each) values. You need to select an option value, option value
                is a value that is set in the answers and it can be used for "if testing" later.
              </span>
            )}
          </div>
        )}
      </div>
      <RemoveAction remove={removeValue} />
      {showRuleGroups && (
        <ValueRuleGroups optionId={value.id} groups={value.visibility || []} toggle={cb(setShowRuleGroups)} />
      )}
    </div>
  );
};
export const QuestionValues = ({
  values,
  kind,
  onChange,
}: {
  values: QuestionValueOption[];
  onChange: (vals: ChangeEvent<QuestionValueOption[]>) => void;
  kind: Val;
}) => {
  const { selectedQuestion } = useBuilderNGN();
  const { addReferentQuestionValue, editReferentQuestionValue, removeReferentQuestionValue } = useAppState();

  const handleChange = (idx: number) => (key: "value" | "label", e: ChangeEvent<any>) => {
    if (key === "value") {
      (values[idx] as any)["value"] = { value: e.target.value };
    } else {
      (values[idx] as any)["label"] = e.target.value;
    }

    if (!selectedQuestion) return;

    editReferentQuestionValue(selectedQuestion.questionId, values[idx].id, {
      [key]: key === "value" ? { value: e.target.value } : e.target.value,
    });
    onChange({ target: { value: values } } as never);
  };

  const insertValue = useCallback(() => {
    if (!selectedQuestion) return;

    let val: Value;

    switch (kind) {
      case Val.String:
        val = { kind: kind, value: "" };
        break;
      case Val.Number:
        val = { kind: kind, value: 0 };
        break;
      case Val.Bool:
        val = { kind: kind, value: true };
        break;
      case Val.Date:
        val = {
          kind: kind,
          value: { kind: DateValueKind.Single, value: new Date().toString() },
        };
        break;
    }

    const option: QuestionValueOption = {
      id: v4(),
      label: "",
      partOrder: values.length,
      questionId: selectedQuestion.questionId,
      value: val,
    };

    values.push(option);

    addReferentQuestionValue(selectedQuestion.questionId, {
      id: option.id,
      label: "",
      partOrder: option.partOrder,
    });
    onChange({ target: { value: values } } as never);
  }, [selectedQuestion, kind, values, addReferentQuestionValue, onChange]);

  const removeValue = useCallback(
    (idx: number) => () => {
      if (!selectedQuestion) return;

      removeReferentQuestionValue(selectedQuestion.questionId, values[idx].id);
      remove<QuestionValueOption>(values, idx);

      onChange({ target: { value: values } } as never);
    },
    [selectedQuestion, removeReferentQuestionValue, values, onChange],
  );

  return (
    <div className={"flex flex-col gap-2 mt-2 pt-2 border-t border-gray-200"}>
      <button className={"w-32 py-[2px] bg-gray-700 text-white text-sm mb-2"} onClick={insertValue}>
        Add New Value
      </button>
      {values.map((value, idx) => (
        <QuestionValue key={value.id} removeValue={removeValue(idx)} value={value} handleChange={handleChange(idx)} />
      ))}
    </div>
  );
};
