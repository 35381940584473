export const Tag = ({ label, onRemove, color }: { label: string; onRemove?: () => void; color: string }) => {
  return (
    <div
      className={`flex px-2 rounded ${color} bg-opacity-65 text-white justify-between gap-4 items-center w-full text-nowrap whitespace-nowrap`}
    >
      <div>{label}</div>
      {onRemove && (
        <div className={"flex cursor-pointer bg-red-400 py-[2px] px-2 rounded translate-x-2"} onClick={onRemove}>
          X
        </div>
      )}
    </div>
  );
};
