import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import { NoData } from "../util/Empty";
import { CarrierMappingData } from "./CarrierMappingData";

export const CarrierMapping = () => {
  const { carrierId, groupPartActive } = useBuilderNGN();

  if (!carrierId) {
    return <NoData />;
  }
  return (
    <div className={"flex flex-col w-full"}>
      {carrierId && (
        <div className={"flex flex-col h-full w-full max-h-[90vh] min-h-[90vh] overflow-y-auto"}>
          <CarrierMappingData category={groupPartActive as never} />
        </div>
      )}
    </div>
  );
};
