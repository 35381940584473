import { useMemo } from "react";
import { QuestionValidityAction, ValidationAlertLevel } from "@Savus-Inc/questionnaire-types";
import { Editor } from "../../util/Editor";
import { useBuilderNGN } from "../../Questionaire/BuilderNGN";
import { getAllRefs } from "../utils/getAvailableRefs";
import classNames from "classnames";
import { cb } from "../../../utils/cd";

export function ValidationGroupMessage({
  onChange,
  value,
}: {
  onChange: (v: QuestionValidityAction) => void;
  value: QuestionValidityAction | null;
}) {
  const { context } = useBuilderNGN();

  const availableRefs = useMemo(
    () =>
      getAllRefs(context()).map(({ label, referenceId }) => ({
        value: referenceId,
        label,
      })),
    [context],
  );

  return (
    <div className={"flex items-center mr-2 gap-3 w-full"}>
      <div className={"flex gap-2 w-full items-center"}>
        <label className={"w-48"}>Message:</label>
        <div className={"flex"}>
          {Object.values(ValidationAlertLevel).map(v => (
            <div
              key={v}
              onClick={cb(onChange, { message: value?.message || "", alertLevel: v })}
              className={classNames("border w-28 text-center py-[2px] cursor-pointer", {
                "bg-purple-400 font-semibold text-white": value?.alertLevel === v,
              })}
            >
              {v}
            </div>
          ))}
        </div>
        <Editor
          refs={availableRefs}
          initial={value?.message || ""}
          onChange={message => onChange({ message, alertLevel: ValidationAlertLevel.Error })}
        />
      </div>
    </div>
  );
}
