import React, { SyntheticEvent, useState } from "react";
import classNames from "classnames";

const ToggleAcceptDecline = ({
  initial,
  onChange,
  disabled = false,
}: {
  initial: boolean;
  onChange: (b: boolean) => void;
  disabled?: boolean;
}) => {
  const [isAccepted, setIsAccepted] = useState(initial);

  const toggleState = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    setIsAccepted(!isAccepted);
    onChange(!isAccepted);
  };

  return (
    <div className={classNames("justify-self-end flex items-center text-sm", { "opacity-50": disabled })}>
      <button
        onClick={toggleState}
        disabled={disabled}
        className={`px-4 py-0.5 rounded-l-xl ${isAccepted ? "bg-green-500 text-white" : "bg-gray-200 text-gray-700"}`}
      >
        Accept
      </button>
      <button
        disabled={disabled}
        onClick={toggleState}
        className={`px-4 py-0.5 rounded-r-xl ${!isAccepted ? "bg-red-500 text-white" : "bg-gray-200 text-gray-700"}`}
      >
        Decline
      </button>
    </div>
  );
};

export default ToggleAcceptDecline;
