import { QuestionUI } from "./QuestionUI";
import { EntityRuleGroups } from "./EntityRuleGroups";
import { useBuilderNGN } from "./BuilderNGN";
import { NoData } from "../util/Empty";
import { useReducer } from "react";
import classNames from "classnames";
import { cb } from "../../utils/cd";
import { InputType } from "@Savus-Inc/questionnaire-types";
import { QuestionMapping } from "./QuestionMapping";

export const QuestionnaireQuestion = () => {
  const { selectedQuestion, activateGroupPart, groupId, currentGroup } = useBuilderNGN();
  const [selectedTab, setTab] = useReducer((_: 0 | 1 | 2, v: 0 | 1 | 2) => v, 0);

  if (!selectedQuestion) return <NoData />;

  return (
    <div className={"flex flex-col w-full"}>
      {selectedQuestion?.parentId && (
        <div
          className={"flex text-sky-800 gap-2 my-1 cursor-pointer"}
          onClick={cb(activateGroupPart, {
            group: groupId || "",
            groupPart: "g-questions",
            questionId: selectedQuestion.parentId,
          })}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='size-6'
          >
            <path strokeLinecap='round' strokeLinejoin='round' d='M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18' />
          </svg>
          Go To Parent Question
        </div>
      )}
      <div className='flex flex-col'>
        <div
          className={
            "flex w-full border-b border-purple-100 font-medium focus:outline-none justify-evenly text-center mb-4"
          }
        >
          <div
            onClick={cb(setTab, 0)}
            className={classNames("text-lg w-1/2 py-1 transition-all cursor-pointer", {
              "bg-sky-500 text-white font-bold ": selectedTab === 0,
            })}
          >
            Details
          </div>
          <div
            onClick={cb(setTab, 1)}
            className={classNames("text-lg  w-1/2 py-1 transition-all cursor-pointer", {
              "bg-sky-500 text-white font-bold": selectedTab === 1,
            })}
          >
            Rules
          </div>
          <div
            onClick={cb(setTab, 2)}
            className={classNames("text-lg  w-1/2 py-1 transition-all cursor-pointer", {
              "bg-sky-500 text-white font-bold": selectedTab === 2,
            })}
          >
            Mapping
          </div>
        </div>
        <div className='flex justify-center gap-4 border-b border-purple-100 pb-3'>
          <h4 className='font-bold'>P-{currentGroup?.partOrder}:</h4>
          <h4>
            <span className='font-bold'>Label: </span>
            {selectedQuestion.question?.label ? (
              selectedQuestion.question.label
            ) : (
              <span className='text-red-600'>Missing Label</span>
            )}
          </h4>

          <h4>
            <span className='font-bold'>ID:</span>{" "}
            {selectedQuestion.question?.externalId ? (
              selectedQuestion.question.externalId
            ) : (
              <span className='text-red-600'>Missing ID</span>
            )}
          </h4>
        </div>
      </div>

      {selectedTab === 0 ? (
        <QuestionUI />
      ) : selectedTab === 1 ? (
        <EntityRuleGroups
          ruleGroups={selectedQuestion.ruleGroups}
          kind={"question"}
          container={
            selectedQuestion.question?.inputType !== InputType.Header &&
            currentGroup?.questions.some(q => q.parentId === selectedQuestion.id)
          }
          ownerId={selectedQuestion.id}
        />
      ) : (
        <QuestionMapping />
      )}
    </div>
  );
};
