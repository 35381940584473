import React, { useMemo } from "react";
import { NoData } from "../util/Empty";
import { AccordionItem, TitleWithAction } from "../util/Accourdion";
import { cb } from "../../utils/cd";
import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import { KnockoutRules } from "./KnockoutRules";
import { DefaultsMapping } from "./DefaultsMapping";
import { DataMapping } from "./DataMapping";

export const CarrierMapItem = ({ carrierId }: { carrierId: string }) => {
  const {
    groupPartActive,
    item,
    insertCarrierMappingItem,
    carrierId: carrier,
  } = useBuilderNGN();

  const carrierMap = useMemo(() => item.carrierQuestionnaires.find(c => c.carrierId === carrierId), [carrierId, item]);

  if (!carrierMap) {
    return <NoData />;
  }

  return (
    <div className={"flex flex-col"}>
      <AccordionItem
        title={<TitleWithAction title={"Knockout Rules"} add={cb(insertCarrierMappingItem, carrierId, "rules")} />}
        selected={groupPartActive === "c-knockout" && carrierId === carrier}
        initial={groupPartActive === "c-knockout" && carrierId === carrier}
      >
        <KnockoutRules carrierId={carrierId} carrierMap={carrierMap} />
      </AccordionItem>
      <AccordionItem
        title={<TitleWithAction title={"Defaults"} add={cb(insertCarrierMappingItem, carrierId, "defaults")} />}
        selected={groupPartActive === "c-defaults" && carrierId === carrier}
        initial={groupPartActive === "c-defaults" && carrierId === carrier}
      >
        <DefaultsMapping carrierId={carrierId} carrierMap={carrierMap} />
      </AccordionItem>
      <AccordionItem
        title={<TitleWithAction title={"Data Mapping"} add={cb(insertCarrierMappingItem, carrierId, "questions")} />}
        selected={groupPartActive === "c-questions" && carrierId === carrier}
        initial={groupPartActive === "c-questions" && carrierId === carrier}
      >
        <DataMapping carrierId={carrierId} carrierMap={carrierMap} />
      </AccordionItem>
    </div>
  );
};