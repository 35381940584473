import { useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { cb } from "../../utils/cd";
import { NGNContextProvider, SandboxNGNProvider, useNGN } from "@Savus-Inc/runtime-ngn/dist/ngn";
import { CustomerRecord } from "../../types/customer-record";
import { Modal } from "../util/Modal";
import { CustomerRecordForm, getPrevious, setPrevious, updatePrevious } from "./CustomerRecordForm";
import { useAppState } from "../../state/State";
import classNames from "classnames";
import { CarrierStatus } from "./CarrierStatus";
import { NoData } from "../util/Empty";
import { DebugView } from "./DebugView";
import useAsyncFn from "../../utils/useAsyncFn";
import { getQuestionnaireCarrierMapping } from "../../http/questionnaire";
import { Answers } from "@Savus-Inc/questionnaire-types";
import { toast } from "react-toastify";
import { Coverages } from "./Coverages";
import { unpackMapping } from "@Savus-Inc/runtime-ngn/dist/utils/unpack-mapping";
function generateRandomString(length: number): string {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function generateRandomHash() {
  let array = new Uint8Array(10);
  window.crypto.getRandomValues(array);

  return Array.from(array)
    .map(b => b.toString(16).padStart(2, "0"))
    .join("");
}

const Spinner = () => <div className={"w-screen h-screen flex items-center justify-center"}>Loading...</div>;
export const QuestionnaireRun = () => {
  const { group, next, prev, availableGroups, jump, questionnaire } = useNGN();

  useEffect(() => {
    const element = document.getElementById("app"); // Get the <html> element
    if (!element) return;

    element.scrollTo(0, 0);
  }, [group]);

  if (!questionnaire) return null;

  return (
    <div className={"flex gap-1"}>
      <div className={"w-96 bg-white"}>
        <div className={"bg-sky-500 text-xl text-white text-center py-2.5"}>{questionnaire.title}</div>
        {availableGroups.map(gr => (
          <div
            key={gr.id}
            className={classNames("text-lg px-2 py-1 border-y flex items-center gap-2", {
              "text-sky-500": gr.partOrder === group,
              "text-green-500 bg-green-100 cursor-pointer": gr.partOrder < group,
            })}
            onClick={cb(jump, gr.partOrder)}
          >
            <span
              className={classNames("w-4 h-4 rounded-full", {
                "bg-sky-500": gr.partOrder === group,
                "bg-gray-100": gr.partOrder !== group,
                "bg-green-500": gr.partOrder < group,
              })}
            ></span>
            {gr.title}
          </div>
        ))}
      </div>
      <div className={"flex-1 flex flex-col gap-2"}>
        <CarrierStatus />
        <div className={"mt-0 p-4 bg-white flex justify-center"}>
          <div className={"flex w-max gap-20 px-10 justify-between"}>
            <button
              className={
                "cursor-pointer px-1 py-2 w-max min-w-40 rounded-lg border border-sky-800 text-lg text-center hover:bg-sky-500 hover:text-white"
              }
              disabled={group === 0}
              onClick={prev}
            >
              Previous
            </button>
            <button
              onClick={cb(next, true)}
              disabled={!(group + 1 <= questionnaire.groups.length)}
              className={
                "cursor-pointer px-1 py-2 w-max min-w-40 rounded-lg border border-sky-800 text-lg text-center hover:bg-sky-500 hover:text-white"
              }
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const QuestionnairePreview = () => {
  const [customerRecord, update] = useReducer(
    (_: CustomerRecord, action: CustomerRecord) => {
      if (!action.id && action.businessType.classCode) {
        action.id = generateRandomHash();
        const previous = getPrevious();
        previous.push();
        setPrevious(previous);
      }

      return action;
    },
    {
      id: "",
      businessName: generateRandomString(12),
      emailAddress: `${generateRandomHash()}@savus.tech`,
      phoneNumber: Math.ceil(Math.random() * 10000000000000).toString(),
      firstName: generateRandomString(6),
      lastName: generateRandomString(6),
      address: {
        address: "",
        city: "",
        stateId: "",
        zip: 0,
      },
      businessType: {
        naicsCode: "",
        naicsTitle: "",
        title: "",
        classCode: "",
      },
      progress: {
        page: 0,
        answers: {},
      },
      fcraStatement: true,
    } as CustomerRecord,
  );

  const [showCS, toggle] = useReducer(p => !p, true);
  const { id } = useParams();
  const [showCoverages, setChowCoverages] = useState<{ applicationId: string; quoteStateId: string }>();

  const {
    state: { currentQuestionnaire },
    get,
  } = useAppState();

  useEffect(() => {
    id && get(id);
  }, [get, id]);

  const {
    exec: getMappings,
    data: mappingsResponse,
    loading: gettingMappings,
  } = useAsyncFn(getQuestionnaireCarrierMapping);

  const onSave = useCallback(
    (page: number, answers: Answers) => {
      updatePrevious({ ...customerRecord, progress: { page, answers } });
    },
    [customerRecord],
  );

  useEffect(() => {
    if (currentQuestionnaire?.id) {
      getMappings(currentQuestionnaire.id);
    }
  }, [currentQuestionnaire?.id, getMappings]);

  const mapping = useMemo(() => {
    if (mappingsResponse?.ok) {
      return unpackMapping(mappingsResponse.data);
    }

    return [];
  }, [mappingsResponse]);

  useEffect(() => {
    if (customerRecord.id) {
      toggle();
    }
  }, [customerRecord.id]);
  if (!currentQuestionnaire) {
    return (
      <div>
        <NoData message={"Questionnaire not found"} />
      </div>
    );
  }

  if (gettingMappings) {
    return <Spinner />;
  }

  if (showCS) {
    return (
      <Modal isOpen={showCS} onClose={cb(toast.error, "Please complete the form to proceed to questionnaire preview")}>
        <CustomerRecordForm initial={customerRecord} onChange={update} />
      </Modal>
    );
  }

  if (showCoverages) {
    return <Coverages applicationId={showCoverages.applicationId} quoteStateId={showCoverages.quoteStateId} />;
  }

  const { progress, ...CS } = customerRecord;

  if (customerRecord?.quoteStateId) {
    return (
      <div className={"flex flex-col justify-center"}>
        <NGNContextProvider
          onEnd={cb(setChowCoverages, {
            applicationId: customerRecord.applicationId || "",
            quoteStateId: customerRecord.quoteStateId,
          })}
          asyncConfig={{
            customerRecordId: customerRecord.id,
            questionnaireApiUrl: `${process.env.REACT_APP_SERVER_URL}/questionnaire/${customerRecord.applicationId}/${customerRecord.quoteStateId}`,
            getToken: () => localStorage.getItem("__token"),
          }}
          context={{ customerRecord: CS, agent: { email: "admin@savus.tech", lastName: "Savus", firstName: "Admin" } }}
          fieldMap={{
            Address: ["customerRecord", "address", "address"],
            City: ["customerRecord", "address", "city"],
            Suite: ["customerRecord", "address", "suite"],
            BusinessTypeNAICS: ["customerRecord", "businessType", "naicsCode"],
            BusinessTypeNAICSTitle: ["customerRecord", "businessType", "naicsTitle"],
            BusinessTypeTitle: ["customerRecord", "businessType", "title"],
            Product: ["product", "name"],
            ProductShortName: ["product", "shortName"],
            State: ["customerRecord", "address", "state", "shortName"],
            StateName: ["customerRecord", "address", "state", "name"],
            StateNameWithCode: ["customerRecord", "address", "state", "fullName"],
            ZipCode: ["customerRecord", "address", "zip"],
            ClassCodeChubb: ["customerRecord", "businessType", "classCode"],
            BusinessName: ["customerRecord", "businessName"],
            PolicyQuoteIdChubb: ["policyQuoteIdChubb"],
            AgentFirstName: ["agent", "firstName"],
            AgentLastName: ["agent", "lastName"],
            AgentEmail: ["agent", "email"],
            __X: ["__X"],
          }}
          spinner={<Spinner />}
        >
          <QuestionnaireRun />
          <DebugView />
        </NGNContextProvider>
      </div>
    );
  }

  return (
    <div className={"flex flex-col justify-center"}>
      <SandboxNGNProvider
        onEnd={cb(alert, "Questionnaire Completed")}
        questionnaire={currentQuestionnaire}
        initialState={{ carrierProducts: [], mapping: mapping, ...progress }}
        onSave={onSave}
        context={{ customerRecord: CS, agent: { email: "admin@savus.tech", lastName: "Savus", firstName: "Admin" } }}
        fieldMap={{
          Address: ["customerRecord", "address", "address"],
          City: ["customerRecord", "address", "city"],
          Suite: ["customerRecord", "address", "suite"],
          BusinessTypeNAICS: ["customerRecord", "businessType", "naicsCode"],
          BusinessTypeNAICSTitle: ["customerRecord", "businessType", "naicsTitle"],
          BusinessTypeTitle: ["customerRecord", "businessType", "title"],
          Product: ["product", "name"],
          ProductShortName: ["product", "shortName"],
          State: ["customerRecord", "address", "state", "shortName"],
          StateName: ["customerRecord", "address", "state", "name"],
          StateNameWithCode: ["customerRecord", "address", "state", "fullName"],
          ZipCode: ["customerRecord", "address", "zip"],
          ClassCodeChubb: ["customerRecord", "businessType", "classCode"],
          BusinessName: ["customerRecord", "businessName"],
          PolicyQuoteIdChubb: ["policyQuoteIdChubb"],
          AgentFirstName: ["agent", "firstName"],
          AgentLastName: ["agent", "lastName"],
          AgentEmail: ["agent", "email"],
          __X: ["__X"],
        }}
        spinner={<Spinner />}
      >
        <QuestionnaireRun />
        <DebugView />
      </SandboxNGNProvider>
    </div>
  );
};
