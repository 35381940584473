import React from "react";
import { unpack } from "../../utils/unpack";
import { DateTypeEnum, DateWhenEnum } from "../../utils/commonValidations";
import { ValidationData } from "./ValidationArguments";

type DateValidationProps = {
  data: ValidationData;
  handleChange: (key: string) => (value: string) => void;
  isSubmitted: boolean;
  inputErr: { [key: string]: string };
  handleOnClick: () => void;
};

const DateValidation: React.FC<DateValidationProps> = ({
  handleChange,
  data,
  isSubmitted,
  inputErr,
  handleOnClick,
}) => {
  return (
    <div className='flex flex-col gap-8'>
      <div>
        <h1 className='p-4 text-2xl font-bold'>Date Validation</h1>
        <p className='px-4'>To add Date Validation please pick one of the following options.</p>
      </div>
      <div className='flex flex-col items-center gap-8'>
        <div className='flex flex-col items-center gap-2'>
          <div className='flex items-center gap-4'>
            <label>Validation:</label>
            <select
              onChange={unpack(handleChange("selectType"))}
              value={data.params.selectType}
              className='w-full border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
            >
              <option value='' disabled selected>
                Select an option
              </option>
              {Object.values(DateTypeEnum).map(item => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          {isSubmitted && inputErr && <p className='text-red-600'>{inputErr.selectType}</p>}
        </div>
        {data.params.selectType === DateTypeEnum.CustomDateRange && (
          <div className='flex flex-col gap-8'>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex flex-col gap-2'>
                <div className='w-60 flex gap-4'>
                  <label>From:</label>
                  <select
                    onChange={unpack(handleChange("selectWhenFrom"))}
                    value={data.params.selectWhenFrom}
                    className='w-40 h-7 border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
                  >
                    <option value='' disabled selected>
                      Select an option
                    </option>
                    {Object.values(DateWhenEnum).map(item => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                {isSubmitted && inputErr && <p className='text-red-600'>{inputErr.selectWhenFrom}</p>}
              </div>
              {data.params.selectWhenFrom !== DateWhenEnum.Today && data.params.selectWhenFrom !== "" && (
                <div className='flex flex-col gap-2'>
                  <div className='flex flex-col'>
                    <div className='flex justify-between gap-4'>
                      <label>Amount:</label>
                      <input
                        onChange={unpack(handleChange("amountFrom"))}
                        value={data.params.amountFrom}
                        type='text'
                        className='w-40 border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
                      />
                    </div>
                    {inputErr && <p className='text-red-600'>{inputErr.amountFrom}</p>}
                  </div>
                  <div className='flex justify-between'>
                    <label>Unit:</label>
                    <select
                      onChange={unpack(handleChange("unitFrom"))}
                      value={data.params.unitFrom}
                      className='w-40 border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
                    >
                      <option value='' disabled selected>
                        Select Unit
                      </option>
                      <option value='year'>Year</option>
                      <option value='month'>Month</option>
                      <option value='day'>Day</option>
                    </select>
                  </div>
                  {isSubmitted && inputErr && <p className='text-red-600'>{inputErr.unitFrom}</p>}
                </div>
              )}
            </div>

            <div className='flex justify-between items-center gap-4'>
              <div className='flex flex-col gap-2'>
                <div className='w-60 flex gap-9'>
                  <label>To:</label>
                  <select
                    onChange={unpack(handleChange("selectWhenTo"))}
                    value={data.params.selectWhenTo}
                    className='w-40 h-7 border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
                  >
                    <option value='' disabled selected>
                      Select an option
                    </option>
                    {Object.values(DateWhenEnum).map(item => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                {isSubmitted && inputErr && <p className='text-red-600'>{inputErr.selectWhenTo}</p>}
              </div>
              {data.params.selectWhenTo !== "Today" && data.params.selectWhenTo !== "" && (
                <div className='flex flex-col gap-2'>
                  <div className='flex flex-col'>
                    <div className='flex justify-between gap-4'>
                      <label>Amount:</label>
                      <input
                        onChange={unpack(handleChange("amountTo"))}
                        value={data.params.amountTo}
                        type='text'
                        className='w-40 border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
                      />
                    </div>
                    {inputErr && <p className='text-red-600'>{inputErr.amountTo}</p>}
                  </div>
                  <div className='flex justify-between gap-4'>
                    <label>Unit:</label>
                    <select
                      onChange={unpack(handleChange("unitTo"))}
                      value={data.params.unitTo}
                      className='w-40 border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
                    >
                      <option value='' disabled selected>
                        Select unit
                      </option>
                      <option value='year'>Year</option>
                      <option value='month'>Month</option>
                      <option value='day'>Day</option>
                    </select>
                  </div>
                  {isSubmitted && inputErr && <p className='text-red-600'>{inputErr.unitTo}</p>}
                </div>
              )}
            </div>
          </div>
        )}
        {(data.params.selectType === "Custom Date Before" || data.params.selectType === "Custom Date After") && (
          <div className=' flex  gap-6'>
            <div>
              <div className='flex items-center gap-2'>
                <label>Amount:</label>
                <input
                  onChange={unpack(handleChange("amount"))}
                  value={data.params.amount}
                  type='text'
                  className='w-40 border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
                />
              </div>
              {inputErr && <p className='text-red-600'>{inputErr.amount} </p>}
            </div>
            <div>
              <div className='flex items-center gap-2'>
                <label>Unit:</label>
                <select
                  onChange={unpack(handleChange("unit"))}
                  value={data.params.unit}
                  className='w-40 border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
                >
                  <option value='' disabled selected>
                    Select unit
                  </option>
                  <option value='year'>Year</option>
                  <option value='month'>Month</option>
                  <option value='day'>Day</option>
                </select>
              </div>
              {isSubmitted && inputErr && <p className='text-red-600'>{inputErr.unit} </p>}
            </div>
          </div>
        )}
        <button
          onClick={handleOnClick}
          className='w-1/2 border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500 hover:bg-sky-500 hover:text-white transition-colors duration-300'
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default DateValidation;
