import { useMemo, useReducer } from "react";
import { defer } from "../../../utils/defer";
import { cb } from "../../../utils/cd";
import { preventDefault } from "../../../utils/preventDefault";
import classNames from "classnames";
import { Tag } from "../Tag";
import Autocomplete from "../../util/Autocomplete";
import { useBuilderNGN } from "../../Questionaire/BuilderNGN";
import { QuestionAction, QuestionActionKind, RuleGroupKind } from "@Savus-Inc/questionnaire-types";
import { Ref, Val } from "@Savus-Inc/dsl/dist/types";

export function GroupMiddleware({
  onChange,
  value,
}: {
  onChange: (v: QuestionAction) => void;
  value: QuestionAction;
  kind: RuleGroupKind;
}) {
  const { item, groupId } = useBuilderNGN();

  const [val, setVal] = useReducer((p: QuestionAction | null, kind: QuestionActionKind | string) => {
    let val: QuestionAction;

    switch (kind) {
      case QuestionActionKind.JumpTo:
        val = {
          kind,
          value: "",
        };
        break;
      case QuestionActionKind.Redirect:
        val = {
          kind,
          value: "",
        };
        break;
      case QuestionActionKind.End:
        val = {
          kind,
          value: "",
        };
        break;
      default: {
        if (!p) {
          val = {
            kind: QuestionActionKind.End,
            value: "",
          };
        } else {
          val = {
            kind: p.kind,
            value: kind as string,
          };
        }
      }
    }

    defer(() => onChange(val));

    return val;
  }, value);

  const availableRefs: Ref[] = useMemo(() => {
    if (val?.kind !== QuestionActionKind.JumpTo) return [];

    return item.groups
      .filter((g, i) => i > item.groups.findIndex(g => g.id === groupId))
      .map(
        v =>
          ({
            value: [],
            referenceId: v.id,
            label: v.title,
            kind: Val.String,
          }) as Ref,
      );
  }, [val, item, groupId]);

  const [selectedRef, setSelectedRef] = useReducer(
    (p: Ref | undefined, v: string) => {
      defer(cb(setVal, v || ""));

      return availableRefs.find(r => r.referenceId === v);
    },
    val?.kind === QuestionActionKind.JumpTo ? availableRefs.find(v => v.referenceId === val?.value) : undefined,
  );

  return (
    <div className={"flex items-center mr-2 ml-2 gap-3"}>
      <div className={"flex"}>
        <button
          onClick={preventDefault(() => setVal(QuestionActionKind.End))}
          className={classNames("rounded-l border border-gray-300 py-1 w-24 text-center cursor-pointer", {
            "bg-gray-600 text-white": QuestionActionKind.End === val?.kind,
          })}
        >
          End
        </button>

        <button
          onClick={preventDefault(() => setVal(QuestionActionKind.Redirect))}
          className={classNames("rounded-r border border-gray-300 py-1 w-24 text-center cursor-pointer", {
            "bg-gray-600 text-white": QuestionActionKind.Redirect === val?.kind,
          })}
        >
          Redirect
        </button>

        <button
          onClick={preventDefault(() => setVal(QuestionActionKind.JumpTo))}
          className={classNames("rounded-r border border-gray-300 py-1 w-24 text-center cursor-pointer", {
            "bg-gray-600 text-white": QuestionActionKind.JumpTo === val?.kind,
          })}
        >
          Jump To
        </button>
      </div>
      {val?.kind === QuestionActionKind.Redirect ? (
        value?.value ? (
          <Tag label={value.value} onRemove={cb(setVal, "")} color={"bg-green-700"} />
        ) : (
          <input
            onBlur={e => setVal(e.target.value)}
            defaultValue={val?.value || ""}
            className='w-full px-1 py-[2px] border border-gray-300 rounded-md focus:outline-none focus:border-sky-500'
          />
        )
      ) : val?.kind === QuestionActionKind.JumpTo ? (
        value?.value ? (
          <Tag label={selectedRef?.label || value.value} onRemove={cb(setVal, "")} color={"bg-green-700"} />
        ) : (
          <Autocomplete
            suggestions={availableRefs.map(v => ({
              value: v.referenceId,
              label: v.label,
            }))}
            onSelect={setSelectedRef}
          />
        )
      ) : (
        <div />
      )}
    </div>
  );
}
