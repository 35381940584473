import { ApiResponse } from "../types/ApiResponse";
import { makeRequest } from "./makeRequest";
import { Product } from "@Savus-Inc/questionnaire-types";

export const listProducts = async (): Promise<ApiResponse<(Product & { id: string })[]>> => {
  return makeRequest("get", "data/referent/products");
};

export const listCarriers = async (): Promise<ApiResponse<{ id: string; name: string }[]>> => {
  return makeRequest("get", "data/referent/carriers");
};

export const listApplications = async (): Promise<ApiResponse<{ id: string; name: string }[]>> => {
  return makeRequest("get", "data/referent/applications");
};

export const listStates = async (): Promise<
  ApiResponse<
    {
      id: string;
      name: string;
      shortName: string;
      fullName: string;
    }[]
  >
> => {
  return makeRequest("get", "data/referent/states");
};

export const listBusinessTypes = async (
  term: string,
): Promise<ApiResponse<{ data: { id: string; name: string }[] }>> => {
  return makeRequest("get", `data/referent/business-types?term=${term}&limit=1&offset=0`);
};
