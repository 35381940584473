import { useCallback, useState } from "react";
import { toast } from "react-toastify";

function useAsyncFn<T extends any[], V>(fn: (...args: T) => Promise<V>) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<V | null>(null);

  const exec = useCallback(
    (...args: T) => {
      setLoading(true);
      fn(...args)
        .then(data => setData(data))
        .catch(error => {
          toast.error(error?.response?.data?.message || error?.message || "Request failed.");
          // console.error(error);
        })
        .finally(() => setLoading(false));
    },
    [fn],
  );

  return { exec, loading, data };
}

export default useAsyncFn;
