import { useCallback } from "react";
import { OpUI } from "../../Op/OpUI";
import { QuestionContext } from "../../../types/QuestionContext";
import { Op } from "@Savus-Inc/dsl/dist/types";
import { RuleGroupKind } from "@Savus-Inc/questionnaire-types";

export const ValuePrefill = ({
  onChange,
  value,
  context,
}: {
  onChange: (v: Op) => void;
  value: Op;
  kind: RuleGroupKind;
  context: QuestionContext;
}) => {
  const update = useCallback(
    (key: "args" | "fn") => (val: Op["args"] | Op["fn"]) => {
      onChange({ ...value, [key]: val });
    },
    [onChange, value],
  );

  return (
    <div>
      <OpUI
        op={value}
        update={update("args")}
        updateFunction={update("fn")}
        context={context}
        key={JSON.stringify(value)}
      />
    </div>
  );
};
