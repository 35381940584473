import { ValuePrefill } from "./ValuePrefill";
import { GroupMiddleware } from "./GroupMiddleware";
import { GroupVisibilityToggle } from "./GroupVisibilityToggle";
import { ValidationGroupMessage } from "./ValidationMessage";
import { useBuilderNGN } from "../../Questionaire/BuilderNGN";
import { QuestionAction, QuestionVisibility, RuleGroupEntity, RuleGroupKind } from "@Savus-Inc/questionnaire-types";

export const RuleGroupAction = ({
  kind,
  onChange,
  value,
}: {
  kind: RuleGroupKind;
  onChange: (v: RuleGroupEntity["actionKind"]) => void;
  value: RuleGroupEntity["actionKind"];
}) => {
  const { context } = useBuilderNGN();

  switch (kind) {
    case RuleGroupKind.Visibility:
      return <GroupVisibilityToggle value={value as QuestionVisibility} onChange={onChange} />;
    case RuleGroupKind.Pre:
      return <GroupMiddleware kind={kind} onChange={onChange} value={value as QuestionAction} />;
    case RuleGroupKind.Post:
      return <GroupMiddleware kind={kind} onChange={onChange} value={value as never} />;
    case RuleGroupKind.Validation:
    case RuleGroupKind.Knockout:
      return <ValidationGroupMessage onChange={onChange} value={value as never} />;
    case RuleGroupKind.PreFill:
      return <ValuePrefill kind={kind} onChange={onChange} value={value as never} context={context()} />;
  }
};
