import React from "react";
import { NoData } from "../util/Empty";
import classNames from "classnames";
import { RemoveAction } from "../util/ItemActions";
import { cb } from "../../utils/cd";
import { useBuilderNGN } from "../Questionaire/BuilderNGN";
import { UploadDefaults } from "../Questionaire/UploadDefaults";
import { CarrierQuestionnaire } from "@Savus-Inc/questionnaire-types";

export const DefaultsMapping = ({ carrierId, carrierMap }: { carrierId: string; carrierMap: CarrierQuestionnaire }) => {
  const {
    groupPartActive,
    activateGroupPart,
    carrierMappingDefaultId,
    removeCarrierMappingItem,
  } = useBuilderNGN();

  if (!carrierMap?.defaults?.length) {
    return <NoData message={"No Defaults Found"} />;
  }

  return (
    <div className={"flex flex-col gap-3"}>
      <UploadDefaults carrierId={carrierId} />
      {carrierMap.defaults.map((q, i) => (
        <div
          key={q.id}
          className={classNames(
            "flex gap-2 items-center justify-between border-sky-200 border-b py-[2px] cursor-pointer px-2 rounded",
            {
              "bg-gray-100": groupPartActive === "c-defaults" && q.id === carrierMappingDefaultId,
            },
          )}
        >
          <div
            className={"w-full max-w-[300px] overflow-ellipsis overflow-hidden whitespace-nowrap "}
            onClick={cb(activateGroupPart, { carrier: carrierId, groupPart: "c-defaults", defaultsId: q.id })}
          >
            {q.jsonNode?.join(".") || "New default"}
          </div>
          <RemoveAction remove={cb(removeCarrierMappingItem, carrierId, i, "defaults")} small invert />
        </div>
      ))}
    </div>
  );
};