import { preventDefault } from "../../utils/preventDefault";
import classNames from "classnames";
import { ComplexOperator } from "@Savus-Inc/dsl/dist/types";

export function ComplexOperatorToggle({
  onClick,
  op,
  omit,
}: {
  onClick: (comp: ComplexOperator) => void;
  op: ComplexOperator;
  omit?: ComplexOperator[];
}) {
  return (
    <div className={"flex items-center mr-1 gap-3 border border-gray-300"}>
      <div className={"flex"}>
        {Object.values(ComplexOperator)
          .filter(v => !(omit || []).includes(v))
          .map(operator => (
            <button
              onClick={preventDefault(() => onClick(operator))}
              className={classNames("py-1 px-2 w-fit text-center cursor-pointer text-sm", {
                "bg-gray-600 text-white": operator === op,
              })}
            >
              {operator}
            </button>
          ))}
      </div>
    </div>
  );
}
