import { CarrierMappingDefaults } from "./CarrierMappingDefaults";
import { CarrierMappingQuestions } from "./CarrierMappingQuestions";
import { CarrierMappingRules } from "./CarrierMappingRules";

export const CarrierMappingData = ({ category }: { category: "c-knockout" | "c-questions" | "c-defaults" }) => {
  return (
    <div className={"flex h-full py-2 flex-col gap-2"}>
      {category === "c-defaults" ? (
        <CarrierMappingDefaults />
      ) : category === "c-questions" ? (
        <CarrierMappingQuestions />
      ) : (
        <CarrierMappingRules />
      )}
    </div>
  );
};
