import React, { MouseEvent, useCallback, useState } from "react";
import { AddIcon } from "./AddNew";
import classNames from "classnames";
import { RemoveIcon } from "./ItemActions";
import { Move } from "./Move";

interface AccordionItemProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  initial?: boolean;
  selected?: boolean;
}

export const TitleWithAction = ({
  title,
  add,
  remove,
  move,
}: {
  title: string | React.ReactNode;
  add?: () => void;
  remove?: () => void;
  move?: (direction: 1 | -1) => void;
}) => {
  const onRemove = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      remove && remove();
    },
    [remove],
  );
  const onAdd = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      add && add();
    },
    [add],
  );

  const onMove = useCallback(
    (direction: 1 | -1) => {
      move && move(direction);
    },
    [move],
  );

  return (
    <div className={"flex items-center justify-between w-full"}>
      <span className={"w-10/12 overflow-hidden whitespace-nowrap text-ellipsis text-left"}>{title}</span>
      <div className={"flex items-center gap-2"}>
        {move && <Move onChange={onMove} />}

        {add && (
          <span onClick={onAdd} className={"p-0.5 border border-green-600 rounded text-green-600"}>
            <AddIcon className={"h-4 w-4"} />
          </span>
        )}
        {remove && (
          <span onClick={onRemove} className={"p-0.5 text-red-400 rounded "}>
            <RemoveIcon />
          </span>
        )}
      </div>
    </div>
  );
};
export const AccordionItem = ({ title, children, initial, selected }: AccordionItemProps) => {
  const [isOpen, setIsOpen] = useState(initial);

  return (
    <div className='flex flex-col'>
      <button
        className={classNames(
          "w-full flex justify-between items-center px-1 p-0.5 my-1 border-b border-purple-100 font-medium focus:outline-none",
          {
            "text-gray-700": !selected,
            "bg-gray-100 text-gray-800": selected,
          },
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}

        <svg
          className={`ml-2 h-5 w-5 transition-transform ${isOpen ? "rotate-180" : ""}`}
          fill='none'
          stroke='currentColor'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
        </svg>
      </button>
      <div
        className={`transition-all bg-white ${
          isOpen ? "max-h-max overflow-visible py-1 pl-2 border border-sky-50" : "max-h-0 h-0 overflow-hidden"
        }`}
      >
        {isOpen ? children : ""}
      </div>
    </div>
  );
};
