import { ChangeEvent } from "react";
import { QuestionValueOption } from "@Savus-Inc/questionnaire-types";
import { DateValue, DateValueKind, Val } from "@Savus-Inc/dsl/dist/types";

export const ValueInput = ({
  value,
  kind,
  onChange,
  onlySingleDate,
}: {
  value: QuestionValueOption["value"];
  onChange: (ev: ChangeEvent) => void;
  kind: Val;
  onlySingleDate?: boolean;
}) => {
  const change = (keys: string[]) => (change: ChangeEvent<any>) => {
    keys.reduce((p: any, c, currentIndex) => {
      if (currentIndex === keys.length - 1) {
        p[c] = change.target.value;
      }

      return p[c];
    }, value);

    onChange({ target: { value: value } } as never);
  };

  switch (kind) {
    case Val.String:
      return (
        <input
          type='text'
          className='w-full border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
          defaultValue={value.value as string}
          onBlur={change(["value"])}
        />
      );
    case Val.Number:
      return (
        <input
          type='number'
          className='w-full border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
          defaultValue={value.value as string}
          onBlur={change(["value"])}
        />
      );
    case Val.Bool:
      return (
        <select
          value={value?.value?.toString() as string}
          className='w-full border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
          onChange={e => {
            change(["value"])({ target: { value: e.target.value === "true" } } as never);
          }}
        >
          <option value={"true"}>True</option>
          <option value={"false"}>False</option>
        </select>
      );
    case Val.Date:
      return (
        <div className={"flex gap-2 w-max"}>
          {!onlySingleDate && (
            <select
              id={"kind"}
              value={(value as DateValue).value?.kind}
              className='w-32 border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
              onChange={v =>
                change(["value"])({
                  target: {
                    value:
                      v.target.value === DateValueKind.Range
                        ? { kind: DateValueKind.Range, value: { from: null, to: null } }
                        : { kind: DateValueKind.Single, value: { from: null } },
                  },
                } as never)
              }
            >
              {Object.values(DateValueKind).map(item => (
                <option value={item} className={"capitalize"} key={item}>
                  {item}
                </option>
              ))}
            </select>
          )}
          {(value as DateValue).value?.kind === DateValueKind.Range ? (
            <div className={"flex gap-2"}>
              <input
                type='date'
                value={((value as DateValue).value.value as any).from?.slice(0, 10)}
                onChange={v =>
                  change(["value", "value", "from"])({ target: { value: new Date(v.target.value).toJSON() } } as never)
                }
                className='w-64 border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
              />
              <input
                type='date'
                value={((value as DateValue).value.value as any).to?.slice(0, 10)}
                onChange={v =>
                  change(["value", "value", "to"])({ target: { value: new Date(v.target.value).toJSON() } } as never)
                }
                className='w-64 border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
              />
            </div>
          ) : (
            <input
              type='date'
              value={((value as DateValue).value.value as any)?.from?.slice(0, 10)}
              onChange={v => {
                change(["value", "value", "from"])({ target: { value: new Date(v.target.value).toJSON() } } as never);
              }}
              className='w-64 relative border border-gray-300 rounded px-1 py-[2px] focus:outline-none focus:border-sky-500'
            />
          )}
        </div>
      );
  }
};
