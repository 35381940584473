import { useBuilderNGN } from "./BuilderNGN";
import { useAppState } from "../../state/State";
import React, { useCallback, useMemo } from "react";
import { AccordionItem, TitleWithAction } from "../util/Accourdion";
import { cb } from "../../utils/cd";
import { CarrierMapItem } from "../CarrierMapping/CarrierMapItem";

export const CarrierMapItems = () => {
  const { item, groupPartActive, insertCarrierMapping, carrierId } = useBuilderNGN();

  const {
    state: { carriers },
  } = useAppState();

  const withDefinedMapping: Record<string, boolean> = useMemo(
    () => item.carrierQuestionnaires.reduce((p, c) => ({ ...p, [c.carrierId]: true }), {}),
    [item],
  );

  const add = useCallback(
    (carrier: { name: string; id: string }) => {
      insertCarrierMapping({ carrierId: carrier.id, carrierName: carrier.name });
    },
    [insertCarrierMapping],
  );

  return (
    <>
      {carriers.map(q => (
        <AccordionItem
          title={<TitleWithAction title={q.name} add={withDefinedMapping[q.id] ? undefined : cb(add, q)} />}
          selected={groupPartActive.startsWith("c-") && carrierId === q.id}
          key={q.id}
          initial={groupPartActive.startsWith("c-") && carrierId === q.id}
        >
          <CarrierMapItem carrierId={q.id} key={q.id} />
        </AccordionItem>
      ))}
    </>
  );
};
