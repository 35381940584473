import { preventDefault } from "../../utils/preventDefault";

export function AddIcon({ className = "w-6 h-5" }: { className?: string }): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke='currentColor'
      className={className}
    >
      <path strokeLinecap='round' strokeLinejoin='round' d='M12 4.5v15m7.5-7.5h-15' />
    </svg>
  );
}

export function AddNew(props: { onClick: () => void; label?: string }) {
  return (
    <button
      className={"flex items-center justify-center gap-2 py-[2px] px-1 bg-gray-700 text-white text-sm rounded"}
      onClick={preventDefault(props.onClick)}
    >
      {props.label || "Add New"}
      <AddIcon />
    </button>
  );
}
