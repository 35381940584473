import { useMemo } from "react";
import { QuestionPreview } from "./QuestionPreview";
import { useNGN } from "@Savus-Inc/runtime-ngn/dist/ngn";
import classNames from "classnames";
import { DisplayDirection, QuestionnaireQuestionGroupEntity } from "@Savus-Inc/questionnaire-types";

export const GroupPreview = ({ group }: { group: QuestionnaireQuestionGroupEntity }) => {
  const { visibleQuestions } = useNGN();

  const questions = useMemo(() => {
    return Object.values(visibleQuestions.questions).filter(v => !v.sectionId && !v.parentId);
  }, [visibleQuestions]);

  return (
    <div className={"pb-10 flex flex-col mt-2 bg-white px-4 "}>
      {!!group.title && <h2 className={"text-2xl font-semibold py-1 border-b border-l-sky-500 mb-4"}>{group.title}</h2>}

      {!!visibleQuestions.sections.length && (
        <div
          className={classNames("flex gap-8 max-h-[82vh] overflow-y-auto", {
            "justify-start": visibleQuestions.displaySections === DisplayDirection.Row,
            "flex-col": visibleQuestions.displaySections === DisplayDirection.Col,
            "flex-wrap gap-y-1 gap-x-8": visibleQuestions.displaySections === DisplayDirection.RowWrap,
          })}
        >
          {visibleQuestions.sections.map((section, i) => (
            <div
              className={classNames("h-full p-4 bg-white ", {
                "border-r": visibleQuestions.sections.length > 1 && i < visibleQuestions.sections.length - 1,
              })}
            >
              <div className={"text-xl font-bold"}>{section.title}</div>
              <div
                className={classNames("flex gap-4", {
                  "justify-evenly": section.display === DisplayDirection.Row,
                  "flex-col": section.display === DisplayDirection.Col,
                  "flex-wrap gap-y-1 gap-x-8": section.display === DisplayDirection.RowWrap,
                })}
              >
                {section.questions.map(q => (
                  <QuestionPreview questionId={q} key={q} />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {questions.map(q => (
        <QuestionPreview questionId={q.id} key={q.id} />
      ))}
    </div>
  );
};
