import React from "react";
import { useBuilderNGN } from "./BuilderNGN";

export const UploadDefaults = ({ carrierId }: { carrierId: string }) => {
  const { bulkSetCarrierDefaults, item } = useBuilderNGN();
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file?.type?.toLowerCase()?.includes("json")) {
      const data = JSON.parse(await file?.text());
      bulkSetCarrierDefaults(carrierId, data);
      return;
    }
    alert("CSV Import is not yet supported");
  };

  return (
    <div className='flex items-center gap-2 mb-2'>
      <a
        className='py-1.5 px-3 rounded-xl border border-gray-200 text-sky-600 '
        href={`data:text/json;charset=utf-8,${encodeURIComponent(
          JSON.stringify(item.carrierQuestionnaires.find(q => q.carrierId === carrierId)?.defaults || []),
        )}`}
        download={`${Date.now()}-questionnaire-${item.title}-defaults.json`}
      >
        Download
      </a>
      <div className='w-full max-w-lg flex flex-col justify-center '>
        <input
          type='file'
          accept='.csv,.json'
          onChange={handleFileChange}
          className='block w-full text-sm text-gray-500 bg-gray-50  px-3 py-1 rounded-xl
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-violet-700
              hover:file:bg-violet-100
          '
        />
      </div>
    </div>
  );
};
