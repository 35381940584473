export const insert = <T>(arr: T[], item: T, index: number): void => {
  arr.splice(index, 0, item);
  arr.forEach((v: any, i) => {
    if (v && typeof v === "object" && "partOrder" in v) {
      v.partOrder = i;
    }
  });
};

export const replace = <T>(arr: T[], item: T, index: number): void => {
  arr.splice(index, 1, item);
  arr.forEach((v: any, i) => {
    if (v && typeof v === "object" && "partOrder" in v) {
      v.partOrder = i;
    }
  });
};

export const remove = <T>(arr: T[], index: number): void => {
  arr.splice(index, 1);
  arr.forEach((v: any, i) => {
    if (v && typeof v === "object" && "partOrder" in v) {
      v.partOrder = i;
    }
  });
};

export function moveArrayItem<T extends { id: string; partOrder: number }>(arr: T[], idx: number, direction: 1 | -1) {
  const newIdx = idx + direction;

  console.log(arr, newIdx, idx, direction);

  if (newIdx < 0 || newIdx >= arr.length) {
    return;
  }

  const member = arr[idx];
  member.partOrder = newIdx;
  const other = arr[newIdx];
  other.partOrder = newIdx - direction;
}
