import React from "react";
import { NoData } from "../util/Empty";
import classNames from "classnames";
import { CarrierQuestionnaire, QuestionValidityAction } from "@Savus-Inc/questionnaire-types";
import { RemoveAction } from "../util/ItemActions";
import { cb } from "../../utils/cd";
import { useBuilderNGN } from "../Questionaire/BuilderNGN";

export const KnockoutRules = ({ carrierId, carrierMap }: { carrierId: string; carrierMap: CarrierQuestionnaire }) => {
  const {
    groupPartActive,
    activateGroupPart,
    carrierMappingRuleId,
    removeCarrierMappingItem,
  } = useBuilderNGN();

  if (!carrierMap?.rules?.length) {
    return <NoData message={"No Knockout Rules Found"} />;
  }

  return (
    <div className={"flex flex-col gap-3"}>
      {carrierMap.rules.map((q, i) => (
        <div
          key={q.id}
          className={classNames(
            "flex gap-2 items-center justify-between border-sky-200 border-b py-[2px] cursor-pointer px-2 rounded",
            {
              "bg-gray-100": groupPartActive === "c-knockout" && q.id === carrierMappingRuleId,
            },
          )}
        >
          <div
            className={"w-full max-w-[300px] overflow-ellipsis overflow-hidden whitespace-nowrap "}
            onClick={cb(activateGroupPart, { carrier: carrierId, groupPart: "c-knockout", knockoutRuleId: q.id })}
          >
            {(q?.actionKind as QuestionValidityAction)?.message}
          </div>
          <RemoveAction remove={cb(removeCarrierMappingItem, carrierId, i, "rules")} small invert />
        </div>
      ))}
    </div>
  );
};