import { ApiResponse } from "../types/ApiResponse";
import { makeRequest } from "./makeRequest";
import { CustomerRecord } from "../types/customer-record";

export const listCustomerRecords = async (): Promise<ApiResponse<{ data: CustomerRecord[] }>> => {
  return makeRequest("get", `data/agency/customers?limit=100&offset=0`);
};

export const getCustomerRecord = async (customerId: string): Promise<ApiResponse<CustomerRecord>> => {
  return makeRequest("get", `data/agency/customers/${customerId}`);
};
export const createCustomerRecord = async (data?: CustomerRecord): Promise<ApiResponse<CustomerRecord>> => {
  return makeRequest("post", `data/agency/customers`, data);
};

export const listPreviousQuotes = async (applicationId: string): Promise<ApiResponse<{ data: any[] }>> => {
  return makeRequest("get", `questionnaire/${applicationId}?limit=100&offset=0`);
};

export const startQuote = async (
  applicationId: string,
  customerRecordId: string,
  products: { carrierId: string; lineOfBusinessId: string }[],
  prefillQuoteId?: string,
): Promise<ApiResponse<CustomerRecord>> => {
  return makeRequest(
    "post",
    `questionnaire/${applicationId}?customerRecordId=${customerRecordId}${
      prefillQuoteId ? `&prefillQuoteId=${prefillQuoteId}` : ""
    }`,
    { products },
  );
};

export const getCoverage = async (applicationId: string, questionnaireStateId?: string): Promise<ApiResponse<any>> => {
  return makeRequest("get", `questionnaire/${applicationId}/${questionnaireStateId}/coverage`);
};
