export const STATES = [
  {
    id: "05b46356-e87f-443b-94c3-078482c77f7f",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Alabama",
    shortName: "AL",
    zipcode: "36104",
    timezone: "Central Time Zone",
  },
  {
    id: "03b52356-e87f-443b-94c3-078482c77f7f",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Washington",
    shortName: "DC",
    zipcode: "36104",
    timezone: "Central Time Zone",
  },
  {
    id: "50e1c009-7a3a-4316-b0b3-b642a94a1f4e",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Alaska",
    shortName: "AK",
    zipcode: "99501",
    timezone: "Alaska Time Zone",
  },
  {
    id: "4fc3e8da-c9ef-46d1-aaac-b752e8478239",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Arizona",
    shortName: "AZ",
    zipcode: "85001",
    timezone: "Mountain Time Zone",
  },
  {
    id: "ca4ead35-aedb-4dc5-a7c5-013f9622b401",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Arkansas",
    shortName: "AR",
    zipcode: "72201",
    timezone: "Central Time Zone",
  },
  {
    id: "5577b3ef-915b-4ff6-ad73-65e5feefce9b",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "California",
    shortName: "CA",
    zipcode: "94203",
    timezone: "Pacific Time Zone",
  },
  {
    id: "15ab64ee-1d94-4342-8ca4-5deddb1aa5ec",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Colorado",
    shortName: "CO",
    zipcode: "80201",
    timezone: "Mountain Time Zone",
  },
  {
    id: "030a723b-ac43-4fa6-8d01-7c4f43cb0fe6",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Connecticut",
    shortName: "CT",
    zipcode: "06101",
    timezone: "Eastern Time Zone",
  },
  {
    id: "0f235834-796c-42ec-ab23-2705db60c465",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Delaware",
    shortName: "DE",
    zipcode: "19901",
    timezone: "Eastern Time Zone",
  },
  {
    id: "fdd8e46e-8708-48ae-af37-0943edf785da",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Florida",
    shortName: "FL",
    zipcode: "32301",
    timezone: "Eastern Time Zone",
  },
  {
    id: "748b4a1d-ef29-4ba1-92ec-62dda684adbe",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Georgia",
    shortName: "GA",
    zipcode: "30301",
    timezone: "Eastern Time Zone",
  },
  {
    id: "d70c0c9c-ba17-40b6-b99e-52de03027424",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Hawaii",
    shortName: "HI",
    zipcode: "96801",
    timezone: "Hawaii-Aleutian Time Zone",
  },
  {
    id: "0cf0f5bc-af34-4c54-821d-b6917b6576f0",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Idaho",
    shortName: "ID",
    zipcode: "83701",
    timezone: "Mountain Time Zone",
  },
  {
    id: "70489b89-ffbe-4f94-9ea3-528807c5367c",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Illinois",
    shortName: "IL",
    zipcode: "62701",
    timezone: "Central Time Zone",
  },
  {
    id: "20dc2e21-999b-46e8-b69c-8072f1b32aca",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Indiana",
    shortName: "IN",
    zipcode: "46201",
    timezone: "Eastern Time Zone",
  },
  {
    id: "036a52f0-473c-43b1-af49-968e44c22556",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Iowa",
    shortName: "IA",
    zipcode: "50301",
    timezone: "Central Time Zone",
  },
  {
    id: "4e59c0ba-0ae8-426e-806d-11049f8af417",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Kansas",
    shortName: "KS",
    zipcode: "66101",
    timezone: "Central Time Zone",
  },
  {
    id: "bf972182-7754-41ed-91e5-f2f119acdf9e",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Kentucky",
    shortName: "KY",
    zipcode: "40601",
    timezone: "Eastern Time Zone",
  },
  {
    id: "a58f4757-9d7a-4b52-9f33-f7f95407b1d4",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Louisiana",
    shortName: "LA",
    zipcode: "70801",
    timezone: "Central Time Zone",
  },
  {
    id: "2862b809-ede9-4fd6-9212-33d9b3aefbe3",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Maine",
    shortName: "ME",
    zipcode: "04330",
    timezone: "Eastern Time Zone",
  },
  {
    id: "69c2f0c8-437f-4e4e-a636-a1d5eaee94eb",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Maryland",
    shortName: "MD",
    zipcode: "21401",
    timezone: "Eastern Time Zone",
  },
  {
    id: "8a1fafff-a79f-41a3-9e3d-d5acb88c3eda",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Massachusetts",
    shortName: "MA",
    zipcode: "02108",
    timezone: "Eastern Time Zone",
  },
  {
    id: "e574fd14-fd3c-4c1f-9659-736152702dd3",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Michigan",
    shortName: "MI",
    zipcode: "48901",
    timezone: "Eastern Time Zone",
  },
  {
    id: "75d17acb-d14e-4451-9a92-57ead0d4e1e7",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Minnesota",
    shortName: "MN",
    zipcode: "55101",
    timezone: "Central Time Zone",
  },
  {
    id: "9355e28f-e5a1-4a51-9dd9-eafd557a74b1",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Mississippi",
    shortName: "MS",
    zipcode: "39201",
    timezone: "Central Time Zone",
  },
  {
    id: "27081a6d-79a8-44a7-9cc0-8eed5daa900a",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Missouri",
    shortName: "MO",
    zipcode: "65101",
    timezone: "Central Time Zone",
  },
  {
    id: "f9be17a4-638d-47ac-b6de-63218f9b7607",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Montana",
    shortName: "MT",
    zipcode: "59601",
    timezone: "Mountain Time Zone",
  },
  {
    id: "c532def0-f1b4-4524-95ad-13bfc37a4046",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Nebraska",
    shortName: "NE",
    zipcode: "68501",
    timezone: "Central Time Zone",
  },
  {
    id: "56feca63-5367-44ba-bc74-9afcdd29e932",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Nevada",
    shortName: "NV",
    zipcode: "89701",
    timezone: "Pacific Time Zone",
  },
  {
    id: "83ed4f88-d3ae-479d-b44f-cceb592ef353",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "New Hampshire",
    shortName: "NH",
    zipcode: "03301",
    timezone: "Eastern Time Zone",
  },
  {
    id: "27245b41-74c4-40b9-bc15-aa330405e061",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "New Jersey",
    shortName: "NJ",
    zipcode: "08601",
    timezone: "Eastern Time Zone",
  },
  {
    id: "af7ab04d-393c-4ea8-9752-50c1f82b2a37",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "New Mexico",
    shortName: "NM",
    zipcode: "87501",
    timezone: "Mountain Time Zone",
  },
  {
    id: "7e23174a-2fea-44e5-9c73-2e1d1e8ef7d2",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "New York",
    shortName: "NY",
    zipcode: "12201",
    timezone: "Eastern Time Zone",
  },
  {
    id: "4396d436-7f9c-43e7-ab0e-2cc7260f54b0",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "North Carolina",
    shortName: "NC",
    zipcode: "27601",
    timezone: "Eastern Time Zone",
  },
  {
    id: "6517f921-092d-4712-80d8-fd7c22be06d4",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "North Dakota",
    shortName: "ND",
    zipcode: "58501",
    timezone: "Central Time Zone",
  },
  {
    id: "d890e076-5af8-48c3-914a-137a379ed169",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Ohio",
    shortName: "OH",
    zipcode: "43201",
    timezone: "Eastern Time Zone",
  },
  {
    id: "61c6087d-5a87-4f62-a565-b75f8205cbbb",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Oklahoma",
    shortName: "OK",
    zipcode: "73101",
    timezone: "Central Time Zone",
  },
  {
    id: "a11ac369-74c0-43da-b1c1-b45e5b8b0d9b",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Oregon",
    shortName: "OR",
    zipcode: "97301",
    timezone: "Pacific Time Zone",
  },
  {
    id: "739e4783-4102-4908-ad2d-6bb9dc62370e",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Pennsylvania",
    shortName: "PA",
    zipcode: "17101",
    timezone: "Eastern Time Zone",
  },
  {
    id: "49d08448-714b-44f7-a646-9b213a4376dc",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Rhode Island",
    shortName: "RI",
    zipcode: "02901",
    timezone: "Eastern Time Zone",
  },
  {
    id: "4be7c331-ceeb-4173-8527-65bbe4abb803",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "South Carolina",
    shortName: "SC",
    zipcode: "29201",
    timezone: "Eastern Time Zone",
  },
  {
    id: "fd9d773b-7cf2-4fa6-aa69-de15200adaad",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "South Dakota",
    shortName: "SD",
    zipcode: "57501",
    timezone: "Central Time Zone",
  },
  {
    id: "4f604dff-5ad7-4dad-a13f-fb2708ea0050",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Tennessee",
    shortName: "TN",
    zipcode: "37201",
    timezone: "Central Time Zone",
  },
  {
    id: "43effd4d-d8cb-4606-9efc-f0452fb02852",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Texas",
    shortName: "TX",
    zipcode: "73301",
    timezone: "Central Time Zone",
  },
  {
    id: "b9a87d76-d2b4-45e1-8a49-c43f759440c1",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Utah",
    shortName: "UT",
    zipcode: "84101",
    timezone: "Mountain Time Zone",
  },
  {
    id: "4cc8c962-9d15-48f6-85fd-1898e6313053",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Vermont",
    shortName: "VT",
    zipcode: "05601",
    timezone: "Eastern Time Zone",
  },
  {
    id: "7d7eb7cf-cdcb-4acc-a692-cc0057e67c23",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Virginia",
    shortName: "VA",
    zipcode: "23218",
    timezone: "Eastern Time Zone",
  },
  {
    id: "ed2d6c2f-3b2d-4b33-9f83-e2e08113c53d",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Washington",
    shortName: "WA",
    zipcode: "98501",
    timezone: "Pacific Time Zone",
  },
  {
    id: "17c37fb3-196e-4cb0-af77-28af22279e35",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "West Virginia",
    shortName: "WV",
    zipcode: "25301",
    timezone: "Eastern Time Zone",
  },
  {
    id: "8658ef2b-c00d-478f-9b1f-789560acd92b",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Wisconsin",
    shortName: "WI",
    zipcode: "53701",
    timezone: "Central Time Zone",
  },
  {
    id: "08bbce3d-01ac-4723-8767-5486fbe1d59b",
    createdAt: "2024-04-16T10:58:09.547Z",
    updatedAt: "2024-04-16T10:58:09.547Z",
    deletedAt: null,
    name: "Wyoming",
    shortName: "WY",
    zipcode: "82001",
    timezone: "Mountain Time Zone",
  },
];
