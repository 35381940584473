import { preventDefault } from "../../utils/preventDefault";
import { useCallback, useEffect, useReducer, useState } from "react";
import classNames from "classnames";

import { cb } from "../../utils/cd";
import { Modal } from "./Modal";
import { unpack } from "../../utils/unpack";

export const RemoveIcon = ({ className = "w-4 h-4" }: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className={className}
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0'
      />
    </svg>
  );
};
export const RemoveAction = ({ remove, small, invert }: { remove: () => void; invert?: boolean; small?: boolean }) => {
  return (
    <button
      className={classNames("rounded text-sm", {
        "px-4 py-1": !small,
        "px-2 py-[2px]": small,
        "bg-red-400 text-white": !invert,
        "text-red-400": invert,
      })}
      onClick={preventDefault(remove)}
    >
      <RemoveIcon />
    </button>
  );
};

export const CopyAction = ({ value, small, invert }: { value: string; small?: boolean; invert?: boolean }) => {
  const [success, setSuccess] = useState(false);

  const copy = useCallback(() => {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        setSuccess(true);
      })
      .catch(err => {
        console.error("Error in copying text: ", err);
      });
  }, [value]);
  useEffect(() => {
    let t: string | number | null = null;
    if (success) {
      t = setTimeout(cb(setSuccess, false), 500) as never;
    }

    return () => {
      if (t) clearTimeout(t);
    };
  }, [success]);
  return (
    <div
      onClick={copy}
      className={classNames("transition-all rounded text-sm cursor-pointer", {
        "bg-sky-400 text-white": invert,
        "text-sky-800": !invert,
        "bg-green-200 text-white": success,
      })}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1}
        stroke='currentColor'
        className='size-6'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M16.5 8.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v8.25A2.25 2.25 0 0 0 6 16.5h2.25m8.25-8.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-7.5A2.25 2.25 0 0 1 8.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 0 0-2.25 2.25v6'
        />
      </svg>
    </div>
  );
};

export const ImportAction = ({ onImport }: { onImport: (data: string) => void }) => {
  const [isOpen, toggle] = useReducer(p => !p, false);
  const [data, setData] = useState("");

  const complete = useCallback(() => {
    onImport(data);
    toggle();
  }, [onImport, data]);

  return (
    <>
      <button className={"px-4 py-1 rounded bg-gray-700 text-white text-sm"} onClick={preventDefault(toggle)}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-4 h-4'
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M12 4.5v15m7.5-7.5h-15' />
        </svg>
      </button>
      <Modal isOpen={isOpen} onClose={toggle}>
        <div className={"flex flex-col gap-2"}>
          <div className={"flex flex-col gap-2"}>
            <label className={"text-sm"}>Paste the data</label>
            <textarea className={"border-2 rounded p-2"} value={data} onChange={unpack(setData)} />
          </div>
          <div className={"flex flex-col gap-2"}>
            <button className={"px-4 py-1 rounded bg-sky-400 text-white text-sm"} onClick={complete}>
              Import
            </button>
            <button className={"px-4 py-1 rounded bg-red-400 text-white text-sm"} onClick={toggle}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const AddAction = ({ add }: { add: () => void }) => {
  return (
    <button className={"px-4 py-1 rounded bg-gray-700 text-white text-sm"} onClick={preventDefault(add)}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='w-4 h-4'
      >
        <path strokeLinecap='round' strokeLinejoin='round' d='M12 4.5v15m7.5-7.5h-15' />
      </svg>
    </button>
  );
};

export const ItemActions = ({
  insert,
  move,
  duplicate,
  remove,
}: Record<"insert" | "remove" | "duplicate" | "move", () => void>) => {
  const [isOpen, toggle] = useReducer(p => !p, false);

  return (
    <div className={"flex-col gap-2 mr-2 relative"}>
      {isOpen && (
        <div className={"absolute gap-2 flex transition-all -left-60"}>
          <AddAction add={insert} />
          <button className={"px-4 py-1 rounded bg-red-400 text-white text-sm"} onClick={preventDefault(remove)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-4 h-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0'
              />
            </svg>
          </button>
          <button className={"px-4 py-1 rounded bg-sky-400 text-white text-sm"} onClick={preventDefault(duplicate)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-4 h-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75'
              />
            </svg>
          </button>
          <button className={"px-4 py-1 rounded bg-orange-400 text-white text-sm"} onClick={preventDefault(move)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth='1.5'
              stroke='currentColor'
              className='w-4 h-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5'
              />
            </svg>
          </button>
        </div>
      )}
      <button className={""} onClick={preventDefault(toggle)}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='w-6 h-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z'
          />
        </svg>
      </button>
    </div>
  );
};
