import { QuestionValues } from "./QuestionValues";
import { useBuilderNGN } from "./BuilderNGN";
import { unpack } from "../../utils/unpack";
import { NoData } from "../util/Empty";
import { Val } from "@Savus-Inc/dsl/dist/types";
import {
  AddMoreIconName,
  InputType,
  QuestionValueAutocompleteIntegration,
  QuestionValueFormatting,
} from "@Savus-Inc/questionnaire-types";
import { cb } from "../../utils/cd";
import { useMemo } from "react";
import { AccordionItem, TitleWithAction } from "../util/Accourdion";
import { QuestionsGroupQuestions } from "./QuestionsGroupQuestions";
import { DisplaySelection } from "./DisplaySelection";
import { Editor } from "../util/Editor";
import { getAllRefs } from "../Rules/utils/getAvailableRefs";
import { QuestionMapping } from "./QuestionMapping";

export const QuestionUI = () => {
  const { changeQuestionUIDetails, updateQuestion, selectedQuestion, currentGroup, context, insertQuestion, item } =
    useBuilderNGN();

  const availableRefs = useMemo(
    () =>
      getAllRefs(context()).map(v => ({
        label: v.label.toString(),
        value: v.referenceId,
      })),
    [context],
  );

  const subQuestions = useMemo(() => {
    return currentGroup?.questions.filter(v => v.parentId === selectedQuestion?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQuestion, currentGroup, item]);

  const availableInputTypes = useMemo(() => {
    if (!selectedQuestion) return [];
    return Object.values(InputType).filter(v => {
      if (selectedQuestion.question?.autocompleteIntegration === QuestionValueAutocompleteIntegration.OwnValues) {
        return ![InputType.Phone, InputType.RichText, InputType.Header, InputType.Textarea].includes(v);
      }

      switch (selectedQuestion.question?.kind) {
        case Val.String:
          return [InputType.Phone, InputType.RichText, InputType.Header, InputType.Textarea].includes(v);
        case Val.Number:
          return false;
        case Val.Bool:
          return v === InputType.YesNo;
        case Val.Date:
          return false;
        default:
          return false;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, selectedQuestion]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const availableParents = useMemo(() => {
    if (!currentGroup) return [];
    return currentGroup?.questions.filter(v => !v.parentId && v.id !== selectedQuestion?.id) || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroup, selectedQuestion]);

  if (!selectedQuestion || !currentGroup) return <NoData />;

  return (
    <div className={"flex flex-col gap-3 pb-10"} key={selectedQuestion.id}>
      <div className=' flex flex-col gap-3 py-4 border-b border-gray-2'>
        <div className={"flex gap-4 items-center"}>
          <label className={"w-32"} htmlFor='label'>
            ID:{" "}
          </label>
          <Editor
            refs={[]}
            initial={selectedQuestion.question?.externalId || ""}
            onChange={changeQuestionUIDetails("externalId")}
          />
        </div>
        <div className={"flex gap-4 items-center"}>
          <label className={"w-32"} htmlFor='label'>
            Label:{" "}
          </label>
          <Editor
            refs={availableRefs}
            initial={selectedQuestion.question?.label || ""}
            onChange={changeQuestionUIDetails("label")}
          />
        </div>
      </div>

      <div className='py-2 flex flex-col gap-3  border-b border-gray-2'>
        {!selectedQuestion.parentId && currentGroup.sections && (
          <div className={"flex gap-4 items-center"}>
            <label className={"w-32"} htmlFor='section'>
              Section
            </label>
            <select
              id={"section"}
              value={selectedQuestion.sectionId || ""}
              className='w-full border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
              onChange={unpack(updateQuestion("sectionId"))}
            >
              <option value={undefined}>None</option>
              {currentGroup.sections.map(item => (
                <option value={item.id} className={"capitalize"}>
                  {item.title} (S-{item.partOrder})
                </option>
              ))}
            </select>
          </div>
        )}

        {!subQuestions?.length && (
          <>
            {!!availableParents.length && (
              <div className={"flex gap-4 items-center"}>
                <label className={"w-32"} htmlFor='parent'>
                  Container question
                </label>
                <select
                  id={"parent"}
                  value={selectedQuestion.parentId || ""}
                  className='w-full border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
                  onChange={unpack(updateQuestion("parentId"))}
                >
                  <option value={undefined}>None</option>
                  {availableParents.map(item => (
                    <option value={item.id} className={"capitalize"}>
                      {item.question?.label} ({item.question?.externalId || ""})
                    </option>
                  ))}
                </select>
              </div>
            )}
          </>
        )}
      </div>

      <div className='pb-4 pt-2 flex flex-col gap-3  border-b border-gray-2'>
        <div className={"flex gap-4 items-center"}>
          <label className={"w-32"} htmlFor='subText'>
            Sub Text:{" "}
          </label>
          <Editor
            refs={availableRefs}
            initial={selectedQuestion.question?.subText || ""}
            onChange={changeQuestionUIDetails("subText")}
          />
        </div>

        {!subQuestions?.length && (
          <>
            <div className={"flex gap-4 items-center"}>
              <label className={"w-32"} htmlFor='placeholder'>
                Placeholder:{" "}
              </label>
              <Editor
                refs={availableRefs}
                initial={selectedQuestion.question?.placeholder || ""}
                onChange={changeQuestionUIDetails("placeholder")}
              />
            </div>
            <div className={"flex gap-4 items-center"}>
              <label className={"w-32"} htmlFor='tooltip'>
                Tooltip:{" "}
              </label>
              <Editor
                refs={availableRefs}
                initial={selectedQuestion.question?.tooltip || ""}
                onChange={changeQuestionUIDetails("tooltip")}
              />
            </div>
          </>
        )}
      </div>

      {!subQuestions?.length && (
        <div className={"flex gap-4 items-center"}>
          <label className={"w-32"} htmlFor='kind'>
            Answer Kind:{" "}
          </label>
          <select
            id={"kind"}
            value={selectedQuestion.question?.kind}
            className='w-full border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
            onChange={unpack(changeQuestionUIDetails("kind"))}
          >
            {Object.values(Val).map(item => (
              <option value={item} className={"capitalize"}>
                {item}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className={"flex gap-4 items-center"}>
        <label className={"w-32"} htmlFor='format'>
          Input Type:{" "}
        </label>
        <select
          id={"format"}
          value={selectedQuestion.question?.inputType || ""}
          className='w-full border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
          onChange={unpack(changeQuestionUIDetails("inputType"))}
        >
          <option value={undefined}>Default</option>
          {availableInputTypes.map(item => (
            <option value={item} className={"capitalize"}>
              {item}
            </option>
          ))}
        </select>
      </div>

      {!subQuestions?.length && (
        <>
          <div className={"flex gap-4 items-center"}>
            <label className={"w-32"} htmlFor='format'>
              Answer Format:{" "}
            </label>
            <select
              id={"format"}
              value={selectedQuestion.question?.format || ""}
              className='w-full border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
              onChange={unpack(changeQuestionUIDetails("format"))}
            >
              <option value={undefined}>None</option>
              {Object.values(QuestionValueFormatting).map(item => (
                <option value={item} className={"capitalize"}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </>
      )}

      <div className={"flex gap-4 items-center justify-start"}>
        <label className={"w-32"} htmlFor='multipleAnswers'>
          Multiple answers:{" "}
        </label>
        <input
          type='checkbox'
          id={"multipleAnswers"}
          className='border border-gray-300 rounded py-[2px] focus:outline-none focus:border-sky-500'
          checked={selectedQuestion.question?.multipleAnswers || false}
          onChange={cb(changeQuestionUIDetails("multipleAnswers"), !selectedQuestion.question?.multipleAnswers)}
        />
      </div>

      {!subQuestions?.length && (
        <>
          <div className={"flex gap-4 items-center"}>
            <label className={"w-32"} htmlFor='autocompleteIntegration'>
              Answer Options:{" "}
            </label>
            <select
              id={"autocompleteIntegration"}
              value={selectedQuestion.question?.autocompleteIntegration || ""}
              className='w-full border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
              onChange={unpack(v =>
                changeQuestionUIDetails("autocompleteIntegration")(v === "Open Ended" ? null : (v as string)),
              )}
            >
              <option value={undefined}>Open Ended</option>
              {Object.values(QuestionValueAutocompleteIntegration).map(item => (
                <option value={item} className={"capitalize"}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </>
      )}

      {!!selectedQuestion.question?.multipleAnswers && (
        <>
          <DisplaySelection
            select={changeQuestionUIDetails("answerDisplay")}
            selected={selectedQuestion.question?.answerDisplay}
            label={"Answer Display"}
          />
          <div className={"flex gap-4 items-center"}>
            <label className={"w-32"} htmlFor='format'>
              Max Answers:{" "}
            </label>
            <input
              type='number'
              defaultValue={selectedQuestion.question?.maxAnswers}
              className='w-full border border-gray-300 rounded py-[2px] focus:outline-none focus:border-sky-500 px-1'
              onBlur={unpack(changeQuestionUIDetails("maxAnswers"))}
            />
          </div>
          <div className={"flex gap-4 items-center"}>
            <label className={"w-32"} htmlFor='format'>
              Min Answers:{" "}
            </label>
            <input
              type='number'
              defaultValue={selectedQuestion.question?.minAnswers}
              className='w-full border border-gray-300 rounded py-[2px] focus:outline-none focus:border-sky-500 px-1'
              onBlur={unpack(changeQuestionUIDetails("minAnswers"))}
            />
          </div>
          <div className={"flex gap-4 items-center"}>
            <label className={"w-32"} htmlFor='icon'>
              Add More Icon Action:{" "}
            </label>
            <select
              id={"icon"}
              value={selectedQuestion.question?.addMoreIcon}
              className='w-full border border-gray-300 rounded px-1 py-1 focus:outline-none focus:border-sky-500'
              onChange={unpack(changeQuestionUIDetails("addMoreIcon"))}
            >
              {Object.values(AddMoreIconName).map(item => (
                <option value={item} className={"capitalize"}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <div className={"flex gap-4 items-center"}>
            <label className={"w-32"} htmlFor='format'>
              Add More Action Text:{" "}
            </label>
            <input
              defaultValue={selectedQuestion.question?.addMoreText}
              className='w-full border border-gray-300 rounded py-[2px] focus:outline-none focus:border-sky-500 px-1'
              onBlur={unpack(changeQuestionUIDetails("addMoreText"))}
            />
          </div>
        </>
      )}

      {!!subQuestions?.length && (
        <DisplaySelection
          select={changeQuestionUIDetails("subQuestions")}
          selected={selectedQuestion.question?.subQuestions}
          label={"Question Display"}
        />
      )}

      {!subQuestions?.length && (
        <>
          {selectedQuestion.question?.autocompleteIntegration === QuestionValueAutocompleteIntegration.OwnValues && (
            <QuestionValues
              values={selectedQuestion.question?.values}
              kind={selectedQuestion.question?.kind}
              onChange={unpack(changeQuestionUIDetails("values"))}
            />
          )}
        </>
      )}
      <QuestionMapping />
      {!selectedQuestion.parentId && (
        <AccordionItem
          title={
            <TitleWithAction title={"Sub Questions"} add={cb(insertQuestion, currentGroup.id, selectedQuestion?.id)} />
          }
          initial={true}
          key={JSON.stringify(subQuestions)}
        >
          <QuestionsGroupQuestions
            questions={(subQuestions || []).sort((a, b) => a.partOrder - b.partOrder)}
            container
            key={JSON.stringify(subQuestions)}
            groupId={currentGroup.id}
          />
        </AccordionItem>
      )}
    </div>
  );
};
