import React, { MouseEvent, useCallback } from "react";

export function Move({ onChange }: { onChange: (direction: 1 | -1) => void }) {
  const change = useCallback(
    (direction: 1 | -1) => (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onChange(direction);
    },
    [onChange],
  );
  return (
    <div className={"flex gap-1"}>
      <span onClick={change(-1)} className={"cursor-pointer rounded text-gray-500"}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='green'
          className='size-6'
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='m4.5 15.75 7.5-7.5 7.5 7.5' />
        </svg>
      </span>
      <span onClick={change(1)} className={"cursor-pointer rounded text-gray-500"}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='orange'
          className='size-6'
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' />
        </svg>
      </span>
    </div>
  );
}
