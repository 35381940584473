import { DisplayDirection } from "@Savus-Inc/questionnaire-types";
import { cb } from "../../utils/cd";
import classNames from "classnames";

export const DisplaySelection = ({
  select,
  selected,
  label,
}: {
  label?: string;
  selected?: DisplayDirection;
  select: (s: DisplayDirection) => void;
}) => {
  const boxIcon = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      className='size-6'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M5.25 7.5A2.25 2.25 0 0 1 7.5 5.25h9a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25h-9a2.25 2.25 0 0 1-2.25-2.25v-9Z'
      />
    </svg>
  );

  return (
    <div className={"flex gap-5"}>
      <div>{label || "Display Direction"}:</div>
      <div
        onClick={cb(select, DisplayDirection.Row)}
        className={classNames("cursor-pointer flex flex-col items-center p-2 rounded min-w-32 justify-center", {
          "border broder-gray-100": selected !== DisplayDirection.Row,
          "border border-sky-400 shadow shadow-sky-400": selected === DisplayDirection.Row,
        })}
      >
        <div className={"flex"}>
          {boxIcon} {boxIcon} {boxIcon}
        </div>
        <div>{DisplayDirection.Row}</div>
      </div>

      <div
        onClick={cb(select, DisplayDirection.Col)}
        className={classNames("cursor-pointer flex flex-col items-center p-2 rounded min-w-32 justify-center", {
          "border broder-gray-100": selected !== DisplayDirection.Col,
          "border border-sky-400 shadow shadow-sky-400": selected === DisplayDirection.Col,
        })}
      >
        <div className={"flex flex-col"}>
          {boxIcon} {boxIcon}
        </div>
        <div>{DisplayDirection.Col}</div>
      </div>

      <div
        onClick={cb(select, DisplayDirection.RowWrap)}
        className={classNames("cursor-pointer flex flex-col items-center p-2 rounded min-w-32 justify-center", {
          "border broder-gray-100": selected !== DisplayDirection.RowWrap,
          "border border-sky-400 shadow shadow-sky-400": selected === DisplayDirection.RowWrap,
        })}
      >
        <div className={"flex flex-wrap w-20"}>
          {boxIcon} {boxIcon} {boxIcon} {boxIcon} {boxIcon}
        </div>
        <div>{DisplayDirection.RowWrap}</div>
      </div>
    </div>
  );
};
