import { unpack } from "../../utils/unpack";
import { useCallback, useMemo, useState } from "react";
import { useBuilderNGN } from "./BuilderNGN";
import { NoData } from "../util/Empty";
import { cb } from "../../utils/cd";
import { QuestionnaireQuestionEntity } from "@Savus-Inc/questionnaire-types";

export const Spotlight = ({
  groupId,
  questionId,
  close,
}: {
  groupId?: string;
  questionId?: string;
  close: () => void;
}) => {
  const [query, setQuery] = useState("");
  const { item, activateGroupPart } = useBuilderNGN();
  const availableQuestions = useMemo(() => {
    if (questionId) {
      return item.groups
        .map(g => g.questions.map(q => ({ ...q, groupId: g.id })))
        .flat()
        .filter(q => {
          return (
            (q.parentId === questionId && q.question?.externalId?.toLowerCase().includes(query.toLowerCase())) ||
            q.question?.label.toLowerCase().includes(query)
          );
        });
    }

    if (groupId) {
      return item.groups
        .filter(g => g.id === groupId)
        .map(g => g.questions.map(q => ({ ...q, groupId: g.id })))
        .flat()
        .filter(q => {
          return (
            q.question?.externalId?.toLowerCase().includes(query.toLowerCase()) ||
            q.question?.label.toLowerCase().includes(query)
          );
        });
    }

    return item.groups
      .map(g => g.questions.map(q => ({ ...q, groupId: g.id })))
      .flat()
      .filter(q => {
        return (
          q.question?.externalId?.toLowerCase().includes(query.toLowerCase()) ||
          q.question?.label.toLowerCase().includes(query)
        );
      });
  }, [item, groupId, questionId, query]);

  const select = useCallback(
    (question: QuestionnaireQuestionEntity) => {
      activateGroupPart({
        questionId: question?.questionId,
        groupPart: "g-questions",
        group: question?.groupId,
      });
      close();
    },
    [activateGroupPart, close],
  );

  return (
    <div>
      <div className={"flex flex-col w-11/12 gap-2 m"}>
        Spotlight search
        <input
          value={query}
          className='w-full border border-gray-300 rounded py-[2px] focus:outline-none focus:border-sky-500 px-1'
          onChange={unpack(setQuery)}
        />
      </div>

      {availableQuestions.length ? (
        <div className={"max-h-[50vh] overflow-y-auto"}>
          <div className={"flex flex-col gap-3"}>
            {availableQuestions.map(q => (
              <div key={q.id} className={"flex gap-4 border rounded px-2 py-1 cursor-pointer"} onClick={cb(select, q)}>
                <div className={"font-semibold"}>{q.question?.externalId || ""}</div>
                <div>{q.question?.label || ""}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NoData message={"No questions found that match provided filter"} />
      )}
    </div>
  );
};
