import {
  BeautifulMentionNode,
  BeautifulMentionsMenuItemProps,
  BeautifulMentionsMenuProps,
  BeautifulMentionsPlugin,
  BeautifulMentionsTheme,
} from "lexical-beautiful-mentions";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { Suggestion } from "./Autocomplete";
import { EditorState } from "lexical";
import {
  getInterpolatedRefId,
  getTextRefParts,
  isRefInterpolated,
} from "@Savus-Inc/runtime-ngn/dist/utils/get-text-ref-parts";
import { useDebounce } from "./useDebounce";

export const Editor = ({
  refs,
  initial,
  onChange,
}: {
  refs: Suggestion[];
  initial: string;
  onChange: (v: string) => void;
}) => {
  const mentionItems = {
    "@": refs.map(v => ({ value: v.label.toString(), id: v.value.toString() })),
    "#": [{ id: "each", value: "each" }],
  };

  function CustomMenu({ loading, ...props }: BeautifulMentionsMenuProps) {
    return (
      <ul {...props} className='m-0 mt-6 bg-white flex flex-col gap-2 rounded border border-gray-100 max-w-[600px]' />
    );
  }

  const CustomMenuItem = forwardRef<HTMLLIElement, BeautifulMentionsMenuItemProps>(({ selected, ...props }, ref) => (
    <li className={`${selected ? "bg-gray-100" : "bg-white"} p-1 border w-[420px] flex-1 flex `} {...props} ref={ref} />
  ));

  const beautifulMentionsTheme: BeautifulMentionsTheme = {
    // 👇 use the trigger name as the key
    "@": "px-2 py-[2px] my-0.5 bg-green-200 rounded border border-gray-200",
    "#": "px-2 py-[2px] my-0.5 bg-sky-200 rounded border border-gray-200",
  };

  const initialState = useMemo(() => {
    return {
      root: {
        children: [
          {
            children:
              getTextRefParts(initial)
                .map(v => {
                  if (v.indexOf("#each") > 0) {
                    return [v.substring(v.indexOf("#each")), "#each", v.substring(v.indexOf("#each") + 5)];
                  }
                  return [v];
                })
                .flat()
                .map(v => {
                  if (isRefInterpolated(v)) {
                    const refId = getInterpolatedRefId(v);
                    const ref = refs.find(v => v.value === refId);

                    if (!ref) {
                      return {
                        detail: 0,
                        format: 0,
                        mode: "normal",
                        style: "",
                        text: "",
                        type: "text",
                        version: 1,
                      };
                    }

                    return {
                      trigger: "@",
                      value: ref?.label,
                      data: {
                        id: refId,
                      },
                      type: "beautifulMention",
                      version: 1,
                    };
                  }

                  if (v === "#each") {
                    return {
                      trigger: "#",
                      value: "each",
                      data: {
                        id: "each",
                      },
                      type: "beautifulMention",
                      version: 1,
                    };
                  }

                  return {
                    detail: 0,
                    format: 0,
                    mode: "normal",
                    style: "",
                    text: v,
                    type: "text",
                    version: 1,
                  };
                }) || [],
            direction: "ltr",
            format: "",
            indent: 0,
            type: "paragraph",
            version: 1,
            textFormat: 0,
          },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "root",
        version: 1,
      },
    };
  }, [initial, refs]);

  const [state, setState] = useState<EditorState | undefined>();

  useDebounce(
    () => {
      if (state)
        onChange(
          (state.toJSON() as any).root.children[0].children
            .map((v: any) => {
              if (v.trigger === "@") {
                return `{${v.data.id}}`;
              } else if (v.trigger === "#") {
                return `#${v.data.id}`;
              }

              return v.text;
            })
            .join(""),
        );
    },
    500,
    [state],
  );

  function change(editorState: EditorState) {
    setState(editorState);
  }

  function onError(error: any) {
    console.error(error);
  }

  const initialConfig = {
    namespace: "MyEditor",
    editorState: JSON.stringify(initialState),
    theme: {
      beautifulMentions: beautifulMentionsTheme,
    },
    onError,
    nodes: [BeautifulMentionNode],
  };

  function MyOnChangePlugin({ onChange }: any) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
      return editor.registerUpdateListener(({ editorState }) => {
        onChange(editorState);
      });
    }, [editor, onChange]);

    return null;
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        contentEditable={
          <ContentEditable
            contentEditable={true}
            className={"rounded px-2 py-[2px] border border-gray-300 bg-white w-full"}
          />
        }
        placeholder={<div></div>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <BeautifulMentionsPlugin
        items={mentionItems}
        menuComponent={CustomMenu}
        menuItemComponent={CustomMenuItem}
        creatable={"{{name}}"}
      />
      <MyOnChangePlugin onChange={change} />
    </LexicalComposer>
  );
};
