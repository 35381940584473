export const BUSINESS_TYPES = {
  data: [
    {
      id: "ed6afe1b-f94a-4641-a4d7-64caf1bacd76",
      title: "Canola farming",
      naicsCode: "111120",
      naicsTitle: "Oilseed (except Soybean) Farming",
    },
    {
      id: "08ed9828-ec43-40ad-a3c0-d7a9a44c8a7f",
      title: "Canola farming",
      naicsCode: "111120",
      naicsTitle: "Oilseed (except Soybean) Farming",
    },
    {
      id: "f69d0163-9a6b-4a15-8c60-83d9222529a3",
      title: "Canola (rapeseed) oil, cake and meal made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "09bd4f33-6e6e-4b9b-8436-6298e624ca71",
      title: "Canola (rapeseed) oil, cake and meal made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "3845daa8-1a46-4bbd-b489-3765d626b453",
      title: "Castor oil and pomace made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "18cc39dd-b5f1-4264-9d92-2c97dfde40dc",
      title: "Castor oil and pomace made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "cf57b67a-2c40-4c27-a68e-55d0d7bcbac7",
      title: "Coconut oil made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "6d6ce5ca-9624-45e4-a3d1-8a6076688eed",
      title: "Coconut oil made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "39de3283-8c26-4e27-b3b6-86720812170a",
      title: "Cottonseed oil, cake and meal made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "a335fc53-53b3-4bf4-9678-aa6c615e9a34",
      title: "Cottonseed oil, cake and meal made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "6f43178b-d11c-4992-8ec6-1d0a37175be4",
      title: "Crude soybean oil manufacturing",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "340e05ec-edbf-407d-a4b8-f23b062d18b0",
      title: "Crude soybean oil manufacturing",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "7ef72b5e-2d49-497b-8fd6-248618e9fd9d",
      title: "Deodorized soybean oil made in oil mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "58fce86d-5a25-4fd6-8e2d-093ede80e649",
      title: "Deodorized soybean oil made in oil mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "9fde71d6-97e7-4323-9f52-9d3755d3e205",
      title: "Flaxseed farming",
      naicsCode: "111120",
      naicsTitle: "Oilseed (except Soybean) Farming",
    },
    {
      id: "cbc11c27-3d7c-4b76-85a0-57deb9a3ad06",
      title: "Flaxseed farming",
      naicsCode: "111120",
      naicsTitle: "Oilseed (except Soybean) Farming",
    },
    {
      id: "220b07e0-c681-4e84-aeb9-e859015e5d5a",
      title: "Flaxseed oil made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "73d9e937-6db2-48dc-af8d-96a2b6f7b1d4",
      title: "Flaxseed oil made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "f54d264a-1566-4908-9a0b-6e994b236780",
      title: "Lecithin, cottonseed made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "e58ee0c9-db27-4eff-8199-ad19be005d32",
      title: "Lecithin, cottonseed made in crushing mills",
      naicsCode: "311224",
      naicsTitle: "Soybean and Other Oilseed Processing",
    },
    {
      id: "56842fdf-71bd-4c46-b650-2c7b4a1dd6ea",
      title: "",
      naicsCode: "315250",
      naicsTitle: "Cut and Sew Apparel Manufacturing (except Contractors)",
    },
    {
      id: "2b60c17e-09c8-4474-a5c6-c7ef06411c8d",
      title: "",
      naicsCode: "315250",
      naicsTitle: "Cut and Sew Apparel Manufacturing (except Contractors)",
    },
    {
      id: "88b93b71-5c9a-4df6-9bd2-24cc92c3c4a1",
      title: "3D printer machinery manufacturing",
      naicsCode: "333248",
      naicsTitle: "All Other Industrial Machinery Manufacturing",
    },
    {
      id: "7f2decbd-19f7-4915-bdf3-c59d281675c7",
      title: "3D printer machinery manufacturing",
      naicsCode: "333248",
      naicsTitle: "All Other Industrial Machinery Manufacturing",
    },
    {
      id: "b565fe5f-0f03-4bb9-a9dc-698b4b6957cc",
      title: "above ground swimming pools manufacturing",
      naicsCode: "339920",
      naicsTitle: "Sporting and Athletic Goods Manufacturing",
    },
    {
      id: "542bce42-6abc-4aa7-ab14-b0fa54814548",
      title: "above ground swimming pools manufacturing",
      naicsCode: "339920",
      naicsTitle: "Sporting and Athletic Goods Manufacturing",
    },
    {
      id: "9b2e755b-f714-43bc-bc0b-7dca167eddf0",
      title: "Abrasion testing machines manufacturing",
      naicsCode: "334519",
      naicsTitle: "Other Measuring and Controlling Device Manufacturing",
    },
    {
      id: "81920941-d726-4730-9f9a-2050be8446d9",
      title: "Abrasion testing machines manufacturing",
      naicsCode: "334519",
      naicsTitle: "Other Measuring and Controlling Device Manufacturing",
    },
    {
      id: "c6ac036d-290f-4074-a81b-fb203674d2cc",
      title: "Abrasive and nonabrasive buffing and polishing wheels manufacturing",
      naicsCode: "327910",
      naicsTitle: "Abrasive Product Manufacturing",
    },
    {
      id: "10428b14-648c-46b3-a181-32ba6f49dbb1",
      title: "Abrasive and nonabrasive buffing and polishing wheels manufacturing",
      naicsCode: "327910",
      naicsTitle: "Abrasive Product Manufacturing",
    },
    {
      id: "8a512538-f9af-4971-84b9-466c54a1bdd7",
      title: "Abrasive-coated cloth made from purchased cloth",
      naicsCode: "327910",
      naicsTitle: "Abrasive Product Manufacturing",
    },
    {
      id: "80b1e6fb-d567-4f40-80e1-c06158b4d4a8",
      title: "Abrasive-coated cloth made from purchased cloth",
      naicsCode: "327910",
      naicsTitle: "Abrasive Product Manufacturing",
    },
    {
      id: "a246aad8-89f9-4ce3-9471-72dab3a79dfd",
      title: "Abrasive-coated paper made from purchased paper",
      naicsCode: "327910",
      naicsTitle: "Abrasive Product Manufacturing",
    },
    {
      id: "eda13fa3-faaf-4e30-b56d-5717ea5166d6",
      title: "Abrasive-coated paper made from purchased paper",
      naicsCode: "327910",
      naicsTitle: "Abrasive Product Manufacturing",
    },
    {
      id: "6f74e8d7-1ee1-4e1a-a1da-d3be6bccb35b",
      title: "Abrasive dental points manufacturing",
      naicsCode: "339114",
      naicsTitle: "Dental Equipment and Supplies Manufacturing",
    },
    {
      id: "c10b9e67-d086-4f5a-aa53-97f81a80ac1a",
      title: "Abrasive dental points manufacturing",
      naicsCode: "339114",
      naicsTitle: "Dental Equipment and Supplies Manufacturing",
    },
    {
      id: "0940f79c-9f49-408e-aa0e-bc14c9e305fe",
      title: "Abrasive, natural and artificial grains manufacturing",
      naicsCode: "327910",
      naicsTitle: "Abrasive Product Manufacturing",
    },
    {
      id: "c4228446-08b2-44bd-be93-4395c694d4b2",
      title: "Abrasive, natural and artificial grains manufacturing",
      naicsCode: "327910",
      naicsTitle: "Abrasive Product Manufacturing",
    },
    {
      id: "e8f7df33-9c44-4ae7-bc99-dff4ea8ec4c0",
      title: "Abrasive products manufacturing",
      naicsCode: "327910",
      naicsTitle: "Abrasive Product Manufacturing",
    },
    {
      id: "4e9546a3-08cf-4931-bd53-da8fe9721e6a",
      title: "Abrasive products manufacturing",
      naicsCode: "327910",
      naicsTitle: "Abrasive Product Manufacturing",
    },
    {
      id: "8242336f-a020-434d-a5b1-0fd24286d25b",
      title: "Accelerators manufacturing",
      naicsCode: "325199",
      naicsTitle: "All Other Basic Organic Chemical Manufacturing",
    },
    {
      id: "70f83bc3-0a2a-4eb3-bc0e-88e3c8c408ba",
      title: "Accelerators manufacturing",
      naicsCode: "325199",
      naicsTitle: "All Other Basic Organic Chemical Manufacturing",
    },
    {
      id: "c71bb360-e6f3-44ef-bb30-5413edaf27bd",
      title: "Accelerometers manufacturing",
      naicsCode: "334519",
      naicsTitle: "Other Measuring and Controlling Device Manufacturing",
    },
    {
      id: "c02ae5cb-4805-4a2d-9b70-5df7eeac5706",
      title: "Accelerometers manufacturing",
      naicsCode: "334519",
      naicsTitle: "Other Measuring and Controlling Device Manufacturing",
    },
    {
      id: "d05ef6cf-c4ba-4f22-a6b7-23999fb17e7e",
      title: "Access flooring installation",
      naicsCode: "238330",
      naicsTitle: "Flooring Contractors",
    },
    {
      id: "5503d71c-131b-4263-9dc3-3787979a94ae",
      title: "Access flooring installation",
      naicsCode: "238330",
      naicsTitle: "Flooring Contractors",
    },
    {
      id: "c33e887b-a7e7-41fa-b374-9616b6cac354",
      title: "Accidental and health reinsurance carriers",
      naicsCode: "524130",
      naicsTitle: "Reinsurance Carriers",
    },
    {
      id: "11d10bd4-c570-420f-b92a-8b1eb749920a",
      title: "Accidental and health reinsurance carriers",
      naicsCode: "524130",
      naicsTitle: "Reinsurance Carriers",
    },
    {
      id: "9d535dbe-84d2-46cb-9311-c36603b22313",
      title: "Accident prevention associations",
      naicsCode: "813319",
      naicsTitle: "Other Social Advocacy Organizations",
    },
    {
      id: "8e0e0c1b-1b41-4b9f-b213-dff2f9f9c2c0",
      title: "Accident prevention associations",
      naicsCode: "813319",
      naicsTitle: "Other Social Advocacy Organizations",
    },
    {
      id: "eae95d7e-6c58-4b9c-871a-863c7eed997c",
      title: "Accordions and parts manufacturing",
      naicsCode: "339992",
      naicsTitle: "Musical Instrument Manufacturing",
    },
    {
      id: "bfee702d-b13a-4eeb-8f9d-97b9d2e3685d",
      title: "Accordions and parts manufacturing",
      naicsCode: "339992",
      naicsTitle: "Musical Instrument Manufacturing",
    },
    {
      id: "8df67ef8-8865-4847-be25-d48cd94e0601",
      title: "Accountants' associations",
      naicsCode: "813920",
      naicsTitle: "Professional Organizations",
    },
    {
      id: "3c80357a-9ad4-495e-9d3e-0fcf5a85244f",
      title: "Accountants' associations",
      naicsCode: "813920",
      naicsTitle: "Professional Organizations",
    },
    {
      id: "10b5997b-9a26-40f9-a072-cfb5f1de30c6",
      title: "Accountants' offices",
      naicsCode: "541219",
      naicsTitle: "Other Accounting Services",
    },
    {
      id: "c8b20351-7670-4670-b53f-b79ce00627e3",
      title: "Accountants' offices",
      naicsCode: "541219",
      naicsTitle: "Other Accounting Services",
    },
    {
      id: "6e7c709b-9469-419d-be68-1308ae9856e1",
      title: "Accountants' private practices",
      naicsCode: "541219",
      naicsTitle: "Other Accounting Services",
    },
    {
      id: "2692b75e-0cf5-4c6d-b3c7-c4155590ddd3",
      title: "Accountants' private practices",
      naicsCode: "541219",
      naicsTitle: "Other Accounting Services",
    },
    {
      id: "e24acb87-6610-44aa-9a5b-76eaafe0569d",
      title: "Account collection services",
      naicsCode: "561440",
      naicsTitle: "Collection Agencies",
    },
    {
      id: "b684a575-fecc-4288-a64e-aa53f9e3269e",
      title: "Account collection services",
      naicsCode: "561440",
      naicsTitle: "Collection Agencies",
    },
  ],
  total: 84,
};
